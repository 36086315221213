import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedSubPage } from '../../../redux/reducers/userStateReducer';
import AdminFreebiesSidebar from '../AdminFreebiesSidebar';
import ListedUserCarts from '../features/ListedUserCarts';
import TagsComp from '../TagsComp';
import { getAllUsersAction, searchUsersAction } from '../../../api/admin';
import { toast } from 'react-toastify';
import SidebarTabNavigation from '../features/SidebarTabNavigation';
import LoadingState from '../../loader/LoadingState';

const CartsAdmin = () => {
  const [activeSideTab, setActiveSideTab] = useState('All');
  const [selected, setSelected] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sortedUsersList, setSortedUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchString, setSearchString] = useState('');

  const selectAUser = (userData, id) => {
    setSelected(id);
    setSelectedUser(userData);
  };

  const filterUsersList = (sortBy) => {
    switch (sortBy.toLowerCase()) {
      case 'all':
        setActiveSideTab(sortBy);
        break;

      case 'wish-list':
        setActiveSideTab(sortBy);
        break;

      case 'cart':
        setActiveSideTab(sortBy);
        break;

      default:
        setActiveSideTab('All');
    }
  };

  const getUserProductList = (user, sortBy) => {
    let productList = [];

    switch (sortBy.toLowerCase()) {
      case 'all':
        productList = user?.cart?.merchandise.concat(
          user?.collection?.merchandise
        );
        break;

      case 'wish-list':
        productList = user?.collection?.merchandise;
        break;

      case 'cart':
        productList = user?.cart?.merchandise;
        break;

      default:
        productList = user?.cart?.merchandise.concat(
          user?.collection?.merchandise
        );
    }

    return productList;
  };

  const calculateStoreOrderAmount = (orders) => {
    return orders?.reduce((sum, order) => sum + order.order_amount, 0);
  };

  const searchUsers = async () => {
    setIsLoading(true);

    await dispatch(
      searchUsersAction({
        query: {
          search: searchString,
        },
      })
    )
      .unwrap()
      .then((response) => {
        setSortedUsersList(response);
        setSelectedUser(response[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err?.response) {
          setIsLoading(false);
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getAllUsersAction({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setSortedUsersList(response);
          setSelectedUser(response[0]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  return (
    <div className='admin__users-container'>
      {isLoading ? (
        <div
          style={{
            paddingTop: '5rem',
            height: '5rem',
            width: '100%',
            backgroundColor: '#191921',
          }}
        />
      ) : (
        <AdminFreebiesSidebar
          isStoreOwner={selectedUser?.store ? true : false}
          type='user'
          setActiveSideTab={setActiveSideTab}
          activeSideTab={activeSideTab}
          data={
            selectedUser?.store
              ? {
                  ...selectedUser,
                  store_cover_picture: selectedUser?.store?.store_cover_picture,
                }
              : selectedUser
          }
          actionButtonText='More'
          sideActionOnclick={() => {
            navigate(`/admin/expanded/${selectedUser?.id}`);
            dispatch(setSelectedSubPage({ selected: 'profile' }));
          }}
          sidebarMaininfoIts={[
            {
              leftInfo: 'Total Products',
              rightInfo: selectedUser?.cart?.merchandise?.length ?? 0,
            },

            {
              leftInfo: 'Total Sales',
              rightInfo: selectedUser?.store?.orders?.length ?? 0,
            },

            {
              leftInfo: 'Total Wish-list',
              rightInfo: selectedUser?.collection?.merchandise?.length ?? 0,
              showHeartIcon: true,
            },

            {
              leftInfo: 'Sales',
              rightInfo: calculateStoreOrderAmount(selectedUser?.store?.orders),
              showCartIcon: true,
            },
          ]}
        />
      )}

      <section className='admin__users-list-container'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <SidebarTabNavigation
            isPayoutsPage={false}
            setActiveSideTab={setActiveSideTab}
            activeSideTab={activeSideTab}
            sortFn={filterUsersList}
            itemsList={[
              {
                navigator: 'All',
                showText: 'All',
              },
              {
                navigator: 'WISH-LIST',
                showText: 'WISH-LIST',
              },
              {
                navigator: 'CART',
                showText: 'CART',
              },
            ]}
          />
          <TagsComp
            showFilter={false}
            search={searchString}
            setSearch={setSearchString}
            onSearch={searchUsers}
            searchPlaceHolder={'Search users'}
          />
        </div>

        <section className='admin__users-list-body'>
          {isLoading ? (
            <LoadingState />
          ) : (
            sortedUsersList?.map((user, index) => {
              return (
                <ListedUserCarts
                  isCarts={true}
                  isAffiliated={true}
                  isRequested={user.isRequested}
                  showVerifySign={user.isVerified}
                  styleVerifySign={{
                    position: 'absolute',
                    top: '25%',
                    left: '5%',
                  }}
                  id={index}
                  key={index}
                  selected={index === selected}
                  setSelected={setSelected}
                  selectAUser={() => selectAUser(user, index)}
                  image={user.image}
                  name={user.name}
                  location={user.profile?.country}
                  emails={user.email}
                  phone={user.phone}
                  cartProducts={user?.cart?.products?.length ?? 0}
                  collectionProducts={user?.collection?.merchandise_list ?? 0}
                  productsList={getUserProductList(user, activeSideTab)}
                />
              );
            })
          )}
        </section>
      </section>
    </div>
  );
};

export default CartsAdmin;
