import Cookies from 'js-cookie';
import { FaTimes } from 'react-icons/fa';
import '../styles/buy/buy.css';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CarouselSec from '../component/buy/CarouselSec';
import FilePurchaseSec from '../component/buy/FilePurchaseSec';
import { RegisterContext } from '../component/auth/context/registerContext';
import { useDispatch, useSelector } from 'react-redux';
import { getAMerchandiseByAdminAction } from '../api/admin';
import {
  getAPublicMerchandiseAction,
  updateUnauthenticatedCart,
  updateUserCartAction,
} from '../api/user';
import { checkIsAdministrator } from '../utils/checkIsAdministrator';
import { toast } from 'react-toastify';
import LoadingState from '../component/loader/LoadingState';
import {
  productCartLicense,
  productCartPrice,
  setProductCartLicense,
  setProductCartPrice,
} from '../redux/progressSlice';
import { CartContext } from '../component/auth/context/cartContext';

const BuyPage = ({ toggleShowModal, setFadeOut, id }) => {
  const [descCommToggleMode, setDescCommToggleMode] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  let setShowRegModal = useContext(RegisterContext);
  const [product, setProduct] = useState({});
  const nonSelect = true;

  let user = {};
  if (typeof window !== 'undefined') {
    user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
  }

  const handleMainAppClick = () => {
    setShowRegModal[1]((old) => ({ ...old, loginDrop: false }));
  };

  const { cart, updateCart } = useContext(CartContext);
  const [inCart, setInCart] = useState(false);

  const productCartprice = useSelector(productCartPrice);
  const productLicense = useSelector(productCartLicense);

  useEffect(() => {
    dispatch(setProductCartPrice(product?.merchandise_amount));
    dispatch(setProductCartLicense('basic'));
  }, [product, dispatch]);

  const addToCart = async (item) => {
    const itemAlreadyInCart = cart.find(
      (product) => product.product_id === item.id
    );

    if (itemAlreadyInCart) {
      toast.error('Item already in cart', {
        autoClose: 2000,
        className: 'toast__message',
      });

      return;
    }
    const updatedCartItems = [
      ...cart,
      {
        image:
          item.merchandise_banners?.length > 0
            ? item.merchandise_banners[0]
            : null,
        product_store_name: item.store?.store_name,
        product_store_email: item.store?.store_email || '',
        product_outlet_id: item.store_id,
        quantity: 1,
        product_id: item.id,
        license: productLicense,
        merchandise_name: item.merchandise_name,
        merchandise_amount: +productCartprice,
      },
    ];
    const data = [
      ...cart.map((cartItem) => ({
        product_store_name: cartItem.product_store_name,
        product_outlet_id: cartItem.product_outlet_id,
        product_id: cartItem.product_id,
        license: cartItem.license,
        quantity: cartItem.quantity,
      })),
      {
        product_store_name: item.store?.store_name,
        product_outlet_id: item.store_id,
        product_id: item.id,
        license: productLicense,
        quantity: 1,
      },
    ];
    if (user?.access_token) {
      await dispatch(
        updateUserCartAction({
          updateData: {
            user_id: user?.id,
            products: data,
          },
        })
      )
        .unwrap()
        .then(() => {
          toast.success('Item added to cart', {
            autoClose: 2000,
            className: 'toast__message',
          });
          setInCart(true);
          updateCart(updatedCartItems);
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
              className: 'toast__message',
            });
          }
        });
    } else {
      await dispatch(
        updateUnauthenticatedCart({
          updateData: {
            products: data,
          },
        })
      )
        .unwrap()
        .then(() => {
          toast.success('Item added to cart', {
            autoClose: 2000,
            className: 'toast__message',
          });
          setInCart(true);
          updateCart(updatedCartItems);
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
              className: 'toast__message',
            });
          }
        });
    }
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    if (
      checkIsAdministrator(user?.team_roles ? user?.team_roles : user?.roles)
    ) {
      if (
        checkIsAdministrator(user?.team_roles ? user?.team_roles : user?.roles)
      ) {
        dispatch(getAMerchandiseByAdminAction({ id: id }))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setProduct(response);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log({ err });
            if (err.response) {
              if (err.response.status === 401) navigate('/coming/soon');
              toast.error(err.response.data.message, {
                autoClose: 2000,
                className: 'toast__message',
              });
            }
          });
      }
    } else {
      dispatch(getAPublicMerchandiseAction({ id: id }))
        .unwrap()
        .then((response) => {
          if (isMounted) {
            setProduct(response);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
              className: 'toast__message',
            });
          }
        });
    }

    return () => {
      isMounted = false;
      setProduct([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);
  return (
    <div onClick={handleMainAppClick}>
      {/* <header>
        <NavBar shortSearch={true} cartCount={cartCount} />
      </header> */}

      {isLoading ? (
        <div
          style={{
            width: '80%',
            margin: '2rem auto',
            justifyContent: 'center',
          }}
        >
          <LoadingState />
        </div>
      ) : (
        <div>
          <main>
            <section className='buy__buy-preview-container'>
              <div className='buy__btn-close-back'>
                <button
                  onClick={(e) => {
                    setFadeOut((prev) => !prev);
                    setTimeout(() => {
                      toggleShowModal();
                    }, 200);
                  }}
                  className='buy__page-close-btn'
                >
                  <FaTimes className='buy__page-close-btn-icon' />
                </button>
              </div>
              <div className='buy__buy-preview-container-inner'>
                <CarouselSec product={product} />

                <FilePurchaseSec
                  product={product}
                  nonSelect={nonSelect}
                  descCommToggleMode={descCommToggleMode}
                  setDescCommToggleMode={setDescCommToggleMode}
                  addToCart={addToCart}
                  inCart={inCart}
                />
              </div>
            </section>
          </main>
        </div>
      )}
    </div>
  );
};

export default BuyPage;
