import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import LandCard from '../card/landCard';
import { toast } from 'react-toastify';
import {
  approveAMerchandiseByStore,
  fetchAStore,
  fetchCollections,
  fetchMerchandise,
  searchMyMerchandiseAction,
} from '../../api/auth';
import { SearchInput } from '../footer/footer';
import { MdShare } from 'react-icons/md';
import LoadingState from '../loader/LoadingState';
import { useMediaQuery } from 'react-responsive';
import { NavBarDropDown } from '../navbar/NavBarDropDown';
import NavBarDropdownItem from '../navbar/NavBarDropdownItem ';
import {
  getAPublicMerchandiseAction,
  getAllCategoriesAction,
} from '../../api/user';
import { filterOptions, sortOptions } from '../navbar/navbar';
import { SocialShare } from './SocialShare';
import Cookies from 'js-cookie';
import { calculateProductRating } from '../../utils/calculateProductRating';
import { countProductComments } from '../../utils/countProductComments';
import Details from '../modals/productdetails/Details';
import { getStoreId } from '../../utils/getUserTeamRoleKey';

const Store = () => {
  const dispatch = useDispatch();
  const [collections, setCollections] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 768 });
  const [activeFilter, setActiveFilter] = useState('');
  const [categories, setCategories] = useState([]);
  const [
    showStoreProductsFilterContainer,
    setShowStoreProductsFilterContainer,
  ] = useState(false);
  const [showShareToSMBox, setShowShareToSMBox] = useState(false);
  const [products, setProducts] = useState([]);
  const [sortedProducts, setSortedProducts] = useState(products);
  const [searchString, setSearchString] = useState('');
  const [productsHeaderNav, setProductsHeaderNav] = useState('recent');

  let user = {};

  if (typeof window !== 'undefined' && Cookies.get('user')) {
    try {
      user = JSON.parse(Cookies.get('user'));
    } catch (error) {
      // Handle the error, e.g., the cookie contains invalid JSON
      console.error("Error parsing 'user' cookie:", error);
    }
  }
  const storeId = getStoreId(user?.groups);
  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    if (storeId) {
      dispatch(fetchAStore({ storeId: storeId }))
        .unwrap()
        .then((response) => {
          if (isMounted) {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message);
          }
        });
    }

    return () => {
      isMounted = false;
      setIsLoading(false);
    };
  }, [dispatch, storeId]);
  useEffect(() => {
    setIsLoading(true);

    dispatch(fetchMerchandise({}))
      .unwrap()
      .then(async (response) => {
        if (response) {
          setProducts(response);
          console.log(response, typeof response);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err?.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  }, [dispatch, collections]);

  useEffect(() => {
    function sortProducts(sortBy) {
      switch (sortBy) {
        case 'featured':
          setSortedProducts(
            (products || []).filter((product) => product.featured)
          );
          break;

        case 'recent':
          setSortedProducts(
            (products || [])
              .slice()
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          );
          break;

        case 'best sellers':
          setSortedProducts(
            (products || []).slice().sort((a, b) => b.sold - a.sold)
          );
          break;

        case 'drafts':
          setSortedProducts(
            (products || []).filter(
              (product) => product.visibility === 'private'
            )
          );
          break;

        default:
          setSortedProducts(
            (products || [])
              .slice()
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          );
      }
    }

    sortProducts(productsHeaderNav);
  }, [productsHeaderNav, products]);

  const searchProducts = async () => {
    setIsLoading(true);

    await dispatch(
      searchMyMerchandiseAction({
        query: {
          search: searchString,
        },
      })
    )
      .unwrap()
      .then((response) => {
        setSortedProducts(response);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err?.response) {
          setIsLoading(false);
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  };

  const hostUrl = process.env.REACT_APP_WEB_URL;

  const clickMobileActiveFilter = (filterTitle) => {
    if (activeFilter.toLowerCase() === filterTitle.toLowerCase()) {
      setActiveFilter('');
      setShowStoreProductsFilterContainer(true);
    } else {
      setActiveFilter(filterTitle);
      setShowStoreProductsFilterContainer(true);
    }
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(approveAMerchandiseByStore({ approveData: { id: 1 } }));

    dispatch(getAllCategoriesAction({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setCategories(response);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(fetchCollections({}))
      .unwrap()
      .then((response) => {
        setCollections(response.wish_list_merchandise);
        if (isMounted) setIsLoading(false);
      })
      .catch((err) => {
        isMounted && setIsLoading(false);
        if (err.response) {
          toast.error(err.response.data.message);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);
  const [detailView, showDetailView] = useState(false);
  const [viewedProduct, setViewedProduct] = useState(null);
  const fetchAProduct = (id) => {
    dispatch(getAPublicMerchandiseAction({ id: id }))
      .unwrap()
      .then((response) => {
        setViewedProduct(response);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  };
  return (
    <section
      className='user__store-freebies-container'
      style={{
        paddingTop: isMobile ? '0.5rem' : '4rem',
      }}
      onClick={() => setShowShareToSMBox(false)}
    >
      {!detailView && (
        <>
          {showShareToSMBox && (
            <SocialShare url={`${hostUrl}/store/${storeId}`} />
          )}

          <div>
            {!isMobile && (
              <div
                style={{ display: 'flex', flexDirection: 'row' }}
                className='user__store-products-header'
              >
                <div className='user__store-products-header-nav'>
                  <div
                    onClick={() => setProductsHeaderNav(() => 'featured')}
                    className={`user__sales-sidebar-header-item ${
                      productsHeaderNav === 'featured' &&
                      'user__sales-shi-selected'
                    }`}
                  >
                    FEATURED
                  </div>

                  <div
                    onClick={() => setProductsHeaderNav(() => 'recent')}
                    className={`user__sales-sidebar-header-item ${
                      productsHeaderNav === 'recent' &&
                      'user__sales-shi-selected'
                    }`}
                  >
                    RECENT
                  </div>

                  <div
                    onClick={() => setProductsHeaderNav(() => 'best sellers')}
                    className={`user__sales-sidebar-header-item ${
                      productsHeaderNav === 'best sellers' &&
                      'user__sales-shi-selected'
                    }`}
                  >
                    BEST SELLERS
                  </div>

                  <div
                    onClick={() => setProductsHeaderNav(() => 'drafts')}
                    className={`user__sales-sidebar-header-item ${
                      productsHeaderNav === 'drafts' &&
                      'user__sales-shi-selected'
                    }`}
                  >
                    IN REVIEW
                  </div>
                </div>

                <SearchInput
                  style={{}}
                  btnStyle={{
                    padding: '.1rem 2rem',
                    height: '90%',
                    right: '1%',
                  }}
                  controlStyle={{ padding: '.4rem 8rem' }}
                  searchString={searchString}
                  setSearchString={setSearchString}
                  onSearch={searchProducts}
                />

                <div
                  className='user__store-share-store-link-box'
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowShareToSMBox(!showShareToSMBox);
                  }}
                >
                  <div className='user__store-share-link-btn'>
                    <MdShare />
                  </div>
                  <div className='user__store-share-link-text'>
                    <span className='user__store_share-link-span'>
                      Share Store
                    </span>
                  </div>
                </div>
              </div>
            )}

            {isMobile && (
              <div
                className='landing__navbar-mobile-filter-box'
                style={{ display: 'flex' }}
              >
                <NavBarDropDown
                  title={'CATEGORY'}
                  isActive={activeFilter === 'category'}
                  onClick={() => clickMobileActiveFilter('category')}
                />
                <NavBarDropDown
                  title={'SORT'}
                  isActive={activeFilter === 'sort'}
                  onClick={() => clickMobileActiveFilter('sort')}
                />
                <NavBarDropDown
                  title={'FILTER'}
                  isActive={activeFilter === 'filter'}
                  onClick={() => clickMobileActiveFilter('filter')}
                />
              </div>
            )}

            {showStoreProductsFilterContainer && (
              <div className='nav_mobile_filter-options-container'>
                {activeFilter.toLocaleLowerCase() === 'category'
                  ? categories?.map((item) => (
                      <NavBarDropdownItem
                        option={item}
                        key={item.name}
                        onClick={() => {
                          setShowStoreProductsFilterContainer(false);
                        }}
                      />
                    ))
                  : activeFilter.toLocaleLowerCase() === 'sort'
                  ? sortOptions?.map((item) => (
                      <NavBarDropdownItem
                        option={item}
                        key={item.name}
                        onClick={() => {
                          setShowStoreProductsFilterContainer(false);
                        }}
                      />
                    ))
                  : activeFilter.toLocaleLowerCase() === 'filter'
                  ? filterOptions?.map((item) => (
                      <NavBarDropdownItem
                        option={item}
                        key={item.name}
                        onClick={() => {
                          setShowStoreProductsFilterContainer(false);
                        }}
                      />
                    ))
                  : null}
              </div>
            )}
          </div>
        </>
      )}

      {isLoading ? (
        <LoadingState />
      ) : detailView ? (
        <div className='product'>
          <h2
            onClick={() => {
              showDetailView(false);
              setViewedProduct(null);
            }}
            style={{
              marginLeft: 'auto',
              width: 'fit-content',
              transform: 'skewX(-25deg)',
              backgroundColor: 'rgba(56, 55, 74, 0.6)',
              padding: '0.5rem 1rem',
            }}
          >
            X
          </h2>
          <Details product={viewedProduct} mgtAction={false} />
        </div>
      ) : (
        <div
          className='user__store-freebies-inner-container'
          style={{ marginTop: '2%' }}
        >
          {sortedProducts && sortedProducts.length > 0 ? (
            sortedProducts.map((prod, index) => (
              <LandCard
                id={prod.id}
                key={prod.merchandise_name + index}
                onClick={() => {
                  fetchAProduct(prod.id);
                  showDetailView(true);
                }}
                img={
                  prod.merchandise_banners?.length > 0
                    ? prod.merchandise_banners[0]
                    : null
                }
                title={prod.merchandise_name}
                ratings={calculateProductRating(prod.ratings)}
                owner={user?.name}
                numOfComments={countProductComments(prod.ratings)}
                numReviewers={prod.ratings?.length}
                price={prod.merchandise_amount}
                firstTag={prod.merchandise_tags ? prod.merchandise_tags[0] : ''}
                firstCate={
                  prod.merchandise_categories
                    ? prod.merchandise_categories[0]
                    : ''
                }
                // navigateToStore={() => navigate(`store/${prod.store_id}`)}
                productId={prod.id}
                visibility={prod.visibility}
                wishlistIds={collections}
                setCollections={setCollections}
                product={prod}
                showDetails={true}
              />
            ))
          ) : (
            <div className='user__no-product-div'>
              Hmmm... No Products? Try Reloading 
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default Store;
