export const NavBarDropDown = ({containerStyle, isActive, title, onClick}) => {
    return (
        <div className="nav_filter-dropdown" style={containerStyle} onClick={onClick}>
            <div className="navbar__dropdown-filter-box" style={{
                color: isActive ? '#FF9700' : '#6D6D91'
            }}>
                {title}
            </div>
        </div>
    );
}
