import { useDispatch } from 'react-redux';
import LoadingState from '../component/loader/LoadingState';
import { useEffect, useState } from 'react';
import SearchBar from '../component/search/SearchBar';
import { searchPublicMerchandiseAction } from '../api/auth';
import {
  filterAllPublicMerchandiseAction,
  getAllCategoriesAction,
  getAllPublicMerchandiseAction,
} from '../api/user';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import NavBar from '../component/navbar/navbar';
import Slogan from '../component/landing/slogan';
import LandCard from '../component/card/landCard';
import { calculateProductRating } from '../utils/calculateProductRating';
import { countProductComments } from '../utils/countProductComments';
import BuyPageModal from '../component/modals/buypage/BuyPageModal';

const ResultsPage = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [navSearchString, setNavSearchString] = useState('');
  const [products, setProducts] = useState([]);
  const [sortedProducts, setSortedProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const { query } = useParams();
  const [yScroll, setYScroll] = useState(0);
  const navigate = useNavigate();

  const searchPublicProducts = async () => {
    setIsLoading(true);

    await dispatch(
      searchPublicMerchandiseAction({
        query: {
          search: searchString ?? navSearchString,
        },
      })
    )
      .unwrap()
      .then((response) => {
        setSortedProducts(response);
        setIsLoading(false);
        setSearchString('');
        setNavSearchString('');
      })
      .catch((err) => {
        if (err?.response) {
          setIsLoading(false);
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  };

  const searchPublicProductsFromNav = async () => {
    setIsLoading(true);

    await dispatch(
      searchPublicMerchandiseAction({
        query: {
          search: navSearchString,
        },
      })
    )
      .unwrap()
      .then((response) => {
        setSortedProducts(response);
        setIsLoading(false);
        setSearchString('');
        setNavSearchString('');
      })
      .catch((err) => {
        if (err?.response) {
          setIsLoading(false);
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  };

  const handleScroll = (event) => {
    setYScroll(window.scrollY);
  };

  const sortProducts = (sortBy) => {
    setIsLoading(true);
    let products = [];

    switch (sortBy.toLowerCase()) {
      case 'best selling':
        products = sortedProducts?.sort((a, b) => b.sold - a.sold);
        break;

      case 'newest':
        products = sortedProducts;
        break;

      case 'oldest':
        products = sortedProducts?.sort((a, b) => b.created_at - a.created_at);
        break;

      case 'rating':
        products = sortedProducts.sort(
          (a, b) =>
            calculateProductRating(b.ratings) -
            calculateProductRating(a.ratings)
        );
        break;

      case 'lower price':
        products = sortedProducts.sort(
          (a, b) => a.merchandise_amount - b.merchandise_amount
        );
        break;

      case 'higher price':
        products = sortedProducts.sort(
          (a, b) => b.merchandise_amount - a.merchandise_amount
        );
        break;

      case 'onsale':
        products = sortedProducts.filter((item) => item.featured === true);
        break;

      default:
        products = sortedProducts;
        break;
    }
    setIsLoading(false);
    setSortedProducts(products);
  };

  const getProductsByCategory = (category) => {
    let products = [];
    products = sortedProducts.filter((product) => {
      if (typeof product.merchandise_categories === 'object') {
        return product.merchandise_categories.includes(category);
      } else {
        return product.merchandise_categories === category;
      }
    });
    setSortedProducts(products);
  };

  const filterProducts = async (filterBy, filterValue) => {
    setIsLoading(true);

    await dispatch(
      filterAllPublicMerchandiseAction({
        filterData: {
          [filterBy.toLowerCase()]: filterValue,
        },
      })
    )
      .unwrap()
      .then((response) => {
        setSortedProducts(response);
        toast.success('success', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message ?? 'Error try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
      });
  };

  const clearFilter = () => {
    setSortedProducts(products);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) window.addEventListener('scroll', (e) => handleScroll(e));

    return () => {
      window.removeEventListener('scroll', (e) => handleScroll(e));
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    if (query !== 'all') {
      dispatch(
        searchPublicMerchandiseAction({
          query: {
            search: query,
          },
        })
      )
        .unwrap()
        .then((response) => {
          setProducts(response);
          setSortedProducts(response);
          setIsLoading(false);
          setSearchString('');
          setNavSearchString('');
        })
        .catch((err) => {
          if (err?.response) {
            setIsLoading(false);
            toast.error(err.response.data.message, {
              autoClose: 2000,
              className: 'toast__message',
            });
          }
        });
    } else if (query === 'filter') {
      dispatch(
        filterAllPublicMerchandiseAction({
          filterData: {
            merchandise_categories: [query],
          },
        })
      )
        .unwrap()
        .then((response) => {
          setProducts(response);
          setSortedProducts(response);
          setIsLoading(false);
        })
        .catch((err) => {
          if (err?.response) {
            setIsLoading(false);
            toast.error(err.response.data.message, {
              autoClose: 2000,
              className: 'toast__message',
            });
          }
        });
    } else {
      dispatch(getAllPublicMerchandiseAction({ page: 1 }))
        .unwrap()
        .then((response) => {
          if (isMounted) {
            setProducts(response);
            setSortedProducts(response);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
              className: 'toast__message',
            });
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [dispatch, query]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getAllCategoriesAction({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setCategories(response);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);
  const [productId, setProductId] = useState(null);
  const [buyPagefadeOut, setBuyPageFadeOut] = useState(false);
  const [buyPageModal, setBuyPageModal] = useState(null);
  return (
    <section className='landing__main-app'>
      {isLoading ? (
        <div
          style={{
            width: '80%',
            margin: '2rem auto',
            justifyContent: 'center',
          }}
        >
          <LoadingState />
        </div>
      ) : (
        <div className='landing__app-container results-container'>
          <header>
            <NavBar
              pageType={'home'}
              shortSearch={yScroll > 470}
              style={{ paddingBottom: '0' }}
              search={yScroll > 150}
              categoriesArr={categories}
              isHome={true}
              searchString={navSearchString}
              setSearchString={setNavSearchString}
              onSearch={searchPublicProductsFromNav}
              sortProductsFN={sortProducts}
              filterProductsFN={filterProducts}
              categorizeProducts={getProductsByCategory}
              clearFilter={clearFilter}
            />
            <Slogan />
          </header>

          <main className='landing__main'>
            <section className='landing__search-section'>
              <SearchBar
                searchString={searchString}
                setSearchString={setSearchString}
                onSearch={searchPublicProducts}
              />
            </section>

            <section className='landing__all-products-container'>
              <div className='landing__all-products-inner-container'>
                {sortedProducts && sortedProducts?.length > 0 ? (
                  sortedProducts?.map((prod, index) => {
                    return (
                      <LandCard
                        key={prod.merchandise_name + index}
                        onClick={() => {
                          setBuyPageModal(!buyPageModal);
                          setProductId(prod.id);
                        }}
                        img={
                          prod.merchandise_banners?.length > 0
                            ? prod.merchandise_banners[0]
                            : null
                        }
                        title={prod.merchandise_name}
                        ratings={calculateProductRating(prod.ratings)}
                        owner={prod.store?.store_name}
                        numOfComments={countProductComments(prod.ratings)}
                        numReviewers={prod.ratings?.length}
                        price={prod?.merchandise_amount}
                        firstCate={
                          prod.merchandise_categories
                            ? prod.merchandise_categories[0]
                            : ''
                        }
                        navigateToStore={() =>
                          navigate(`/store/${prod.store_id}`)
                        }
                        productId={prod.id}
                      />
                    );
                  })
                ) : (
                  <div
                    className='user__no-product-div'
                    style={{ alignContent: 'center' }}
                  >
                    Nothing to see here!
                  </div>
                )}
              </div>
              <div
                style={{
                  color: 'white',
                  fontWeight: 600,
                  padding: '2rem',
                  fontSize: '1.2rem',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <p>
                  Showing {sortedProducts?.length || 0} of{' '}
                  {products?.length || 0} Products.
                </p>
              </div>
            </section>
          </main>
        </div>
      )}

      {buyPageModal && (
        <BuyPageModal
          toggleShowModal={() => setBuyPageModal(!buyPageModal)}
          fadeOut={buyPagefadeOut}
          setFadeOut={setBuyPageFadeOut}
          id={productId}
        />
      )}
    </section>
  );
};

export default ResultsPage;
