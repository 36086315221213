import NavBar from "../component/navbar/navbar";

export const Creator = () => {
    return (
        <div>
            <header>
                <NavBar isComingSoon={true}/>
            </header>
            <main>
                <section className="privacy_policy_container">
                    <h1 className="privacy_policy-title">CREATOR AGREEMENT</h1>
                    
                    <div className="privacy_policy-body">
                        <span className="privacy_policy-section-title">
                        Introduction
                        </span>

                        <div className="privacy_policy-paragraph">
                        This Creator Onboarding Terms and Conditions (“the Agreement”) is between you (“the Creator”) and Loooty Limited (“Loooty”, “we”, “us”), in connection with the Loooty Marketplace.  In this Agreement, the Creator and Loooty are collectively referred to as “Parties” and individually as a “Party”.
                        </div>

                        <div className="privacy_policy-paragraph">
                        Loooty is an Afro-centric Art Asset marketplace (the Platform) owned and managed by Loooty Digital Marketplace Limited (Loooty) 
                        that enables creators to display and sell their 2D and 3D digital artwork and other game wares, while also providing buyers with a diverse selection of high-quality art pieces in various styles and formats. The Platform agrees to provide the Artists with a platform to showcase, promote, sell, and distribute their 2D and 3D artworks to the users of the Platform, 
                        subject to the terms and conditions set forth in this Agreement.
                        </div>

                        <div className="privacy_policy-paragraph">
                        The Creator is any person (natural or artificial), duly registered on the Loooty Platform to display and sell their Artworks to the Users on the Platform.
                        </div>

                        <div className="privacy_policy-paragraph">
                        This Agreement contains the terms and conditions that apply to you and regulate your access to and use of the Platform as a Creator. By registering as a Creator on the Platform, you agree to be bound by the terms of this Agreement and accept the Terms and Conditions and other Onboarding Documents as may be amended from time to time which forms an integral part of the terms upon which you may use the Marketplace. Where you represent a legal entity or company, you agree that you have the requisite authority to enter into this Agreement 
                        and that this Agreement shall apply to both you and the legal entity which you represent
                        </div>

                        <div className="privacy_policy-paragraph">
                        You also represent that, by entering into this Agreement, you are of at least 18 years of age or must have attained the age of majority in your jurisdiction. 
                        If you do not meet this requirement, please do not proceed with the onboarding.
                        </div>

                        <div className="privacy_policy-paragraph">
                        It is important that you read and understand the terms of this Agreement before agreeing to them.
                        </div>

                        <span className="privacy_policy-section-title">
                        Definitions & Interpretations
                        </span>

                        <div className="privacy_policy-paragraph">
                        In this Agreement, the following words shall have the meanings ascribed to them below unless the context clearly indicates the contrary:
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">"Agreement"</span>
                            means this Creator Onboarding Terms and Conditions together with all amendments and schedules attached hereto, and any document incorporated into this Agreement by reference. However, in the event that there is a conflict between the terms of this Agreement and the term of any document incorporated by reference; this Agreement shall prevail.  
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">“Assets”</span>
                            includes 2D Assets, UI Assets, Game UI Assets, 3D Assets, Game-ready, VR, AR, Plugins, Tools and Sound.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">“Confidential Information”</span>
                            means all information or data (whether provided orally, visually, in writing or in any other medium or by any other method) which is directly or indirectly disclosed at any time or made available to the Creator during the duration of this Agreement;
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">“Creator”</span>
                            Refers to the individual or entity who has produced, designed, or developed the Artwork available on the Loooty Marketplace and offers to grant a Licence of the Asset to a Purchaser by way of license. The Creator of Artwork holds the intellectual property rights, including copyrights, in the original artwork.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">“Creator Onboarding Form”</span>
                            means the Form accessible on the Platform Sign-up page to a prospective Creator which must be completed by the prospective Creator as part of the application and registration process;
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">“Intellectual property” </span>
                            means all patents, rights to inventions, copyrights and any related rights, design rights, trademarks, business names and domain names, rights in get up, goodwill and right to sue for passing off, database rights, rights to use, and protect the confidentiality of, confidential information, and all other intellectual property rights, whether registered or unregistered, including all applications and rights to apply for and be granted, renewals or extensions of, and rights to claim property from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the future exercisable in any part of the world, which subsist or will subsist now or in the future
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">“Users”</span>
                            means all registered users of the Platform who place orders for Artwork on the Platform or interact on the Platform;	
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">“Platform”</span>
                            means the Loooty website and mobile application -together with the payment infrastructure. 
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">“Marketing Content”</span>
                            means any content put up by a Creator that goes beyond describing the Services, and its functions or purpose; content intended to persuade the Users to order Assets on the Platform;
                        </div>

                        <span className="privacy_policy-section-title">
                        Commencement And Duration 
                        </span>
                        <div className="privacy_policy-paragraph">
                            This Agreement shall commence after:

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                The Creator has set up its account by signing up and completing the Creator Onboarding Form on the Platform and is notified of its acceptance by Loooty.
                            </div>

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                For the avoidance of doubt, the Commencement Date shall be the day Loooty notifies the Creator of the approval of its application.
                            </div>
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            This Agreement shall remain in full force and effect from the Commencement Date until it is terminated in accordance with Clause 11 of this Agreement or by operation of law. 
                        </div>

                        <span className="privacy_policy-section-title">
                        Account Setup And Artwork Upload
                        </span>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Creator shall set up an account on the Platform and provide accurate and up-to-date information, including contact details, links to their portfolio, samples of products to be sold on the platform and all other information that may be required by Loooty from time to time.
                        </div>
                        
                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Creators shall be solely responsible for maintaining the confidentiality of their account credentials and ensuring the security of their account.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Creators shall upload their Atworks on the Platform according to the provided guidelines and specifications. The Creators represent and warrant that they have the necessary rights and permissions to upload, display, and sell their artworks on the Platform.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Creators shall ensure that their uploaded Artworks do not infringe upon any intellectual property rights, including copyright, trademarks, or patents, of third parties. 
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Creators shall promptly respond to any inquiries, requests, or issues related to their artworks, sales, or customer support on the Platform.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Platform reserves the right to review, approve, and determine the suitability of the uploaded artworks for display on the Platform. The Platform may, at its discretion, reject or remove any artwork that violates the Platform's content guidelines or infringes upon the rights of third parties.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            Loooty may refuse, in its sole discretion, to allow Creators to offer an Asset for sale on the Marketplace, and is not required to give reasons for its decision
                        </div>

                        <span className="privacy_policy-section-title">
                        Payments
                        </span>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Creators shall set the prices for their Artworks, including any applicable taxes or fees, in accordance with the pricing policies of the Platform.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            By uploading any Assets to the Platform, the Creator acknowledges and accepts that 10% of the Purchase Price of each Asset received by Loooty shall be retained by Loooty by way of commission.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Platform shall facilitate the payment process on behalf of the Creators, including processing payments (through third-party payment gateway companies) from Purchasers, deducting any applicable fees or commissions, and remitting the remaining proceeds to the Creators.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Creators acknowledge and agree that Loooty may charge a commission or transaction fee on each sale made through the Platform, as outlined in the Platform's fee structure.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            Loooty shall provide a wallet for each creator upon registration. The wallet shall be a depository of all payments received by Loooty on behalf of the Creator and shall be accessible to the Creator at all times. Loooty shall enable cash out into the Creators wallet two times a month (ie, the first and third Monday of every month).
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            A Creator who wishes to cash out their payment for the month must make a request within the approved windows and such request shall be processed within three (3) business days of request.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            To receive payment, Creators must have accumulated a minimum threshold of $20 within a month, failure of which the Creator cannot withdraw the available balance until the minimum threshold is reached. 
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            In the event that a third-party payment platform is offline or down for maintenance, Creators who have requested payment through that platform shall be notified of this and we shall process all payments as soon as the Platform is back online. 
                        </div>
                        
                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            Any and all transaction fees due in connection with a Withdrawal (including, but not limited to, third-party fees) shall be payable by the Creators. In the event that Loooty is required to pay any such fees on behalf of the Creators, these shall be deducted by Loooty from the withdrawal payment made to the Creator.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            To increase sales, Loooty has provided the option of Affiliate Marketing to Creators. Where a Creator activates the Affiliate Marketing option, he will be required to pay a commission. Please see the Affiliate Marketing Agreement (AMA).
                        </div>

                        <span className="privacy_policy-section-title">
                        Store Verification
                        </span>

                        <div className="privacy_policy-paragraph">
                        The Platform makes provision for store verification. Store verification increases user engagement with the store and boosts the Stores to the front of the line for featured products, Blogs and Forum Posts in future.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            To get verified, Stores must meet the following requirements-
                        

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                Attain a gross revenue of $20 sum monthly
                            </div>

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                Have good activity on their store and a net positive customer reviews and ratings (on average, customers enjoyed the products and support they got from the store) 
                            </div>

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                Have a fast average response time to Purchasers' messages
                            </div>

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                Must be a store on Loooty for 6 months
                            </div>

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                Must have withdrawn their revenue from the Loooty platform at least once
                            </div>

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                Must have no pending reports on their products or a clean report record
                            </div>
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            Stores that meet these criteria can then apply for verification, they do not need to fill out any form or provide extra information. 
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            Stores may lose their verification status if
                       

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                They are not logged in for  6 months
                            </div>

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                We get several problematic reports from users that we confirm to be true, such as stealing intellectual property, etc.
                            </div>

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                There is a net negative customer reviews that indicates a consistent drop in the quality of assets. Stores can veto this by contacting Loooty admin, in the case of an inflow of ‘fake reviews’ on the part of users just to bring down the store’s overall rating. Where this is found to be the case, Loooty will restore their verification status and take appropriate legal action
                            </div>
                        </div>

                        <span className="privacy_policy-section-title">Warranties</span>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            It is the sole legal and exclusive owner (or has duly obtained the license from the original owner for this use) of all intellectual property rights in each Asset
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Asset does not infringe the rights of any third party;
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Asset does not contain viruses or other computer codes, files or programs which are designed to limit or destroy the functionality of other computer software or hardware;
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Asset shall not create liability for Loooty or cause Loooty to lose (in whole or in part) the services of its Internet Service Providers (ISP) or other suppliers;
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Asset shall not cause Loooty to violate any applicable law, statute, ordinance or regulation by making it available on the Marketplace; and
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Asset complies with the Content Standards set out in our Terms of Use.
                        </div>

                        <span className="privacy_policy-section-title">
                            Relationship Between Parties 
                        </span>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Creators hereby acknowledge and agree that Loooty only provides the Platform for the sale of digital assets by connecting Users with Creators
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Creators acknowledge that nothing contained in this Agreement or other Onboarding Documents is intended to:

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                create a partnership, joint venture, employment, or any legal relationship of any kind between Loooty and the Creators that would impose liability upon Loooty for the act or failure to act of the Creator, its sub-contractors and other agents; or
                            </div>

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                authorize the Creator to act as an agent of Loooty; or
                            </div>
                        </div>

                        <div className="privacy_policy-sub-section">
                            <span className="privacy_policy-bullet">.</span>
                            entitle the Creator to any employment or terminal benefits.
                        </div>

                        <span className="privacy_policy-section-title">
                        Intellectual Property Rights (IPR)
                        </span>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Creators retain all intellectual property rights in their Artworks. By uploading their artworks onto the Platform, the Creators grant the Platform a non-exclusive, worldwide, royalty-free license to display, promote, and distribute the artworks solely for the purposes of operating and promoting the Platform.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Creator recognizes that the IPR in Loooty’s name, logo or branding or trademark is owned entirely by Loooty, and agrees that it shall not use any of these IP rights except with the prior written consent of Loooty;
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            All IPR on the website and Platform and in its Software and/or Documentation are and will remain the absolute property of Loooty or its licensors as appropriate;
                        </div>
                        
                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            Loooty hereby grants to the Creator a non-exclusive, non-transferable revocable and limited license for the duration of this Agreement only, to use the Platform for the sole purpose of accessing and sale of its digital assets on the Platform. Subject thereto, nothing in the Agreement shall be deemed to have given the Creator a license or any other right to use any of Loooty’s IPR.
                        </div>

                        <span className="privacy_policy-section-title">
                        Suspension Of Creator’s Access And Account 
                        </span>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Creator acknowledges and agrees that if Loooty determines, in its absolute discretion, or is notified by a third party, that an Asset does not comply with any applicable laws and/or Our Terms of Use, Loooty may disable the Creator's account and withhold all amounts that the Creator may have earned from the sale of Licences in respect of the relevant Asset until any dispute in relation to the Asset has been resolved to the satisfaction of Loooty. 
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The Creator acknowledges and agrees that Loooty may, at its sole discretion, elect at any time and for any reason to remove Assets from the Marketplace without notice to the Creator. Loooty shall not be responsible for any loss that the Creator may suffer as a result of an Asset being removed from the Marketplace by Loooty.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            Loooty reserves the right to:
                        

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                Suspend the Creator’s access to the Platform at any time it considers that there is or is likely to be a breach of security, in which event.
                            </div>

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                Suspend the Creator’s account upon the receipt of several unsatisfactory reviews or complaints from User(s) which we confirm to be accurate. In this case, Loooty may at its discretion investigate the basis of such unsatisfactory reports before the suspension of the Creator’s account; and
                            </div>

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                Reactivate the Creator’s account at its discretion upon the fulfilment of specified conditions by Loooty.
                            </div>
                        </div>

                        <span className="privacy_policy-section-title">
                        Termination
                        </span>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            Either Party shall be entitled to terminate this Agreement at any time by giving to the other Party a month’s prior written notice. Such notice shall be communicated via email. 
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            Notwithstanding the foregoing clause, Loooty reserves the right to terminate this Agreement at any time and restrict the Creator’s access to the Platform without liability to Loooty if:
                       

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                The Creator commits a breach of this Agreement (including a breach of any of the Policies) and if capable of remedy, fails to remedy the breach within seven (7) days of a notice to do so;
                            </div>

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                The Creator is in breach of the terms and conditions of the third-party payment service provider;
                            </div>

                            <div className="privacy_policy-sub-section">
                                <span className="privacy_policy-bullet">.</span>
                                Loooty at its sole discretion considers the Creator ineligible or unsuitable to be listed on its Platform; or
                            </div>
                        </div>
                        
                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            In the event of termination, the Creator shall have the option to remove their assets from the Platform, and the Platform shall cease promoting and distributing the Creators' artworks within a reasonable timeframe.
                        </div>
                        
                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            The termination of this Agreement shall not discharge the liabilities of the defaulting Party that are due at the time of the termination, including all payment obligations of either Party under this Agreement.
                        </div>

                        <span className="privacy_policy-section-title">
                        Dispute Resolution
                        </span>

                        <div className="privacy_policy-paragraph">
                        This Terms of Use and any disputes arising from or in connection with it shall be governed by and construed in accordance with the laws of Nigeria. Any dispute, controversy, or claim arising out of or relating to this Terms of Use or the use of this digital marketplace shall first be resolved amicably through good-faith negotiations. Where Parties are unable to resolve their dispute through negotiation, either Party may approach a court of competent jurisdiction for the resolution of the dispute.
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}
