import { RiStarSFill } from 'react-icons/ri';

const WolfGuyImg = '/assets/image/loootydefault.jpg';

const Comment = ({
  comment = 'Hey there am commenting',
  author = 'Leo - Art Joourney',
  info,
}) => {
  return (
    <div className='buy__comm-container'>
      <div className='buy__comm-first-layer'>
        <div className='buy__author-field'>
          <img
            className='buy__user-profile-img'
            src={info?.user?.picture ?? WolfGuyImg}
            alt='user profile'
          />
          {author}
        </div>

        <button className='buy__customer-tag'>
          <span style={{ transform: 'skewX(20deg)' }}>Customers</span>
        </button>
        <div className='buy__rating-container'>
          {[
            ...Array(info.score).map(() => (
              <RiStarSFill style={{ color: '#747482' }} />
            )),
          ]}
        </div>

        <div className='buy__comment-date'>
          {new Date(info.created_at).toDateString()}
        </div>

        {/* <MdOutlineMoreVert style={{cursor: "pointer", color: "#6E6E7C"}}/> */}
      </div>

      <div className='buy__comm-second-layer'>
        <div className='buy__main-comm'>{comment}</div>
      </div>

      <div className='buy__comm-third-layer'>
        <div className='buy__reactions-container'>
          {/* <div className="buy__like-cont">
                       <AiFillLike /> {info.score}
                    </div> */}

          {/* <span className="buy__comment-reply"> reply </span> */}
        </div>
      </div>
    </div>
  );
};

export default Comment;
