import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  fetchUserSubscriptionsAction,
  unSubscribeToStoreAction,
} from '../../api/auth';
import SuscribersCard from './SuscribersCard';
import LoadingState from '../loader/LoadingState';

const Suscribers = () => {
  const dispatch = useDispatch();
  const [mySubscriptions, setMySubscriptions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const unSubscribeFromStore = async (subscription) => {
    setIsLoading(true);
    await dispatch(
      unSubscribeToStoreAction({
        storeInfo: {
          store_id: subscription?.id,
        },
      })
    )
      .unwrap()
      .then(async () => {
        setIsLoading(false);
        let subscriptions = mySubscriptions;
        console.log({ subscriptions });
        subscriptions = subscriptions?.filter(
          (item) => item.id !== subscription?.id
        );
        console.log({ subscriptions });
        setMySubscriptions(subscriptions);
        toast.success('Unsubscribed successfully', {
          autoClose: 2000,
          className: 'toast__message',
        });
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(fetchUserSubscriptionsAction({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setMySubscriptions(response?.subscriptions);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  return (
    <section className='users__suscribers-container'>
      {isLoading ? (
        <LoadingState />
      ) : (
        <div className='users__suscribers-container-inner'>
          {mySubscriptions && mySubscriptions?.length > 0 ? (
            mySubscriptions.map((subscription) => (
              <SuscribersCard
                subscription={subscription}
                unSubscribeFromStore={() => unSubscribeFromStore(subscription)}
              />
            ))
          ) : (
            <div
              className='user__no-product-div'
              style={{
                justifySelf: 'center',
                alignSelf: 'center',
              }}
            >
              You are not subscribed to any store!
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default Suscribers;
