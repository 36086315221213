import { useCallback, useEffect, useState } from "react";
import AdminFreebiesSidebar from "../AdminFreebiesSidebar";
import TagsComp from "../TagsComp";
import ListedUserPayout from "../features/ListedUserPayout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedSubPage } from "../../../redux/reducers/userStateReducer";
import SidebarTabNavigation from "../features/SidebarTabNavigation";
import {
  approveAPayoutRequestAction,
  getAllPayoutRequestsAction,
  postponeAPayoutRequestAction,
  searchPayoutRequestsAction,
} from "../../../api/admin";
import LoadingState from "../../loader/LoadingState";
import { toast } from "react-toastify";
import ApprovePayoutModal from "../modals/payout/ApprovePayoutModal";
import PostponePayoutModal from "../modals/payout/PostponePayoutModal";

const PayoutsAdmin = () => {
  const [activeSideTab, setActiveSideTab] = useState("General");
  const [selected, setSelected] = useState(0);
  const [newDate, setNewDate] = useState(null);
  const [message, setMessage] = useState("");
  let isStoreOwner = true;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [payoutRequestList, setPayoutRequestList] = useState([]);
  const [sortedPayoutRequestList, setSortedPayoutRequestList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPayoutRequest, setSelectedPayoutRequest] = useState(null);
  const [showApprovePayoutRequestModal, setShowApprovePayoutRequestModal] =
    useState(false);
  const [showPostponePayoutRequestModal, setShowPostponePayoutRequestModal] =
    useState(false);
  const [approveModalfadeOut, setApproveModalFadeOut] = useState(false);
  const [postponeModalfadeOut, setPostponeModalFadeOut] = useState(false);
  const [searchString, setSearchString] = useState("");

  const selectAPayout = (payoutData, id) => {
    setSelected(id);
    setSelectedPayoutRequest(payoutData);
  };

  /**
   * Filters the payout request list based on the given sortBy value.
   *
   * @param {string} sortBy - The value used to determine how to filter the payout request list.
   *                         Possible values are "general", "stores", and "affiliates".
   * @return {void} This function does not return a value.
   */
  const filterPayoutList = (sortBy) => {
    let sortedPayouts = [];

    switch (sortBy.toLowerCase()) {
      case "general":
        setActiveSideTab(sortBy);
        sortedPayouts = payoutRequestList;
        break;

      case "stores":
        setActiveSideTab(sortBy);
        sortedPayouts = payoutRequestList?.filter(
          (request) => request.type === "store"
        );
        break;

      case "affiliates":
        setActiveSideTab(sortBy);
        sortedPayouts = payoutRequestList?.filter(
          (request) => request.type === "affiliate"
        );
        break;

      default:
        sortedPayouts = payoutRequestList;
    }

    setSortedPayoutRequestList(sortedPayouts);
  };

  const approveAPayoutRequest = async () => {
    setIsLoading(true);

    await dispatch(
      approveAPayoutRequestAction({
        approveData: {
          id: selectedPayoutRequest?.id,
        },
      })
    )
      .unwrap()
      .then(() => {
        const arr = [...payoutRequestList];
        let approvedRequest = arr?.find(
          (req) => req.id === selectedPayoutRequest?.id
        );
        if (approvedRequest) {
          approvedRequest.paid = true;
          setPayoutRequestList(arr);
          toast.success("Request has been approved", {
            autoClose: 2000,
            className: "toast__message",
          });
          fetchPayoutData();
          setIsLoading(false);
          setApproveModalFadeOut((prev) => !prev);
          setTimeout(() => {
            setShowApprovePayoutRequestModal(!showApprovePayoutRequestModal);
          }, 400);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Error! Try again later", {
          autoClose: 2000,
          className: "toast__message",
        });
        setIsLoading(false);
      });
  };

  const postponeAPayoutRequest = async () => {
    setIsLoading(true);

    await dispatch(
      postponeAPayoutRequestAction({
        postponeData: {
          id: selectedPayoutRequest?.id,
          message,
          new_date: newDate,
        },
      })
    )
      .unwrap()
      .then(() => {
        const arr = [...payoutRequestList];
        let postponedRequest = arr?.find(
          (req) => req.id === selectedPayoutRequest?.id
        );
        if (postponedRequest) {
          postponedRequest.paid = true;
          setPayoutRequestList(arr);
          toast.success("Request has been postponed", {
            autoClose: 2000,
            className: "toast__message",
          });
          setIsLoading(false);
          setPostponeModalFadeOut((prev) => !prev);
          setTimeout(() => {
            setShowPostponePayoutRequestModal(!showPostponePayoutRequestModal);
          }, 400);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Error! Try again later", {
          autoClose: 2000,
          className: "toast__message",
        });
        setIsLoading(false);
      });
  };

  const searchPayoutRequests = async () => {
    setIsLoading(true);

    await dispatch(
      searchPayoutRequestsAction({
        query: {
          search: searchString,
        },
      })
    )
      .unwrap()
      .then((response) => {
        setSortedPayoutRequestList(response);
        setSelectedPayoutRequest(response[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err?.response) {
          setIsLoading(false);
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: "toast__message",
          });
        }
      });
  };
  const fetchPayoutData = useCallback(() => {
    dispatch(getAllPayoutRequestsAction({}))
      .unwrap()
      .then((response) => {
        setIsLoading(false);
        setPayoutRequestList(response);
        setSortedPayoutRequestList(response);
        setSelectedPayoutRequest(response[0]);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: "toast__message",
          });
        }
      });
  }, [dispatch]);
  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    if (isMounted) {
      fetchPayoutData();
    }

    return () => {
      isMounted = false;
    };
  }, [fetchPayoutData]);
  return (
    <div className="admin__users-container">
      {showApprovePayoutRequestModal && (
        <ApprovePayoutModal
          payoutRequestData={selectedPayoutRequest}
          toggleShowModal={() =>
            setShowApprovePayoutRequestModal(!showApprovePayoutRequestModal)
          }
          isLoading={isLoading}
          fadeOut={approveModalfadeOut}
          setFadeOut={setApproveModalFadeOut}
          approvePayout={approveAPayoutRequest}
        />
      )}

      {showPostponePayoutRequestModal && (
        <PostponePayoutModal
          toggleShowModal={() =>
            setShowPostponePayoutRequestModal(!showPostponePayoutRequestModal)
          }
          isLoading={isLoading}
          fadeOut={postponeModalfadeOut}
          setFadeOut={setPostponeModalFadeOut}
          postponePayout={postponeAPayoutRequest}
          comment={message}
          setComment={setMessage}
          date={newDate}
          setDate={setNewDate}
        />
      )}

      {isLoading ? (
        <div
          style={{
            paddingTop: "5rem",
            height: "5rem",
            width: "100%",
            backgroundColor: "#191921",
          }}
        />
      ) : (
        <AdminFreebiesSidebar
          isPayoutsPage={true}
          isStoreOwner={isStoreOwner}
          type="store"
          setActiveSideTab={setActiveSideTab}
          activeSideTab={activeSideTab}
          actionButtonText={"More"}
          data={selectedPayoutRequest?.store}
          sideActionOnclick={() => {
            navigate(
              `/admin/expanded/${selectedPayoutRequest?.store?.user?.id}`
            );
            dispatch(setSelectedSubPage({ selected: "sales" }));
          }}
          sidebarMaininfoIts={[
            {
              leftInfo: "Method",
              rightInfo: selectedPayoutRequest?.payment_method,
            },

            {
              leftInfo: "Merchant",
              rightInfo: selectedPayoutRequest?.payment_merchant,
            },

            {
              leftInfo: "Account id",
              rightInfo: selectedPayoutRequest?.payout_id,
            },

            {
              leftInfo: "Ready For Payout",
              rightInfo: `$ ${selectedPayoutRequest?.store?.user?.wallet?.balance}`,
            },

            {
              leftInfo: "Processing Orders",
              rightInfo: `$ ${selectedPayoutRequest?.amount}`,
            },
          ]}
        />
      )}

      <section className="admin__users-list-container">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <SidebarTabNavigation
            isPayoutsPage={false}
            setActiveSideTab={setActiveSideTab}
            activeSideTab={activeSideTab}
            sortFn={filterPayoutList}
            itemsList={[
              {
                navigator: "General",
                showText: "General",
              },
              {
                navigator: "Stores",
                showText: "Stores",
              },
              {
                navigator: "Affiliates",
                showText: "Affiliates",
              },
            ]}
          />
          <TagsComp
            showFilter={false}
            searchPlaceHolder="Search payment method, merchant status and amount"
            search={searchString}
            setSearch={setSearchString}
            onSearch={searchPayoutRequests}
          />
        </div>
        <section className="admin__users-list-body">
          {isLoading ? (
            <LoadingState />
          ) : (
            sortedPayoutRequestList?.map((request, index) => {
              return (
                <ListedUserPayout
                  isPayoutRequested={request?.status}
                  showVerifySign={request?.user?.user_details_verified}
                  styleVerifySign={{
                    position: "absolute",
                    top: "25%",
                    left: "5%",
                  }}
                  id={index}
                  key={index}
                  selected={index === selected}
                  setSelected={setSelected}
                  image={request?.user?.picture}
                  name={request?.store?.store_name}
                  location={request?.store?.user?.profile?.country}
                  emails={request?.user?.email}
                  merchant={request?.payment_merchant}
                  isPaid={request?.paid}
                  amount={request?.amount}
                  selectARequest={() => selectAPayout(request, index)}
                  showPostponePayoutRequestModal={() =>
                    setShowPostponePayoutRequestModal(
                      !showPostponePayoutRequestModal
                    )
                  }
                  showApprovePayoutRequestModal={() =>
                    setShowApprovePayoutRequestModal(
                      !showApprovePayoutRequestModal
                    )
                  }
                />
              );
            })
          )}
        </section>
      </section>
    </div>
  );
};

export default PayoutsAdmin;
