import UserPurpleInnerStripeBtn from "../../buttons/UserPurpleButton";
import CardArrowPointer from "../home/CardArrowPointer";
import AfilliatedDropdown from "./AfilliatedDropdown";
import FireMailButton from "./FireMailButton";
import LongSelectedCone from "./LongSelectedCone";
import { GoLocation, GoVerified } from "react-icons/go";
import { MdOutlineTimer } from "react-icons/md";
import { BsDot } from "react-icons/bs";
import { BiMessageError } from "react-icons/bi";

const CatManImg = "/assets/image/loootydefault.jpg";

const PayoutsOptionsDispay = ({
  amount,
  isPaid = false,
  isPayoutRequested = false,
}) => {
  if (isPayoutRequested === "pending") {
    return (
      <div className="admin__listed-user-payment-request">
        <BiMessageError className="admin__listed-user-payment-request-icon" />
        <span className="admin__listed-user-payment-request-text">
          Payment Request
        </span>
        <BsDot className="admin__listed-user-payment-request-icon sub" />
        <span className="admin__listed-user-payment-request-text">
          $ {amount}
        </span>
      </div>
    );
  }
  return (
    <div className="admin__listed-user-time-pay-container">
      {isPaid && isPayoutRequested === "completed" ? (
        <GoVerified className="admin__listed-user-time-payout-icon" />
      ) : isPayoutRequested === "postponed" ? (
        <MdOutlineTimer className="admin__listed-user-time-payout-icon" />
      ) : null}
      <span className="admin__listed-user-time-payout-text">$ {amount}</span>
    </div>
  );
};

const ListedUserPayout = ({
  selected,
  isPaid,
  isAffiliated,
  isPayoutRequested,
  styleVerifySign,
  showVerifySign,
  image,
  name,
  location,
  emails,
  merchant,
  amount,
  selectARequest,
  showApprovePayoutRequestModal,
  showPostponePayoutRequestModal,
}) => {
  return (
    <section className="admin__users-listed-outer">
      <div
        onClick={() => selectARequest()}
        className={`admin__users-listed-user ${selected && "selected"}`}
      >
        {selected && <LongSelectedCone />}
        <div className="admin__users-listed-item image">
          <img
            className="admin__users-listed-item image"
            src={image || CatManImg}
            alt=""
          />
        </div>
        <div className="admin__users-listed-item name">{name}</div>
        <div className="admin__users-listed-item location">
          <GoLocation /> {location}
        </div>
        <div className="admin__users-listed-item email">{merchant}</div>

        <div className="admin__users-listed-item email">{emails}</div>
        <div className="admin__users-listed-item extra-container">
          {selected ? (
            <div className="admin__users-listed-item extra">
              <BiMessageError className="admin__listed-user-payment-request-icon" />
              <FireMailButton
                text={"POSTPONE"}
                onClick={showPostponePayoutRequestModal}
              />
              <UserPurpleInnerStripeBtn
                onClick={showApprovePayoutRequestModal}
                style={{ width: "10rem", padding: "1rem 3rem" }}
                text={"PAID"}
              />{" "}
            </div>
          ) : (
            <PayoutsOptionsDispay
              isPaid={isPaid}
              isPayoutRequested={isPayoutRequested}
              amount={amount}
            />
          )}
        </div>
        <CardArrowPointer
          style={{
            top: "40%",
            right: "1.2%",
            color: `${selected ? "#7C187A" : "#3B3B43"}`,
            transform: `rotate(${selected ? "-90deg" : "90deg"})`,
          }}
        />
      </div>
      {isAffiliated && selected && <AfilliatedDropdown />}
    </section>
  );
};

export default ListedUserPayout;
