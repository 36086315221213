import BuyPage from '../../../pages/buypage';

export default function BuyPageModal({
  toggleShowModal,
  fadeOut,
  setFadeOut,
  id,
}) {
  return (
    <div
      className={`buypage__overlay ${fadeOut ? 'lgn__fade-out-anim' : ''}`}
      style={{ color: '#ffffff', zIndex: 1500 }}
    >
      {' '}
      <BuyPage
        toggleShowModal={toggleShowModal}
        fadeOut={fadeOut}
        setFadeOut={setFadeOut}
        id={id}
      />
    </div>
  );
}
// navigate(`/buy/page/${prod.id}`);
