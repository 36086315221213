import axios from 'axios';

export const requestAccess = async (data) => {
  try {
    const response = await axios.post(
      `https://discord.com/api/webhooks/1080447985156116550/mWECKHm15nWIiWrhV5-tRUq6Mv6Iq6V6pmsBkGbGmtwH7iJ_C4FiwBdsk_0XFNZEC0iM`,
      { content: JSON.stringify(data) },
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const subscribeWebhook = async (data) => {
  try {
    const response = await axios.post(
      `https://discord.com/api/webhooks/1179379647079776357/2SIQFwBpMLdCpcg2FrdhNcQlN-CfdMc7MzWfAoa92BpCc81HeX51rSqVbFzy402Efk_b
`,
      { content: JSON.stringify(data) },
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const becomeCreatorWebhook = async (data) => {
  try {
    const response = await axios.post(
      `https://discord.com/api/webhooks/1180347558703415386/Rdbquu9Dw_i5sLKLLjLc950NqvCof2tF842RrHEcUh9IYGrZRBSHVFo5LuvMt9ld2VGv
`,
      { content: JSON.stringify(data) },
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response;
  } catch (err) {
    throw new Error(err.message);
  }
};
