import { MdCloudDownload } from "react-icons/md";

const LibraryProductCard = ({ product, setIsActive, isActive, setIsActiveProduct }) => {
    return (
      <section
        style={{ transform: isActive === product?.id && "scale(1)" }}
        onClick={() => {
          setIsActive(product?.merchandise_id)
          setIsActiveProduct(product)
        }}
        className="user__main-lib-card"
      >
        <img
          className="user__main-lib-card-img"
          src={product?.merchandise_cover_picture[0]}
          style={{ width: "" }}
          alt="collections"
        />
        <div className="user__main-lib-card-body">
          <h3 className="user__card-collection-title">{product?.merchandise_name}</h3>
          <p className="user__card-collection-description">
            by {product?.store_name} in {product?.merchandise_tags[0]}
          </p>
        </div>
  
        <div className="user__main-lib-card-footer">
          <MdCloudDownload style={{ fontSize: "1.2rem" }} /> DOWNLOAD FILES
        </div>
  
        {isActive === product?.merchandise_id && <div className="user__pointy-arrow"></div>}
      </section>
    );
};


export default LibraryProductCard