import { AiTwotoneHeart } from "react-icons/ai";
import { FaStripe } from "react-icons/fa";
import { GrPaypal } from "react-icons/gr";
import { VscTriangleRight } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductIntoCollection,
  fetchCollections,
  removeProductIntoCollection,
} from "../../api/auth";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import {
  productCartLicense,
  productCartPrice,
  setProductCartLicense,
  setProductCartPrice,
} from "../../redux/progressSlice";
import Cookies from "js-cookie";
import { useContext } from "react";
import { CartContext } from "../auth/context/cartContext";
import { useMediaQuery } from "react-responsive";

const BuyCart = ({ product, addToCart }) => {
  const dispatch = useDispatch();
  // const [showRating, setShowRating] = useState(false);
  const [showPriceChoice, setShowPriceChoice] = useState(false);
  const [collections, setCollections] = useState([]);
  const isMobile = useMediaQuery({ minWidth: 300, maxWidth: 480 });

  let user = {};

  if (typeof window !== "undefined" && Cookies.get("user")) {
    try {
      user = JSON.parse(Cookies.get("user"));
    } catch (error) {
      // Handle the error, e.g., the cookie contains invalid JSON
      console.error("Error parsing 'user' cookie:", error);
    }
  } // const tempRatings = [
  //   {
  //     name: 'Josh',
  //     comment: 'Making good sense',
  //     score: 3,
  //   },
  //   {
  //     name: 'Mary',
  //     comment: 'Making good sense',
  //     score: 5,
  //   },
  //   {
  //     name: 'Josh',
  //     comment: 'Making good sense',
  //     score: 4,
  //   },
  // ];

  useEffect(() => {
    dispatch(fetchCollections({}))
      .unwrap()
      .then((response) => {
        setCollections(response.wish_list_merchandise);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
      });
  }, [dispatch]);

  //Add to wishlist
  const addProductToCollection = async (productID) => {
    try {
      await dispatch(
        addProductIntoCollection({
          collectionInfo: {
            user_id: user?.id,
            wish_list_merchandise: [productID],
          },
        })
      ).unwrap();

      return true;
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message, {
          autoClose: 2000,
          className: "toast__message",
        });
      }
      return false;
    }
  };

  const wishlistItem = (productID) => {
    addProductToCollection(productID).then((success) => {
      if (success) {
        dispatch(fetchCollections({}))
          .unwrap()
          .then((response) => {
            setCollections(response.wish_list_merchandise);
            toast.success("wishlist updated", {
              autoClose: 4000,
              className: "toast__message",
            });
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message, {
                autoClose: 4000,
                className: "toast__message",
              });
            }
          });
      }
    });
  };

  //Remove from wishlist
  const removeProductToCollection = async (productID) => {
    try {
      await dispatch(
        removeProductIntoCollection({
          collectionInfo: {
            user_id: user?.id,
            wish_list_merchandise: [productID],
          },
        })
      ).unwrap();

      return true;
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message, {
          autoClose: 2000,
          className: "toast__message",
        });
      }
      return false;
    }
  };

  const unwishlistItem = (productID) => {
    removeProductToCollection(productID).then((success) => {
      if (success) {
        dispatch(fetchCollections({}))
          .unwrap()
          .then((response) => {
            setCollections(response.wish_list_merchandise);
            toast.success("wishlist updated", {
              autoClose: 4000,
              className: "toast__message",
            });
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message);
            }
          });
      }
    });
  };
  const wishlistNumber =
    collections &&
    Object.keys(collections).map((key) => Number(collections[key]));
  const isWishlisted = wishlistNumber?.includes(product.id);

  const { cart } = useContext(CartContext);

  const inCart = cart?.find((cartItem) => cartItem.product_id === product.id);

  const productCartprice = useSelector(productCartPrice);
  const productCartlicense = useSelector(productCartLicense);
  return (
    <div className="buy__cart-buy-container">
      <div className="buy__cart-buy-header">
        File <VscTriangleRight color="#6D6D91" size={16} />
        <ul className="upload__fileList" style={{ border: "none" }}>
          <li style={{ paddingRight: "8px", backgroundColor: "none" }}>
            <span> {product.merchandise_name}.zip</span>
          </li>
        </ul>
      </div>

      <div className="buy__cart-purchase-main-container">
        <div className="buy__purchase-btn-container">
          {inCart ? (
            <span
              className="buy__purchase-btn"
              style={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "center",
                position: "relative",
                fontWeight: "600",
                cursor: "default",
              }}
            >
              <span> &#10003; ADDED TO CART</span>{" "}
              <span
                style={{
                  position: "absolute",
                  top: "0.7rem",
                  right: "0.9rem",
                  padding: "5px",
                  fontSize: "0.9rem",
                  backgroundColor: "rgba(0,0,0,0.4)",
                }}
              >
                {" "}
                ${productCartprice?.toLocaleString()}
              </span>{" "}
            </span>
          ) : (
            <span
              onMouseEnter={() => {
                setShowPriceChoice(true);
              }}
              // onMouseLeave={() => {
              //   setShowPriceChoice(false);
              // }}
              onClick={() => addToCart(product)}
              className="buy__purchase-btn"
              style={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "center",
                position: "relative",
                fontWeight: "600",
              }}
            >
              <span>ADD TO CART</span>{" "}
              <span
                style={{
                  position: "absolute",
                  top: "0.7rem",
                  right: "0.9rem",
                  padding: "5px",
                  fontSize: "0.9rem",
                  backgroundColor: "rgba(0,0,0,0.4)",
                }}
              >
                ${productCartprice?.toLocaleString()}
              </span>{" "}
            </span>
          )}

          <button
            className="buy__heart-btn-container"
            onClick={
              isWishlisted
                ? () => unwishlistItem(product.id)
                : () => wishlistItem(product.id)
            }
          >
            <AiTwotoneHeart
              style={{
                transform: "SkewX(20deg)",
                color: isWishlisted ? "red" : "#ffffff",
              }}
            />
          </button>

          {/* {showRating && isWishlisted && (
            <div className='buy__purchase-rating'>
              <div>{calculateProductRating(tempRatings)} out of 5 stars</div>
              <p>Rate this product</p>
              <div className='buy__purchase-ratebox'>
                <StarRating
                  rateNumber={calculateProductRating(product?.ratings) || 0}
                  id={product.id}
                  style={{ fontSize: '2rem' }}
                />
              </div>
            </div>
          )} */}

          {!inCart && showPriceChoice && !isMobile && (
            <div className="buy__purchase-prices">
              <div
                className="buy__price"
                onClick={() => {
                  dispatch(setProductCartPrice(product.merchandise_amount));
                  dispatch(setProductCartLicense("basic"));
                  setShowPriceChoice(false);
                }}
              >
                Basic Price:{" "}
                <span>${product.merchandise_amount?.toLocaleString()}</span>
              </div>
              <div
                className="buy__price"
                onClick={() => {
                  dispatch(
                    setProductCartPrice(product.merchandise_commercial_amount)
                  );
                  dispatch(setProductCartLicense("commercial"));
                  setShowPriceChoice(false);
                }}
              >
                Commercial Price:{" "}
                <span>
                  {" "}
                  <span>
                    ${product.merchandise_commercial_amount?.toLocaleString()}
                  </span>
                </span>
              </div>
            </div>
          )}
          {isMobile && !inCart && (
            <div className="buy__purchase-prices">
              <div
                className="buy__price"
                style={{
                  backgroundColor:
                    productCartlicense === "basic"
                      ? "rgba(0, 0, 0, 0.4)"
                      : "transparent",
                }}
                onClick={() => {
                  dispatch(setProductCartPrice(product.merchandise_amount));
                  dispatch(setProductCartLicense("basic"));
                }}
              >
                Basic Price:{" "}
                <span>${product.merchandise_amount?.toLocaleString()}</span>
              </div>
              <div
                className="buy__price"
                style={{
                  backgroundColor:
                    productCartlicense === "commercial"
                      ? "rgba(0, 0, 0, 0.4)"
                      : "transparent",
                }}
                onClick={() => {
                  dispatch(
                    setProductCartPrice(product.merchandise_commercial_amount)
                  );
                  dispatch(setProductCartLicense("commercial"));
                }}
              >
                Commercial Price:{" "}
                <span>
                  {" "}
                  <span>
                    ${product.merchandise_commercial_amount?.toLocaleString()}
                  </span>
                </span>
              </div>
            </div>
          )}
        </div>
        <div
          className="buy__payment-methods-container"
          style={{
            marginTop: isMobile && !inCart ? "2rem" : undefined,
            width: isMobile ? "100%" : undefined,
            paddingLeft: isMobile ? "1.5rem" : undefined,
          }}
        >
          <p style={{ color: "#6D6D91", fontSize: ".8rem" }}>
            Payment made easy! You can pay with any of this payment system:
          </p>
          <div className="buy__payment-options">
            <GrPaypal className="buy__payment-options-logos" />
            <FaStripe className="buy__payment-options-logos" />
            <img
              src="/assets/image/logo/paystack.svg"
              alt="paystack"
              className="paystack-logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyCart;
