import { MdModeEditOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FaSave } from 'react-icons/fa';
import LoadingState from '../../../loader/LoadingState';
import OrderItem from '../../../user/OrderItem';
import LoootyLoader from '../../../loader/loootyLoader';
import {
  fetchAStoreSettingsByAdminAction,
  updateAStoreSettingsByAdminAction,
  updateUserByAdminAction,
} from '../../../../api/admin';
import { useParams } from 'react-router-dom';

const AdminStoreSettings = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [saveStoreName, setSaveStoreName] = useState(false);
  const { id } = useParams();

  const [storeSettings, setStoreSettings] = useState({
    name: '',
    enabled: '',
    affiliate: '',
  });
  const handleStoreSettingsChange = (key) => {
    setStoreSettings((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const updateStoreName = async () => {
    setIsLoading(true);
    await dispatch(
      updateUserByAdminAction({
        updateData: {
          user_id: user?.id,
          name: storeSettings?.name,
        },
      })
    )
      .then(() => {
        toast.success('Name updated', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  const editStoreSettings = async () => {
    setIsLoading(true);
    await dispatch(
      updateAStoreSettingsByAdminAction({
        updateData: {
          enabled: storeSettings.enabled,
          affiliate: storeSettings.affiliate,
          store_id: storeSettings?.store_id,
        },
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Store settings updated', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log({ err });
        toast.error(err || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);
    dispatch(fetchAStoreSettingsByAdminAction({ user_id: id }))
      .then((res) => {
        if (isMounted) {
          setStoreSettings({
            ...res?.payload,
            name: user?.name,
          });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        isMounted && setIsLoading(false);
        toast.error(
          err.payload?.response?.data?.message || 'Error try again later',
          {
            autoClose: 2000,
            className: 'toast__message',
          }
        );
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch, id, user?.name]);

  return (
    <div>
      {isLoading ? (
        <LoadingState />
      ) : (
        <div className='user__store-sett-body'>
          <div
            className='user__sales-mc-item-body'
            style={{ marginBottom: '2rem' }}
          >
            <span className='account-sett-input-label'>STORE URL</span>
            <div className='user__sales-form-group'>
              <div className='user__sales-skew-container'>
                <span className='user__store-sett-url-input-prefix'>
                  {'https://loooty.com/'}
                </span>
                <input
                  disabled={!saveStoreName}
                  className='user__sales-mc-input'
                  value={storeSettings?.name || ''}
                  onChange={(e) =>
                    setStoreSettings((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }))
                  }
                  style={{
                    paddingLeft: 0,
                  }}
                />
              </div>
              <div className='user__sales-mc-input-edit-skew-container'>
                {saveStoreName ? (
                  <FaSave
                    className='user__sales-mc-input-edit'
                    onClick={async () => {
                      setSaveStoreName(false);
                      await updateStoreName();
                    }}
                  />
                ) : (
                  <MdModeEditOutline
                    className='user__sales-mc-input-edit'
                    onClick={() => setSaveStoreName(true)}
                  />
                )}
              </div>
            </div>
            <p className='user__account-sett-input-info'>
              You can change your store link once a month
            </p>
          </div>

          <OrderItem
            itemTitle={'Store Enabled'}
            itemDescription='Enable your store to make your products discoverable on the marketplace and make your store page public'
            isChecked={storeSettings?.enabled}
            setIsChecked={async () => {
              handleStoreSettingsChange('enabled');
            }}
          />

          <OrderItem
            itemTitle={'Enable Affiliate Links'}
            itemDescription='Allow people to use your product link to advertise and make sales for you and which will automatically give them 10% on every sales they make'
            isChecked={storeSettings.affiliate}
            setIsChecked={async () => {
              handleStoreSettingsChange('affiliate');
            }}
          />

          <div className='user__sales-mc-item-container button'>
            <button
              className='user__sales-mc-item-btn'
              type='button'
              onClick={editStoreSettings}
            >
              <span>{isLoading ? <LoootyLoader /> : 'SAVE'}</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminStoreSettings;
