import AffiliatedItemCarts from "./AffiliatedItemCarts";

const AfilliatedDropdownCarts = ({products}) => {
  return (
    <section className="admin__listed-users-afilliated-container">
      <div className="admin__listed-users-afilliated-container-inner">
        {
          products?.length > 0 ? products.map((product) => (
            <AffiliatedItemCarts key={product.id} merchandiseData={product}/>
          )) : (
            <p style={{color: '#ffffff', fontSize: '1rem'}}>Nothing to see here</p>
          )
        }
        
      </div>
    </section>
  );
};

export default AfilliatedDropdownCarts