import { useEffect, useState } from 'react';
import { AiFillPlusCircle } from 'react-icons/ai';
import AdminFreebiesSidebar from '../AdminFreebiesSidebar';
import TagsComp from '../TagsComp';
import { TiTimes } from 'react-icons/ti';
import {
  createProductCategoryAction,
  createProductTagAction,
  deleteProductCategoryAction,
  deleteProductTagAction,
  getAllProductCategoriesAction,
  getAllProductTagsAction,
} from '../../../api/admin';
import SidebarTabNavigation from '../features/SidebarTabNavigation';
import { toast } from 'react-toastify';
import LoadingState from '../../loader/LoadingState';
import { useDispatch } from 'react-redux';
import CreateTagModal from '../modals/tags/CreateTagModal';
import DeleteTagModal from '../modals/tags/DeleteTagModal';
import CreateCategoryModal from '../modals/category/CreateCategoryModal';
import DeleteCategoryModal from '../modals/category/DeleteCategoryModal';

const Catsimage = ({ fill = '#B3BABE' }) => {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='1.4rem'
      height='1.4rem'
      viewBox='0 0 85.000000 76.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,76.000000) scale(0.100000,-0.100000)'
        fill={fill}
        stroke='none'
      >
        <path
          d='M0 380 l0 -380 425 0 425 0 0 380 0 380 -425 0 -425 0 0 -380z m774
278 c13 -19 16 -59 16 -234 0 -202 -1 -212 -21 -230 -20 -18 -41 -19 -350 -19
-302 0 -330 1 -346 18 -16 15 -18 41 -21 204 -2 102 -1 201 3 219 12 66 2 64
370 64 334 0 334 0 349 -22z m-166 -575 c-8 -41 -358 -41 -366 0 -3 16 11 17
183 17 172 0 186 -1 183 -17z'
        />
        <path
          d='M400 595 c0 -8 -11 -22 -24 -30 -20 -13 -29 -14 -46 -5 -18 10 -25 9
-38 -4 -13 -12 -13 -18 -2 -31 17 -20 0 -60 -29 -70 -29 -9 -27 -43 3 -55 29
-11 41 -42 27 -69 -14 -25 10 -47 36 -33 21 13 73 -11 73 -33 0 -9 10 -15 25
-15 18 0 25 5 25 18 0 26 47 47 67 31 21 -18 49 12 35 37 -13 23 4 60 29 67
24 7 25 44 1 52 -26 8 -43 46 -30 69 14 25 -8 49 -32 36 -25 -14 -66 3 -73 29
-6 24 -47 29 -47 6z m96 -89 c28 -28 34 -41 34 -78 -1 -116 -151 -152 -205
-48 -19 37 -19 63 0 100 19 36 59 59 104 60 25 0 42 -9 67 -34z'
        />
        <path
          d='M376 484 c-9 -23 -7 -97 3 -113 7 -11 19 -9 62 16 29 16 55 35 57 41
2 6 -24 25 -56 41 -51 26 -61 28 -66 15z'
        />
      </g>
    </svg>
  );
};

export const CategoriesTabloid = ({
  style,
  onClick,
  text = 'CG TUTORIALS',
  isAddBtn = false,
  imageFill = '#B3BABE',
}) => {
  return (
    <div style={style} onClick={onClick} className='admin__categories-tab'>
      <div className='admin__categories-tab-image-container'>
        {isAddBtn ? (
          <AiFillPlusCircle style={{ color: '#AF23AF', fontSize: '1.5rem' }} />
        ) : (
          <Catsimage fill={imageFill} />
        )}
      </div>

      <span className='admin__categories-tab-text'>{text}</span>
    </div>
  );
};

const TagsTabloid = ({
  style,
  popNo,
  onClick,
  onDeleteClick,
  text = 'CG TUTORIALS',
  isAddBtn = false,
  imageFill = '#B3BABE',
  isAmill = false,
}) => {
  return (
    <div style={style} className='admin__tags-tab'>
      <div className='admin__tags-tab-image-container' onClick={onClick}>
        {isAddBtn ? (
          <AiFillPlusCircle style={{ color: '#AF23AF', fontSize: '1.5rem' }} />
        ) : (
          <span
            className={`admin__tags-tab-count-notifier ${isAmill && 'amilly'}`}
          >
            {popNo}
          </span>
        )}
      </div>

      <span className='admin__tags-tab-text'>{text}</span>
      <TiTimes className='admin__tags-tab-close' onClick={onDeleteClick} />
    </div>
  );
};

const TagsCatAdmin = () => {
  const [activeSideTab, setActiveSideTab] = useState('All');
  const [selected, setSelected] = useState(0);
  const [universallySelected, setUniversallySelected] = useState({
    isFirst: false,
    cat: 0,
    tag: 0,
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [tagsList, setTagsList] = useState(null);
  const [sortedtagsList, setSortedTagsList] = useState(null);
  const [categoriesList, setCategoriesList] = useState(null);
  const [sortedCategoriesList, setSortedCategoriesList] = useState(null);
  const [showDeleteTagModal, setShowDeleteTagModal] = useState(false);
  const [showCreateTagModal, setShowCreateTagModal] = useState(false);
  const [deleteTagModalfadeOut, setDeleteTagModalFadeOut] = useState(false);
  const [createTagModalfadeOut, setCreateTagModalFadeOut] = useState(false);
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);
  const [deleteCategoryModalfadeOut, setDeleteCategoryModalFadeOut] =
    useState(false);
  const [createCategoryModalfadeOut, setCreateCategoryModalFadeOut] =
    useState(false);
  const [searchString, setSearchString] = useState('');
  const [newCategoryData, setNewCategoryData] = useState({
    name: '',
    type: 'merchandise',
    // base64_image: ''
  });
  const [newTagData, setNewTagData] = useState({
    name: '',
    type: 'merchandise',
    // base64_image: ''
  });

  const createProductCategory = async () => {
    setIsLoading(true);

    await dispatch(createProductCategoryAction({ createData: newCategoryData }))
      .unwrap()
      .then(() => {
        let catList = categoriesList;
        catList.push(newCategoryData);
        setCategoriesList(catList);
        setSortedCategoriesList(catList);
        toast.success('Category created successfully', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
        setCreateCategoryModalFadeOut((prev) => !prev);
        setTimeout(() => {
          setShowCreateCategoryModal(!showCreateCategoryModal);
        }, 500);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };
  const createProductTag = async () => {
    setIsLoading(true);

    await dispatch(createProductTagAction({ createData: newTagData }))
      .unwrap()
      .then(() => {
        let tagList = tagsList;
        tagList.push(newTagData);
        setTagsList(tagList);
        setSortedTagsList(tagList);
        toast.success('Tag created successfully', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
        setCreateTagModalFadeOut((prev) => !prev);
        setTimeout(() => {
          setShowCreateTagModal(!showCreateTagModal);
        }, 500);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  const deleteProductTag = async () => {
    setIsLoading(true);

    await dispatch(deleteProductTagAction({ deleteData: { id: selected?.id } }))
      .unwrap()
      .then(() => {
        let tagList = tagsList;
        tagList = tagList.filter((tag) => tag.name !== selected?.name);
        console.log({ tagList });
        setTagsList(tagList);
        setSortedTagsList(tagList);
        toast.success('Tag deleted successfully', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
        setDeleteTagModalFadeOut((prev) => !prev);
        setTimeout(() => {
          setShowDeleteTagModal(!showDeleteTagModal);
        }, 500);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  const deleteProductCategory = async () => {
    setIsLoading(true);

    await dispatch(
      deleteProductCategoryAction({ deleteData: { id: selected?.id } })
    )
      .unwrap()
      .then(() => {
        let catList = categoriesList;
        catList = catList.filter((cat) => cat.name !== selected?.name);
        setCategoriesList(catList);
        setSortedCategoriesList(catList);
        toast.success('Category deleted successfully', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
        setDeleteCategoryModalFadeOut((prev) => !prev);
        setTimeout(() => {
          setShowDeleteCategoryModal(!showDeleteCategoryModal);
        }, 500);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  const filterTagsAndCategory = (sortBy) => {
    switch (sortBy.toLowerCase()) {
      case 'all':
        setActiveSideTab(sortBy);
        setSortedCategoriesList(categoriesList);
        setSortedTagsList(tagsList);
        break;

      case 'tags':
        setActiveSideTab(sortBy);
        setSortedTagsList(tagsList);
        setSortedCategoriesList([]);
        break;

      case 'categories':
        setActiveSideTab(sortBy);
        setSortedCategoriesList(categoriesList);
        setSortedTagsList([]);
        break;

      default:
        setSortedCategoriesList(categoriesList);
        setSortedTagsList(tagsList);
    }
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getAllProductCategoriesAction({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setCategoriesList(response);
          setSortedCategoriesList(response);
          setSelected(response[0]);
          setUniversallySelected((old) => ({
            ...old,
            active: 0,
            cat: 0,
          }));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getAllProductTagsAction({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setTagsList(response);
          setSortedTagsList(response);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  return (
    <div className='admin__users-container'>
      {showCreateTagModal && (
        <CreateTagModal
          toggleShowModal={() => setShowCreateTagModal(!showCreateTagModal)}
          isLoading={isLoading}
          fadeOut={createTagModalfadeOut}
          setFadeOut={setCreateTagModalFadeOut}
          data={newTagData}
          setData={setNewTagData}
          createTagFn={createProductTag}
        />
      )}

      {showDeleteTagModal && (
        <DeleteTagModal
          toggleShowModal={() => setShowDeleteTagModal(!showDeleteTagModal)}
          isLoading={isLoading}
          fadeOut={deleteTagModalfadeOut}
          setFadeOut={setDeleteTagModalFadeOut}
          deleteTagFn={deleteProductTag}
        />
      )}

      {showCreateCategoryModal && (
        <CreateCategoryModal
          toggleShowModal={() =>
            setShowCreateCategoryModal(!showCreateCategoryModal)
          }
          isLoading={isLoading}
          data={newCategoryData}
          setData={setNewCategoryData}
          fadeOut={createCategoryModalfadeOut}
          setFadeOut={setCreateCategoryModalFadeOut}
          createCategoryFn={createProductCategory}
        />
      )}

      {showDeleteCategoryModal && (
        <DeleteCategoryModal
          toggleShowModal={() =>
            setShowDeleteCategoryModal(!showDeleteCategoryModal)
          }
          isLoading={isLoading}
          fadeOut={deleteCategoryModalfadeOut}
          setFadeOut={setDeleteCategoryModalFadeOut}
          deleteCategoryFn={deleteProductCategory}
        />
      )}

      {isLoading ? (
        <div
          style={{
            paddingTop: '5rem',
            height: '5rem',
            width: '100%',
            backgroundColor: '#191921',
          }}
        />
      ) : (
        <AdminFreebiesSidebar
          type='tags'
          setActiveSideTab={setActiveSideTab}
          activeSideTab={activeSideTab}
          actionButtonText='DELETE'
          data={selected}
          sideActionOnclick={
            universallySelected?.active === 0
              ? () => setShowDeleteCategoryModal(!showDeleteCategoryModal)
              : universallySelected?.active === 1
              ? () => setShowDeleteTagModal(!showDeleteTagModal)
              : null
          }
          sidebarMaininfoIts={[
            {
              leftInfo: 'Name',
              rightInfo: selected?.name,
              showEditableSign: true,
            },

            {
              leftInfo: 'Icon Image',
              rightInfo:
                selected?.image?.length > 0 ? selected?.image[0] : 'No image',
              showEditableSign: false,
            },
          ]}
        />
      )}

      <section className='admin__users-tags-container'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <SidebarTabNavigation
            isPayoutsPage={false}
            setActiveSideTab={setActiveSideTab}
            activeSideTab={activeSideTab}
            sortFn={filterTagsAndCategory}
            itemsList={[
              {
                navigator: 'All',
                showText: 'All',
              },
              {
                navigator: 'Categories',
                showText: 'Categories',
              },
              {
                navigator: 'Tags',
                showText: 'Tags',
              },
            ]}
          />
          <TagsComp
            showFilter={false}
            search={searchString}
            setSearch={setSearchString}
            // onSearch={searchUsers}
          />
        </div>
        <section className='admin__users-tags-body'>
          {isLoading ? (
            <LoadingState />
          ) : (
            sortedCategoriesList?.length > 0 && (
              <div className='admin__categories-container'>
                <h3 className='admin__categories-container-header'>
                  <span
                    onClick={() =>
                      setUniversallySelected((old) => ({ ...old, active: 0 }))
                    }
                    style={{
                      borderBottom: `.3rem solid ${
                        universallySelected.active === 0 ? '#FF9700' : '#353449'
                      }`,
                    }}
                  >
                    CATEGORIES
                  </span>
                </h3>
                <div className='admin__categories-container-body'>
                  {sortedCategoriesList?.map((cat, index) => {
                    return (
                      <CategoriesTabloid
                        style={{
                          borderLeft: `.4rem solid ${
                            universallySelected.cat === index &&
                            universallySelected.active === 0
                              ? '#FF9700'
                              : '#353449'
                          }`,
                          color: `${
                            universallySelected.cat === index &&
                            universallySelected.active === 0
                              ? '#FF9700'
                              : '#959AA0'
                          }`,
                        }}
                        onClick={() => {
                          setUniversallySelected((old) => ({
                            ...old,
                            active: 0,
                            cat: index,
                          }));
                          setSelected(cat);
                        }}
                        imageFill={
                          universallySelected.cat === index &&
                          universallySelected.active === 0
                            ? '#FF9700'
                            : '#B3BABE'
                        }
                        text={cat.name}
                      />
                    );
                  })}
                  <CategoriesTabloid
                    text='ADD CATEGORY'
                    isAddBtn={true}
                    onClick={() =>
                      setShowCreateCategoryModal(!showCreateCategoryModal)
                    }
                  />
                </div>
              </div>
            )
          )}

          {isLoading ? (
            <LoadingState />
          ) : (
            sortedtagsList?.length > 0 && (
              <div className='admin__categories-container'>
                <h3 className='admin__categories-container-header'>
                  <span
                    onClick={() =>
                      setUniversallySelected((old) => ({ ...old, active: 1 }))
                    }
                    style={{
                      borderBottom: `.3rem solid ${
                        universallySelected.active === 1 ? '#FF9700' : '#353449'
                      }`,
                    }}
                  >
                    TAGS
                  </span>
                </h3>
                <div className='admin__categories-container-body'>
                  {sortedtagsList?.map((tag, index) => {
                    return (
                      <TagsTabloid
                        style={{
                          borderLeft: `.4rem solid ${
                            universallySelected.tag === index &&
                            universallySelected.active === 1
                              ? '#FF9700'
                              : '#353449'
                          }`,
                          color: `${
                            universallySelected.tag === index &&
                            universallySelected.active === 1
                              ? '#FF9700'
                              : '#959AA0'
                          }`,
                        }}
                        onClick={() => {
                          setUniversallySelected((old) => ({
                            ...old,
                            active: 1,
                            tag: index,
                          }));
                          setSelected(tag);
                        }}
                        onDeleteClick={() => {
                          setSelected(tag);
                          setShowDeleteTagModal(!showDeleteTagModal);
                        }}
                        imageFill={
                          universallySelected.tag === index &&
                          universallySelected.active === 1
                            ? '#FF9700'
                            : '#B3BABE'
                        }
                        text={tag.name}
                        popNo={tag.popNo}
                        isAmill={tag.isAMill}
                      />
                    );
                  })}
                  <TagsTabloid
                    text='ADD TAGS'
                    isAddBtn={true}
                    onClick={() => setShowCreateTagModal(!showCreateTagModal)}
                  />
                </div>
              </div>
            )
          )}
        </section>
      </section>
    </div>
  );
};

export default TagsCatAdmin;
