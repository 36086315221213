import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createMerchandiseStore, logOutAction } from '../../api/auth';
import LoootyLoader from '../loader/loootyLoader';
import { RegisterContext } from './context/registerContext';
import OrderItem from '../user/OrderItem';
import { isValidAspectRatio } from '../../utils/imageUtils';
import { becomeCreatorWebhook } from '../../api/coming';
import { CartContext } from './context/cartContext';

export const convertToBase64 = (
  blob,
  maxSize = 2048,
  checkAspectRatio = true
) => {
  return new Promise(async (resolve, reject) => {
    // Check if the image size is within the specified maxSize
    console.log(blob?.size, blob);

    if (blob?.size > maxSize * 1024) {
      toast.error(
        `Image size exceeds the maximum allowed resolution of ${maxSize}.`,
        {
          autoClose: 5000,
          className: 'toast__message',
        }
      );
    } else if (blob?.height < 200) {
      toast.error(`The image must have a minimum height of 200px.`, {
        autoClose: 5000,
        className: 'toast__message',
      });
    } else if (checkAspectRatio) {
      isValidAspectRatio(blob)
        .then((isValid) => {
          if (isValid) {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.readAsDataURL(blob);
          } else {
            toast.warn(
              `The image must have a 1:1 aspect ratio and resolution not exceeding ${maxSize}x${maxSize}.`,
              {
                autoClose: 5000,
                className: 'toast__message',
              }
            );
            return;
          }
        })
        .catch((error) => {
          toast.error(
            `The image must have a 1:1 aspect ratio and resolution not exceeding ${maxSize}x${maxSize}.`,
            {
              autoClose: 2000,
              className: 'toast__message',
            }
          );
          return;
        });
    } else {
      const image = new Image();
      image.src = URL.createObjectURL(blob);

      image.onload = () => {
        if (image.height < 200) {
          toast.error(`The image must have a minimum height of 200px.`, {
            autoClose: 5000,
            className: 'toast__message',
          });
          return;
        } else {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(blob);
        }
      };
    }
  });
};

const OpenStoreModal = () => {
  const user = useSelector((state) => state.user.user);
  console.log('use', user);
  const [errors, setErrors] = useState({
    store_name: false,
    store_email: false,
    base64_photo: false,
    why_loooty: false,
    portfolio_link: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [readTerms, setReadTerms] = useState(false);
  const [readCreatorTerms, setReadCreatorTerms] = useState(false);
  const [storeInfo, setStoreInfo] = useState({
    full_name: '',
    store_email: user?.email,
    why_loooty: '',
    portfolio_link: '',
    store_name: user?.name,
    // store_cover_picture: [],
  });
  const [showRegModal, setShowRegModal] = useContext(RegisterContext);
  const { updateCart } = useContext(CartContext);
  useEffect(() => {
    if (!user) {
      navigate('/');
      setShowRegModal({ ...showRegModal, login: true, openStore: false });
    }
  }, [navigate, setShowRegModal, showRegModal, user]);
  const logout = async () => {
    await dispatch(logOutAction({ showToast: false }));
    navigate('/');
    setShowRegModal({ ...showRegModal, login: true, openStore: false });
    updateCart([]);
  };

  //Send to discord
  const handleBecomeCreator = async () => {
    try {
      await becomeCreatorWebhook(storeInfo);
    } catch (error) {
      console.error('Error subscribing:', error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!readTerms) {
      toast.error('Please read terms of use', {
        autoClose: 2000,
        className: 'toast__message',
      });
      return;
    }

    setIsLoading(true);
    await dispatch(
      createMerchandiseStore({
        storeInfo: {
          ...storeInfo,
        },
      })
    )
      .unwrap()
      .then(async (response) => {
        if (response) {
          setIsLoading(false);
          handleBecomeCreator();
          setShowRegModal({
            ...showRegModal,
            confirmStore: true,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);

        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
          if (err.response.data.errors) {
            if (err.response.data.errors.store_name) {
              setErrors((old) => ({ ...old, store_name: true }));
              toast.error(err.response.data.errors.store_name[0], {
                autoClose: 2000,
                className: 'toast__message',
              });
            }

            if (err.response.data.errors.store_email) {
              setErrors((old) => ({ ...old, store_email: true }));
              toast.error(err.response.data.errors.store_email[0], {
                autoClose: 2000,
                className: 'toast__message',
              });
            }

            if (err.response.data.errors.base64_photo) {
              setErrors((old) => ({ ...old, base64_photo: true }));
              toast.error(err.response.data.errors.base64_photo[0], {
                autoClose: 2000,
                className: 'toast__message',
              });
            }

            if (err.response.data.errors.why_loooty) {
              setErrors((old) => ({ ...old, why_loooty: true }));
              toast.error(err.response.data.errors.why_loooty[0], {
                autoClose: 2000,
                className: 'toast__message',
              });
            }

            if (err.response.data.errors.portfolio_link) {
              setErrors((old) => ({ ...old, portfolio_link: true }));
              toast.error(err.response.data.errors.portfolio_link[0], {
                autoClose: 2000,
                className: 'toast__message',
              });
            }
          }
        }
      });
  };
  return (
    <div
      className={`opn__open-store-modal-overlay ${
        fadeOut ? 'lgn__fade-out-anim' : ''
      }`}
    >
      {showRegModal.confirmStore && (
        <div
          className={`opn__open-store-modal-overlay ${
            fadeOut ? 'lgn__fade-out-anim' : ''
          }`}
        >
          <div
            style={{
              maxWidth: 400,
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
              backgroundColor: '#38383e',
              padding: '2rem',
            }}
          >
            <div>
              <p style={{ marginBottom: '1rem' }}>
                Thanks for applying to be a Loooty Creator! While we review your
                application, feel free to upload your products.
              </p>
              <p>
                {' '}
                Your store will go live as soon as we approve your application.
                Ready to share your creations with the world? Start uploading
                now!
              </p>
            </div>
            <button
              onClick={() => logout()}
              className='opn__open-store-btn'
              style={{ width: '100%' }}
            >
              <span className='opn__open-store-btn-text'>OK</span>
            </button>
          </div>
        </div>
      )}
      <div className='opn__open-store-modal'>
        <div className='opn__close-btn-container'>
          <div
            onClick={(e) => {
              setFadeOut((prev) => !prev);
              setTimeout(() => {
                setShowRegModal({ ...showRegModal, openStore: false });
              }, 700);
            }}
            className='opn__close-btn'
          >
            <span className='opn__close-btn-text'>X</span>
          </div>
        </div>
        <h2 className='opn__open-store-modal-title'>Opening a Store</h2>
        <div className='opn__open-store-modal-info'>
          <p>We can only accept the most qualified sellers at the moment</p>
          <p>Show us why you would be a great shop owner</p>
        </div>

        <div className='opn__open-store-form-group'>
          <label className='opn__open-store-form-label'>
            <span style={{ transform: 'skewX(25deg)' }}>Full name</span>
          </label>
          <div
            style={{
              border: errors.full_name
                ? '.2rem solid #df4759'
                : ' .2rem solid #2B2B38',
            }}
            className='opn__open-store-form-control-container'
          >
            <input
              placeholder='Loooty Collosal, Aristocrati etc..'
              onChange={(e) =>
                setStoreInfo((old) => ({ ...old, full_name: e.target.value }))
              }
              onFocus={() => {
                setErrors((old) => ({ ...old, full_name: false }));
              }}
              value={storeInfo.full_name}
              className='opn__open-store-form-control'
            />
          </div>
          <span className='opn__open-store-response-info'>
            Ensure this is your legal name as it will be used for payout
            verification
          </span>
        </div>

        <div className='opn__open-store-form-group'>
          <label className='opn__open-store-form-label'>
            <span style={{ transform: 'skewX(25deg)' }}>
              Links to your portfolio
            </span>
          </label>
          <div
            style={{
              border: errors.portfolio_link
                ? '.2rem solid #df4759'
                : ' .2rem solid #2B2B38',
            }}
            className='opn__open-store-form-control-container'
          >
            <input
              placeholder='http://myportfolio.com'
              onFocus={() => {
                setErrors((old) => ({ ...old, portfolio_link: false }));
              }}
              onChange={(e) =>
                setStoreInfo((old) => ({
                  ...old,
                  portfolio_link: e.target.value,
                }))
              }
              value={storeInfo.portfolio_link}
              className='opn__open-store-form-control'
            />
          </div>
        </div>

        <div className='opn__open-store-form-group'>
          <label className='opn__open-store-form-label'>
            <span style={{ transform: 'skewX(25deg)' }}>
              why do you want to sell on loooty?
            </span>
          </label>
          <div
            style={{
              border: errors.why_loooty
                ? '.2rem solid #df4759'
                : ' .2rem solid #2B2B38',
            }}
            className='opn__open-store-form-control-container'
          >
            <input
              placeholder='I want my work to reach the wider african audience'
              onFocus={() => {
                setErrors((old) => ({ ...old, why_loooty: false }));
              }}
              onChange={(e) =>
                setStoreInfo((old) => ({ ...old, why_loooty: e.target.value }))
              }
              value={storeInfo.why_loooty}
              className='opn__open-store-form-control'
            />
          </div>
        </div>

        <p className='opn__open-store-response-info'>
          {' '}
          You should receive an answer within a few hours
        </p>

        <div className='opn__open-store-terms-container'>
          <OrderItem
            isChecked={readCreatorTerms}
            setIsChecked={() => setReadCreatorTerms(!readCreatorTerms)}
          />

          <p>
            You agree to our
            <a href='/creator/agreement' style={{ color: '#FF9700' }}>
              {' '}
              Creator Onboarding Agreement
            </a>
          </p>
        </div>

        <div className='opn__open-store-terms-container'>
          <OrderItem
            isChecked={readTerms}
            setIsChecked={() => setReadTerms(!readTerms)}
          />

          <p>
            You agree to our
            <a href='/terms/conditions' style={{ color: '#FF9700' }}>
              {' '}
              Terms of use
            </a>{' '}
            &
            <a href='/privacy/policy' style={{ color: '#FF9700' }}>
              {' '}
              Privacy Policy
            </a>
          </p>
        </div>

        <div className='opn__open-store-btn-group'>
          <button
            disabled={!readTerms || !readCreatorTerms ? true : false}
            style={{
              opacity: !readTerms ? '0.6' : '1',
              backgroundColor: readTerms ? '#FF9700' : '#454350',
              outline: readTerms ? '3px solid #FFC166' : '3px solid #ffffff',
            }}
            onClick={handleSubmit}
            className='opn__open-store-btn'
          >
            <span className='opn__open-store-btn-text'>
              {isLoading ? <LoootyLoader /> : 'SUBMIT'}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default OpenStoreModal;
