import NavBar from '../component/navbar/navbar';
import { RiImageEditLine } from 'react-icons/ri';
import { FaCamera, FaPlusCircle } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import {
  fetchMerchandiseStore,
  fetchUser,
  updateMerchandiseStore,
  updateUserAction,
} from '../api/auth';
import { toast } from 'react-toastify';
import PageSelectComp from '../component/user/PageSelectComp';
import UniversalTagHeaderDesktop from '../component/user/UniversalTagHeaderDesktop';
import { getTeamRoleKey } from '../utils/getUserTeamRoleKey';
import { checkIsAdministrator } from '../utils/checkIsAdministrator';
import UniversalTagHeaderMobile from '../component/user/UniversalTagHeaderMobile';
import Cookies from 'js-cookie';
import LoootyLoader from '../component/loader/loootyLoader';
import { CGBarSlim } from '../component/card/cbBar';
import { getAllProductCategoriesAction } from '../api/admin';
import { MdDiscount } from 'react-icons/md';

const CreatorImg = '/assets/image/creatorDefaultImg.png';
const UserImg = '/assets/image/userDefaultImg.png';

export const ChangeCoverBtn = ({
  style,
  merchandiseStoreId,
  refetchProfile,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const selectCoverImage = async (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      setLoading(true);

      const base64Image = reader.result; // Keep the entire data URI

      await dispatch(
        updateMerchandiseStore({
          storeInfo: {
            id: merchandiseStoreId,
            base64_photos: [base64Image], // Include the entire data URI
          },
        })
      )
        .unwrap()
        .then(async () => {
          setLoading(false);
          toast.success('Cover image updated!', {
            autoClose: 2000,
            className: 'toast__message',
          });
          refetchProfile();
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
              className: 'toast__message',
            });
          }
        });
    };
  };

  return (
    <div style={style} className='user__change-cover-btn'>
      <label className='user__change-cover-btn-inner'>
        <RiImageEditLine style={{ color: 'white', fontSize: '1.4rem' }} />
        {loading ? <LoootyLoader /> : 'Change Cover'}
        <input
          id='inputTag'
          type='file'
          name='coverImage'
          accept='image/*'
          onChange={async (event) =>
            await selectCoverImage(event.target.files[0])
          }
          style={{ display: 'none' }}
        />
      </label>
    </div>
  );
};

export const ProfileUser = ({
  merchandiseStore,
  user,
  hasStore,
  profile,
  refetchProfile,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const selectProfileImage = async (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      setLoading(true);
      await dispatch(
        updateUserAction({
          updateData: {
            user_id: user?.id,
            base64_photos: [reader.result],
          },
        })
      )
        .unwrap()
        .then(async () => {
          setLoading(false);
          toast.success('Profile image updated!', {
            autoClose: 2000,
            className: 'toast__message',
          });
          refetchProfile();
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
              className: 'toast__message',
            });
          }
        });
    };
  };
  let teamKey = getTeamRoleKey(user.team_roles, 'store');
  return (
    <div className='user__profile-container'>
      <div className='user__profile-img-box'>
        <img
          src={profile?.picture || (teamKey ? CreatorImg : UserImg)}
          className='user__profile-img'
          alt='profile'
        />
        <label className='user__profile-img-change-box'>
          {!loading ? <FaCamera /> : <p>saving...</p>}
          <input
            type='file'
            name='profileImage'
            accept='image/*'
            onChange={async (event) =>
              await selectProfileImage(event.target.files[0])
            }
            style={{ display: 'none' }}
          />
        </label>
      </div>
      <h3 className='user__profile-name'>{profile?.name ?? ''}</h3>
      <p className='user__profile-name' style={{ fontSize: 20 }}>
        {profile?.description || 'No Bio Yet'}
      </p>

      {/* {hasStore && (
        <div className='user__profile-suscribers'>
          {merchandiseStore?.subscribers?.length ?? 0} Followers
        </div>
      )} */}
    </div>
  );
};
export const ProfileUserAdmin = ({ user, hasStore, style }) => {
  let teamKey = getTeamRoleKey(user.team_roles, 'store');
  return (
    <div style={style} className='user__profile-container admin'>
      <img
        src={user?.picture || (teamKey ? CreatorImg : UserImg)}
        className='user__profile-img admin'
        alt=''
      />
      <div className='user__profile-container-admin'>
        <h3 className='user__profile-name'>{user?.name || null}</h3>
        <p className='user__profile-name'>{user?.description || null}</p>

        {hasStore && (
          <div className='user__profile-suscribers'>
            {user?.subscriptions?.length ?? 0} Subscriptions
          </div>
        )}
      </div>
    </div>
  );
};

const User = () => {
  const [toggleVisAdmin, setToggleVisAdmin] = useState(false);
  const isMobile = useMediaQuery({ minWidth: 281, maxWidth: 768 });
  const [hasStore, setHasStore] = useState(false);
  const dispatch = useDispatch();
  const { subpage } = useParams();
  let user = null;

  if (typeof window !== 'undefined' && Cookies.get('user')) {
    try {
      user = JSON.parse(Cookies.get('user'));
    } catch (error) {
      console.error('Error parsing user cookie:', error);
    }
  }

  const hasStoreTags = [
    {
      name: 'store',
      // dropDownList: ['MY STORE'],
    },
    {
      name: 'profile',
    },
    {
      name: 'library',
      dropDownList: ['LIBRARY', 'WISH-LIST'],
    },

    {
      name: 'sales',
      dropDownList: ['SALES', 'PAYOUT SETTINGS'],
    },
    {
      name: 'settings',
      // dropDownList: ["affiliates", "settings"],
    },
  ];

  const hasNoStoreTags = [
    {
      name: 'profile',
    },
    {
      name: 'library',
      dropDownList: ['LIBRARY', 'WISH-LIST'],
    },
    {
      name: 'settings',
      // dropDownList: ["affiliates", "settings"],
    },
  ];

  const mobileHasStoreTags = [
    {
      name: 'store',
    },

    {
      name: 'profile',
    },
    {
      name: 'library',
    },
    {
      name: 'wish-list',
    },

    {
      name: 'sales',
    },
    {
      name: 'PAYOUT SETTINGS',
    },
    {
      name: 'settings',
    },
  ];

  const mobileHasNoStoreTags = [
    {
      name: 'SUBSCRIPTIONS',
    },
    {
      name: 'profile',
    },
    {
      name: 'library',
    },
    {
      name: 'wish-list',
    },

    {
      name: 'PAYOUT SETTINGS',
    },
    {
      name: 'settings',
    },
  ];
  const [merchandiseStore, setMerchandiseStore] = useState(null);
  const [yScroll, setYScroll] = useState(0);
  const [categories, setCategories] = useState([]);

  const defaultActiveInPageHeader = merchandiseStore ? 'store' : 'profile';
  const [activeInpageHeader, setActiveInPageHeader] = useState(
    subpage || defaultActiveInPageHeader
  );
  const navigate = useNavigate();
  const handleScroll = () => {
    setYScroll(window.scrollY);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.addEventListener('scroll', (e) => handleScroll(e));
    }

    return () => {
      window.removeEventListener('scroll', (e) => handleScroll(e));
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getAllProductCategoriesAction({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setCategories(response);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  const filterPublicProducts = (queryString) => {
    navigate(`/filter/result/${queryString}`);
  };
  // STORE COMPONENT FUNCTIONS

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    if (isMounted) {
      if (getTeamRoleKey(user?.team_roles, 'store')) {
        setHasStore(true);
      }

      if (checkIsAdministrator(user?.team_roles)) {
        setToggleVisAdmin(true);
      }

      setActiveInPageHeader(subpage);
    }
    if (hasStore) {
      dispatch(fetchMerchandiseStore({}))
        .unwrap()
        .then(async () => {
          if (isMounted) {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (err.response) {
            setIsLoading(false);
            if (err.status === 401) navigate('/coming/soon');
            toast.error(err.response.data.message, {
              autoClose: 2000,
              className: 'toast__message',
            });
          }
        });
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleVisAdmin, subpage, dispatch]);

  const [userProfile, setUserProfile] = useState(null);
  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(fetchUser({ properties: 1 }))
      .unwrap()
      .then((response) => {
        setMerchandiseStore(response.store);
        if (isMounted) setIsLoading(false);
        setUserProfile(response);
      })
      .catch((err) => {
        if (err?.response) {
          setIsLoading(false);
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
          isMounted && setIsLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  const refetchProfile = () => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(fetchUser({ properties: 1 }))
      .unwrap()
      .then((response) => {
        setMerchandiseStore(response.store);
        if (isMounted) setIsLoading(false);
        setUserProfile(response);
      })
      .catch((err) => {
        if (err?.response) {
          setIsLoading(false);
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
          isMounted && setIsLoading(false);
        }
      });
  };

  if (!merchandiseStore && hasStore)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20%',
        }}
      >
        <LoootyLoader />
      </div>
    );
  return (
    <section className='user__main-container'>
      <header>
        <NavBar
          pageType={'user'}
          loggedIn={true}
          shortSearch={true}
          style={{ paddingBottom: '0', paddingTop: '0' }}
        />
      </header>

      <main className='user__main'>
        <section
          style={{
            backgroundImage: `url(${
              merchandiseStore
                ? merchandiseStore?.store_pictures[0]
                : '/assets/image/background/empty-store.png'
            })`,
            backgroundSize: `${merchandiseStore ? 'cover' : 'contain'}`,
          }}
          className='user__profile-preview-container'
        >
          {merchandiseStore && (
            <ChangeCoverBtn
              merchandiseStoreId={merchandiseStore.id}
              refetchProfile={refetchProfile}
            />
          )}

          <ProfileUser
            merchandiseStore={merchandiseStore}
            user={user}
            profile={userProfile}
            hasStore={hasStore}
            refetchProfile={refetchProfile}
          />

          {yScroll > 50 ? (
            <CGBarSlim
              categoryList={categories}
              onFilter={filterPublicProducts}
              style={{
                top: yScroll > 50 && 0,
                zIndex: 1500,
              }}
            />
          ) : null}

          <div className='user__profile-caution'>
            {merchandiseStore
              ? merchandiseStore.why_loooty
              : user
              ? user?.email
              : null}
          </div>

          {/* <div className="user__location-container">
            <RiInstagramFill /> Enugu, Nigeria.
          </div> */}
        </section>
        {merchandiseStore && user !== null && (
          <section className='user__product-action-container'>
            {merchandiseStore && user !== null && (
              <button
                onClick={(e) => navigate('/user/upload')}
                className='user__upload-products-btn'
              >
                <div className='user__upload-products-btn-inner'>
                  UPLOAD&nbsp;PRODUCTS{' '}
                  <FaPlusCircle
                    style={{ fontSize: '1.3rem' }}
                    color='rgba(255,255,255,0.5)'
                  />
                </div>
              </button>
            )}

            {/* {!merchandiseStore && user !== null && (
            <button
              onClick={(e) => navigate('/open/store')}
              className='user__upload-products-btn'
            >
              <div className='user__upload-products-btn-inner'>''</div>
            </button>
          )} */}

            <button className='user__upload-products-btn'>
              <div className='user__upload-products-btn-inner'>
                DISCOUNTS{' '}
                <MdDiscount
                  style={{ fontSize: '1.3rem', transform: 'scaleX(-1)' }}
                  color='rgba(255,255,255,0.5)'
                />
              </div>
            </button>

            <div className='user__admin-visitor-container'>
              <button
                onClick={() => setToggleVisAdmin(true)}
                className={`user__AV-btn ${
                  toggleVisAdmin && 'user_AV-btn-active'
                }`}
              >
                <span className='user__AV-btn-inner'>Admin</span>
              </button>
              <button
                onClick={() => navigate(`/store/${merchandiseStore.id}`)}
                className={`user__AV-btn ${
                  !toggleVisAdmin && 'user_AV-btn-active-dup'
                }`}
              >
                <span className='user__AV-btn-inner'>Visitor</span>
              </button>
            </div>
          </section>
        )}
        {!isMobile ? (
          <UniversalTagHeaderDesktop
            toggleVisAdmin={toggleVisAdmin}
            activeInpageHeader={activeInpageHeader}
            TagList={
              merchandiseStore && user !== null ? hasStoreTags : hasNoStoreTags
            }
            setActiveInPageHeader={setActiveInPageHeader}
          />
        ) : isMobile ? (
          <UniversalTagHeaderMobile
            toggleVisAdmin={toggleVisAdmin}
            TagList={
              merchandiseStore && user !== null
                ? mobileHasStoreTags
                : mobileHasNoStoreTags
            }
            activeInpageHeader={activeInpageHeader}
            setActiveInPageHeader={setActiveInPageHeader}
          />
        ) : !isMobile ? (
          <UniversalTagHeaderMobile
            activeInpageHeader={activeInpageHeader}
            setActiveInPageHeader={setActiveInPageHeader}
          />
        ) : (
          <UniversalTagHeaderDesktop
            activeInpageHeader={activeInpageHeader}
            setActiveInPageHeader={setActiveInPageHeader}
          />
        )}
        <PageSelectComp
          inPageName={activeInpageHeader}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          refetchProfile={refetchProfile}
        />
      </main>
    </section>
  );
};

export default User;
