import { useState } from 'react';
import CarouselSec from '../../buy/CarouselSec';
import DetailsRight from './DetailsRight';

export default function Details({ product, mgtAction, refetchMerchandise }) {
  const [showDesc, setShowdesc] = useState(false);
  const [showFiles, setShowfiles] = useState(false);

  return (
    <div className='product__details'>
      <CarouselSec product={product} />
      {product && (
        <div className='details__right'>
          <DetailsRight
            product={product}
            showDesc={showDesc}
            setShowdesc={setShowdesc}
            showFiles={showFiles}
            setShowfiles={setShowfiles}
            mgtAction={mgtAction}
            refetchMerchandise
          />
        </div>
      )}
    </div>
  );
}
