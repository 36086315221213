import { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import LoootyLoader from '../loader/loootyLoader';
import { useSearchParams } from 'react-router-dom';
import { resetPassword } from '../../api/auth';
import { BsEyeFill } from 'react-icons/bs';
import { FaEyeSlash } from 'react-icons/fa';

function validatePassword(password) {
  const lengthRegex = /^.{8,}$/; // Minimum 8 characters
  const capitalLetterRegex = /[A-Z]/; // At least one uppercase letter
  const symbolRegex = /[!@#$%^&*()_+{}:;<>,.?~\\-]/; // At least one symbol

  if (
    lengthRegex.test(password) &&
    capitalLetterRegex.test(password) &&
    symbolRegex.test(password)
  ) {
    return true;
  } else {
    return false;
  }
}

const ResetPasswordModal = () => {
  const web_url = process.env.REACT_APP_WEB_URL || '';

  const [fadeOut] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({
    email: false,
    new_password: false,
    new_password_confirmation: false,
  });

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const userEmail = localStorage.getItem('loootymail');
  const [userDetails, setUserDetails] = useState({
    email: userEmail,
    token,
    new_password: '',
    new_password_confirmation: '',
  });
  const [hidePassword, setHidePassword] = useState(true);

  // const facebookWebSignIn = () => {
  //   let data = {
  //     client_id: process.env.REACT_APP_FACEBOOK_APP_ID,
  //     nonce:(Math.random() + 1).toString(36),
  //     redirect_uri:`${web_url}/api/auth/socialite/facebook/callback`,
  //     response_mode:'fragment',
  //     response_type:'code+id_token',
  //     scope: '',
  //     state:'state_parameter_passthrough_value',
  //   }

  //   return `https://developer.facebook.com/auth/authorize?client_id=${data.client_id}&nonce=${data.nonce}&redirect_uri=${data.redirect_uri}&response_mode=${data.response_mode}&response_type=${data.response_type}&scope=${data.scope}&state=${data.state}`;
  // }

  const sendResetMail = async (e) => {
    e.preventDefault();
    if (!userDetails.email) {
      toast.error('Please input email', {
        autoClose: 2000,
        className: 'toast__message',
      });
      return;
    }

    if (!validatePassword(userDetails.new_password)) {
      toast.error(
        'Password must be atleast 8 characters, including a capital letter and a symbol',
        {
          autoClose: 2000,
          className: 'toast__message',
        }
      );
      return;
    }
    if (userDetails.new_password !== userDetails.new_password_confirmation) {
      toast.error('Password must match', {
        autoClose: 2000,
        className: 'toast__message',
      });
      return;
    }
    setIsLoading(true);

    try {
      await dispatch(resetPassword({ resetData: { ...userDetails } }))
        .unwrap()
        .then(async () => {
          setIsLoading(false);
          toast.success('Your password has been reset', {
            autoClose: 2000,
            className: 'toast__message',
          });
          setTimeout(() => {
            window.location.href = `${web_url}/login`;
          }, 1000);
        })
        .catch((err) => {
          console.log({ err });
          toast.error(err || 'Error! Try again later', {
            autoClose: 2000,
            className: 'toast__message',
          });
          setIsLoading(false);
        });
    } catch (error) {
      // Handle other errors here
    }
  };

  return (
    <div
      className={`reg__sign-up-overlay ${fadeOut ? 'lgn__fade-out-anim' : ''}`}
    >
      <section className='reg__sign-up-modal'>
        <div className='reg__close-btn-container'></div>
        <div className='reg__loooty-innitials-container'>
          <div className='reg_logo-container'></div>
          <div className='reg__loooty-about'>
            <div>Welcome back</div>
          </div>

          <ul className='reg__loooty-features'>
            <li className='reg__loooty-features-item'>
              Advance search and filtering options
            </li>
            <li className='reg__loooty-features-item'>
              Add art work to custom selection
            </li>
            <li className='reg__loooty-features-item'>
              Never miss a new artwork with tag
            </li>
            <li className='reg__loooty-features-item'>
              Uploader and collection subscription
            </li>
          </ul>
        </div>

        <div className='reg__sign-up-forms-container'>
          <form
            autoComplete='off'
            onSubmit={(e) => sendResetMail(e)}
            className='reg__sign-up-form'
          >
            <div>
              <div className='reg__form-group-container'>
                <p style={{ color: '#b0b0bf' }}>Email</p>
                <div
                  style={{
                    border: errors.email
                      ? '.2rem solid #df4759'
                      : '.2rem solid #2B2B38',
                  }}
                  className='reg__form-group'
                >
                  <input
                    autoComplete='off'
                    type='text'
                    onFocus={() => {
                      setErrors((old) => ({ ...old, email: false }));
                    }}
                    onChange={(e) =>
                      setUserDetails((old) => ({
                        ...old,
                        email: e.target.value,
                      }))
                    }
                    value={userDetails.email}
                    className='reg__form-control'
                    placeholder='Enter email'
                  />
                  <span
                    style={{ visibility: 'hidden' }}
                    className='sign__hide-sensitive-info-button'
                  >
                    <BsEyeFill className='lgn__hide-sensitive-ib-icon' />
                  </span>
                </div>

                <div className='reg__form-group-container'>
                  <p style={{ color: '#b0b0bf' }}>New Password</p>
                  <div
                    style={{
                      border: errors.new_password
                        ? '.2rem solid #df4759'
                        : '.2rem solid #2B2B38',
                    }}
                    className='reg__form-group'
                  >
                    <input
                      autoComplete='off'
                      type={hidePassword ? 'password' : 'text'}
                      onFocus={() => {
                        setErrors((old) => ({ ...old, new_password: false }));
                      }}
                      onChange={(e) =>
                        setUserDetails((old) => ({
                          ...old,
                          new_password: e.target.value,
                        }))
                      }
                      value={userDetails.new_password}
                      className='reg__form-control'
                      placeholder='Enter email'
                    />
                    <span
                      onClick={() => setHidePassword(!hidePassword)}
                      className='sign__hide-sensitive-info-button'
                    >
                      {hidePassword ? (
                        <BsEyeFill className='lgn__hide-sensitive-ib-icon' />
                      ) : (
                        <FaEyeSlash className='lgn__hide-sensitive-ib-icon' />
                      )}
                    </span>
                  </div>
                  <div className='reg__form-group-container'>
                    <p style={{ color: '#b0b0bf' }}>Confirm New Password</p>
                    <div
                      style={{
                        border: errors.new_password_confirmation
                          ? '.2rem solid #df4759'
                          : '.2rem solid #2B2B38',
                      }}
                      className='reg__form-group'
                    >
                      <input
                        autoComplete='off'
                        type={hidePassword ? 'password' : 'text'}
                        onFocus={() => {
                          setErrors((old) => ({
                            ...old,
                            new_password_confirmation: false,
                          }));
                        }}
                        onChange={(e) =>
                          setUserDetails((old) => ({
                            ...old,
                            new_password_confirmation: e.target.value,
                          }))
                        }
                        value={userDetails.new_password_confirmation}
                        className='reg__form-control'
                        placeholder='Enter email'
                      />
                      <span
                        onClick={() => setHidePassword(!hidePassword)}
                        className='sign__hide-sensitive-info-button'
                      >
                        {hidePassword ? (
                          <BsEyeFill className='lgn__hide-sensitive-ib-icon' />
                        ) : (
                          <FaEyeSlash className='lgn__hide-sensitive-ib-icon' />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  onClick={sendResetMail}
                  className='reg__login-btn'
                  style={{ marginTop: '2rem', padding: '1rem 2rem' }}
                >
                  <span className='reg__login-btn-text'>
                    {isLoading ? <LoootyLoader /> : 'Reset Password'}
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default ResetPasswordModal;
