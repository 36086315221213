import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setSelectedSubPage,
  setSelectedSubPageAdmin,
} from "../../redux/reducers/userStateReducer";
import IdentityBtn from "../buttons/identityBtn";

export const defaultTags = [
  {
    name: "subscriptions",
  },
  {
    name: "profile",
  },
  {
    name: "library",
  },
  {
    name: "messages",
  },
  {
    name: "settings",
    // dropDownList: ["affiliates", "settings"],
  },
];

const UniversalTagHeaderDesktop = ({
  activeInpageHeader,
  setActiveInPageHeader,
  TagList = defaultTags,
  style,
  onClick,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  return (
    <section
      style={style}
      className="user__admin-page-section-header-container"
    >
      {TagList?.map((tagsBtn, index) => (
        <IdentityBtn
          key={tagsBtn.name}
          onClick={(e) => {
            if (onClick) {
              onClick();
              return;
            }
            dispatch(setSelectedSubPage({ selected: tagsBtn.name }));
            setActiveInPageHeader(tagsBtn.name.toLowerCase())
            navigate(`/user/${tagsBtn.name}`)
          }}
          style={{
            padding: "1rem 6rem",
            backgroundColor: `${
              activeInpageHeader === tagsBtn.name ||
              (tagsBtn.dropDownList && activeInpageHeader === tagsBtn.dropDownList[0].toLowerCase()) || 
              (tagsBtn.dropDownList && activeInpageHeader === tagsBtn.dropDownList[1].toLowerCase())
                ? "#15151C"
                : "#191921"
            }`,
            color: `${
              activeInpageHeader === tagsBtn.name ? "#CECED8" : "#4D4D6B"
            }`,
            borderLeft: `1rem solid ${
              activeInpageHeader === tagsBtn.name ||
              (tagsBtn.dropDownList && activeInpageHeader === tagsBtn.dropDownList[0].toLowerCase()) || 
              (tagsBtn.dropDownList && activeInpageHeader === tagsBtn.dropDownList[1].toLowerCase())
              ? "#7C187A" : "#353449"
            }`,
          }}
          dropDownList={tagsBtn.dropDownList}
          text={tagsBtn.name.toUpperCase()}
          
        />
      ))}
    </section>
  );
};

export const UniversalTagHeaderDesktopAdmin = ({
  activeInpageHeader,
  setActiveInPageHeader,
  TagList = defaultTags,
  style,
  onClick,
}) => {
  const dispatch = useDispatch();
  return (
    <section
      style={style}
      className="user__admin-page-section-header-container"
    >
      {TagList.map((tagsBtn) => (
        <IdentityBtn
          key={tagsBtn.name}
          onClick={(e) => {
            if (onClick) {
              onClick();
              // return
            }
            dispatch(setSelectedSubPageAdmin({ selected: tagsBtn.name }));
            setActiveInPageHeader(tagsBtn.name.toLowerCase())
          }}
          style={{
            padding: "1rem 6rem",
            backgroundColor: `${
              activeInpageHeader === tagsBtn.name ? "#15151C" : "#191921"
            }`,
            color: `${
              activeInpageHeader === tagsBtn.name ? "#CECED8" : "#4D4D6B"
            }`,
            borderLeft: `1rem solid ${
              activeInpageHeader === tagsBtn.name ? "#7C187A" : "#353449"
            }`,
          }}
          dropDownList={tagsBtn.dropDownList}
          text={tagsBtn.name.toUpperCase()}
        />
      ))}
    </section>
  );
};
export default UniversalTagHeaderDesktop;