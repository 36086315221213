import { getTeamRoleKey } from '../../../utils/getUserTeamRoleKey';
import UserPurpleInnerStripeBtn from '../../buttons/UserPurpleButton';
import CardArrowPointer from '../home/CardArrowPointer';
import AfilliatedDropdown from './AfilliatedDropdown';
import FireMailButton from './FireMailButton';
import LongSelectedCone from './LongSelectedCone';
import NotifierMail from './NotifierMail';
import ReportMessageNotifier from './ReportMessageNotifier';
import VerifiedLogo from './VerifiedLogo';
import { GoLocation } from 'react-icons/go';

const CreatorImg = '/assets/image/creatorDefaultImg.png';
const UserImg = '/assets/image/userDefaultImg.png';
const ListedUser = ({
  isUser = false,
  selected,
  isAffiliated,
  isRequested,
  styleVerifySign,
  selectAUser,
  showVerifySign,
  userData,
  showBlockUserModal,
  showUnblockUserModal,
}) => {
  let teamKey = getTeamRoleKey(userData?.roles, 'store');

  return (
    <section className='admin__users-listed-outer'>
      <div
        onClick={() => selectAUser()}
        className={`admin__users-listed-user ${selected && 'selected'}`}
      >
        {selected && <LongSelectedCone />}
        <div className='admin__users-listed-item image'>
          <img
            className='admin__users-listed-item image'
            src={userData?.picture || (teamKey ? CreatorImg : UserImg)}
            alt=''
          />
        </div>
        <div className='admin__users-listed-item name'>
          {userData?.name}
          {showVerifySign && <VerifiedLogo style={styleVerifySign} />}
        </div>
        <div className='admin__users-listed-item location'>
          <GoLocation /> {userData?.location}
        </div>
        <div className='admin__users-listed-item email'>{userData?.email}</div>
        <div className='admin__users-listed-item email'>
          {userData?.phone || userData?.email}
        </div>
        {!isUser && (
          <div className='admin__users-listed-item extra-container'>
            {selected ? (
              <div className='admin__users-listed-item extra'>
                <NotifierMail isUrgent={true} />
                <FireMailButton />
                <UserPurpleInnerStripeBtn
                  onClick={
                    userData?.blocked
                      ? showUnblockUserModal
                      : showBlockUserModal
                  }
                  style={{ width: '10rem', padding: '1rem 3rem' }}
                  text={
                    userData?.blocked ? (
                      <>
                        <span>UNBLOCK</span>
                        <span>USER</span>
                      </>
                    ) : (
                      <>
                        <span>BLOCK</span>
                        <span>USER</span>
                      </>
                    )
                  }
                />{' '}
              </div>
            ) : (
              <ReportMessageNotifier
                occupantText={isRequested ? 'Store Request' : 'Report'}
                isRequested={isRequested}
              />
            )}
          </div>
        )}
        <CardArrowPointer
          style={{
            top: '40%',
            right: '1.2%',
            color: `${selected ? '#7C187A' : '#3B3B43'}`,
            transform: `rotate(${selected ? '-90deg' : '90deg'})`,
          }}
        />
      </div>
      {isAffiliated && selected && <AfilliatedDropdown />}
    </section>
  );
};

export default ListedUser;
