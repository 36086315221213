import { useState } from 'react';

const WolfGuyImg = '/assets/image/loootydefault.jpg';

const AffiliatedItem = ({
  productName = 'Beach House with Rumps',
  productLink = 'https://loooty.vercel.app/...',
  productCoverImage = WolfGuyImg,
  productQty = 20,
  productPpb = 'USD 20.00',
  productAccumulated = 'USD 400.00',
}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  return (
    <div className='admin__users-afilliated-item'>
      <div className='admin__users-afilliated-item-sub container'>
        <img
          className='admin__users-afilliated-item-sub image'
          src={productCoverImage}
          alt=''
        />
      </div>
      <div
        className={`admin__users-afilliated-item-sub product-name ${
          isEnabled && 'enabled'
        } `}
      >
        {productName}
      </div>
      <div className='admin__users-afilliated-item-sub product-link'>
        {productLink}
      </div>

      <div className='admin__users-afilliated-item-sub product-qty'>
        {productQty}
      </div>

      <div className='admin__users-afilliated-item-sub product-ppb'>
        {productPpb}
      </div>

      <div className='admin__users-afilliated-item-sub product-accumulated'>
        {productAccumulated}
      </div>
      <div className='admin__users-afilliated-item-sub modifiers'>
        <button
          onClick={() => setIsEnabled(true)}
          className={`admin__users-afilliated-modifier-btn ${
            isEnabled && 'enabled'
          }`}
        >
          Enable
        </button>
        <button
          onClick={() => setIsEnabled(false)}
          className={`admin__users-afilliated-modifier-btn ${
            !isEnabled && 'disabled'
          }`}
        >
          Disable
        </button>
      </div>
    </div>
  );
};

export default AffiliatedItem;
