import { MdEdit } from 'react-icons/md';
import LoootyLoader from '../../../loader/loootyLoader';
import { BiCategory } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { createCatalogue } from '../../../../api/admin';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { searchMerchandiseAction } from '../../../../api/auth';

const CreateCatalogueModal = ({
  refetch,
  toggleShowModal,
  fadeOut,
  setFadeOut,
  isLoading,
  products,
}) => {
  const [transFormedProducts, setTransformedProducts] = useState(products);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    catalogue_title: '',
    catalogue_description: '',
    catalogue_stores: ['270427-5238g8h3-45r8u235'],
  });

  const [query, setQuery] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);

  function toggleSelection(id) {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  }
  //create catalogue
  const handleCreateCatalogue = () => {
    dispatch(
      createCatalogue({
        catalogueInfo: { ...data, catalogue_merchandise: selectedIds },
      })
    )
      .unwrap()
      .then(() => {
        setTransformedProducts(products);
        refetch();
        toast.success('Catalogue Created', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setTimeout(() => {
          toggleShowModal();
        }, 200);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  };
  useEffect(() => {
    if (query?.length < 3) {
      setTransformedProducts(products);
      return;
    }
    dispatch(searchMerchandiseAction({ query: { search: query } }))
      .unwrap()
      .then((response) => {
        setTransformedProducts(response);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  }, [query, products, dispatch]);
  return (
    <div
      className={`reg__sign-up-overlay ${fadeOut ? 'lgn__fade-out-anim' : ''}`}
      style={{ color: '#ffffff' }}
    >
      <section className='reg__sign-up-modal user__withdrawal-modal'>
        <div className='reg__close-btn-container'>
          <div
            onClick={(e) => {
              setFadeOut((prev) => !prev);
              setTimeout(() => {
                toggleShowModal();
              }, 200);
            }}
            className='reg__close-btn'
          >
            <span className='reg__close-btn-text'>X</span>
          </div>
        </div>

        <div className='reg__sign-up-forms-container withdrawal-modal'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <BiCategory
              className='user__lib-folder-large'
              style={{
                fontSize: '1.5rem',
                marginRight: '1rem',
                color: '#74747d',
              }}
            />
            <h2 className='user__withdrawal-modal-heading'>Create Catalogue</h2>
          </div>

          <div>
            <p
              className='user__delete-folder-modal-text'
              style={{ marginBottom: '2rem' }}
            >
              Create a catalogue that can be used to identify similar items on
              the app
            </p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleCreateCatalogue();
              }}
            >
              <div>
                <p style={{ margin: '0.5rem 0' }}>Catalogue Title</p>

                <input
                  type='text'
                  onChange={(e) =>
                    setData((old) => ({
                      ...old,
                      catalogue_title: e.target.value,
                    }))
                  }
                  value={data?.catalogue_title || ''}
                  className='catalogue__titleInput'
                />
              </div>
              <section className='upload__text-area-container'>
                <p style={{ margin: '1rem 0 0.5rem' }}>Catalogue description</p>
                <textarea
                  onChange={(e) =>
                    setData((old) => ({
                      ...old,
                      catalogue_description: e.target.value,
                    }))
                  }
                  value={data?.catalogue_description || ''}
                  rows={1}
                  cols={30}
                  className='upload__text-area'
                ></textarea>
                <button className='upload__text-area-btn'>
                  <MdEdit />
                </button>
              </section>

              <div className='catalogue__container'>
                <p>Click to Add Product to catalogue</p>
                <input
                  type='text'
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder='search products'
                  value={query}
                  className='catalogue__titleInput'
                />
                <div className='catalogue__products'>
                  {transFormedProducts?.map((prod) => (
                    <div
                      key={prod.id}
                      onClick={() => toggleSelection(prod.id)}
                      style={
                        selectedIds.includes(prod.id)
                          ? { border: '1px solid rgb(255, 151, 0)' }
                          : undefined
                      }
                    >
                      <img
                        src={prod.merchandise_banners[0]}
                        alt='banner'
                        width={58}
                        height={58}
                      />
                      <p>{prod.merchandise_name}</p>
                      <span
                        className={
                          selectedIds.includes(prod.id)
                            ? 'catalogue__selected'
                            : 'no-display'
                        }
                      >
                        <IoMdCheckmarkCircle color='#e8e8e8' size={20} />
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <div className='user__delete-folder-btn-container'>
                <div
                  className='user__library-trash-btn'
                  type='button'
                  onClick={() => {
                    setFadeOut((prev) => !prev);
                    setTimeout(() => {
                      toggleShowModal();
                    }, 200);
                  }}
                >
                  <span className='user__library-trash-btn-text'>Cancel</span>
                </div>

                <div style={{ width: 'fit-content', marginLeft: '1rem' }}>
                  <button className='user__upload-products-btn' type='submit'>
                    <div className='user__upload-products-btn-inner'>
                      {isLoading ? <LoootyLoader /> : 'SAVE'}
                    </div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateCatalogueModal;
