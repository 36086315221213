import Affiliates from './Affiliates';
import Library from './Library';
import Collections from './Collections';
import Messages from './Messages';
import Profile from './ProFile';
import Settings from './Settings';
import Store from './Store';
import Suscribers from './Suscribers';
import Balance from './Balance';
import Sales from './Sales';

const PageSelectComp = ({ inPageName, refetchProfile }) => {
  switch (inPageName.toLowerCase()) {
    case 'profile':
      return <Profile refetchHeadProfile={refetchProfile} />;

    case 'store':
      return <Store />;

    case 'library':
      return <Library />;

    case 'wish-list':
      return <Collections />;

    case 'subscriptions':
      return <Suscribers />;

    case 'messages':
      return <Messages />;

    case 'settings':
      return <Settings />;

    case 'sales':
      return <Sales />;

    case 'payout settings':
      return <Balance />;

    case 'affiliates':
      return <Affiliates />;

    default:
      return <Profile />;
  }
};

export default PageSelectComp;
