import React, { createContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserCartAction } from "../../../api/user";

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [loadingCart, setLoading] = useState(true);
  const dispatch = useDispatch();

  // Fetch cart data from the backend when the component mounts
  useEffect(() => {
    let isMounted = true;
    const fetchCartFromBackend = async () => {
      try {
        // Fetch cart data from the backend using Redux action
        const response = await dispatch(getUserCartAction({})).unwrap();
        setCart(response.products);
      } catch (error) {
        // Handle errors if needed
        // console.error('Error fetching cart data:', error);
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (isMounted) fetchCartFromBackend();
    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  const updateCart = (newCart) => {
    setCart(newCart);
  };

  const cartLength = cart?.length;

  if (loadingCart) {
    return <div>Loading...</div>;
  }

  return (
    <CartContext.Provider value={{ cart, updateCart, cartLength, loadingCart }}>
      {children}
    </CartContext.Provider>
  );
};

export { CartProvider, CartContext };
