import { createSlice } from '@reduxjs/toolkit';

const progressSlice = createSlice({
  name: 'progress',
  initialState: {
    uploadProgress: 0,
    productCartPrice: 0,
    productCartLicense: 'basic',
  },
  reducers: {
    setUploadProgress: (state, action) => {
      state.uploadProgress = action.payload;
    },
    resetUploadProgress: (state) => {
      state.uploadProgress = 0;
    },
    setProductCartPrice: (state, action) => {
      state.productCartPrice = action.payload;
    },
    setProductCartLicense: (state, action) => {
      state.productCartLicense = action.payload;
    },
  },
});

export const {
  setUploadProgress,
  resetUploadProgress,
  setProductCartPrice,
  setProductCartLicense,
} = progressSlice.actions;

export const selectUploadProgress = (state) => state.progress.uploadProgress;
export const productCartPrice = (state) => state.progress.productCartPrice;
export const productCartLicense = (state) => state.progress.productCartLicense;

export default progressSlice.reducer;
