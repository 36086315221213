import { useState } from 'react';
import { ImUserMinus } from 'react-icons/im';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteUserAction } from '../../../api/auth';
import LoootyLoader from '../../loader/loootyLoader';

const DeleteUserModal = ({ toggleShowModal }) => {
  const dispatch = useDispatch();
  const [fadeOut, setFadeOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.user?.user);

  const deleteUser = async () => {
    setIsLoading(true);

    await dispatch(
      deleteUserAction({
        deleteData: {
          user_id: user?.id,
        },
      })
    )
      .unwrap()
      .then(() => {
        toast.success('User Account deleted', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
        setFadeOut((prev) => !prev);
        setTimeout(() => {
          toggleShowModal();
        }, 600);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  return (
    <div
      className={`reg__sign-up-overlay ${fadeOut ? 'lgn__fade-out-anim' : ''}`}
    >
      <section className='reg__sign-up-modal user__withdrawal-modal'>
        <div className='reg__close-btn-container'>
          <div
            onClick={(e) => {
              setFadeOut((prev) => !prev);
              setTimeout(() => {
                toggleShowModal();
              }, 600);
            }}
            className='reg__close-btn'
          >
            <span className='reg__close-btn-text'>X</span>
          </div>
        </div>

        <div className='reg__sign-up-forms-container withdrawal-modal'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <ImUserMinus
              className='user__lib-folder-large'
              style={{
                fontSize: '1.5rem',
                marginRight: '1rem',
                color: '#74747d',
              }}
            />
            <h2 className='user__withdrawal-modal-heading'>Delete Account</h2>
          </div>

          <div>
            <p className='user__delete-folder-modal-text'>
              Delete Account, action is irreversible, continue ?
            </p>

            <div className='user__delete-folder-btn-container'>
              <button
                className='user__library-trash-btn'
                type='button'
                onClick={() => {
                  setFadeOut((prev) => !prev);
                  setTimeout(() => {
                    toggleShowModal();
                  }, 400);
                }}
              >
                <span className='user__library-trash-btn-text'>Cancel</span>
              </button>

              <div style={{ width: 'fit-content', marginLeft: '1rem' }}>
                <button
                  onClick={deleteUser}
                  className='user__upload-products-btn'
                  type='button'
                >
                  <div className='user__upload-products-btn-inner'>
                    {isLoading ? <LoootyLoader /> : 'Delete'}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DeleteUserModal;
