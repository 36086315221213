import LoootyLoader from "../../../loader/loootyLoader";
import SidebarInfoField from "../../features/SidebarInfoField";

const ApprovePayoutModal = ({
  toggleShowModal,
  fadeOut,
  payoutRequestData,
  setFadeOut,
  isLoading,
  approvePayout,
}) => {
  return (
    <div
      className={`reg__sign-up-overlay ${fadeOut ? "lgn__fade-out-anim" : ""}`}
      style={{ color: "#ffffff" }}
    >
      <section className="reg__sign-up-modal user__withdrawal-modal">
        <div className="reg__close-btn-container">
          <div
            onClick={(e) => {
              setFadeOut((prev) => !prev);
              setTimeout(() => {
                toggleShowModal();
              }, 600);
            }}
            className="reg__close-btn"
          >
            <span className="reg__close-btn-text">X</span>
          </div>
        </div>

        <div className="reg__sign-up-forms-container withdrawal-modal">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <h2 className="user__withdrawal-modal-heading">Payout</h2>
            <span
              className="user__withdrawal-modal-balance-amount"
              style={{
                width: "fitContent",
                padding: "0.8rem 1rem",
                margin: "auto",
                color: "#ff9700",
              }}
            >
              ${payoutRequestData?.amount}
            </span>
          </div>

          <div>
            <div className="user__withdrawal-modal-payment-header">
              PAYMENT DETAILS
            </div>

            <section className="admin__sidebar-info">
              <SidebarInfoField
                leftText="Method"
                rightText={payoutRequestData?.payment_method}
                style={{ paddingLeft: "0" }}
              />

              <SidebarInfoField
                leftText="Merchant"
                rightText={payoutRequestData?.payment_merchant}
                style={{ paddingLeft: "auto" }}
              />

              <SidebarInfoField
                leftText="Accounterchant"
                rightText={payoutRequestData?.payout_id}
              />
            </section>

            <div style={{ width: "fit-content", margin: " 2rem auto" }}>
              <button
                onClick={approvePayout}
                className="user__sales-mc-item-btn"
                type="button"
              >
                <div className="user__upload-products-btn-inner">
                  {isLoading ? <LoootyLoader /> : "PAID"}
                </div>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ApprovePayoutModal;
