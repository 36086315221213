import { BsSuitHeartFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
// import { StarRating } from '../../card/landCard';
import {
  addProductIntoCollection,
  fetchCollections,
  removeProductIntoCollection,
} from '../../../api/auth';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const WhiteList = ({
  color = '#7c187a',
  degrees,
  left,
  right,
  top,
  bottom,
  isTop,
  isFav,
  favCount,
  addToWishlist,
  removeFromWishlist,
}) => {
  return (
    <div
      style={{ transform: `rotate(${degrees})`, left, right, top, bottom }}
      className='landing__card-whiteList'
    >
      {isTop && isFav && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p
            style={{
              color: '#ffffff',
              fontSize: '0.9rem',
              left: '4%',
              top: '10%',
              position: 'absolute',
            }}
          >
            {favCount}
          </p>
          <BsSuitHeartFill
            style={{
              color: 'red',
              fontSize: '1.1rem',
              left: '15%',
              top: '13%',
              position: 'absolute',
              cursor: 'pointer',
            }}
            onClick={removeFromWishlist}
          />
        </div>
      )}

      {isTop && !isFav && (
        <BsSuitHeartFill
          style={{
            color: '#ffffff',
            fontSize: '1.1rem',
            left: '15%',
            top: '13%',
            position: 'absolute',
            cursor: 'pointer',
          }}
          onClick={addToWishlist}
        />
      )}

      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 87.11 91.79'>
        <g id='Layer_2' data-name='Layer 2'>
          <g id='Layer_1-2' data-name='Layer 1'>
            <polygon
              points='0.34 91.79 0 0.1 87.11 0 0.34 91.79'
              style={{ fill: color }}
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

const AdminProductCard = ({
  onClick,
  img,
  title,
  owner,
  firstTag,
  price,
  productId,
  visibility,
  navigateToStore,
  favCount,
  ratingsScore,
  ratingsCount,
  wishlistIds,
  setCollections,
  selectedProduct,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const wishlistNumber =
    wishlistIds &&
    Object.keys(wishlistIds).map((key) => Number(wishlistIds[key]));
  const isWishlisted = wishlistNumber?.includes(productId);
  let user = {};
  if (typeof window !== 'undefined' && Cookies.get('user')) {
    try {
      user = JSON.parse(Cookies.get('user'));
    } catch (error) {
      // Handle the error, e.g., the cookie contains invalid JSON
      console.error("Error parsing 'user' cookie:", error);
    }
  }
  //Add to wishlist
  const addProductToCollection = async (productID) => {
    try {
      await dispatch(
        addProductIntoCollection({
          collectionInfo: {
            user_id: user?.id,
            wish_list_merchandise: [productID],
          },
        })
      ).unwrap();

      return true;
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message, {
          autoClose: 2000,
          className: 'toast__message',
        });
      }
      return false;
    }
  };

  const wishlistItem = (productID) => {
    addProductToCollection(productID).then((success) => {
      if (success) {
        dispatch(fetchCollections({}))
          .unwrap()
          .then((response) => {
            setCollections(response.wish_list_merchandise);
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message);
            }
          });
      }
    });
  };

  //Remove from wishlist
  const removeProductToCollection = async (productID) => {
    try {
      await dispatch(
        removeProductIntoCollection({
          collectionInfo: {
            user_id: user?.id,
            wish_list_merchandise: [productID],
          },
        })
      ).unwrap();

      return true;
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message, {
          autoClose: 2000,
          className: 'toast__message',
        });
      }
      return false;
    }
  };

  const unwishlistItem = (productID) => {
    removeProductToCollection(productID).then((success) => {
      if (success) {
        dispatch(fetchCollections({}))
          .unwrap()
          .then((response) => {
            setCollections(response.wish_list_merchandise);
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message);
            }
          });
      }
    });
  };
  return (
    <div className='landing__card' style={{ height: '34vh' }}>
      {selectedProduct?.id === productId && visibility !== 'private' && (
        <WhiteList
          isTop={true}
          top={'-2%'}
          left='-2%'
          isFav={isWishlisted}
          favCount={favCount}
          addToWishlist={async () => await wishlistItem(productId)}
          removeFromWishlist={async () => await unwishlistItem(productId)}
        />
      )}

      <div className='landing__card-image-box' style={{ height: '73%' }}>
        <img
          src={img}
          alt=''
          className='landing-card-img'
          style={{ height: '100%' }}
          onClick={onClick}
        />
        {visibility === 'private' && (
          <div
            className='landing__card-overlay'
            onClick={() => navigate(`/user/upload?productId=${productId}`)}
          >
            <span>Draft</span>
          </div>
        )}
      </div>
      <div className={'landing__card-body'}>
        <h3 className={'landing__product-title'} onClick={onClick}>
          <span style={{ textTransform: 'capitalize', marginTop: '1rem' }}>
            {title}
          </span>
        </h3>
        <h5 className='landing__product-title-affirm'>
          by{' '}
          <span
            className='landing__product-author'
            style={{
              color: selectedProduct?.id === productId ? '#FF9700' : '#6D6D91',
            }}
            onClick={navigateToStore}
          >
            {owner}
          </span>{' '}
          in {firstTag}
        </h5>

        <div className='landing__rating-container'>
          {visibility !== 'private' && (
            <>
              {/* <div className='landing__rating-container-inner'>
                <StarRating
                  rateNumber={ratingsScore}
                  id={productId}
                  isClickable={true}
                />
              </div>
              <div className='landing__card-stats-container'>
                <span className='landing__card-stats-views'>
                  <span style={{ marginLeft: '0.5rem' }}>{ratingsCount}</span>
                </span>

                {/*  <span className='landing__card-stats-comments'>
                  <AiTwotoneMessage /> {numOfComments}
                </span>
              </div> */}
            </>
          )}
          {selectedProduct?.id === productId && (
            <WhiteList
              color={'#FF9700'}
              degrees={'180deg'}
              bottom={'10%'}
              right='-2%'
            />
          )}

          <div
            style={{
              color: selectedProduct?.id === productId ? '#000000' : '#ffffff',
            }}
            className='landing__price'
          >
            ${Number(price).toLocaleString()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProductCard;
