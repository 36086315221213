import PhoneInput from "react-phone-number-input";
import DropDown from "../dropdown/Dropdown";
import { MdEmail } from "react-icons/md";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { GrPaypal } from "react-icons/gr";
import { FaStripeS } from "react-icons/fa";
import Cookies from "js-cookie";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./stripe/CheckoutForm";
import { getClientSecret } from "../../api/user";
import { useDispatch } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
export default function CartRight({
  email,
  handleDataChange,
  countries,
  selectedOption,
  setSelectedOption,
  value,
  handlePhoneNumberChange,
  loginToPurchase,
  totalAmount,
  createPaystackPayment,
  paymentReference,
  zeroDollarPayment,
}) {
  let user = null;
  if (typeof window !== "undefined") {
    const userCookie = Cookies.get("user");
    user = userCookie ? JSON.parse(userCookie) : null;
  }
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    stripePromise.then((resolvedStripe) => {
      setStripe(resolvedStripe);
    });
  }, []);
  const [showStripe, setShowStripe] = useState(false);
  const dispatch = useDispatch();
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState("paystack");
  const [clientSecret, setClientSecret] = useState("");

  const checkoutStripe = async (e) => {
    e.preventDefault();
    if (totalAmount < 1) {
      zeroDollarPayment();
      return;
    }
    if (email === "") {
      toast.warn("Add email", {
        autoClose: 2000,
        className: "toast__message",
      });
      return;
    }
    if (!selectedOption || selectedOption === "") {
      toast.warn("Please select country", {
        autoClose: 2000,
        className: "toast__message",
      });
      return;
    } else {
      setShowStripe(true);
    }
  };
  useEffect(() => {
    if (selectedPaymentMethod === "stripe" && paymentReference) {
      dispatch(
        getClientSecret({
          id: paymentReference,
        })
      )
        .unwrap()
        .then((response) => {
          setClientSecret(response.clientSecret);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedPaymentMethod, dispatch, paymentReference]);
  const appearance = {
    theme: "stripe",
  };
  const loader = "auto";
  const options = {
    clientSecret,
    appearance,
    loader,
  };

  return (
    <div style={{ marginRight: "2rem" }}>
      <section className="cart__payment-gateway-container">
        {showStripe && (
          <div className="stripe-checkout">
            <Elements stripe={stripe} options={options}>
              <CheckoutForm />
            </Elements>
          </div>
        )}
        <div className="cart__gateway-options-container">
          <section className="cart__payment-type">
            <div
              className={`cart__payment-type-btn ${
                selectedPaymentMethod === "paystack" &&
                "cart__payment-type-btn-selected"
              }`}
              onClick={() => setSelectedPaymentMethod("paystack")}
            >
              <img
                src="/assets/image/logo/paystack.svg"
                style={{ width: "1rem" }}
                alt="paystack"
                className="paystack-logo"
              />
              Paystack
            </div>

            <div
              className={`cart__payment-type-btn ${
                selectedPaymentMethod === "stripe" &&
                "cart__payment-type-btn-selected"
              }`}
              style={{ opacity: "0.5", cursor: "not-allowed" }}
              // onClick={() => setSelectedPaymentMethod('stripe')}
            >
              <FaStripeS /> Stripe
            </div>
            <div
              style={{ opacity: "0.5", cursor: "not-allowed" }}
              className={`cart__payment-type-btn ${
                selectedPaymentMethod === "paypal" &&
                "cart__payment-type-btn-selected"
              }`}
              onClick={() => {}}
            >
              <GrPaypal /> Paypal
            </div>
          </section>
        </div>
      </section>
      <form className="cart__card-insertion-container">
        <div className="cart_info_container">
          <p
            style={{
              color: "#BCBCBC",
              fontSize: "0.8rem",
              marginTop: "2rem",
            }}
          >
            *Email
          </p>
          <div className="user__sales-form-group">
            <div
              className="user__sales-skew-container"
              style={{
                position: "relative",
                width: "100%",
                backgroundColor: "#15151c",
              }}
            >
              <input
                placeholder="Fill in your Email here"
                className="user__sales-mc-input"
                style={{ width: "100% !important" }}
                name="email"
                value={email}
                onChange={(e) => handleDataChange(e)}
              />
              <div
                className="user__sales-mc-input-edit-skew-container"
                style={{
                  position: "absolute",
                  left: "unset",
                  right: 4,
                  top: 4,
                  transform: "none",
                  backgroundColor: "transparent",
                }}
              >
                <MdEmail className="user__sales-mc-input-edit" />
              </div>
            </div>
          </div>
        </div>
        <div className="cart_info_container">
          <p style={{ color: "#BCBCBC", fontSize: "0.8rem" }}>*Country</p>
          <DropDown
            options={countries.sort()}
            placeholder={
              selectedOption === "" ? (
                <span style={{ color: "#666683", fontSize: "1rem" }}>
                  Enter country
                </span>
              ) : (
                selectedOption
              )
            }
            selected={selectedOption}
            onSelect={(option) => {
              setSelectedOption(option);
            }}
            style={{
              flexDirection: "row-reverse",
              border: "1px solid #636363",
            }}
          />
        </div>
        <div className="cart_info_container">
          <p style={{ color: "#BCBCBC", fontSize: "0.8rem" }}>Phone</p>

          <PhoneInput
            placeholder="Enter phone number"
            value={value}
            onChange={handlePhoneNumberChange}
            className="phone-input-custom"
            defaultCountry="NG"
          />
        </div>
        <p
          style={{
            color: "#BCBCBC",
            fontSize: "1rem",
            textAlign: "center",
          }}
        >
          Options with asterick (*) are compulsory
        </p>
        {user?.access_token ? (
          <div
            style={{
              width: "80%",
              margin: "0 auto",
            }}
          >
            <button
              onClick={(e) => {
                if (selectedPaymentMethod === "stripe") {
                  checkoutStripe(e);
                } else if (selectedPaymentMethod === "paystack") {
                  createPaystackPayment(e);
                }
              }}
              className="cart__payBtn"
            >
              <span style={{ transform: "SkewX(20deg)" }}>PAY</span>
            </button>
          </div>
        ) : (
          <div
            style={{
              width: "80%",
              margin: "0 auto",
              position: "relative",
            }}
          >
            <button
              onClick={(e) => loginToPurchase(e)}
              className="cart__payBtn"
            >
              <span style={{ transform: "SkewX(20deg)" }}>
                {totalAmount === 0 ? "Login to Download" : "Login to Purchase"}
              </span>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}
