import IdentityBtn from '../buttons/identityBtn';
import BuyCart from './BuyCart';
import CommentSec from './CommentSec';
import TagsComp from './TagsComp';
import { countProductComments } from '../../utils/countProductComments';
import { returnProductWithComments } from '../../utils/returnProductComments';
import { ShortenedParagraph } from '../../utils/shortenParagraph';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CatmanImg = '/assets/image/loootydefault.jpg';
const FilePurchaseSec = ({
  product,
  descCommToggleMode,
  setDescCommToggleMode,
  addToCart,
  nonSelect,
  inCart,
}) => {
  let url = '';

  const copyToClipboard = (text) => {
    navigator.clipboard?.writeText(text).then(() =>
      toast.success('Copied to clipboard', {
        autoClose: 2000,
        className: 'toast__message',
      })
    );
  };

  if (typeof window !== 'undefined') {
    url = window.location.href;
  }
  const navigate = useNavigate();
  return (
    <div className='buy__file-purchase-container'>
      <div className='buy__file-info-container'>
        <section className='buy__file-attributed-container'>
          <div className='buy__file-attributed-body'>
            <img
              src={product?.user_img ?? CatmanImg}
              className='buy__file-attributed-img'
              alt=''
            />
            <span>
              by:{' '}
              <span
                style={{
                  color: '#FF9700',
                  fontSize: '1rem',
                  cursor: 'pointer',
                }}
                onClick={() => navigate(`/store/${product.store_id}`)}
              >
                {product?.store?.store_name}
              </span>{' '}
              {product?.merchandise_name}
            </span>
            <span>
              in {'>'}{' '}
              {product?.merchandise_categories &&
              typeof product?.merchandise_categories === 'object'
                ? product?.merchandise_categories.map((cat) => (
                    <span>{cat}</span>
                  ))
                : typeof product?.merchandise_categories === 'string'
                ? product?.merchandise_categories
                : null}
            </span>
          </div>

          <div className='buy__comment-header'>
            <IdentityBtn
              onClick={(e) => setDescCommToggleMode(true)}
              style={{
                backgroundColor: descCommToggleMode ? '#232230' : '#15151C',
                borderLeft: `.5rem solid ${
                  descCommToggleMode ? '#7C187A' : '#232230'
                }`,
                padding: '.5rem 4rem',
              }}
              text={'Description'}
            />
            <IdentityBtn
              onClick={(e) => setDescCommToggleMode(true)}
              style={{
                backgroundColor: !descCommToggleMode ? '#232230' : '#15151C',
                borderLeft: `.5rem solid ${
                  !descCommToggleMode ? '#7C187A' : '#232230'
                }`,
                padding: '.5rem 4rem',
                color: 'rgba(255, 255, 255, 0.4)',
              }}
              text={`${countProductComments(product?.ratings) ?? 0} Comments`}
            />
          </div>

          {descCommToggleMode ? (
            <section className='buy__comment-tab-container'>
              <div className='buy__comment-description'>
                {
                  <ShortenedParagraph
                    text={product?.merchandise_description}
                    maxLength={50}
                  />
                }
              </div>

              <TagsComp
                tags={product?.merchandise_tags}
                nonSelect={nonSelect}
                product={product}
              />

              <BuyCart
                product={product}
                addToCart={addToCart}
                inCart={inCart}
              />

              {/* <div className='buy_socials-container'>
                <button
                  style={{ backgroundColor: '#3B5997' }}
                  className='buy__social-btn'
                >
                  <BsFacebook className='buy__socials-logo' /> Share
                </button>
                <button
                  style={{ backgroundColor: '#54ABEE' }}
                  className='buy__social-btn'
                >
                  <AiFillTwitterCircle className='buy__socials-logo' /> Tweet
                </button>
                <button
                  style={{
                    background:
                      'linear-gradient(to right, #5A55CD, #BB2A97, #F87526, #FBA94A )',
                  }}
                  className='buy__social-btn'
                >
                  <BsInstagram className='buy__socials-logo' /> Share
                </button>
                <button
                  style={{ backgroundColor: '#3B5997' }}
                  className='buy__social-btn'
                >
                  <FaLinkedin className='buy__socials-logo' /> Share
                </button>
              </div> */}

              <div className='buy__copy-url-form'>
                <div className='buy__copy-url-form-group'>
                  <input
                    placeholder='https://loooty.com/dcbucj3/283hbcjdc.blend'
                    className='buy__copy-url-form-control'
                    defaultValue={url}
                  />
                  <button
                    className='buy__copy-url-btn'
                    onClick={() => copyToClipboard(url)}
                  >
                    <span className='buy__copy-url-btn-text'>Copy</span>
                  </button>
                </div>
              </div>
            </section>
          ) : (
            <CommentSec
              ratings={returnProductWithComments(product.ratings)}
              productId={product.id}
            />
          )}
        </section>
      </div>

      <div className='buy__purchase-info-container'></div>
    </div>
  );
};

export default FilePurchaseSec;
