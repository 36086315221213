export const OrderCheckbox = ({ isActive }) => {
  return (
    <div
      style={{ opacity: "1" }}
      className="user__main-sett-checkbox"
    >
      <div
        style={{ backgroundColor: `${isActive ? "#FF9700" : "transparent" }` }}
        className="user__main-sett-checkbox-active"
      ></div>
    </div>
  );
};

export default OrderCheckbox