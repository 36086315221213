import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import LoadingState from '../../../loader/LoadingState';
import OrderItem from '../../../user/OrderItem';
import {
  fetchUserNotificationSettings,
  updateNotificationSettingsByAdminAction,
} from '../../../../api/admin';
import { useParams } from 'react-router-dom';

const AdminUserNotificationSettings = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const [notificationSettings, setNotificationSettings] = useState({});
  const handleNotificationSettingsChange = (key) => {
    setNotificationSettings((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const updateNotificationSettings = async (obj) => {
    await dispatch(updateNotificationSettingsByAdminAction({ updateData: obj }))
      .then(() =>
        toast.success('Settings updated', {
          autoClose: 2000,
          className: 'toast__message',
        })
      )
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          autoClose: 2000,
          className: 'toast__message',
        });
      });
  };

  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);

    dispatch(fetchUserNotificationSettings({ user_id: id }))
      .then((res) => {
        if (isMounted) {
          setNotificationSettings(res?.payload);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        isMounted && setIsLoading(false);
        toast.error(
          err.payload?.response?.data?.message || 'Error try again later',
          {
            autoClose: 2000,
            className: 'toast__message',
          }
        );
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch, id]);

  return (
    <div>
      {isLoading ? (
        <LoadingState />
      ) : (
        <div>
          <div className='user__main-sett-body'>
            <div className='user__main-sett-body-left'>
              <OrderItem
                itemTitle={'Order Placed'}
                itemDescription='Every time a paid order is placed you will be notified'
                isChecked={notificationSettings?.order_placed}
                setIsChecked={async () => {
                  handleNotificationSettingsChange('order_placed');
                  await updateNotificationSettings({
                    order_placed: !notificationSettings?.order_placed,
                  });
                }}
              />
              <OrderItem
                itemTitle={'Free Order Placed'}
                itemDescription='Every time a free order is placed you will be notified'
                isChecked={notificationSettings?.free_order_placed}
                setIsChecked={async () => {
                  handleNotificationSettingsChange('free_order_placed');
                  await updateNotificationSettings({
                    free_order_placed: !notificationSettings?.free_order_placed,
                  });
                }}
              />
              <OrderItem
                itemTitle='Comment'
                itemDescription='Every time someone makes a comment on your product or reply to your comment you will be notified'
                isChecked={notificationSettings?.comment}
                setIsChecked={async () => {
                  handleNotificationSettingsChange('comment');
                  await updateNotificationSettings({
                    comment: !notificationSettings?.comment,
                  });
                }}
              />
              <OrderItem
                itemTitle='Product rated'
                itemDescription={
                  'Every time someone rates your product you will be notified'
                }
                isChecked={notificationSettings?.product_rated}
                setIsChecked={async () => {
                  handleNotificationSettingsChange('product_rated');
                  await updateNotificationSettings({
                    product_rated: !notificationSettings?.product_rated,
                  });
                }}
              />
              <OrderItem
                itemTitle='Creator Updates'
                itemDescription={
                  'You will get notified every time a store you subscribe to updates something new'
                }
                isChecked={notificationSettings?.subscription_updates}
                setIsChecked={async () => {
                  handleNotificationSettingsChange('subscription_updates');
                  await updateNotificationSettings({
                    subscription_updates:
                      !notificationSettings?.subscription_updates,
                  });
                }}
              />
              <OrderItem
                itemTitle='Collection Updates'
                itemDescription={
                  'Keep me updated about new offers or price changes for products in my collection'
                }
                isChecked={notificationSettings?.collection_updates}
                setIsChecked={async () => {
                  handleNotificationSettingsChange('collection_updates');
                  await updateNotificationSettings({
                    collection_updates:
                      !notificationSettings?.collection_updates,
                  });
                }}
              />
              <OrderItem
                itemTitle='Blog Post'
                itemDescription={
                  'Let me know every time a new article is published on the blog'
                }
                isChecked={notificationSettings?.blog_post}
                setIsChecked={async () => {
                  handleNotificationSettingsChange('blog_post');
                  await updateNotificationSettings({
                    blog_post: !notificationSettings?.blog_post,
                  });
                }}
              />
              <OrderItem
                itemTitle='Push Notification'
                itemDescription={
                  'You will receive a browser notification for every new notification enabled above'
                }
                isChecked={notificationSettings?.push_notification}
                setIsChecked={async () => {
                  handleNotificationSettingsChange('push_notification');
                  await updateNotificationSettings({
                    push_notification: !notificationSettings?.push_notification,
                  });
                }}
              />
            </div>
          </div>

          <div className='user__main-sett-footer'>
            <div className='user__settings-footer-divider' />
            <OrderItem
              itemTitle={'Show Adult Content'}
              isChecked={notificationSettings?.explicit_content}
              setIsChecked={async () => {
                handleNotificationSettingsChange('explicit_content');
                await updateNotificationSettings({
                  explicit_content: !notificationSettings?.explicit_content,
                });
              }}
            />
            <OrderItem
              itemTitle={'Email me about popular products and freebies'}
              isChecked={notificationSettings?.freebies_emails}
              setIsChecked={async () => {
                handleNotificationSettingsChange('freebies_emails');
                await updateNotificationSettings({
                  freebies_emails: !notificationSettings?.freebies_emails,
                });
              }}
            />
            <OrderItem
              itemTitle={'Email me about site updates and features'}
              isChecked={notificationSettings?.site_updates_emails}
              setIsChecked={async () => {
                handleNotificationSettingsChange('site_updates_emails');
                await updateNotificationSettings({
                  site_updates_emails:
                    !notificationSettings?.site_updates_emails,
                });
              }}
            />
            <OrderItem
              itemTitle={'Email me about featured blog posts'}
              isChecked={notificationSettings?.blog_post_emails}
              setIsChecked={async () => {
                handleNotificationSettingsChange('blog_post_emails');
                await updateNotificationSettings({
                  blog_post_emails: !notificationSettings?.blog_post_emails,
                });
              }}
            />
            <OrderItem
              itemTitle={'Email me whenever there is a new notification'}
              isChecked={notificationSettings?.notification_emails}
              setIsChecked={async () => {
                handleNotificationSettingsChange('notification_emails');
                await updateNotificationSettings({
                  notification_emails:
                    !notificationSettings?.notification_emails,
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminUserNotificationSettings;
