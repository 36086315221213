const StartSelling = ({ onClick }) => {
  return (
    <div className='landing__start-selling-container'>
      <h1 className='landing__start-selling-text'>Start Selling on Loooty</h1>

      <h3 className='landing__start-selling-desc'>
        Share your art resources with the world and keep as much as 95% of
        revenues
      </h3>

      <button
        onClick={onClick}
        className='landing__slogan-browse'
        style={{ backgroundColor: 'rgb(255, 151, 0)' }}
      >
        <span className='landing__slogan-browse-text'>BECOME A CREATOR</span>
      </button>
    </div>
  );
};

export default StartSelling;
