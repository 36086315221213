import { useEffect, useState } from 'react';
import { MdModeEditOutline } from 'react-icons/md';
import { FaSave } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
  showUserByAdminAction,
  updateUserByAdminAction,
} from '../../../../api/admin';
import LoadingState from '../../../loader/LoadingState';

const AdminUserAccountSettings = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  let user = null;
  const [saveUserEmail, setSaveUserEmail] = useState(false);
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState({
    email: '',
  });

  const handleChange = (e) => {
    setUserDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const updateUserEmail = async () => {
    setIsLoading(true);
    await dispatch(
      updateUserByAdminAction({
        updateData: {
          user_id: user?.id,
          email: userDetails?.email,
        },
      })
    )
      .then(() => {
        toast.success('Email updated', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log({ err });
        toast.error(err || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(showUserByAdminAction({ user_id: id }))
      .then((res) => {
        if (isMounted) {
          setUserDetails((prev) => ({
            ...prev,
            email: res?.payload.email,
          }));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log({ err });
        isMounted && setIsLoading(false);
        toast.error(
          err.payload?.response?.data?.message || 'Error try again later',
          {
            autoClose: 2000,
            className: 'toast__message',
          }
        );
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch, id]);

  return (
    <div>
      {isLoading ? (
        <LoadingState />
      ) : (
        <div className=' account-sett-body'>
          <div
            className='user__sales-mc-item-body'
            style={{ marginBottom: '1.5rem' }}
          >
            <span className='account-sett-input-label'>LOGIN EMAIL</span>
            <div className='user__sales-form-group'>
              <div className='user__sales-skew-container'>
                <input
                  placeholder='Fill in your Email here'
                  className='user__sales-mc-input'
                  value={userDetails.email || ''}
                  name='email'
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className='user__sales-mc-input-edit-skew-container'>
                {saveUserEmail ? (
                  <FaSave
                    className='user__sales-mc-input-edit'
                    onClick={async () => {
                      setSaveUserEmail(false);
                      await updateUserEmail();
                    }}
                  />
                ) : (
                  <MdModeEditOutline
                    className='user__sales-mc-input-edit'
                    onClick={() => setSaveUserEmail(true)}
                  />
                )}
              </div>
            </div>
            <p className='user__account-sett-input-info'>
              The email you use to access Loooty
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminUserAccountSettings;
