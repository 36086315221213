import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { setSelectedSubPage } from "../redux/reducers/userStateReducer";
import NavBar from "../component/navbar/navbar";
import TagheaderCompMobile from "../component/admin/TagheaderCompMobile";
import { useDispatch, useSelector } from "react-redux";
import { UniversalTagHeaderDesktopAdmin } from "../component/user/UniversalTagHeaderDesktop";
import Footer from "../component/footer/footer";
import PageSelectCompNotifier from "../component/admin/PageSelectCompNotifier";

const hasStoreTags = [
  {
    name: "all (3)",
  },
  {
    name: "ratings (3)",
  },
  {
    name: "comments (3)",
  },
  {
    name: "sales (3)",
  },
  {
    name: "forum (0)",
  },
  {
    name: "updates (1)",
  },
];

const NotificationPage = () => {
  const toggleVisAdmin = true;
  const isMobile = useMediaQuery({ minWidth: 481, maxWidth: 768 });
  const dispatch = useDispatch();

  const activeInpageHeader = useSelector(
    (state) => state.userSubPageState.selectedAdmin
  ); //Enum types: store, profile, library, messages, sales, settings

  useEffect(() => {
    let isMounted = true;

    if(isMounted) {
      if (!toggleVisAdmin) {
        dispatch(setSelectedSubPage({ selected: "all (3)" }));
      }
    }

    return () => {
      isMounted = false;
    }

  }, [dispatch, toggleVisAdmin]);

  return (
    <section className="admin__main-container">
      <header>
        <NavBar
          pageType={"user"}
          loggedIn={true}
          shortSearch={true}
          style={{ paddingBottom: "0", paddingTop: "0" }}
          search={true}
        />
      </header>

      <main className="admin__main">
        <div className="admin__separator"></div>
        {/*  */}

        {!isMobile ? (
          <UniversalTagHeaderDesktopAdmin
            style={{ zoom: ".8" }}
            toggleVisAdmin={toggleVisAdmin}
            activeInpageHeader={activeInpageHeader}
            TagList={hasStoreTags}
          />
        ) : (
          <TagheaderCompMobile
            toggleVisAdmin={toggleVisAdmin}
            activeInpageHeader={activeInpageHeader}
          />
        )}

        <PageSelectCompNotifier inPageName={activeInpageHeader} />
      </main>
      <Footer/>
    </section>
  );
};

export default NotificationPage;
