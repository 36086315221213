import React, { useState } from 'react';

export const ShortenedParagraph = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const displayText = isExpanded ? text : text?.slice(0, maxLength);

  return (
    <div>
      <span>{displayText}</span>
      {text?.length > maxLength && (
        <span onClick={toggleExpand} className='buy__read-more'>
          {isExpanded ? '... Read less' : '... Read more'}
        </span>
      )}
    </div>
  );
};
