import { useState } from 'react';
import { MdModeEditOutline } from 'react-icons/md';
import { FaEyeSlash, FaSave } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserAction, updateUserPasswordAction } from '../../api/auth';
import { toast } from 'react-toastify';
import LoootyLoader from '../loader/loootyLoader';
import { BsEyeFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const AccountSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [saveUserEmail, setSaveUserEmail] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: user?.email,
    old_password: '',
    new_password: '',
    new_password_confirmation: '',
  });

  const handleChange = (e) => {
    setUserDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [hidePassword, setHidePassword] = useState([true, true, true]);
  const [passwordInputType, setPasswordInputType] = useState([
    'password',
    'password',
    'password',
  ]);

  const togglePasswordVisibility = (index) => {
    setHidePassword((prevState) => {
      const newState = [...prevState];
      newState[index] = !prevState[index];
      return newState;
    });
  };

  const changePasswordInputType = (index, type) => {
    let newArray = passwordInputType;
    newArray[index] = type;
    setPasswordInputType(newArray);
  };

  const updateUserEmail = async () => {
    setIsLoading(true);
    await dispatch(
      updateUserAction({
        updateData: {
          user_id: user?.id,
          email: userDetails?.email,
        },
      })
    )
      .then((res) => {
        toast.success('Email updated', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log({ err });
        toast.error(err || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  const changeUserPassword = async () => {
    if (
      !userDetails.old_password ||
      !userDetails.new_password ||
      !userDetails.new_password_confirmation
    ) {
      toast.error('Invalid input', {
        autoClose: 2000,
        className: 'toast__message',
      });
      return;
    }
    if (userDetails.new_password !== userDetails.new_password_confirmation) {
      toast.error('Passwords do not match', {
        autoClose: 2000,
        className: 'toast__message',
      });
      return;
    }
    setIsLoading(true);
    await dispatch(
      updateUserPasswordAction({
        updateData: {
          user_id: user?.id,
          ...userDetails,
        },
      })
    )
      .then((res) => {
        toast.success('Password updated', {
          autoClose: 2000,
          className: 'toast__message',
        });
        // else toast.error(res.payload?.response?.data?.message ||  "Error try again later")
        setIsLoading(false);
        navigate('/coming/soon');
      })
      .catch((err) => {
        console.log({ err });
        toast.error(err || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  return (
    <div className=' account-sett-body'>
      <div
        className='user__sales-mc-item-body'
        style={{ marginBottom: '1.5rem' }}
      >
        <span className='account-sett-input-label'>LOGIN EMAIL</span>
        <div className='user__sales-form-group'>
          <div className='user__sales-skew-container'>
            <input
              placeholder='Fill in your Email here'
              className='user__sales-mc-input'
              value={userDetails.email || ''}
              name='email'
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className='user__sales-mc-input-edit-skew-container'>
            {saveUserEmail ? (
              <FaSave
                className='user__sales-mc-input-edit'
                onClick={async () => {
                  setSaveUserEmail(false);
                  await updateUserEmail();
                }}
              />
            ) : (
              <MdModeEditOutline
                className='user__sales-mc-input-edit'
                onClick={() => setSaveUserEmail(true)}
              />
            )}
          </div>
        </div>
        <p className='user__account-sett-input-info'>
          The email you use to access Loooty
        </p>
      </div>

      <div className='user__sales-mc-item-body'>
        <span className='account-sett-input-label'>LOGIN PASSWORD</span>
        <div style={{ marginBottom: '1rem' }}>
          <div className='user__sales-form-group'>
            <div className='user__sales-skew-container'>
              <input
                className='user__sales-mc-input'
                type={passwordInputType[0]}
                name='old_password'
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className='user__sales-mc-input-edit-skew-container'>
              <MdModeEditOutline className='user__sales-mc-input-edit' />
            </div>
            {hidePassword[0] ? (
              <BsEyeFill
                className='user__account-sett-sensitive-icon'
                onClick={() => {
                  togglePasswordVisibility(0);
                  changePasswordInputType(0, 'text');
                }}
              />
            ) : (
              <FaEyeSlash
                className='user__account-sett-sensitive-icon'
                onClick={() => {
                  togglePasswordVisibility(0);
                  changePasswordInputType(0, 'password');
                }}
              />
            )}
          </div>
          <p className='user__account-sett-input-info'>Old password</p>
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <div className='user__sales-form-group'>
            <div className='user__sales-skew-container'>
              <input
                className='user__sales-mc-input'
                type={passwordInputType[1]}
                name='new_password'
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className='user__sales-mc-input-edit-skew-container'>
              <MdModeEditOutline className='user__sales-mc-input-edit' />
            </div>
            {hidePassword[1] ? (
              <BsEyeFill
                className='user__account-sett-sensitive-icon'
                onClick={() => {
                  togglePasswordVisibility(1);
                  changePasswordInputType(1, 'text');
                }}
              />
            ) : (
              <FaEyeSlash
                className='user__account-sett-sensitive-icon'
                onClick={() => {
                  togglePasswordVisibility(1);
                  changePasswordInputType(1, 'password');
                }}
              />
            )}
          </div>
          <p className='user__account-sett-input-info'>New password</p>
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <div className='user__sales-form-group'>
            <div className='user__sales-skew-container'>
              <input
                className='user__sales-mc-input'
                type={passwordInputType[2]}
                name='new_password_confirmation'
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className='user__sales-mc-input-edit-skew-container'>
              <MdModeEditOutline className='user__sales-mc-input-edit' />
            </div>
            {hidePassword[2] ? (
              <BsEyeFill
                className='user__account-sett-sensitive-icon'
                onClick={() => {
                  togglePasswordVisibility(2);
                  changePasswordInputType(2, 'text');
                }}
              />
            ) : (
              <FaEyeSlash
                className='user__account-sett-sensitive-icon'
                onClick={() => {
                  togglePasswordVisibility(2);
                  changePasswordInputType(2, 'password');
                }}
              />
            )}
          </div>
          <p className='user__account-sett-input-info'>Confirm new password</p>
        </div>
      </div>
      <div className='user__sales-mc-item-container button'>
        <button
          className='user__sales-mc-item-btn'
          type='button'
          onClick={changeUserPassword}
        >
          <span>{isLoading ? <LoootyLoader /> : 'SAVE'}</span>
        </button>
      </div>
    </div>
  );
};

export default AccountSettings;
