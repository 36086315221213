import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RegisterContext } from '../component/auth/context/registerContext';
import OpenStoreModal from '../component/auth/openStoreModal';
import SignUpModal from '../component/auth/signUpModal';
import { IdentityBtnWithBG } from '../component/buttons/identityBtn';
import FaqCard from '../component/card/faqCard';
import OddCarousel from '../component/carousel/oddCarousel';
import Footer from '../component/footer/footer';
import StartSelling from '../component/landing/startSelling';
import NavBar from '../component/navbar/navbar';

const OpenAStore = () => {
  const [showRegModal, setShowRegModal] = useContext(RegisterContext);
  const user = useSelector((state) => state.user);

  const { pathname } = useLocation();
  const handleMainAppClick = () => {
    setShowRegModal((old) => ({ ...old, loginDrop: false }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    // if (!user.user) {
    //   navigate("/");
    // }
  }, [user.user]);

  const faq = [
    {
      question: 'What is Loooty?',
      answer: `Loooty is an Afro-centric platform that empowers creators and developers to sell their CG assets and codes. We're dedicated to supporting African creators and developers by providing a marketplace with low sales tax and easy payout options.`,
    },

    {
      question: 'How can I register on Loooty?',
      answer: `Registering on Loooty is straightforward. Simply click the "Sign Up" button on our homepage, fill out the required information, and you're all set to explore our platform.`,
    },

    {
      question: 'How can I become a creator or developer on Loooty?',
      answer: ` If you're an artist or developer, you can become a creator on Loooty by registering and then clicking "Become a Creator" in your profile settings. You'll be prompted to provide your portfolio link to get started.`,
    },
  ];

  return (
    <div onClick={handleMainAppClick} className='opnstore__app-container'>
      {showRegModal.register && <SignUpModal />}
      {showRegModal.openStore && (
        <OpenStoreModal
          onClick={(e) =>
            setShowRegModal({
              ...showRegModal,
              openStore: !showRegModal.openStore,
            })
          }
        />
      )}

      <header>
        <NavBar
          shortSearch={true}
          style={{ paddingBottom: '0' }}
          search={true}
        />

        <IdentityBtnWithBG
          style={{ backgroundColor: '#15151C' }}
          type={'light'}
          text={'Become A Creator'}
        />

        <StartSelling
          onClick={(e) =>
            setShowRegModal({
              ...showRegModal,
              openStore: !showRegModal.openStore,
            })
          }
        />
      </header>

      <main>
        <OddCarousel />

        <section className='opnstore__steps-container'>
          <div className='opnstore__steps-first-layer'>
            <div className='opnstore_step-divider'></div>
            <div className='opnstore__steps-item'>
              <h2 className='opnstore__step-item-header'>
                Control over your product
              </h2>

              <p className='opnstore__step-item-desc'>
                Easiest platform to get started on and completely free.
              </p>
            </div>

            <div className='opnstore__steps-item'>
              <h2 className='opnstore__step-item-header'>
                Track how you are doing
              </h2>

              <p className='opnstore__step-item-desc'>
                With powerful analytics at your disposal, it's easy to see how
                well you are doing and plan for your future of riches.
              </p>
            </div>
          </div>

          <div className='opnstore__steps-second-layer'>
            <div className='opnstore__steps-item opnstore__step-slant'>
              <h2
                style={{ transform: 'skewX(18deg)' }}
                className='opnstore__step-item-header'
              >
                Get paid Often
              </h2>

              <p
                style={{ transform: 'skewX(18deg)' }}
                className='opnstore__step-item-desc'
              >
                Payouts are on the 1st and 15th of each month as long as you
                reach a balance of $20 for that period. get paid via paypal or
                wire transfer (some conditions apply)
              </p>
            </div>
          </div>
        </section>

        <IdentityBtnWithBG
          style={{ backgroundColor: '#15151C' }}
          type={'light'}
          text={'Frequently Asked Questions'}
        />
        <section className='opnstore__faq-sec'>
          <div className='opnstore__faq-sec-inner'>
            {faq.map((fqItem, index) => {
              return (
                <FaqCard
                  key={fqItem.answer.toString(16) + String(index)}
                  answer={fqItem.answer}
                  question={fqItem.question}
                />
              );
            })}
          </div>
        </section>
      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default OpenAStore;
