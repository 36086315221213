import { MdModeEditOutline } from 'react-icons/md';
// import OrderItem from './OrderItem';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  fetchMerchandiseStore,
  updateMerchandiseStore,
  updateStoreSettingsAction,
} from '../../api/auth';
import { toast } from 'react-toastify';
import LoootyLoader from '../loader/loootyLoader';
import { FaSave } from 'react-icons/fa';
import LoadingState from '../loader/LoadingState';
import { getTeamRoleKey } from '../../utils/getUserTeamRoleKey';
import Cookies from 'js-cookie';

const StoreSettings = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [saveStoreName, setSaveStoreName] = useState(false);

  const [storeSettings, setStoreSettings] = useState({
    name: '',
    enabled: '',
    affiliate: '',
  });
  // const handleStoreSettingsChange = (key) => {
  //   setStoreSettings((prevState) => ({
  //     ...prevState,
  //     [key]: !prevState[key],
  //   }));
  // };

  const updateStoreName = async () => {
    setIsLoading(true);
    await dispatch(
      updateMerchandiseStore({
        storeInfo: {
          id: storeSettings?.store_id,
          link_name: storeSettings?.name,
        },
      })
    )
      .then((res) => {
        toast.success('Name updated', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log({ err });
        toast.error(err || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  const editStoreSettings = async () => {
    setIsLoading(true);
    await dispatch(
      updateStoreSettingsAction({
        updateData: {
          store_operating_status: storeSettings.enabled ? 'open' : 'closed',
          affiliation: storeSettings.affiliate,
          store_id: storeSettings?.store_id,
        },
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Store settings updated', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    let user = JSON.parse(Cookies.get('user'));

    let teamKey = getTeamRoleKey(user.team_roles, 'store');
    if (teamKey) {
      setIsLoading(true);

      dispatch(fetchMerchandiseStore({}))
        .unwrap()
        .then(async (response) => {
          if (isMounted) {
            setStoreSettings({
              name: response.store_name,
              affiliate: response.affiliation,
              enabled:
                response.store_operating_status === 'open' ? true : false,
            });
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (err.response) {
            setIsLoading(false);
            toast.error(err.response.data.message, {
              autoClose: 2000,
              className: 'toast__message',
            });
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  return (
    <div>
      {isLoading ? (
        <LoadingState />
      ) : (
        <div className='user__store-sett-body'>
          <div
            className='user__sales-mc-item-body'
            style={{ marginBottom: '2rem' }}
          >
            <span className='account-sett-input-label'>STORE URL</span>
            <div className='user__sales-form-group'>
              <div className='user__sales-skew-container'>
                <span className='user__store-sett-url-input-prefix'>
                  {'https://loooty.com/'}
                </span>
                <input
                  disabled={!saveStoreName}
                  className='user__sales-mc-input'
                  value={storeSettings?.name || ''}
                  onChange={(e) =>
                    setStoreSettings((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }))
                  }
                  style={{
                    paddingLeft: 0,
                  }}
                />
              </div>
              <div className='user__sales-mc-input-edit-skew-container'>
                {saveStoreName ? (
                  <FaSave
                    className='user__sales-mc-input-edit'
                    onClick={async () => {
                      setSaveStoreName(false);
                      await updateStoreName();
                    }}
                  />
                ) : (
                  <MdModeEditOutline
                    className='user__sales-mc-input-edit'
                    onClick={() => setSaveStoreName(true)}
                  />
                )}
              </div>
            </div>
            <p className='user__account-sett-input-info'>
              You can change your store link once a month
            </p>
          </div>

          {/* <OrderItem
            itemTitle={'Store Enabled'}
            itemDescription='Enable your store to make your products discoverable on the marketplace and make your store page public'
            isChecked={storeSettings?.enabled}
            setIsChecked={async () => {
              handleStoreSettingsChange('enabled');
            }}
          />

          <OrderItem
            itemTitle={'Enable Affiliate Links'}
            itemDescription='Allow people to use your product link to advertise and make sales for you and which will automatically give them 10% on every sales they make'
            isChecked={storeSettings.affiliate}
            setIsChecked={async () => {
              handleStoreSettingsChange('affiliate');
            }}
          /> */}

          <div className='user__sales-mc-item-container button'>
            <button
              className='user__sales-mc-item-btn'
              type='button'
              onClick={editStoreSettings}
            >
              <span>{isLoading ? <LoootyLoader /> : 'SAVE'}</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default StoreSettings;
