export const sidebarHeaderItemList = [
    {
      navigator: "all-product",
      showText: "ALL PRODUCT",
    },
    {
      navigator: "features",
      showText: "FEATURES",
    },
    {
      navigator: "daily-picks",
      showText: "DAILY PICKS",
    },
  ];