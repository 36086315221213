import { useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';

export const InpageDropdownNav = ({
  activeNavHeader,
  setActiveNavHeader,
  NavList,
  style,
  setSettingsSection,
}) => {
  const [showMobileNavHeaderItems, setShowMobileNavHeaderItems] =
    useState(false);
  return (
    <section
      style={{ ...style, position: 'relative' }}
      className='user__mobile-page-section-header-container'
    >
      <div
        className='user__mobile-page-section-header-item user__mobile-page-section-header-item-selected user__inpage-mobile-nav-header'
        onClick={() => setShowMobileNavHeaderItems(!showMobileNavHeaderItems)}
      >
        {activeNavHeader}
        <MdArrowDropDown className='user__mobile-page-section-header-arrow-down' />
      </div>

      <div className='mobile-inpage-header-dropdown-item-container'>
        {showMobileNavHeaderItems
          ? NavList?.map((item, index) => (
              <div
                className='user__mobile-page-section-header-item user__inpage-mobile-nav-header'
                onClick={() => {
                  setActiveNavHeader(index);
                  setSettingsSection(item);
                  setShowMobileNavHeaderItems(!showMobileNavHeaderItems);
                }}
              >
                <span
                  style={{
                    color: `${
                      activeNavHeader === item ? '#FF9700' : '#4D4D6B'
                    }`,
                  }}
                >
                  {item}
                </span>
              </div>
            ))
          : null}
      </div>
    </section>
  );
};
