import { BsFillSuitHeartFill } from 'react-icons/bs';
import { FaShoppingCart } from 'react-icons/fa';
import { RiMailSendFill } from 'react-icons/ri';
import UserPurpleInnerStripeBtn from '../../buttons/UserPurpleButton';
import CardArrowPointer from '../home/CardArrowPointer';
import AfilliatedDropdownCarts from './AfilliatedDropdownCarts';
import LongSelectedCone from './LongSelectedCone';
import { GoLocation } from 'react-icons/go';

const CatManImg = '/assets/image/loootydefault.jpg';

const CartAndHeart = ({
  cartProducts,
  collectionProducts,
  style,
  selected,
}) => {
  return (
    <section style={style} className='admin__users-listed-cart'>
      <div className='admin__users-listed-cart-item heart'>
        <BsFillSuitHeartFill />
        {cartProducts}
      </div>
      <div className='admin__users-listed-cart-item'>
        <FaShoppingCart />
        {collectionProducts}
      </div>
    </section>
  );
};

const CartAndHeartSelected = ({
  cartProducts,
  collectionProducts,
  style,
  selected,
}) => {
  return (
    <section style={style} className='admin__users-listed-cart-selected'>
      <div className='admin__users-listed-cart-item-selected heart'>
        <BsFillSuitHeartFill />
        {collectionProducts}
      </div>
      <div className='admin__users-listed-cart-item-selected'>
        <FaShoppingCart />
        {cartProducts}
      </div>
    </section>
  );
};

export const ListedUserCarts = ({
  isUser = false,
  selected,
  isAffiliated,
  selectAUser,
  image,
  name,
  location,
  emails,
  phone,
  cartProducts,
  collectionProducts,
  productsList,
}) => {
  return (
    <section className='admin__users-listed-outer'>
      <div
        onClick={() => selectAUser()}
        className={`admin__users-listed-user ${selected && 'selected'}`}
      >
        {selected && <LongSelectedCone />}
        <div className='admin__users-listed-item image'>
          <img
            className='admin__users-listed-item image'
            src={image || CatManImg}
            alt=''
          />
        </div>
        <div className='admin__users-listed-item name'>{name}</div>
        <div className='admin__users-listed-item location'>
          <GoLocation /> {location}
        </div>
        <div className='admin__users-listed-item email'>{emails}</div>
        <div className='admin__users-listed-item email'>
          {phone || '############'}
        </div>
        {!isUser && (
          <div className='admin__users-listed-item extra-container'>
            {selected && !isUser ? (
              <div
                style={{ left: '-40%' }}
                className='admin__users-listed-item extra'
              >
                <CartAndHeartSelected
                  style={{ color: '#FF9700' }}
                  cartProducts={cartProducts}
                  collectionProducts={collectionProducts}
                />
                <UserPurpleInnerStripeBtn
                  actionButtonOnclick={() => {}}
                  style={{ width: '10rem', padding: '1rem 3rem' }}
                  text={
                    <span style={{ transform: 'skew(-25deg)' }}>
                      <RiMailSendFill className='admin__users-fire-button-icon' />
                    </span>
                  }
                />{' '}
              </div>
            ) : (
              <CartAndHeart
                cartProducts={cartProducts}
                collectionProducts={collectionProducts}
              />
            )}
          </div>
        )}
        <CardArrowPointer
          style={{
            top: '40%',
            right: '1.2%',
            color: `${selected ? '#7C187A' : '#3B3B43'}`,
            transform: `rotate(${selected ? '-90deg' : '90deg'})`,
          }}
        />
      </div>
      {isAffiliated && selected && (
        <AfilliatedDropdownCarts products={productsList} />
      )}
    </section>
  );
};

export default ListedUserCarts;
