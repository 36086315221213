import { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import CollectionDescription from './CollectionDescription';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import LoadingState from '../loader/LoadingState';
import { RiDeleteBinFill, RiPencilFill } from 'react-icons/ri';
import { fetchALibrary, fetchAUserFolders } from '../../api/admin';
import { useParams } from 'react-router-dom';
import LibraryItem from '../user/LibraryItem';
import LibraryProductCard from '../user/LibraryProductCard';
import DeleteFolder from '../modals/folder/DeleteFolder';
import EditFolderModal from '../modals/collection/EditFolderModal';

const AdminUserLibrary = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [activeSideUser, setActiveSideUser] = useState(0);
  const [library, setLibrary] = useState(null);
  const [folders, setFolders] = useState(null);
  const [groupedLibraryProducts, setGroupedLibraryProducts] = useState(null);
  const [activeProduct, setActiveProduct] = useState({});
  const [isActive, setIsActive] = useState(null);
  const [settingNavHeader, setSettingsHeaderNav] = useState('All Products');
  const [showEditFolderModal, setShowEditFolderModal] = useState(false);
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const { id } = useParams();

  const [showEditFolderBoxArray, setShowEditFolderBoxArray] = useState([]);
  const toggleShowEditFolderBox = (index) => {
    const array = showEditFolderBoxArray?.map((bool, i) =>
      i === index ? !bool : false
    );
    setShowEditFolderBoxArray(array);
  };

  function groupLibraryProductsByFolderName(data, folderName) {
    let arr = data.filter(
      (item) =>
        item.hasOwnProperty('folder_name') && item.folder_name === folderName
    );
    setGroupedLibraryProducts(arr);
  }

  const countFolderProducts = (folderId) => {
    let count = 0;
    library?.merchandise_list?.forEach((item) => {
      if (item.id === folderId) count++;
    });
    return count;
  };

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    dispatch(fetchALibrary({ id }))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setLibrary(response);
          setGroupedLibraryProducts(response?.merchandise_list);
          setLoading(false);
        }
      })
      .catch((err) => {
        isMounted && setLoading(false);
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch, id]);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    dispatch(
      fetchAUserFolders({
        filterData: {
          user_id: id,
          type: 'library',
        },
      })
    )
      .unwrap()
      .then(async (response) => {
        if (isMounted) {
          setFolders(response);
          setShowEditFolderBoxArray(response?.data?.map(() => false));
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  }, [dispatch, id]);
  return (
    <section className='user__library'>
      {showEditFolderModal && (
        <EditFolderModal
          toggleShowModal={() => setShowEditFolderModal(false)}
          folderId={selectedFolderId}
          merchandiseList={groupedLibraryProducts}
        />
      )}

      {showDeleteFolderModal && (
        <DeleteFolder
          toggleShowModal={() => setShowDeleteFolderModal(false)}
          folderData={selectedFolder}
          merchandiseList={groupedLibraryProducts}
          setSelectedFolder={setSelectedFolder}
        />
      )}

      <div className='user__library-inner'>
        <section className='user__library-sidebar'>
          <section className='user__library-sidebar-header'>
            <div className='user__library-sidebar-header-item'>
              <span className='user__library-sidebar-header-item-text'>
                {settingNavHeader}
              </span>
            </div>
          </section>

          <section className='user__library-sidebar-body'>
            <LibraryItem
              prodNumber={
                library?.merchandise_list
                  ? library?.merchandise_list?.length
                  : 0
              }
              itemName={'All Products'}
              onClick={() => {
                setActiveSideUser(0);
                setSettingsHeaderNav('All Products');
                setGroupedLibraryProducts(library?.merchandise_list);
              }}
              active={activeSideUser === 0}
              onEditClick={() => toast.error('Cannot modify folder')}
            />
            {folders &&
              folders?.length > 0 &&
              folders.map((folderDetails, index) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    width: '95%',
                    margin: 'auto',
                  }}
                  key={folderDetails.id}
                >
                  <LibraryItem
                    prodNumber={countFolderProducts(folderDetails.id)}
                    itemName={folderDetails.name}
                    onClick={() => {
                      setActiveSideUser(folderDetails.id);
                      setSettingsHeaderNav(folderDetails.name);
                      setSelectedFolder(folderDetails);
                      groupLibraryProductsByFolderName(
                        library.merchandise_list,
                        folderDetails.name
                      );
                    }}
                    active={activeSideUser === folderDetails.id}
                    onEditClick={() => toggleShowEditFolderBox(index)}
                  />

                  {showEditFolderBoxArray[index] && (
                    <div className='user__library-edit-folder-dropdown-container'>
                      <div
                        className='user__library-edit-folder-dropdown-item'
                        onClick={() => {
                          setSelectedFolderId(folderDetails.id);
                          toggleShowEditFolderBox(index);
                          setShowEditFolderModal(!showEditFolderModal);
                        }}
                      >
                        <RiPencilFill className='user__library-item-icon' />
                        <span>Edit folder</span>
                      </div>

                      <div
                        className='user__library-edit-folder-dropdown-item'
                        onClick={() => {
                          setSelectedFolder(folderDetails);
                          toggleShowEditFolderBox(index);
                          setShowDeleteFolderModal(!showDeleteFolderModal);
                        }}
                      >
                        <RiDeleteBinFill className='user__library-item-icon' />
                        <span>Remove</span>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </section>

          <section className='user__library-sidebar-body trash-button-section'>
            <button
              className='user__library-trash-btn'
              onClick={() => {
                if (!selectedFolder) {
                  toast.error('Cannot delete folder', {
                    autoClose: 2000,
                    className: 'toast__message',
                  });
                  return;
                } else {
                  setShowDeleteFolderModal(!showDeleteFolderModal);
                }
              }}
            >
              <FaTrash className='user__library-trash-btn-logo' />
              <span className='user__library-trash-btn-text'>Trash</span>
            </button>
          </section>
        </section>

        <section className='user__main-lib-container'>
          {loading ? (
            <LoadingState />
          ) : (
            <div>
              <div className='user__main-lib-body-collections'>
                {groupedLibraryProducts?.length > 0 ? (
                  groupedLibraryProducts.map((product) => (
                    <LibraryProductCard
                      key={product?.merchandise_id}
                      isActive={isActive}
                      setIsActive={setIsActive}
                      setIsActiveProduct={setActiveProduct}
                      product={product}
                    />
                  ))
                ) : (
                  <div
                    className='user__no-product-div'
                    style={{ width: 'fitContent', margin: '0 auto' }}
                  >
                    Nothing to see here yet!
                  </div>
                )}
              </div>

              {isActive && <CollectionDescription product={activeProduct} />}

              <div className='user__main-lib-footer'></div>
            </div>
          )}
        </section>
      </div>
    </section>
  );
};

export default AdminUserLibrary;
