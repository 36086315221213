import FilterClear from "./FilterClear";
import FilterSearchBar from "./FilterSearchBar";
import FilterSelector from "./FilterSelector";

const TagsComp = ({ showFilter, searchPlaceHolder, search, setSearch, onSearch }) => {
  return (
    <section className="admin__tags-container">
      <div className="admin__feature-tags-container">
        <div style={{width: showFilter ? '30%' : '80%'}}>
          <FilterSearchBar 
            search={search}
            setSearchString={setSearch}
            onSearch={onSearch}
            placeHolder={searchPlaceHolder}
          />
        </div>
        {
          showFilter && 
          <section className="admin__filter-selector-parent-container">
            <FilterSelector title="CATEGORY" titlevalue="Show all" />
            <FilterSelector title="LICENSE" titlevalue="Show all"/>
            <FilterSelector title="PRICE" titlevalue="205 to 500"/>
            <FilterClear/>
          </section>
        }
       
      </div>
    </section>
  );
};

export default TagsComp