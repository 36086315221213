import { useEffect } from 'react';
import { getCataloguesByAdmin, updateCatalogue } from '../../../../api/admin';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useState } from 'react';
import LoootyLoader from '../../../loader/loootyLoader';
import { BiCategory } from 'react-icons/bi';

export default function AddToCatalogue({
  toggleShowModal,
  fadeOut,
  setFadeOut,
  product,
  refetch,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [allCatalogues, setAllCatalogues] = useState([]);
  const dispatch = useDispatch();
  //fetch catalogues
  const fetchCatalogues = async () => {
    let isMounted = true;
    setIsLoading(true);

    await dispatch(getCataloguesByAdmin({}))
      .unwrap()
      .then((response) => {
        if (!response) {
          setAllCatalogues([]);
          setIsLoading(false);
        }
        if (isMounted) {
          setAllCatalogues(response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  };
  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getCataloguesByAdmin({}))
      .unwrap()
      .then((response) => {
        if (!response) {
          setAllCatalogues([]);
          setIsLoading(false);
        }
        if (isMounted) {
          setAllCatalogues(response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  //create catalogue
  const handleUpdateCatalogue = (catalogueMerch, id) => {
    if (catalogueMerch.includes(product.id)) {
      toast.warn('Product is already in catalogue', {
        autoClose: 2000,
        className: 'toast__message',
      });
      return;
    }

    const updatedCatalogue = [...catalogueMerch, product.id];
    dispatch(
      updateCatalogue({
        catalogueInfo: { id, catalogue_merchandise: updatedCatalogue },
      })
    )
      .unwrap()
      .then(() => {
        refetch ? refetch() : fetchCatalogues();
        toast.success('Product Added to Catalogue', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setTimeout(() => {
          toggleShowModal();
        }, 200);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  };

  return (
    <div
      className={`reg__sign-up-overlay ${fadeOut ? 'lgn__fade-out-anim' : ''}`}
      style={{ color: '#ffffff' }}
    >
      <section className='reg__sign-up-modal user__withdrawal-modal'>
        <div className='reg__close-btn-container'>
          <div
            onClick={(e) => {
              setFadeOut((prev) => !prev);
              setTimeout(() => {
                toggleShowModal();
              }, 200);
            }}
            className='reg__close-btn'
          >
            <span className='reg__close-btn-text'>X</span>
          </div>
        </div>

        <div className='reg__sign-up-forms-container withdrawal-modal'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <BiCategory
              className='user__lib-folder-large'
              style={{
                fontSize: '1.5rem',
                marginRight: '1rem',
                color: '#74747d',
              }}
            />
            <h2 className='user__withdrawal-modal-heading'>Choose Catalogue</h2>
          </div>

          {isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 150,
              }}
            >
              <LoootyLoader />
            </div>
          ) : (
            <div className='catalogue__grid'>
              {!allCatalogues?.length ? (
                <p style={{ minHeight: '3rem', textAlign: 'center' }}>
                  No Catalogues Available
                </p>
              ) : (
                allCatalogues?.map((cat) => (
                  <div
                    key={cat.id}
                    className='admin__categories-tab'
                    onClick={() =>
                      handleUpdateCatalogue(cat.catalogue_merchandise, cat.id)
                    }
                  >
                    {cat.catalogue_title}
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
