import OrderCheckbox from "./OrderCheckbox";

const OrderItem = ({itemTitle, itemDescription, isChecked, setIsChecked}) => {
  return (
    <div
      onClick={setIsChecked}
      className="user__main-sett-item"
    >
      <OrderCheckbox isActive={isChecked} setIsActive={setIsChecked} />

      <div className="user__main-sett-item-body">
        <h4 className="user__main-sett-item-header">{itemTitle}</h4>
        {
          itemDescription && <p className="user__main-sett-item-main">
            {itemDescription}
          </p>
        }
      </div>
    </div>
  );
};
  
export default OrderItem