import {
  VscTriangleDown,
  VscTriangleRight,
  VscTriangleUp,
} from 'react-icons/vsc';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  FaArrowAltCircleDown,
  FaPlusCircle,
  FaTimesCircle,
} from 'react-icons/fa';
import { BsFileEarmarkZipFill } from 'react-icons/bs';
import { FiTrash2 } from 'react-icons/fi';

import { ShortenedParagraph } from '../../../utils/shortenParagraph';
import DeleteMerchandise from '../../admin/modals/merchandise/DeleteMerchandise';
import ApproveMerchandise from '../../admin/modals/merchandise/ApproveMerchandise';
import { useDispatch } from 'react-redux';
import { downloadMerchandiseFileAction } from '../../../api/auth';

const filesData = [
  {
    filename: 'file 1',
    size: '32kb',
    downloadUrl: 'downloadme',
  },
  {
    filename: 'file 2',
    size: '32kb',
    downloadUrl: 'downloadme',
  },
  {
    filename: 'file 3',
    size: '32kb',
    downloadUrl: 'downloadme',
  },
  {
    filename: 'file 4',
    size: '32kb',
    downloadUrl: 'downloadme',
  },
  {
    filename: 'file 5',
    size: '32kb',
    downloadUrl: 'downloadme',
  },
];

export default function DetailsRight({
  product,
  showDesc,
  showFiles,
  setShowdesc,
  mgtAction,
  refetchMerchandise,
}) {
  const [approveModal, setApproveModal] = useState(null);
  const [approveModalfadeOut, setApproveModalFadeOut] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);
  const [deleteModalfadeOut, setDeleteModalFadeOut] = useState(false);
  const [signedUrl, setSignedUrl] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (product.merchandise_upload_id === null) return;
    dispatch(
      downloadMerchandiseFileAction({
        merchandise_upload_id: product.merchandise_upload_id,
      })
    )
      .unwrap()
      .then((response) => {
        setSignedUrl(response.signed_url);
      });
  }, [dispatch, product.merchandise_upload_id]);
  return (
    <>
      <h1 className='details__heading'>
        {product?.merchandise_name} | {product?.merchandise_categories[0]}
      </h1>
      <div className='details__imgName'>
        <img
          src={product?.merchandise_banners[0]}
          alt='banner'
          style={{ height: '60px', width: '60px', borderRadius: '50%' }}
        />
        by;{' '}
        <span className='userName capitalize'>
          {product?.store?.store_name}
        </span>{' '}
        in{' '}
        <span className='capitalize'>{product?.merchandise_categories[0]}</span>
        {mgtAction && product?.management_approved === false ? (
          <>
            <span
              onClick={() => setApproveModal(true)}
              className='details__btn message'
              style={{
                backgroundColor: 'rgba(0,0,0,0.2)',
                border: 'none',
                outline: 'unset',
              }}
            >
              <span style={{ height: '14px', width: '14px' }}>
                <FaPlusCircle color='green' />
              </span>{' '}
              <span>Approve</span>
            </span>
            <span
              onClick={() => setDeleteModal(true)}
              className='details__btn delete'
              style={{
                backgroundColor: 'rgba(0,0,0,0.2)',
                border: 'none',
                outline: 'unset',
              }}
            >
              <span style={{ height: '14px', width: '14px' }}>
                <FaTimesCircle color='#c51414' />
              </span>{' '}
              <span>Delete</span>
            </span>
          </>
        ) : (
          <>
            {/* <span className='details__btn message'>
              <span style={{ height: '14px', width: '14px' }}>
                <TbMailFast />
              </span>{' '}
              <span>Message</span>
            </span> */}
            {mgtAction && (
              <span
                className='details__btn delete'
                onClick={() => setDeleteModal(true)}
              >
                <span>
                  <FiTrash2 />
                </span>{' '}
                <span>Delete</span>
              </span>
            )}
          </>
        )}
      </div>
      <div>
        <p onClick={() => setShowdesc((prev) => !prev)}>
          <span className='details__description'>Description</span>{' '}
          <span>
            {showDesc ? (
              <VscTriangleUp color='#6D6D91' />
            ) : (
              <VscTriangleDown color='#6D6D91' />
            )}
          </span>
        </p>
        {showDesc && (
          <div className='details__paragraph'>
            <ShortenedParagraph
              text={product?.merchandise_description}
              maxLength={50}
            />
          </div>
        )}
      </div>

      <div>
        <p
          // onClick={() => setShowfiles((prev) => !prev)}
          style={{ marginBottom: '1rem' }}
        >
          <span className='details__description'>File</span>{' '}
          <span>
            <VscTriangleRight color='#6D6D91' />
          </span>
          <span
            style={{
              backgroundColor: '#757581',
              color: '#1d1d1d',
              fontWeight: 700,
              padding: '2px 5px',
              borderRadius: '5px',
              marginLeft: '5px',
            }}
          >
            {product.merchandise_name}.zip
          </span>
          <a
            href={signedUrl}
            download={'file.txt'}
            className='details__files'
            style={{ color: 'rgba(0,0,0,0.80)' }}
          >
            <span>
              <FaArrowAltCircleDown color='rgba(0,0,0,0.80)' size={20} />
            </span>
            <span style={{ color: 'rgba(0,0,0,0.80)' }}>Download File</span>
          </a>
        </p>
        {showFiles && (
          <div className='details__fileItems'>
            {filesData.map((file, index) => (
              <Link href={file.downloadUrl} key={index}>
                <span>
                  <BsFileEarmarkZipFill color='#6D6D91' />
                </span>
                <span>{file.filename}</span>
                <span className='size'>zip/{file.size}</span>
                <span className='download'>
                  <FaArrowAltCircleDown color='rgba(0,0,0,0.60)' size={20} />
                </span>
              </Link>
            ))}
          </div>
        )}
      </div>
      {approveModal && (
        <ApproveMerchandise
          toggleShowModal={() => setApproveModal(!approveModal)}
          fadeOut={approveModalfadeOut}
          setFadeOut={setApproveModalFadeOut}
          title={product.merchandise_name}
          refetchMerchandise={refetchMerchandise}
          id={product.id}
        />
      )}
      {deleteModal && (
        <DeleteMerchandise
          toggleShowModal={() => setDeleteModal(!deleteModal)}
          fadeOut={deleteModalfadeOut}
          setFadeOut={setDeleteModalFadeOut}
          title={product.merchandise_name}
          refetchMerchandise={refetchMerchandise}
          id={product.id}
        />
      )}
    </>
  );
}
