import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  library,
  logout,
  merchandiseStore,
  signUp,
  collections,
  products,
  profile,
} from '../redux/reducers/authReducer';
import { getTeamRoleKey } from '../utils/getUserTeamRoleKey';
import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_BASE_URL;
let config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

let resetConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
export const signUpAction = createAsyncThunk(
  'auth/signup',
  async (params, thunkAPI) => {
    try {
      const data = await axios.post(
        `${baseUrl}/api/auth/register`,
        params.signUpData,
        config
      );

      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const resetPasswordLink = createAsyncThunk(
  'auth/resetLink',
  async (params, thunkAPI) => {
    try {
      const data = await axios.post(
        `${baseUrl}/api/auth/forgot`,
        params.resetData,
        resetConfig
      );

      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (params, thunkAPI) => {
    try {
      const data = await axios.post(
        `${baseUrl}/api/auth/reset`,
        params.resetData,
        resetConfig
      );

      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const signInAction = createAsyncThunk(
  'auth/signin',
  async (params, thunkAPI) => {
    try {
      const data = await axios.post(
        `${baseUrl}/api/auth/login`,
        params.signInData,
        config
      );
      thunkAPI.dispatch(signUp({ user: data.data.data }));
      return data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const socialSignInAction = createAsyncThunk(
  'auth/signin',
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(signUp({ user: params.data }));
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createMerchandiseStore = createAsyncThunk(
  'api/store',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/onboard/store`,
        params.storeInfo,
        newConfig
      );
      console.log(data.data);
      thunkAPI.dispatch(
        merchandiseStore({ merchandiseStore: data.data.data[0] })
      );
      return data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const uploadMerchandiseFileAction = createAsyncThunk(
  'api/merchandise',
  async ({ store_id }, thunkAPI) => {
    let user = JSON.parse(Cookies.get('user'));

    try {
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          Team: teamKey,
        },
      };

      newConfig.headers['Content-Type'] = 'application/json';

      const data = await axios.post(
        `${baseUrl}/api/merchandise/upload`,
        { store_id },
        newConfig
      );
      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const downloadMerchandiseFileAction = createAsyncThunk(
  'api/merchandise/download',
  async ({ merchandise_upload_id }, thunkAPI) => {
    let user = JSON.parse(Cookies.get('user'));

    try {
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          Team: teamKey,
        },
      };

      newConfig.headers['Content-Type'] = 'application/json';

      const data = await axios.post(
        `${baseUrl}/api/merchandise/download`,
        { merchandise_upload_id },
        newConfig
      );
      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchMerchandiseStore = createAsyncThunk(
  'api/store',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      if (!teamKey) return;
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.get(`${baseUrl}/api/store/me`, newConfig);

      thunkAPI.dispatch(
        merchandiseStore({ merchandiseStore: data.data.data.data[0] })
      );
      return data.data.data.data[0];
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAStore = createAsyncThunk(
  'api/public/store',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');

      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.get(
        `${baseUrl}/api/public/store/show?id=${params.storeId}&properties=1`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateMerchandiseStore = createAsyncThunk(
  'api/store',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/store/update`,
        params.storeInfo,
        newConfig
      );
      thunkAPI.dispatch(fetchMerchandiseStore());
      return data.data.message;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const subscribeToStoreAction = createAsyncThunk(
  'api/user/store',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/store/subscribe`,
        params.storeInfo,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const unSubscribeToStoreAction = createAsyncThunk(
  'api/user/store',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/store/unsubscribe`,
        params.storeInfo,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchMerchandise = createAsyncThunk(
  'api/merchandise',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.get(`${baseUrl}/api/merchandise/me`, newConfig);
      thunkAPI.dispatch(products({ products: data.data.data.data }));
      return data.data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createMerchandise = createAsyncThunk(
  'api/merchandise',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/merchandise/store`,
        params.productInfo,
        newConfig
      );
      return data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateAMerchandiseAction = createAsyncThunk(
  'api/merchandise',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/merchandise/update`,
        params.productInfo,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAMerchandiseAction = createAsyncThunk(
  'api/merchandise',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.get(
        `${baseUrl}/api/merchandise/show?id=${params.productID}&properties=1`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const approveAMerchandiseByStore = createAsyncThunk(
  'api/merchandise',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/merchandise/approve/store`,
        params.approveData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const searchMyMerchandiseAction = createAsyncThunk(
  'api/public/merchandise',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/merchandise/search/index`,
        params.query,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const searchMerchandiseAction = createAsyncThunk(
  'api/public/merchandise',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/public/merchandise/search/index`,
        params.query,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const searchPublicMerchandiseAction = createAsyncThunk(
  'api/merchandise',
  async (params, thunkAPI) => {
    try {
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/public/merchandise/search/index`,
        params.query,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const filterPublicMerchandiseAction = createAsyncThunk(
  'api/filtermerchandise',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');

      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
          Team: teamKey,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/merchandise/filter/index`,
        params.query,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createMerchandiseDraftAction = createAsyncThunk(
  'api/merchandise',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/merchandise/store`,
        params.productInfo,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateMerchandiseDraftAction = createAsyncThunk(
  'api/merchandise',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/merchandise/draft/update`,
        params.productInfo,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchUser = createAsyncThunk(
  'api/user',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.get(
        `${baseUrl}/api/user/me?properties=${params.properties}`,
        newConfig
      );

      thunkAPI.dispatch(
        signUp({
          user: {
            ...user,
            ...data.data.data,
          },
        })
      );
      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateUserAction = createAsyncThunk(
  'user/update',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/user/update`,
        params.updateData,
        newConfig
      );
      thunkAPI.dispatch(fetchUser({ properties: 1 }));
      return data.data.message;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchProfile = createAsyncThunk(
  'api/profile',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.get(
        `${baseUrl}/api/profile/me?properties=1`,
        newConfig
      );

      thunkAPI.dispatch(profile({ profile: data.data.data }));
      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateProfileAction = createAsyncThunk(
  'user/profile',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/profile/update`,
        params.updateData,
        newConfig
      );
      thunkAPI.dispatch(fetchProfile({ properties: 1 }));
      return data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const logOutAction = createAsyncThunk(
  'auth/logout',
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(logout());
      Cookies.remove('user');
      if (params.showToast) {
        toast.success('Log out was successful!', {
          autoClose: 2000,
          className: 'toast__message',
        });
      }
    } catch (err) {
      toast.error(err.message, {
        autoClose: 2000,
        className: 'toast__message',
      });
    }
  }
);

export const fetchLibrary = createAsyncThunk(
  'api/library',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));

      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.get(
        // `${baseUrl}/api/folder/me?type=library&properties=1`,
        `${baseUrl}/api/folder/me?properties=1`,
        newConfig
      );

      thunkAPI.dispatch(library({ library: data.data.data }));
      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchCollections = createAsyncThunk(
  'api/collection',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.get(
        `${baseUrl}/api/wish-list/me?properties=1`,
        newConfig
      );

      thunkAPI.dispatch(
        collections({ collections: data.data.data.wish_list_merchandise })
      );
      return data.data.data.wish_list_merchandise;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const addProductIntoCollection = createAsyncThunk(
  'api/collection',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/wish-list/append`,
        params.collectionInfo,
        newConfig
      );
      thunkAPI.dispatch(fetchCollections());

      return data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const removeProductIntoCollection = createAsyncThunk(
  'api/collection',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/wish-list/remove`,
        params.collectionInfo,
        newConfig
      );
      thunkAPI.dispatch(fetchCollections());

      return data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createFolderAction = createAsyncThunk(
  'api/folder',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/folder/store`,
        params.folderData,
        newConfig
      );
      thunkAPI.dispatch(fetchMyFolders({ folderType: params.folderData.type }));
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchMyFolders = createAsyncThunk(
  'api/folder',
  async ({ folderType }, thunkAPI) => {
    // Destructure folderType from the action payload
    try {
      let user = JSON.parse(Cookies.get('user'));
      const data = await axios.get(`${baseUrl}/api/folder/me?properties=1`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      return data.data.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const fetchMyFolders = createAsyncThunk(
//   'api/folder',
//   async (params, thunkAPI) => {
//     try {
//       let state = thunkAPI.getState();

//       const newConfig = {
//         ...config,
//         'Content-Type': 'application/json',
//         headers: {
//           ...config.headers,
//           Authorization: `Bearer ${state.user.user.access_token}`,
//         },
//       };
//       const data = await axios.get(
//         `${baseUrl}/api/folder/me?type=${params.folderType}`,
//         newConfig
//       );

//       thunkAPI.dispatch(folders({ folders: data.data.data.data }));
//       return data.data.data;
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err);
//     }
//   }
// );

export const fetchMyNotificationSettings = createAsyncThunk(
  'api/settings/notification',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.get(
        `${baseUrl}/api/settings/notification/me`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateNotificationSettingsAction = createAsyncThunk(
  'user/settings/notification',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/profile/update`,
        {
          notification_preferences: params.updateData,
          user_id: user.id,
        },
        newConfig
      );
      thunkAPI.dispatch(fetchProfile({ properties: 1 }));
      return data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateUserPasswordAction = createAsyncThunk(
  'auth/signup',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/auth/change`,
        params.updateData,
        newConfig
      );

      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateStoreSettingsAction = createAsyncThunk(
  'api/settings/store',
  async (params, thunkAPI) => {
    let user = JSON.parse(Cookies.get('user'));
    try {
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/store/update`,
        params.updateData,
        newConfig
      );
      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchStoreStatsAction = createAsyncThunk(
  'api/stats',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/stats/store`,
        params.storeData,
        newConfig
      );
      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchStoreSalesStatsAction = createAsyncThunk(
  'api/stats',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/stats/sales`,
        params.storeData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchMyWalletAction = createAsyncThunk(
  'api/wallet',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.get(`${baseUrl}/api/wallet/me`, newConfig);
      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateWalletMinimumBalanceAction = createAsyncThunk(
  'api/wallet',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
          Team: teamKey,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/wallet/minimum-balance/update`,
        params.updateData,
        newConfig
      );

      thunkAPI.dispatch(fetchMyWalletAction());
      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateWalletBankDetailsAction = createAsyncThunk(
  'api/wallet',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
          Team: teamKey,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/wallet/bank/store`,
        params.updateData,
        newConfig
      );

      thunkAPI.dispatch(fetchMyWalletAction());
      return data.data.data;
    } catch (err) {
      // Extracting relevant information from the Axios error
      const errorData = {
        message: err.message,
        response: err.response?.data,
      };
      // Returning the extracted error data
      return thunkAPI.rejectWithValue(errorData);
    }
  }
);

export const sendPayoutRequestAction = createAsyncThunk(
  'api/payout-request',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
          Team: teamKey,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/payout-request/store`,
        params.payoutData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchFolderAction = createAsyncThunk(
  'api/folder',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');

      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
          Team: teamKey,
        },
      };
      const data = await axios.get(
        `${baseUrl}/api/folder/show?id=${params.folder_id}&properties=1`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateFolderProductsAction = createAsyncThunk(
  'api/folder',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));

      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/folder/update-products`,
        params.updateData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteFolderAction = createAsyncThunk(
  'api/folder',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/folder/delete`,
        params.deleteData,
        newConfig
      );

      thunkAPI.dispatch(fetchMyFolders({ folderType: params.deleteData.type }));
      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteUserAction = createAsyncThunk(
  'api/user',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/user/delete`,
        params.deleteData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchUserSubscriptionsAction = createAsyncThunk(
  'api/user',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.get(
        `${baseUrl}/api/user/subscriptions`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const rateProductAction = createAsyncThunk(
  'api/rating',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/rating/store`,
        params.storeData,
        newConfig
      );

      thunkAPI.dispatch(fetchMyFolders({ folderType: params.deleteData.type }));
      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const sendKycAction = createAsyncThunk(
  'store/kyc',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
          Team: teamKey,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/store/kyc`,
        params.data,
        newConfig
      );

      return data.data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
