export const isValidAspectRatio = (file) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);

    image.onload = () => {
      const width = image.width;
      const height = image.height;

      if (
        width >= 1024 &&
        height >= 1024 &&
        width <= 2048 &&
        height <= 2048 &&
        width === height
      ) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
  });
};
