import { useState } from 'react';
import SettingsItem from './SettingsItem';
import { FaTrash } from 'react-icons/fa';
import SettingsPageSection from './SettingsPageSection';
import DeleteUserModal from '../modals/settings/DeleteUserModal';
import { useMediaQuery } from 'react-responsive';
import { InpageDropdownNav } from './InpageDropdownNav';

const Settings = () => {
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 768 });
  const [activeSideUser, setActiveSideUser] = useState(0);
  const [settingsSection, setSettingsSection] = useState('notifications');
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const settingsItems = ['Notification', 'Account', 'Store'];

  return (
    <section className='user__settings'>
      {showDeleteUserModal && (
        <DeleteUserModal
          toggleShowModal={() => setShowDeleteUserModal(!showDeleteUserModal)}
        />
      )}

      <div className='user__settings-inner'>
        <section className='user__settings-sidebar'>
          <section className='user__settings-sidebar-header'>
            <div className='user__settings-sidebar-header-item'>
              <span className='user__settings-sidebar-header-item-text'>
                {settingsItems[activeSideUser]}
              </span>
            </div>
          </section>

          <section className='user__settings-sidebar-body'>
            {settingsItems.map((item, index) => (
              <SettingsItem
                key={item}
                itemName={item}
                onClick={() => {
                  setActiveSideUser(index);
                  setSettingsSection(item);
                }}
                active={activeSideUser === index}
              />
            ))}
          </section>

          <section className='user__settings-sidebar-body trash-button-section'>
            <button
              className='user__settings-trash-btn'
              type='button'
              onClick={() => setShowDeleteUserModal(!showDeleteUserModal)}
            >
              <FaTrash className='user__settings-trash-btn-logo' />
              <span className='user__settings-trash-btn-text'>
                Delete Account
              </span>
            </button>
          </section>
        </section>

        <section className='user__main-sett-container'>
          {isMobile && (
            <InpageDropdownNav
              activeNavHeader={settingsItems[activeSideUser]}
              NavList={settingsItems}
              setActiveNavHeader={setActiveSideUser}
              setSettingsSection={setSettingsSection}
            />
          )}
          <SettingsPageSection sectionName={settingsSection} />
        </section>
      </div>
    </section>
  );
};

export default Settings;
