import UserPurpleInnerStripeBtn from '../buttons/UserPurpleButton';
import UserInfoProfile from './features/UserInfoProfile';
import ProductInfoProfile from './features/ProductInfoProfile';
import SidebarInfoField from './features/SidebarInfoField';
import { useNavigate } from 'react-router-dom';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useState } from 'react';
import AddToCatalogue from './modals/catalogues/AddToCatalogue';
import { VscTriangleDown, VscTriangleUp } from 'react-icons/vsc';
import { IoMdRemoveCircle } from 'react-icons/io';
import RemoveFromCatalogue from './modals/catalogues/RemoveFromCatalogue';
import { FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import ApproveMerchandise from './modals/merchandise/ApproveMerchandise';
import DeleteMerchandise from './modals/merchandise/DeleteMerchandise';

const categoriesImage = '/assets/image/logo/cates.png';
const CreatorImg = '/assets/image/creatorDefaultImg.png';
const UserImg = '/assets/image/userDefaultImg.png';

const AdminFreebiesSidebar = ({
  isStoreOwner,
  type = 'product',
  sidebarMaininfoIts,
  actionButtonText = 'EXPAND',
  actionButtonOnclick,
  isPayoutsPage,
  sideActionOnclick,
  data,
  allCatalogues,
  refetchCatalogues,
  activeTabView,
  setActiveTabview,
  refetchProducts,
  unapproveLength,
}) => {
  const navigate = useNavigate();
  let storeOwnerBGStyle = {
    content: ' ',
    height: '7rem',
    width: '90%',
    top: '9%',
    backgroundColor: '#0f0110',
    position: 'absolute',
  };
  const [showCatalogueModal, setShowCatalogueModal] = useState(null);
  const [createModalfadeOut, setCreateModalFadeOut] = useState(false);
  const [approveModal, setApproveModal] = useState(null);
  const [approveModalfadeOut, setApproveModalFadeOut] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);
  const [deleteModalfadeOut, setDeleteModalFadeOut] = useState(false);
  const [removeCatalogueModal, setRemoveCatalogueModal] = useState(null);
  const [removeModalfadeOut, setRemoveModalFadeOut] = useState(false);
  const [catalogue, setCatalogue] = useState(null);
  const [showCatalogues, setShowCatalogues] = useState(false);
  const productCatalogues = allCatalogues?.filter((obj) =>
    obj.catalogue_merchandise.includes(Number(data?.id))
  );

  return (
    <section className='admin__freebies-sidebar'>
      <div className='admin__top'>
        {type === 'product' && (
          <div className='admin__product-tabs'>
            <p
              className={activeTabView === 'review' ? 'activeTabView' : ''}
              onClick={() => {
                if (unapproveLength === 0) return;
                else setActiveTabview('review');
              }}
              style={{
                opacity: unapproveLength === 0 ? 0.4 : 1,
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <span>Review products</span>
              <span
                style={{
                  padding: '2px 5px',
                  borderRadius: '50%',
                  marginLeft: 5,
                  fontSize: 12,
                  backgroundColor: 'purple',
                }}
              >
                {unapproveLength}
              </span>
            </p>
            <p
              className={activeTabView === 'approved' ? 'activeTabView' : ''}
              onClick={() => setActiveTabview('approved')}
            >
              Approved Products
            </p>
          </div>
        )}
      </div>
      <div className={`admin__freebies-header `}>
        {isStoreOwner && (
          <div
            style={{
              ...storeOwnerBGStyle,
              backgroundImage: `url(${
                data?.store_pictures ? data?.store_pictures[0] : null
              })`,
              backgroundSize: 'cover',
            }}
          />
        )}

        {type === 'user' ? (
          <UserInfoProfile userData={data} />
        ) : type === 'store' ? (
          <UserInfoProfile
            userData={{
              picture:
                data?.picture ||
                (data?.visibility === 'public' ? CreatorImg : UserImg),
              name: data?.store_name,
              subscriptions: data?.subscriptions,
              profile: data?.profile,
            }}
          />
        ) : type === 'tags' ? (
          <UserInfoProfile
            userData={{
              picture: categoriesImage,
              name: data?.name,
              subscriptions: data?.subscriptions,
              profile: data?.profile,
            }}
          />
        ) : (
          <ProductInfoProfile info={data} />
        )}
        {isPayoutsPage && (
          <div className='amdin__sidebar-payment-details'>PAYMENT DETAILS</div>
        )}
        <section className='admin__sidebar-info'>
          {sidebarMaininfoIts
            ? sidebarMaininfoIts.map((sidebarMainItem) => {
                return (
                  <SidebarInfoField
                    key={sidebarMainItem.leftInfo}
                    leftText={sidebarMainItem.leftInfo}
                    rightText={sidebarMainItem.rightInfo}
                    showVerifySign={sidebarMainItem.showVerifySign}
                    showEditableSign={sidebarMainItem.showEditableSign}
                    showHeartIcon={sidebarMainItem.showHeartIcon}
                    showCartIcon={sidebarMainItem.showCartIcon}
                  />
                );
              })
            : null}
          {type === 'product' && activeTabView === 'approved' && (
            <div className='sidebar__catalogues'>
              <p onClick={() => setShowCatalogues((prev) => !prev)}>
                <span>Catalogues</span>
                <span>
                  {showCatalogues ? (
                    <VscTriangleUp color='#6D6D91' size={16} />
                  ) : (
                    <VscTriangleDown color='#6D6D91' size={16} />
                  )}
                </span>
              </p>
              {showCatalogues && (
                <div>
                  {productCatalogues &&
                    productCatalogues?.map((cat) => (
                      <span
                        key={cat.id}
                        className='admin__categories-tab'
                        style={{
                          justifyContent: 'space-between',
                          padding: '0.5rem',
                          backgroundColor: 'rgba(0,0,0,0.2)',
                          textTransform: 'capitalize',
                        }}
                        onClick={() => {
                          setCatalogue(cat);
                          setRemoveCatalogueModal(true);
                        }}
                      >
                        {cat.catalogue_title}
                        <IoMdRemoveCircle size={20} color='#7c187a' />
                      </span>
                    ))}
                </div>
              )}
            </div>
          )}
          {type === 'product' && (
            <div className='product__expand'>
              <div className=''>
                <UserPurpleInnerStripeBtn
                  onClick={() => {
                    if (sideActionOnclick) {
                      sideActionOnclick();
                      return;
                    }
                    navigate('/admin/expanded');
                  }}
                  actionButtonText={actionButtonText}
                  actionButtonOnclick={actionButtonOnclick}
                  style={{ width: '100%' }}
                  text={actionButtonText}
                />
              </div>

              {activeTabView === 'approved' ? (
                <p
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    borderBottom: '1px solid #3e3e3f80',
                    padding: '0.5rem',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowCatalogueModal(true)}
                >
                  <AiFillPlusCircle size={20} color='#7c187a' />
                  <span>Add to Catalogue</span>
                </p>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                    marginLeft: 5,
                  }}
                >
                  <p
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      borderBottom: '1px solid #3e3e3f80',
                      padding: '0.5rem',
                      cursor: 'pointer',
                      backgroundColor: 'rgba(0,0,0,0.2)',
                    }}
                    onClick={() => setApproveModal(true)}
                  >
                    <FaPlusCircle color='green' />
                    <span>Approve</span>
                  </p>
                  <p
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      borderBottom: '1px solid #3e3e3f80',
                      padding: '0.5rem',
                      cursor: 'pointer',
                      backgroundColor: 'rgba(0,0,0,0.2)',
                    }}
                    onClick={() => setDeleteModal(true)}
                  >
                    <FaTimesCircle color='#c51414' />
                    <span>Delete</span>
                  </p>
                </div>
              )}
            </div>
          )}
          {type !== 'product' && (
            <div className='admin__sidebar-info-field btn'>
              <UserPurpleInnerStripeBtn
                onClick={() => {
                  if (sideActionOnclick) {
                    sideActionOnclick();
                    return;
                  }

                  navigate('/admin/expanded');
                }}
                actionButtonText={actionButtonText}
                actionButtonOnclick={actionButtonOnclick}
                style={{ width: '40%' }}
                text={actionButtonText}
                product={data}
              />
            </div>
          )}
          {showCatalogueModal && (
            <AddToCatalogue
              toggleShowModal={() => setShowCatalogueModal(!showCatalogueModal)}
              fadeOut={createModalfadeOut}
              setFadeOut={setCreateModalFadeOut}
              product={data}
              refetch={refetchCatalogues}
            />
          )}
          {removeCatalogueModal && (
            <RemoveFromCatalogue
              toggleShowModal={() =>
                setRemoveCatalogueModal(!removeCatalogueModal)
              }
              fadeOut={removeModalfadeOut}
              setFadeOut={setRemoveModalFadeOut}
              catalogue={catalogue}
              refetch={refetchCatalogues}
              id={data.id}
            />
          )}
          {approveModal && (
            <ApproveMerchandise
              toggleShowModal={() => setApproveModal(!approveModal)}
              fadeOut={approveModalfadeOut}
              setFadeOut={setApproveModalFadeOut}
              title={data.merchandise_name}
              refetch={refetchProducts}
              id={data.id}
            />
          )}
          {deleteModal && (
            <DeleteMerchandise
              toggleShowModal={() => setDeleteModal(!deleteModal)}
              fadeOut={deleteModalfadeOut}
              setFadeOut={setDeleteModalFadeOut}
              title={data.merchandise_name}
              refetch={refetchProducts}
              id={data.id}
            />
          )}
        </section>
      </div>
    </section>
  );
};

export default AdminFreebiesSidebar;
