import { useDispatch } from 'react-redux';
import { setSelectedSubPage } from '../../redux/reducers/userStateReducer';
import IdentityBtn from '../buttons/identityBtn';

const defaultTags = [
  {
    name: 'subscriptions',
  },
  {
    name: 'profile',
  },
  {
    name: 'library',
  },
  {
    name: 'messages',
  },
  {
    name: 'settings',
    // dropDownList: ["affiliates", "settings"],
  },
];

const UniversalAdminTagHeaderDesktop = ({
  activeInpageHeader,
  setActiveInPageHeader,
  TagList = defaultTags,
  style,
  onClick,
  hide,
}) => {
  const dispatch = useDispatch();
  if (hide) return null;
  return (
    <section
      style={style}
      className='user__admin-page-section-header-container'
    >
      {TagList?.map((tagsBtn, index) => (
        <IdentityBtn
          key={tagsBtn.name}
          onClick={(e) => {
            if (onClick) {
              onClick();
              return;
            }
            dispatch(setSelectedSubPage({ selected: tagsBtn.name }));
            setActiveInPageHeader(tagsBtn.name.toLowerCase());
          }}
          style={{
            padding: '1rem 6rem',
            backgroundColor: `${
              activeInpageHeader === tagsBtn.name ||
              (tagsBtn.dropDownList &&
                activeInpageHeader === tagsBtn.dropDownList[0].toLowerCase()) ||
              (tagsBtn.dropDownList &&
                activeInpageHeader === tagsBtn.dropDownList[1].toLowerCase())
                ? '#15151C'
                : '#191921'
            }`,
            color: `${
              activeInpageHeader === tagsBtn.name ? '#CECED8' : '#4D4D6B'
            }`,
            borderLeft: `1rem solid ${
              activeInpageHeader === tagsBtn.name ||
              (tagsBtn.dropDownList &&
                activeInpageHeader === tagsBtn.dropDownList[0].toLowerCase()) ||
              (tagsBtn.dropDownList &&
                activeInpageHeader === tagsBtn.dropDownList[1].toLowerCase())
                ? '#7C187A'
                : '#353449'
            }`,
          }}
          dropDownList={tagsBtn.dropDownList}
          text={tagsBtn.name.toUpperCase()}
          type='admin'
        />
      ))}
    </section>
  );
};

export default UniversalAdminTagHeaderDesktop;
