import { AiTwotoneMessage } from 'react-icons/ai';
import { FaStar } from 'react-icons/fa';

const WolfGuyImg = '/assets/image/loootydefault.jpg';

const ProductInfoProfile = ({ info }) => {
  return (
    <div className='admin__sidebar-profile-container'>
      <div className='admin__sidebar-img-container'>
        <img
          className='admin__sidebar-img'
          src={
            info?.merchandise_banners?.length > 0
              ? info?.merchandise_banners[0]
              : WolfGuyImg
          }
          alt='product'
        />
      </div>
      <h3>{info?.merchandise_name}</h3>
      <h6>{info?.store?.store_name}</h6>
      <div className='admin__sidebar-rating-container'>
        <span className='admin__main-rating-container'>
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
          <span>4</span>
        </span>

        <span className='admin__sidebar-message-container'>
          <AiTwotoneMessage />
          <span>0</span>
        </span>
      </div>
    </div>
  );
};

export default ProductInfoProfile;
