import "@stripe/stripe-js";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { RegisterProvider } from "./component/auth/context/registerContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/cssLoader";
import Home from "./pages/home";
import OpenAStore from "./pages/openAStore";
import BuyPage from "./pages/buypage";
import Cart from "./pages/cart";
import User from "./pages/user";
import Store from "./pages/store";
import UploadPage from "./pages/upload";
import Admin from "./pages/admin/Admin";
import Expanded from "./pages/admin/Expanded";
import Notifications from "./pages/notificationPage";
import ComingSoon from "./pages/comingSoon";
import SignUpExperimental from "./pages/signupExperimental";
import ResultsPage from "./pages/results";
import PrivacyPolicy from "./pages/privacyPolicy";
import Terms from "./pages/terms";
import { Register } from "./pages/register";
import { Creator } from "./pages/creator";
import SocialiteCallback from "./pages/socialiteCallback";
import { ResetPassword } from "./pages/resetPassword";
import Login from "./pages/login";
import Categoryfilter from "./pages/categoryfilter";
import { CartProvider } from "./component/auth/context/cartContext";

function App() {
  return (
    <React.Fragment>
      <ToastContainer />
      <CartProvider>
        <RegisterProvider>
          <Routes>
            <Route
              path="/api/auth/socialite/google/callback"
              element={<SocialiteCallback />}
              exact
            />
            <Route
              path="/api/auth/socialite/facebook/callback"
              element={<SocialiteCallback />}
              exact
            />
            <Route path="/" element={<Home />} exact />
            <Route path="/home" element={<Home />} exact />
            <Route path="/open/store" element={<OpenAStore />} exact />
            <Route
              path="/search/result/:query"
              element={<ResultsPage />}
              exact
            />
            <Route
              path="/filter/result/:query"
              element={<Categoryfilter />}
              exact
            />

            <Route path="/buy/page/:id" element={<BuyPage />} exact />
            <Route path="/cart" element={<Cart />} exact />
            <Route path="/user/:subpage" element={<User />} exact />
            <Route path="/store/:storeId" element={<Store />} exact />
            <Route path="/user/upload" element={<UploadPage />} exact />
            <Route path="/admin/product" element={<Admin />} exact />
            <Route path="/admin/expanded/:id" element={<Expanded />} exact />
            <Route path="/notification" element={<Notifications />} exact />
            <Route
              path="/signup/experimental"
              element={<SignUpExperimental />}
              exact
            />
            <Route path="/reset-password" element={<ResetPassword />} exact />
            <Route path="/ComingSoon" element={<ComingSoon />} exact />
            <Route path="/privacy/policy" element={<PrivacyPolicy />} exact />
            <Route path="/terms/conditions" element={<Terms />} exact />
            <Route
              path="/loooty-exclusive-register"
              element={<Register />}
              exact
            />
            <Route path="/login" element={<Login />} exact />

            <Route path="/creator/agreement" element={<Creator />} exact />
          </Routes>
        </RegisterProvider>
      </CartProvider>
    </React.Fragment>
  );
}

export default App;
