const SalesDropdown = ({
  showDropdown = false,
  onMouseEnter,
  onMouseLeave,
  setSelected,
  initialMinimumBalance,
}) => {
  // const dispatch = useDispatch();

  // const updateMinimumBalance = async (number) => {
  //   let updatedBalance = Number(initialMinimumBalance) + number;
  //   setSelected(updatedBalance);

  //   await dispatch(
  //     updateWalletMinimumBalanceAction({
  //       updateData: {
  //         minimum_balance: updatedBalance,
  //       },
  //     })
  //   )
  //     .unwrap()
  //     .then((res) => {
  //       toast.success('Minimum balance updated!', {
  //         autoClose: 2000,
  //         className: 'toast__message',
  //       });
  //     })
  //     .catch((err) => {
  //       console.log({ err });
  //       toast.error(err?.response?.data?.message || 'Try again later', {
  //         autoClose: 2000,
  //         className: 'toast__message',
  //       });
  //     });
  // };

  return showDropdown ? (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className='user__sales-mc-dropdown-container'
    >
      <span onClick={(e) => setSelected(50)}>${50}</span>
      <span onClick={(e) => setSelected(100)}>${100}</span>
    </div>
  ) : null;
};

export default SalesDropdown;
