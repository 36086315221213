const SidebarTabNavigationItem = ({
    activeSideTab,
    showText,
    navigator,
    onClick
  }) => {
    return (
      <div
        onClick={() => onClick(navigator)}
        className={`admin__freebies-sidebar-header-item ${
          activeSideTab === navigator &&
          "admin__freebies-sidebar-header-item-active"
        }`}
      >
        {showText}
      </div>
    );
  };

  export default SidebarTabNavigationItem