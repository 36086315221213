import { MdArrowDropDown } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setSelectedSubPage } from "../../redux/reducers/userStateReducer";
import { useState } from "react";

const defaultTags = [
  {
    name: "subscriptions",
  },
  {
    name: "profile",
  },
  {
    name: "library",
  },
  {
    name: "wish-list",
  },
  {
    name: "messages",
  },
  {
    name: "settings",
  },
];

const UniversalTagHeaderMobile = ({
  activeInpageHeader,
  setActiveInPageHeader,
  TagList = defaultTags,
  style,
  onClick,
  isAccountOwner,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showMobileHeaderItems, setShowMobileHeaderItems] = useState(false);
  return (
    <section
      style={{ ...style, position: "relative" }}
      className="user__mobile-page-section-header-container"
    >
      <div
        className="user__mobile-page-section-header-item user__mobile-page-section-header-item-selected"
        onClick={() => {
          if (isAccountOwner === undefined) {
            setShowMobileHeaderItems(!showMobileHeaderItems);
          }
        }}
      >
        {activeInpageHeader}
        {isAccountOwner === undefined && (
          <MdArrowDropDown className="user__mobile-page-section-header-arrow-down" />
        )}
      </div>

      <div className="mobile-universal-header-dropdown-item-container">
        {showMobileHeaderItems
          ? TagList?.map((tagsBtn, index) => (
              <div
                className="user__mobile-page-section-header-item"
                onClick={(e) => {
                  if (onClick) {
                    onClick();
                    return;
                  }
                  setShowMobileHeaderItems(false);
                  dispatch(setSelectedSubPage({ selected: tagsBtn.name }));
                  setActiveInPageHeader(tagsBtn.name.toLowerCase());
                  navigate(`/user/${tagsBtn.name}`);
                }}
                key={tagsBtn.name}
              >
                <span
                  style={{
                    color: `${
                      activeInpageHeader === tagsBtn.name
                        ? "#FF9700"
                        : "#4D4D6B"
                    }`,
                  }}
                >
                  {tagsBtn.name}
                </span>
              </div>
            ))
          : null}
      </div>
    </section>
  );
};

export default UniversalTagHeaderMobile;
