import LoootyLoader from "../../../loader/loootyLoader";
import { ImUserMinus } from "react-icons/im";

const UnblockUserModal = ({toggleShowModal, fadeOut, setFadeOut, isLoading, unblockUser}) => {
    return (
        <div
            className={`reg__sign-up-overlay ${fadeOut ? "lgn__fade-out-anim" : ""}`}
            style={{color: '#ffffff'}}
        >
        <section className="reg__sign-up-modal user__withdrawal-modal">
            <div className="reg__close-btn-container">
                <div
                    onClick={(e) => {
                        setFadeOut((prev) => !prev);
                        setTimeout(() => {toggleShowModal();}, 600);
                    }}
                    className="reg__close-btn"
                >
                    <span className="reg__close-btn-text">X</span>
                </div>
            </div>

            <div className="reg__sign-up-forms-container withdrawal-modal">
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center" }}>
                    <ImUserMinus
                        className="user__lib-folder-large"
                        style={{ fontSize: "1.5rem", marginRight: "1rem", color: "#74747d" }}
                    />
                    <h2 className="user__withdrawal-modal-heading">Unblock Account</h2>
                </div>

                <div>
                    <p className="user__delete-folder-modal-text">Unblock Account, continue ?</p>

                    <div className="user__delete-folder-btn-container">
                        <button 
                            className="user__library-trash-btn" 
                            type="button" 
                            onClick={() => {
                                setFadeOut((prev) => !prev);
                                setTimeout(() => {toggleShowModal();}, 400);
                            }}
                        >
                            <span className="user__library-trash-btn-text">
                                Cancel
                            </span>
                        </button>

                        <div style={{width: "fit-content", marginLeft: "1rem"}}>
                            <button
                                onClick={unblockUser}
                                className="user__upload-products-btn"
                                type="button"
                            >
                                <div className="user__upload-products-btn-inner">
                                    { isLoading ? <LoootyLoader /> : "Unblock" }
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    );
}

export default UnblockUserModal;