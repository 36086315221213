import "react-phone-number-input/style.css";
import Cookies from "js-cookie";
import NavBar from "../component/navbar/navbar";
import "../styles/cart/cart.css";
import { useContext, useEffect, useState } from "react";
import OrderCheckbox from "../component/user/OrderCheckbox";
import { RegisterContext } from "../component/auth/context/registerContext";
import { useDispatch } from "react-redux";
import {
  checkoutUserCartAction,
  emptyUserCartAction,
  getPaymentProviderKeysAction,
  updateUnauthenticatedCart,
  updateUserCartAction,
  zeroDollarAction,
} from "../api/user";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { HiTrash } from "react-icons/hi";
import { parsePhoneNumber } from "react-phone-number-input";
import isoCountries from "i18n-iso-countries";
import { CartContext } from "../component/auth/context/cartContext";
import { usePaystackPayment } from "react-paystack";
import { useNavigate } from "react-router-dom";
import CartRightMobile from "../component/forms/CartRightMobile";
import CartRight from "../component/forms/CartRight";
const countryList = require("country-list");

let countries = countryList.getNames();

const WolfGuyImg = "../assets/image/loootydefault.jpg";

const CartItem = ({
  product,
  removeFromCart,
  checkBoxActive,
  setCheckboxActiveItems,
  setSelectedArray,
}) => {
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 768 });
  if (!product) {
    return null;
  }
  return (
    <div className="cart__cart-item-outer">
      <div
        onClick={() => {
          setCheckboxActiveItems();
          setSelectedArray(product);
        }}
      >
        <OrderCheckbox
          isActive={checkBoxActive}
          setIsActive={setCheckboxActiveItems}
        />
      </div>
      <div className="cart__cart-item">
        <div className="cart__item-img-container">
          <img
            className="cart__item-img"
            src={product?.image ?? WolfGuyImg}
            alt=""
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            paddingLeft: isMobile ? "1rem" : "0",
          }}
          className="cart_item-mobile-description-box"
        >
          <div className="cart__item-title">{product?.merchandise_name}</div>
          <div className="cart__item-license-type capitalize">
            {product?.license} License
          </div>
        </div>
        <button className="cart__item-mobile-price-btn">
          <span style={{ transform: "skewX(20deg)" }}>
            $&nbsp;{product?.merchandise_amount}
          </span>
        </button>

        <button className="cart__item-price-btn">
          <span style={{ transform: "skewX(20deg)" }}>
            $&nbsp;
            {product?.merchandise_amount}
          </span>
        </button>
        <button
          className="cart__item-price-close-btn"
          onClick={() => removeFromCart(product?.product_id)}
        >
          <span
            className="cart__item-price-close-btn-text"
            style={{ transform: "skewX(20deg)", zIndex: "1" }}
          >
            x
          </span>
          <span className="cart__cart-item-builder-close"></span>
        </button>
      </div>
    </div>
  );
};

const Cart = () => {
  let user = null;
  if (typeof window !== "undefined") {
    const userCookie = Cookies.get("user");
    user = userCookie ? JSON.parse(userCookie) : null;
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 768 });
  const { cart, updateCart, cartLength, loadingCart } = useContext(CartContext);
  const [isActive, setIsActive] = useState(false);
  const [showRegModal, setShowRegModal] = useContext(RegisterContext);
  const [selectedOption, setSelectedOption] = useState("");
  const [paymetKeys, setPaymentKeys] = useState({});
  const [paymentReference, setPaymentReference] = useState("");
  const [convertedAmount, setConvertedAmount] = useState(0);

  const [value, setValue] = useState();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [data, setData] = useState({
    email: user?.email || "",
    phone_code: selectedCountry?.value,
    phone: user?.phone || value,
  });
  const { email, phone_code, phone } = data;
  const emptyFields = email === "";

  const handleDataChange = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const calculateTotalAmount = () => {
    let total = 0;
    for (const product of cart) {
      total += product.merchandise_amount;
    }
    return total;
  };

  const totalAmount = calculateTotalAmount();

  const [checkboxActiveItems, setCheckboxActiveItems] = useState([]);
  const handleToggleAllCheckboxes = (isChecked) => {
    const newList = Array(checkboxActiveItems.length).fill(isChecked);
    setCheckboxActiveItems(newList);
  };
  const handleCheckBoxClick = (index) => {
    const list = checkboxActiveItems;
    list[index] = !list[index];
    setCheckboxActiveItems(list);
  };
  const zeroDollarPayment = async () => {
    try {
      await dispatch(zeroDollarAction({ id: paymentReference })).unwrap();
      updateCart([]);
      localStorage.clear();
      window.location.href = `${window.location.origin}/user/library`;
    } catch (error) {
      console.log(error);
    }
  };

  const config = {
    reference: paymentReference,
    email: user?.email || data.email,
    amount: convertedAmount && parseInt(convertedAmount?.replace(/[,.]/g, "")),
    publicKey: paymetKeys.pay_stack_key,
  };

  const onSuccess = (reference) => {
    navigate(`/user/library`);
    localStorage.clear();
    updateCart([]);
  };

  const onClose = (reference) => {};

  const initializePayment = usePaystackPayment(config);

  const [selectedArray, setSelectedArray] = useState([]);
  const toggleObjectInArray = (objectToToggle) => {
    const existingIndex = selectedArray.findIndex(
      (item) => item.product_id === objectToToggle.product_id
    );

    if (existingIndex === -1) {
      setSelectedArray((prevArray) => [...prevArray, objectToToggle]);
    } else {
      const newArray = selectedArray.filter(
        (item) => item.product_id !== objectToToggle.product_id
      );
      setSelectedArray(newArray);
    }
  };
  //load cart on mount
  useEffect(() => {
    const updateUserCart = async () => {
      try {
        if (cart.length) {
          await dispatch(
            updateUserCartAction({
              updateData: {
                user_id: user.id,
                products: cart,
              },
            })
          );
        }
      } catch (error) {
        console.error("Error updating user cart:", error);
      }
    };

    updateUserCart();

    return () => {
      // Cleanup if needed
    };
  }, [dispatch, cart, user?.id]);

  // Checkout function
  useEffect(() => {
    let isMounted = true;

    const handleCheckout = async () => {
      try {
        if (
          cart?.length !== 0 &&
          paymentReference === "" &&
          data.email !== ""
        ) {
          const response = await dispatch(
            checkoutUserCartAction({
              checkoutData: {
                user_id: user?.id ?? data.email,
                order_type: "standard",
              },
            })
          ).unwrap();
          if (isMounted && response) {
            setPaymentReference(response.id);
            setConvertedAmount(response.convertedAmount);
          }
        }
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: "toast__message",
          });
        }
      }
    };

    handleCheckout();

    return () => {
      isMounted = false;
    };
  }, [dispatch, data.email, cart, paymentReference, user?.id]);

  // delete selected
  const removeSelected = async () => {
    const updatedCart = cart.filter(
      (item) =>
        !selectedArray.some(
          (subItem) => subItem["product_id"] === item["product_id"]
        )
    );

    if (user?.access_token) {
      if (updatedCart.length === 0) {
        await dispatch(
          emptyUserCartAction({
            updateData: {
              user_id: user?.id,
            },
          })
        )
          .unwrap()
          .then((response) => {
            updateCart(updatedCart);
            toast.success("Cart cleared", {
              autoClose: 2000,
              className: "toast__message",
            });
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
                className: "toast__message",
              });
            }
          });
      } else {
        await dispatch(
          updateUserCartAction({
            updateData: {
              user_id: user?.id,
              products: updatedCart,
            },
          })
        )
          .unwrap()
          .then((response) => {
            toast.success("Item removed from cart", {
              autoClose: 2000,
              className: "toast__message",
            });
            updateCart(updatedCart);
            setPaymentReference("");
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
                className: "toast__message",
              });
            }
          });
      }
    } else {
      await dispatch(
        updateUnauthenticatedCart({
          updateData: {
            products: updatedCart?.length === 0 ? null : updatedCart,
          },
        })
      )
        .unwrap()
        .then((response) => {
          toast.success("Item removed from cart", {
            autoClose: 2000,
            className: "toast__message",
          });
          updateCart(updatedCart);
          setPaymentReference("");
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
              className: "toast__message",
            });
          }
        });
    }
  };
  const removeFromCart = async (itemId) => {
    const updatedCarted = cart.filter((item) => item.product_id !== itemId);

    if (user?.access_token) {
      if (updatedCarted.length === 0) {
        await dispatch(
          emptyUserCartAction({
            updateData: {
              user_id: user?.id,
            },
          })
        )
          .unwrap()
          .then((response) => {
            updateCart(updatedCarted);
            toast.success("Cart cleared", {
              autoClose: 2000,
              className: "toast__message",
            });
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
                className: "toast__message",
              });
            }
          });
      } else {
        await dispatch(
          updateUserCartAction({
            updateData: {
              user_id: user?.id,
              products: updatedCarted,
            },
          })
        )
          .unwrap()
          .then((response) => {
            toast.success("Item removed from cart", {
              autoClose: 2000,
              className: "toast__message",
            });
            updateCart(updatedCarted);
            setPaymentReference("");
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
                className: "toast__message",
              });
            }
          });
      }
    } else {
      await dispatch(
        updateUnauthenticatedCart({
          updateData: {
            products: updatedCarted?.length === 0 ? null : updatedCarted,
          },
        })
      )
        .unwrap()
        .then((response) => {
          toast.success("Item removed from cart", {
            autoClose: 2000,
            className: "toast__message",
          });
          updateCart(updatedCarted);
          setPaymentReference("");
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
              className: "toast__message",
            });
          }
        });
    }
  };

  //Paystack
  const createPaystackPayment = async (e) => {
    e.preventDefault();
    if (totalAmount < 1) {
      zeroDollarPayment();
      return;
    }
    if (data.email === "") {
      toast.warn("Add email", {
        autoClose: 2000,
        className: "toast__message",
      });
      return;
    }

    if (selectedOption === "") {
      toast.warn("Please select country", {
        autoClose: 2000,
        className: "toast__message",
      });
      return;
    }
    await initializePayment(onSuccess, onClose);
  };

  useEffect(() => {
    dispatch(getPaymentProviderKeysAction({}))
      .unwrap()
      .then((response) => {
        setPaymentKeys(response);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: "toast__message",
          });
        }
      });
  }, [dispatch]);

  const isAnyCheckboxChecked = checkboxActiveItems.some(
    (isChecked) => isChecked
  );

  isoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
  isoCountries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
  const handlePhoneNumberChange = (newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    const phoneNumber = typeof value === "string" && parsePhoneNumber(value);
    if (phoneNumber) {
      const countryName = isoCountries.getName(phoneNumber.country, "en"); // Use the desired locale

      setSelectedCountry({
        label: countryName,
        value: phoneNumber.countryCallingCode,
        country_code: phoneNumber.country,
      });
    } else {
      setSelectedCountry(null);
    }
  }, [value]);
  const loginToPurchase = (e) => {
    e.preventDefault();
    console.log(showRegModal.login);
    setShowRegModal((old) => ({ ...old, login: true }));
  };

  return (
    <div className="">
      <header>
        <NavBar style={{ paddingBottom: "0" }} cartCount={cart?.length} />
      </header>

      <main className="cart">
        <section className="cart__inner-container">
          <section className="cart__items-container">
            <div className="cart__category-container">
              {isActive ? (
                <span
                  className="cart__cat-item"
                  onClick={() => {
                    setIsActive(() => !isActive);
                    handleToggleAllCheckboxes(false);
                  }}
                >
                  {cart.length !== 0 && (
                    <OrderCheckbox
                      isActive={isActive}
                      setIsActive={setIsActive}
                    />
                  )}
                  ALL
                </span>
              ) : (
                <span
                  className="cart__cat-item"
                  onClick={() => {
                    setIsActive(() => !isActive);
                    handleToggleAllCheckboxes(true);
                  }}
                >
                  <OrderCheckbox
                    isActive={isActive}
                    setIsActive={setIsActive}
                  />
                  ALL
                </span>
              )}
              <span className="cart__cat-item product">PRODUCT</span>
              {!isMobile && (
                <span className="cart__cat-item borded">LICENSE</span>
              )}

              <span style={{ border: "none" }} className="cart__cat-item">
                PRICE
              </span>
            </div>

            <div className="cart__cart-items-container">
              {!loadingCart && cart.length > 0 ? (
                cart.map((item, index) => (
                  <CartItem
                    key={index}
                    setCheckboxActiveItems={() => handleCheckBoxClick(index)}
                    checkIndex={0}
                    checkBoxActive={checkboxActiveItems[index]}
                    product={item}
                    removeFromCart={removeFromCart}
                    allActive={isActive}
                    setSelectedArray={toggleObjectInArray}
                  />
                ))
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    color: "#ffffff",
                    fontSize: "2rem",
                    marginTop: "2rem",
                  }}
                >
                  Add items to cart
                </p>
              )}
            </div>

            <div className="cart__calc-container">
              <div className="cart__subtotal-container">
                <span className="cart__subtotal-title">SUBTOTAL</span>

                <span className="cart__subtotal-title-price">
                  ${totalAmount}
                </span>
              </div>
            </div>
            {isAnyCheckboxChecked &&
              cart &&
              cart.length &&
              selectedArray.length && (
                <button className="cart__trash" onClick={removeSelected}>
                  <HiTrash className="user__library-trash-btn-logo" />
                  <span className="user__library-trash-btn-text">
                    Delete selected
                  </span>
                </button>
              )}
          </section>

          {cartLength === 0 ? null : (
            <>
              <CartRightMobile
                phone_code={phone_code}
                phone={phone}
                email={email}
                handleDataChange={handleDataChange}
                countries={countries}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                value={value}
                handlePhoneNumberChange={handlePhoneNumberChange}
                user={user}
                createPaystackPayment={createPaystackPayment}
                emptyFields={emptyFields}
                loginToPurchase={loginToPurchase}
                totalAmount={totalAmount}
                cartFromBackend={cart}
                paymentReference={paymentReference}
                zeroDollarPayment={zeroDollarPayment}
              />
              <CartRight
                phone_code={phone_code}
                phone={phone}
                email={email}
                handleDataChange={handleDataChange}
                countries={countries}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                value={value}
                handlePhoneNumberChange={handlePhoneNumberChange}
                user={user}
                createPaystackPayment={createPaystackPayment}
                emptyFields={emptyFields}
                loginToPurchase={loginToPurchase}
                totalAmount={totalAmount}
                cartFromBackend={cart}
                paymentReference={paymentReference}
                zeroDollarPayment={zeroDollarPayment}
              />
            </>
          )}
        </section>
      </main>
    </div>
  );
};

export default Cart;
