export const checkIsAdministrator = (teamRoles) => {
  if (Array.isArray(teamRoles)) {
    const adminRole = teamRoles.find(
      (role) => role.display_name === 'Application Administrator'
    );
    return Boolean(adminRole);
  } else if (teamRoles) {
    return Object.keys(teamRoles).includes('administrator');
  }
  // Handle other cases if needed
  return false; // Default return value when teamRoles is not an array and not defined
};
