import PhoneInput from "react-phone-number-input";
import DropDown from "../dropdown/Dropdown";
import { MdEmail } from "react-icons/md";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
// import { CartContext } from "../auth/context/cartContext";
import { GrStripe } from "react-icons/gr";
import { FaServicestack } from "react-icons/fa";
import Cookies from "js-cookie";
import { getClientSecret } from "../../api/user";
import { useDispatch } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./stripe/CheckoutForm";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

export default function CartRightMobile({
  email,
  phone_code,
  phone,
  handleDataChange,
  countries,
  selectedOption,
  setSelectedOption,
  value,
  handlePhoneNumberChange,
  loginToPurchase,
  totalAmount,
  createPaystackPayment,
  paymentReference,
  zeroDollarPayment,
}) {
  let user = null;
  if (typeof window !== "undefined") {
    const userCookie = Cookies.get("user");
    user = userCookie ? JSON.parse(userCookie) : null;
  }

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState("paystack");
  // const { updateCart } = useContext(CartContext);

  const [clientSecret, setClientSecret] = useState("");
  const [showStripe, setShowStripe] = useState(false);
  const dispatch = useDispatch();
  const [stripe, setStripe] = useState(null);

  // const item = {
  //   price: "price_1OEiV0HJH5K8HItbg3T1CvsR",
  //   quantity: 1,
  // };

  // const checkoutOptions = {
  //   lineItems: [item],
  //   mode: "payment",
  //   successUrl: `${window.location.origin}/user/library`,
  //   cancelUrl: `${window.location.origin}/cart`,
  // };

  const redirectToCheckout = async (e) => {
    e.preventDefault();
    if (totalAmount < 1) {
      zeroDollarPayment();
      return;
    }
    if (email === "") {
      toast.warn("Add email", {
        autoClose: 2000,
        className: "toast__message",
      });
      return;
    }
    if (!selectedOption || selectedOption === "") {
      toast.warn("Please select country", {
        autoClose: 2000,
        className: "toast__message",
      });
      return;
    } else {
      setShowStripe(true);
    }
  };
  useEffect(() => {
    stripePromise.then((resolvedStripe) => {
      setStripe(resolvedStripe);
    });
  }, []);
  useEffect(() => {
    if (selectedPaymentMethod === "stripe" && paymentReference) {
      dispatch(
        getClientSecret({
          id: paymentReference,
        })
      )
        .unwrap()
        .then((response) => {
          setClientSecret(response.clientSecret);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedPaymentMethod, dispatch, paymentReference]);
  const appearance = {
    theme: "stripe",
  };
  const loader = "auto";
  const options = {
    clientSecret,
    appearance,
    loader,
  };
  return (
    <section className="cart__payment-type-mobile">
      {showStripe && (
        <div className="stripe-checkout">
          <Elements stripe={stripe} options={options}>
            <CheckoutForm />
          </Elements>
        </div>
      )}
      <a
        href="#0"
        style={{
          backgroundColor: "#05a2ce",
          outline: "0.2rem solid #ffffff",
        }}
        className={`coming__soon-navbar-request-btn payment-option-btn ${
          selectedPaymentMethod === "paystack" &&
          "cart__payment-type-btn-selected-mobile"
        }`}
        onClick={() => setSelectedPaymentMethod("paystack")}
      >
        <FaServicestack className="cart_payment-option-logo" />
        <span>Pay with Paystack</span>
      </a>

      <a
        href="#0"
        style={{
          backgroundColor: "#008cdd",
          outline: "0.2rem solid #ffffff",
          opacity: "0.5",
          cursor: "not-allowed",
        }}
        className={`coming__soon-navbar-request-btn payment-option-btn ${
          selectedPaymentMethod === "stripe" &&
          "cart__payment-type-btn-selected-mobile"
        }`}
        // onClick={() => setSelectedPaymentMethod("stripe")}
      >
        <GrStripe className="cart_payment-option-logo" />
        <span>Pay with Stripe</span>
      </a>

      <form className="cart__card-insertion-container-mobile">
        <div className="cart_info_container">
          <p
            style={{
              color: "#BCBCBC",
              fontSize: "0.8rem",
              marginTop: "2rem",
            }}
          >
            *Email
          </p>
          <div className="user__sales-form-group">
            <div
              className="user__sales-skew-container"
              style={{
                position: "relative",
                width: "100%",
                backgroundColor: "#15151c",
              }}
            >
              <input
                placeholder="Fill in your Email here"
                className="user__sales-mc-input"
                style={{ width: "100% !important" }}
                name="email"
                value={email}
                onChange={(e) => handleDataChange(e)}
              />
              <div
                className="user__sales-mc-input-edit-skew-container"
                style={{
                  position: "absolute",
                  left: "unset",
                  right: 4,
                  top: 4,
                  transform: "none",
                  backgroundColor: "transparent",
                }}
              >
                <MdEmail className="user__sales-mc-input-edit" />
              </div>
            </div>
          </div>
        </div>
        <div className="cart_info_container">
          <p
            style={{ color: "#BCBCBC", fontSize: "0.8rem", marginTop: "2rem" }}
          >
            *Country
          </p>
          <DropDown
            options={countries.sort()}
            placeholder={
              selectedOption === "" ? (
                <span style={{ color: "#666683", fontSize: "1rem" }}>
                  Enter country
                </span>
              ) : (
                selectedOption
              )
            }
            selected={selectedOption}
            onSelect={(option) => {
              setSelectedOption(option);
            }}
            style={{
              flexDirection: "row-reverse",
              border: "1px solid #636363",
            }}
          />
        </div>

        <div className="cart_info_container">
          <p
            style={{ color: "#BCBCBC", fontSize: "0.8rem", marginTop: "2rem" }}
          >
            Phone
          </p>

          <PhoneInput
            placeholder="Enter phone number"
            value={value}
            onChange={handlePhoneNumberChange}
            className="phone-input-custom"
            defaultCountry="NG"
          />
        </div>

        <p
          style={{
            color: "#BCBCBC",
            fontSize: "1rem",
            textAlign: "center",
            marginTop: "1rem",
          }}
        >
          Options with asterisk (*) are compulsory
        </p>

        {user?.access_token ? (
          <div
            style={{
              margin: "2rem auto",
            }}
          >
            <button
              onClick={(e) => {
                if (selectedPaymentMethod === "stripe") {
                  redirectToCheckout(e);
                } else if (selectedPaymentMethod === "paystack") {
                  createPaystackPayment(e);
                }
              }}
              className="cart__payBtn"
            >
              <span style={{ transform: "SkewX(20deg)" }}>PAY </span>
            </button>
          </div>
        ) : (
          <div
            style={{
              width: "80%",
              margin: "0 auto",
            }}
          >
            <button
              onClick={(e) => loginToPurchase(e)}
              className="cart__payBtn"
            >
              <span style={{ transform: "SkewX(20deg)" }}>
                {totalAmount === 0 ? "Login to Download" : "Login to Purchase"}
              </span>
            </button>
          </div>
        )}
      </form>
    </section>
  );
}
