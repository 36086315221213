import NavBar from "../component/navbar/navbar"


const SignUpExperimental = () => {
 
    return (
        <div>
            <NavBar />
        </div>
    )
}

export default SignUpExperimental