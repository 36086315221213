export const getTeamRoleKey = (teamRoles, role) => {
  let foundRole;
  if (Array.isArray(teamRoles)) {
    foundRole = teamRoles.find((obj) => {
      const objRole = obj?.role;
      if (objRole && objRole.name === role) {
        return objRole.name;
      } else {
        return obj.name;
      }
    });
  } else if (teamRoles && typeof teamRoles === 'object') {
    // Add a check to ensure teamRoles is defined as an object
    const roles = Object.values(teamRoles);
    roles.forEach((obj) => {
      if (obj.roles.includes(role)) {
        foundRole = obj;
      }
    });
  }

  if (foundRole) {
    return foundRole.name;
  } else {
    // Handle the case when the role is not found
    return null;
  }
};

export function getStoreId(obj) {
  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      typeof obj[key] === 'object' &&
      'store_id' in obj[key]
    ) {
      return obj[key].store_id;
    }
  }
  // Handle the case where the object is empty or doesn't contain the expected structure
  return undefined;
}
