import { useState } from 'react';
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaSave,
  FaTwitter,
} from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';

export function getLinkByName(name, arr) {
  const obj = arr?.find((item) => item.name === name);
  return obj ? obj.link : null;
}

const SocialMediaInput = ({ labelName, onChange, value, type, onSave }) => {
  const [saveStates, setSaveStates] = useState({
    ig: false,
    fb: false,
    tw: false,
    linkedin: false,
  });
  const { ig, fb, tw, linkedin } = saveStates;
  return (
    <div className='user__main-profile-form-group'>
      <div className='user__label-container'>
        <label className='user__main-profile-label'>
          <span className={'user__main-profile-label-inner'}>{labelName}</span>
        </label>
      </div>

      <section className='user__main-profile-form-control-container-social'>
        <section className='user__main-profile-form-control-container-social-inner'>
          <div className='user__main-profile-skew-container-social'>
            <div className='user__main-profile-social-icon-container'>
              <FaInstagram
                style={{
                  color: '385797',
                  fontSize: '2rem',
                  transform: 'skewX(20deg)',
                }}
              />
            </div>
            <input
              type={type}
              disabled={!ig}
              onChange={(e) => onChange('instagram', e.target.value)}
              value={getLinkByName('instagram', value) || ''}
              className='user__main-profile-form-control-social'
            />
            <span className='user__main-profile-form-control-edit-btn'>
              {!ig ? (
                <MdEdit
                  onClick={() => setSaveStates({ ...saveStates, ig: true })}
                  style={{ cursor: 'pointer', transform: 'skewX(20deg)' }}
                />
              ) : (
                <FaSave
                  onClick={async () => {
                    await onSave({});
                    setSaveStates({ ...saveStates, ig: false });
                  }}
                  style={{ cursor: 'pointer', transform: 'skewX(20deg)' }}
                />
              )}
            </span>
          </div>

          <div className='user__main-profile-skew-container-social'>
            <div className='user__main-profile-social-icon-container'>
              <FaTwitter
                style={{
                  color: '385797',
                  fontSize: '2rem',
                  transform: 'skewX(20deg)',
                }}
              />
            </div>
            <input
              type={type}
              disabled={!tw}
              onChange={(e) => onChange('twitter', e.target.value)}
              value={getLinkByName('twitter', value) || ''}
              className='user__main-profile-form-control-social'
            />
            <span className='user__main-profile-form-control-edit-btn'>
              {!tw ? (
                <MdEdit
                  onClick={() => setSaveStates({ ...saveStates, tw: true })}
                  style={{ cursor: 'pointer', transform: 'skewX(20deg)' }}
                />
              ) : (
                <FaSave
                  onClick={async () => {
                    await onSave();
                    setSaveStates({ ...saveStates, tw: false });
                  }}
                  style={{ cursor: 'pointer', transform: 'skewX(20deg)' }}
                />
              )}
            </span>
          </div>
        </section>

        <section className='user__main-profile-form-control-container-social-inner'>
          <div className='user__main-profile-skew-container-social'>
            <div className='user__main-profile-social-icon-container'>
              <FaFacebook
                style={{
                  color: '385797',
                  fontSize: '2rem',
                  transform: 'skewX(20deg)',
                }}
              />
            </div>
            <input
              type={type}
              disabled={!fb}
              onChange={(e) => onChange('facebook', e.target.value)}
              value={getLinkByName('facebook', value) || ''}
              className='user__main-profile-form-control-social'
            />
            <span className='user__main-profile-form-control-edit-btn'>
              {!fb ? (
                <MdEdit
                  onClick={() => {
                    setSaveStates({ ...saveStates, fb: true });
                  }}
                  style={{ cursor: 'pointer', transform: 'skewX(20deg)' }}
                />
              ) : (
                <FaSave
                  onClick={async () => {
                    await onSave();
                    setSaveStates({ ...saveStates, fb: false });
                  }}
                  style={{ cursor: 'pointer', transform: 'skewX(20deg)' }}
                />
              )}
            </span>
          </div>

          <div className='user__main-profile-skew-container-social'>
            <div className='user__main-profile-social-icon-container'>
              <FaLinkedin
                style={{
                  color: '385797',
                  fontSize: '2rem',
                  transform: 'skewX(20deg)',
                  marginRight: '5px',
                }}
              />
            </div>
            <input
              type={type}
              disabled={!linkedin}
              onChange={(e) => onChange('linkedin', e.target.value)}
              value={getLinkByName('linkedin', value) || ''}
              className='user__main-profile-form-control-social'
            />
            <span className='user__main-profile-form-control-edit-btn'>
              {!linkedin ? (
                <MdEdit
                  onClick={() => {
                    setSaveStates({ ...saveStates, linkedin: true });
                  }}
                  style={{ cursor: 'pointer', transform: 'skewX(20deg)' }}
                />
              ) : (
                <FaSave
                  onClick={async () => {
                    await onSave();
                    setSaveStates({ ...saveStates, linkedin: false });
                  }}
                  style={{ cursor: 'pointer', transform: 'skewX(20deg)' }}
                />
              )}
            </span>
          </div>
        </section>
      </section>
    </div>
  );
};

export default SocialMediaInput;
