const FilterSystem = ({ activeFilter, setActiveFilter }) => {
  return (
    <section className='landing__filter-sytem-container'>
      <div className='landing__filter-system-inner-container'>
        <div className='landing__filter-headers'>
          <span
            onClick={(e) => {
              setActiveFilter('popular');
            }}
            className={`landing__filter-header-item ${
              activeFilter === 'popular'
                ? 'landing__filter-header-item-active'
                : ''
            }`}
          >
            POPULAR
          </span>
          <span
            onClick={(e) => {
              setActiveFilter('recent');
            }}
            className={`landing__filter-header-item ${
              activeFilter === 'recent'
                ? 'landing__filter-header-item-active'
                : ''
            }`}
          >
            RECENT
          </span>
          <span
            onClick={(e) => {
              setActiveFilter('sale');
            }}
            className={`landing__filter-header-item ${
              activeFilter === 'sale'
                ? 'landing__filter-header-item-active'
                : ''
            }`}
          >
            ON SALE
          </span>
          <span
            onClick={(e) => {
              setActiveFilter('free');
            }}
            className={`landing__filter-header-item ${
              activeFilter === 'free'
                ? 'landing__filter-header-item-active'
                : ''
            }`}
          >
            FREE
          </span>
        </div>
      </div>
    </section>
  );
};

export default FilterSystem;
