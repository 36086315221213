import { useEffect, useState } from 'react';
import OrderItem from './OrderItem';
import { useDispatch } from 'react-redux';
import { fetchProfile, updateNotificationSettingsAction } from '../../api/auth';
import { toast } from 'react-toastify';
import LoadingState from '../loader/LoadingState';

const NotificationSettings = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [notificationSettings, setNotificationSettings] = useState({
    order_placed: {
      email: false,
      push: false,
    },
    free_order_placed: {
      email: false,
      push: false,
    },
    comment: {
      email: false,
      push: false,
    },
    product_rated: {
      email: false,
      push: false,
    },
    subscription_updates: {
      email: false,
      push: false,
    },
    collection_updates: {
      email: false,
      push: false,
    },
    blog_post: {
      email: false,
      push: false,
    },
    explicit_content: {
      email: false,
      push: false,
    },
    freebies: {
      email: false,
      push: false,
    },
    site_updates: {
      email: false,
      push: false,
    },
  });

  const handleNotificationSettingsChange = (key) => {
    setNotificationSettings((prevState) => ({
      ...prevState,
      [key]: {
        email: !prevState[key].email,
        push: prevState[key].push,
      },
    }));
  };

  const togglePushNotification = () => {
    const updatedNotifications = {};

    for (const key in notificationSettings) {
      if (Object.hasOwnProperty.call(notificationSettings, key)) {
        updatedNotifications[key] = {
          ...notificationSettings[key],
          push: notificationSettings[key].email
            ? !notificationSettings[key].push
            : notificationSettings[key].push,
        };
      }
    }
    setNotificationSettings(updatedNotifications);
  };

  const updateNotificationSettings = async () => {
    await dispatch(
      updateNotificationSettingsAction({ updateData: notificationSettings })
    )
      .then(() =>
        toast.success('Settings updated', {
          autoClose: 2000,
          className: 'toast__message',
        })
      )
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          autoClose: 2000,
          className: 'toast__message',
        });
      });
  };

  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);
    dispatch(fetchProfile({ properties: 0 }))
      .then((res) => {
        if (isMounted) {
          if (res?.payload.notification_preferences) {
            setNotificationSettings(res?.payload.notification_preferences);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log({ err });
        isMounted && setIsLoading(false);
        toast.error(
          err.payload?.response?.data?.message || 'Error try again later',
          {
            autoClose: 2000,
            className: 'toast__message',
          }
        );
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  return (
    <div>
      {isLoading ? (
        <LoadingState />
      ) : (
        <div>
          <div className='user__main-sett-body'>
            <div className='user__main-sett-body-left'>
              <OrderItem
                itemTitle={'Order Placed'}
                itemDescription='Every time a paid order is placed you will be notified'
                isChecked={notificationSettings?.order_placed?.email}
                setIsChecked={async () => {
                  handleNotificationSettingsChange('order_placed');
                  await updateNotificationSettings();
                }}
              />
              <OrderItem
                itemTitle={'Free Order Placed'}
                itemDescription='Every time a free order is placed you will be notified'
                isChecked={notificationSettings?.free_order_placed?.email}
                setIsChecked={async () => {
                  handleNotificationSettingsChange('free_order_placed');
                  await updateNotificationSettings();
                }}
              />
              <OrderItem
                itemTitle='Comment'
                itemDescription='Every time someone makes a comment on your product or reply to your comment you will be notified'
                isChecked={notificationSettings?.comment?.email}
                setIsChecked={async () => {
                  handleNotificationSettingsChange('comment');
                  await updateNotificationSettings();
                }}
              />
              <OrderItem
                itemTitle='Product rated'
                itemDescription={
                  'Every time someone rates your product you will be notified'
                }
                isChecked={notificationSettings?.product_rated?.email}
                setIsChecked={async () => {
                  handleNotificationSettingsChange('product_rated');
                  await updateNotificationSettings();
                }}
              />
              <OrderItem
                itemTitle='Creator Updates'
                itemDescription={
                  'You will get notified every time a store you subscribe to updates something new'
                }
                isChecked={notificationSettings?.subscription_updates?.email}
                setIsChecked={async () => {
                  handleNotificationSettingsChange('subscription_updates');
                  await updateNotificationSettings();
                }}
              />
              <OrderItem
                itemTitle='Collection Updates'
                itemDescription={
                  'Keep me updated about new offers or price changes for products in my collection'
                }
                isChecked={notificationSettings?.collection_updates?.email}
                setIsChecked={async () => {
                  handleNotificationSettingsChange('collection_updates');
                  await updateNotificationSettings();
                }}
              />
              <OrderItem
                itemTitle='Blog Post'
                itemDescription={
                  'Let me know every time a new article is published on the blog'
                }
                isChecked={notificationSettings?.blog_post?.email}
                setIsChecked={async () => {
                  handleNotificationSettingsChange('blog_post');
                  await updateNotificationSettings();
                }}
              />
              <OrderItem
                itemTitle='Push Notification'
                itemDescription={
                  'You will receive a browser notification for every new notification enabled above'
                }
                isChecked={notificationSettings?.order_placed.push}
                setIsChecked={async () => {
                  togglePushNotification();
                  await updateNotificationSettings();
                }}
              />
            </div>
          </div>

          <div className='user__main-sett-footer'>
            <div className='user__settings-footer-divider' />
            <OrderItem
              itemTitle={'Show Adult Content'}
              isChecked={notificationSettings?.explicit_content?.email}
              setIsChecked={async () => {
                handleNotificationSettingsChange('explicit_content');
                await updateNotificationSettings();
              }}
            />
            <OrderItem
              itemTitle={'Email me about popular products and freebies'}
              isChecked={notificationSettings?.freebies_emails?.email}
              setIsChecked={async () => {
                handleNotificationSettingsChange('freebies');
                await updateNotificationSettings();
              }}
            />
            <OrderItem
              itemTitle={'Email me about site updates and features'}
              isChecked={notificationSettings?.site_updates_emails?.email}
              setIsChecked={async () => {
                handleNotificationSettingsChange('site_updates');
                await updateNotificationSettings();
              }}
            />
            <OrderItem
              itemTitle={'Email me about featured blog posts'}
              isChecked={notificationSettings?.blog_post_emails?.email}
              setIsChecked={async () => {
                handleNotificationSettingsChange('blog_post');
                await updateNotificationSettings();
              }}
            />
            <OrderItem
              itemTitle={'Email me whenever there is a new notification'}
              isChecked={notificationSettings?.notification_emails?.email}
              setIsChecked={async () => {
                handleNotificationSettingsChange('notification_emails');
                await updateNotificationSettings();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationSettings;
