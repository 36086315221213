import { useEffect, useState } from 'react';
import { FaMinus, FaPlus, FaPlusCircle } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import {
  approveAMerchandiseByStore,
  createMerchandise,
  fetchAMerchandiseAction,
  updateAMerchandiseAction,
  uploadMerchandiseFileAction,
} from '../api/auth';
import { convertToBase64 } from '../component/auth/openStoreModal';
import IdentityBtn from '../component/buttons/identityBtn';
import LoootyLoader from '../component/loader/loootyLoader';
import NavBar from '../component/navbar/navbar';
import OrderCheckbox from '../component/user/OrderCheckbox';
import { BsFileEarmarkCheck, BsTags } from 'react-icons/bs';
import { getAllCategoriesAction } from '../api/user';
import { CategoriesTabloid } from '../component/admin/inpage/TagsCatAdmin';
import Cookies from 'js-cookie';
import { checkIsAdministrator } from '../utils/checkIsAdministrator';
import { getTeamRoleKey } from '../utils/getUserTeamRoleKey';
import { getAllProductTagsAction } from '../api/admin';
import { VscTriangleUp } from 'react-icons/vsc';
// import FileUpload, { shortenFileName } from '../component/upload/FileUpload';
import MoreAboutLicense from '../component/admin/modals/uploads/MoreAboutLicense';
import {
  resetUploadProgress,
  selectUploadProgress,
  setUploadProgress,
} from '../redux/progressSlice';
const gifSpinner = '/assets/gifs/spinner-mini.gif';

const removeItemFromArray = (myArray, val) => {
  const index = myArray.indexOf(val);
  if (index !== -1) {
    myArray.splice(index, 1);
    return myArray;
  }

  return -1;
};
const cursorStyle = {
  cursor: 'default',
};
export const MainTags = ({
  text = '3D Assets',
  defaultState = false,
  productInfo,
  setProductInfo,
  nonSelect,
}) => {
  const [isSelected, setIsSelected] = useState(defaultState);
  const removeItemFromArray = (myArray, val) => {
    const index = myArray.indexOf(val);
    if (index !== -1) {
      myArray.splice(index, 1);
      return myArray;
    }

    return -1;
  };

  // const TagsArray = productInfo.merchandise_tags ? productInfo.merchandise_tags : tags
  return (
    <button
      onClick={
        nonSelect
          ? null
          : (e) => {
              let check = removeItemFromArray(
                productInfo.merchandise_tags,
                text
              );

              if (check === -1) {
                if (productInfo.merchandise_tags.length > 4) {
                  toast.error('You can only choose up to five(5) tags', {
                    autoClose: 2000,
                    className: 'toast__message',
                  });
                  return;
                }
                let newArray = [...productInfo.merchandise_tags, text];
                setProductInfo((old) => ({
                  ...old,
                  merchandise_tags: newArray,
                }));
                setIsSelected(!isSelected);
                return;
              }

              setProductInfo((old) => ({ ...old, merchandise_tags: check }));
              setIsSelected(!isSelected);
            }
      }
      className='upload__tag-btn'
      style={nonSelect ? cursorStyle : undefined}
    >
      {text}
      <div
        className={`upload__tag-selected-user ${
          productInfo.merchandise_tags?.some((item) => item === text) &&
          !nonSelect &&
          'upload__tag-selected-transparent-user'
        }`}
      ></div>
    </button>
  );
};

const UploadPage = () => {
  const [isCommercialActive, setIsCommercialActive] = useState(false);
  const [basicPrice, setBasicPrice] = useState(0);
  const [commercialPrice, setCommercialPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [clickProtect, setClickProtect] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [showLicense, setShowLicense] = useState(false);
  let user = {};
  if (typeof window !== 'undefined' && Cookies.get('user')) {
    try {
      user = JSON.parse(Cookies.get('user'));
    } catch (error) {
      // Handle the error, e.g., the cookie contains invalid JSON
      console.error("Error parsing 'user' cookie:", error);
    }
  }
  const uploadProgress = useSelector(selectUploadProgress);
  const [productInfo, setProductInfo] = useState({
    store_id: getTeamRoleKey(user.team_roles, 'store'),
    merchandise_name: '',
    merchandise_description: '',
    merchandise_categories: [],
    merchandise_tags: [],
    explicit_content: 1,
    base64_banners: [],
    base64_photos: [],
    youtube_link: '',
    // merchandise_upload_id: "",
  });
  const searchParams = new URLSearchParams(location.search);
  const [isTagsOpen, setIsTagsOpen] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [zipFile, setZipfile] = useState(null);
  const maxProgress = 100;
  const cappedProgress =
    uploadProgress > maxProgress ? maxProgress : uploadProgress;
  const productId = searchParams.get('productId');
  useEffect(() => {
    let isMounted = true;

    if (productId) {
      setIsLoading(true);

      dispatch(fetchAMerchandiseAction({ productID: productId }))
        .unwrap()
        .then((response) => {
          if (
            response.user_id !== user?.id &&
            !checkIsAdministrator(user.team_roles)
          )
            navigate('/user/profile');
          if (isMounted) {
            setProductInfo({
              ...response,
              store_id: response.store_id ?? '',

              base64_banners: response?.merchandise_banners ?? [],
              base64_photos: response?.merchandise_pictures ?? [],
              merchandise_upload_id: response?.merchandise_upload_id,
            });
            setBasicPrice(response?.merchandise_amount);
            setCommercialPrice(response?.merchandise_commercial_amount);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
              className: 'toast__message',
            });
          }
        });
    }

    return () => (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, productId, user.id]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getAllCategoriesAction({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setCategories(response);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getAllProductTagsAction())
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setTags(response);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  const uploadFileToServer = async (file) => {
    setFileUploading(true);
    dispatch(
      uploadMerchandiseFileAction({
        store_id: productInfo.store_id,
      })
    )
      .unwrap()
      .then(async (response) => {
        const signedUrl = response.signed_url;
        const options = {
          headers: {
            'Content-Type': 'application/zip',
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            dispatch(setUploadProgress(progress));
          },
        };

        await axios.put(signedUrl, file, options).then((response) => {
          setZipfile(file.name);
          if (response.status === 200) {
            setFileUploaded(true);
            toast.success('File Uploaded!', {
              autoClose: 2000,
              className: 'toast__message',
            });
            dispatch(resetUploadProgress());
          }
        });

        setProductInfo((prev) => ({
          ...prev,
          merchandise_upload_id: response.merchandise_upload_id,
        }));
      })
      .catch((err) => {
        toast.error(err.message ?? 'Error, try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        dispatch(resetUploadProgress());
      })
      .finally(() => {
        setFileUploading(false);
      });
  };

  const handlePublish = async (e) => {
    e.preventDefault();

    if (!productInfo?.merchandise_name) {
      toast.error('Product must have a name', {
        autoClose: 2000,
        className: 'toast__message',
      });
      return;
    }

    if (productInfo?.merchandise_tags.length === 0) {
      toast.error('Product must have tags', {
        autoClose: 2000,
        className: 'toast__message',
      });
      return;
    }
    if (productInfo?.merchandise_categories?.length === 0) {
      toast.error('Product must have a category', {
        autoClose: 2000,
        className: 'toast__message',
      });
      return;
    }

    if (!productInfo?.merchandise_description) {
      toast.error('Product must have a description', {
        autoClose: 2000,
        className: 'toast__message',
      });
      return;
    }

    if (!productInfo?.base64_banners.length === 0) {
      toast.error('Product must have a cover picture', {
        autoClose: 2000,
        className: 'toast__message',
      });
      return;
    }

    if (!productInfo?.base64_photos.length === 0) {
      toast.error('Product must have at least one preview picture', {
        autoClose: 2000,
        className: 'toast__message',
      });
      return;
    }

    setIsLoading(true);
    setClickProtect(true);
    if (isCommercialActive) {
      setProductInfo((prevState) => ({
        ...prevState,
        merchandise_amount: basicPrice,
        merchandise_commercial_amount: commercialPrice,
      }));
    }

    dispatch(
      productId
        ? updateAMerchandiseAction({
            productInfo: {
              ...productInfo,
              id: productId,
            },
          })
        : createMerchandise({
            productInfo: {
              ...productInfo,
              merchandise_amount: basicPrice,
              merchandise_commercial_amount: commercialPrice,
            },
          })
    )
      .unwrap()
      .then(async (response) => {
        const id = response?.data?.id;
        await dispatch(
          approveAMerchandiseByStore({
            approveData: {
              id,
            },
          })
        )
          .unwrap()
          .then(() => {
            setIsLoading(false);
            toast.success('Product successfully uploaded for review', {
              autoClose: 2000,
              className: 'toast__message',
            });
            setTimeout(() => navigate('/user/store'), 3000);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        setClickProtect(false);
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
          if (err.response.data.errors) {
            if (err.response.data.errors.merchandise_name) {
              toast.error(err.response.data.errors.merchandise_name[0], {
                autoClose: 2000,
                className: 'toast__message',
              });
            }

            if (err.response.data.errors.merchandise_description) {
              toast.error(err.response.data.errors.merchandise_description[0], {
                autoClose: 2000,
                className: 'toast__message',
              });
            }

            if (err.response.data.errors['base64_banners.0']) {
              toast.error(
                err.response.data.errors['base64_banners.0'][0] ||
                  'Image dimensions are a minimum width:1200 and a minimum height:1200',
                {
                  autoClose: 2000,
                  className: 'toast__message',
                }
              );
            }

            if (err.response.data.errors['base64_photos.0']) {
              toast.error(
                err.response.data.errors['base64_photos.0'][0] ||
                  'Image dimensions are a minimum width:1200 and a minimum height:1200',
                {
                  autoClose: 2000,
                  className: 'toast__message',
                }
              );
            }
            if (err.response.data.errors['base64_photos.1']) {
              toast.error(
                err.response.data.errors['base64_photos.1'][0] ||
                  'Image dimensions are a minimum width:1200 and a minimum height:1200',
                {
                  autoClose: 2000,
                  className: 'toast__message',
                }
              );
            }

            if (err.response.data.errors['base64_photos.2']) {
              toast.error(
                err.response.data.errors['base64_photos.2'][0] ||
                  'Image dimensions are a minimum width:1200 and a minimum height:1200',
                {
                  autoClose: 2000,
                  className: 'toast__message',
                }
              );
            }

            if (err.response.data.errors.base64_files) {
              toast.error(err.response.data.errors.base64_files[0], {
                autoClose: 2000,
                className: 'toast__message',
              });
            }
          }
        }
      });
  };

  // const handleDraft = async (e) => {
  //   e.preventDefault();
  //   console.log('isdraft');

  //   if (!productInfo?.merchandise_name) {
  //     toast.error('Product must have a name', {
  //       autoClose: 2000,
  //       className: 'toast__message',
  //     });
  //     return;
  //   }

  //   if (productInfo?.merchandise_tags.length === 0) {
  //     toast.error('Product must have tags', {
  //       autoClose: 2000,
  //       className: 'toast__message',
  //     });
  //     return;
  //   }
  //   if (productInfo?.merchandise_categories?.length === 0) {
  //     toast.error('Product must have a category', {
  //       autoClose: 2000,
  //       className: 'toast__message',
  //     });
  //     return;
  //   }

  //   if (!productInfo?.merchandise_description) {
  //     toast.error('Product must have a description', {
  //       autoClose: 2000,
  //       className: 'toast__message',
  //     });
  //     return;
  //   }

  //   if (!productInfo?.base64_banners.length === 0) {
  //     toast.error('Product must have a cover picture', {
  //       autoClose: 2000,
  //       className: 'toast__message',
  //     });
  //     return;
  //   }

  //   if (!productInfo?.base64_photos.length === 0) {
  //     toast.error('Product must have at least one preview picture', {
  //       autoClose: 2000,
  //       className: 'toast__message',
  //     });
  //     return;
  //   }

  //   setIsLoading(true);
  //   setClickProtect(true);

  //   if (isCommercialActive) {
  //     setProductInfo((prevState) => ({
  //       ...prevState,
  //       commercial_price: commercialPrice,
  //     }));
  //   }
  //   console.log(productId);
  //   dispatch(
  //     productId
  //       ? updateMerchandiseDraftAction({
  //           productInfo: {
  //             ...productInfo,
  //             id: productId,
  //           },
  //         })
  //       : createMerchandiseDraftAction({
  //           productInfo: {
  //             ...productInfo,
  //             merchandise_amount: basicPrice,
  //             merchandise_commercial_amount: commercialPrice,
  //           },
  //         })
  //   )
  //     .unwrap()
  //     .then(async () => {
  //       setIsLoading(false);
  //       toast.success('Product saved to draft', {
  //         autoClose: 2000,
  //         className: 'toast__message',
  //       });
  //       setTimeout(() => navigate('/user/store'), 3000);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       setClickProtect(false);
  //       if (err.response) {
  //         toast.error(err.response.data.message, {
  //           autoClose: 2000,
  //           className: 'toast__message',
  //         });
  //         if (err.response.data.errors) {
  //           if (err.response.data.errors.merchandise_name) {
  //             toast.error(err.response.data.errors.merchandise_name[0], {
  //               autoClose: 2000,
  //               className: 'toast__message',
  //             });
  //           }

  //           if (err.response.data.errors.merchandise_description) {
  //             toast.error(err.response.data.errors.merchandise_description[0], {
  //               autoClose: 2000,
  //               className: 'toast__message',
  //             });
  //           }

  //           if (err.response.data.errors.base64_cover_picture) {
  //             toast.error(err.response.data.errors.base64_cover_picture[0], {
  //               autoClose: 2000,
  //               className: 'toast__message',
  //             });
  //           }

  //           if (err.response.data.errors.base64_photos) {
  //             toast.error(err.response.data.errors.base64_photos[0], {
  //               autoClose: 2000,
  //               className: 'toast__message',
  //             });
  //           }

  //           if (err.response.data.errors.base64_files) {
  //             toast.error(err.response.data.errors.base64_files[0], {
  //               autoClose: 2000,
  //               className: 'toast__message',
  //             });
  //           }
  //         }
  //       }
  //     });
  // };

  return (
    <div className='upload__container'>
      <header>
        <NavBar />
      </header>

      <main className='upload__mock-up'>
        <section className='upload__title-section-user'>
          <div className='upload__title-container'>
            <div className='upload__title'>Category:</div>
          </div>

          <div className='upload__main-content'>
            <div className='upload_main-content-category-container-user'>
              {categories?.slice(0, 8).map((category) => (
                <CategoriesTabloid
                  key={category.id}
                  text={category.name}
                  style={{
                    borderLeft: `.4rem solid ${
                      productInfo.merchandise_categories.includes(category.name)
                        ? '#FF9700'
                        : '#353449'
                    }`,
                    color: `${
                      productInfo.merchandise_categories.includes(category.name)
                        ? '#FF9700'
                        : '#959AA0'
                    }`,
                  }}
                  onClick={() => {
                    let check = removeItemFromArray(
                      productInfo.merchandise_categories,
                      category.name
                    );

                    if (check === -1) {
                      if (productInfo.merchandise_categories.length > 4) {
                        toast.error(
                          'You can only choose up to five(5) categories',
                          {
                            autoClose: 2000,
                            className: 'toast__message',
                          }
                        );
                        return;
                      }
                      let newArray = [
                        ...productInfo.merchandise_categories,
                        category.name,
                      ];
                      setProductInfo((old) => ({
                        ...old,
                        merchandise_categories: newArray,
                      }));
                      return;
                    }

                    setProductInfo((old) => ({
                      ...old,
                      merchandise_categories: check,
                    }));
                  }}
                />
              ))}
            </div>
            <section className='upload__image-dropbox-container-user'>
              <div className='upload__image-dropbox-twin'>
                {productInfo.base64_banners[0] ? (
                  <img
                    className='upload__image-form-control-image'
                    src={productInfo.base64_banners}
                    alt='cover'
                  />
                ) : null}
                <input
                  accept='image/*'
                  onChange={async (e) => {
                    const baseFile = await convertToBase64(e.target.files[0]);
                    setProductInfo((old) => {
                      return {
                        ...old,
                        base64_banners: [baseFile],
                      };
                    });
                  }}
                  placeholder='none'
                  type={'file'}
                  className='upload__image-dropbox'
                />

                <div
                  className={
                    productInfo.base64_banners[0]
                      ? 'upload__image-dropbox-hover'
                      : 'upload__image-dropbox-labels'
                  }
                >
                  <FaPlusCircle
                    style={{ color: '#737377', fontSize: '1.5rem' }}
                  />
                  <span className='upload__image-head'>
                    {productInfo.base64_banners[0] ? 'Change ' : 'Upload '}Image
                    Preview
                  </span>
                  <p className='upload__image-desc'>
                    or Drag and drop cover here
                  </p>
                </div>
              </div>
            </section>

            <section className='upload__image-preview-user'>
              <div className='upload__image-preview-inner-user'>
                <div className='upload__image-item'>
                  {productInfo.base64_photos[0] ? (
                    <img
                      className='upload__image-form-control-image'
                      src={productInfo.base64_photos[0]}
                      alt='preview'
                    />
                  ) : null}

                  <FaPlusCircle className='upload__image-iconister' />
                  <p>Preview Image</p>

                  <input
                    onChange={async (e) => {
                      let newArray = productInfo.base64_photos;
                      newArray[0] = await convertToBase64(
                        e.target.files[0],
                        2048,
                        false
                      );

                      setProductInfo((old) => ({
                        ...old,
                        base64_photos: newArray,
                      }));
                    }}
                    type={'file'}
                    className='upload__image-form-control'
                  />
                </div>

                <div className='upload__image-item'>
                  {productInfo.base64_photos[1] ? (
                    <img
                      className='upload__image-form-control-image'
                      src={productInfo.base64_photos[1]}
                      alt='preview'
                    />
                  ) : null}
                  <FaPlusCircle className='upload__image-iconister' />
                  <p>Preview Image</p>
                  <input
                    onChange={async (e) => {
                      let newArray = productInfo.base64_photos;
                      if (newArray.length === 0)
                        newArray[0] = await convertToBase64(
                          e.target.files[0],
                          2048,
                          false
                        );
                      else
                        newArray[1] = await convertToBase64(
                          e.target.files[0],
                          2048,
                          false
                        );

                      setProductInfo((old) => ({
                        ...old,
                        base64_photos: newArray,
                      }));
                    }}
                    type={'file'}
                    className='upload__image-form-control'
                  />
                </div>

                <div className='upload__image-item'>
                  {productInfo.base64_photos[2] ? (
                    <img
                      className='upload__image-form-control-image'
                      src={productInfo.base64_photos[2]}
                      alt='preview'
                    />
                  ) : null}
                  <FaPlusCircle className='upload__image-iconister' />
                  <p>Preview Image</p>
                  <input
                    onChange={async (e) => {
                      let newArray = productInfo.base64_photos;
                      if (newArray.length <= 2)
                        newArray.push(
                          await convertToBase64(e.target.files[0], 2048, false)
                        );
                      else
                        newArray[2] = await convertToBase64(
                          e.target.files[0],
                          2048,
                          false
                        );

                      setProductInfo((old) => ({
                        ...old,
                        base64_photos: newArray,
                      }));
                    }}
                    type={'file'}
                    className='upload__image-form-control'
                  />
                </div>

                <div className='upload__image-item'>
                  {productInfo.base64_photos[3] ? (
                    <img
                      className='upload__image-form-control-image'
                      src={productInfo.base64_photos[3]}
                      alt='preview'
                    />
                  ) : null}
                  <FaPlusCircle className='upload__image-iconister' />
                  <p>Preview Image</p>
                  <input
                    onChange={async (e) => {
                      let newArray = productInfo.base64_photos;
                      newArray.push(
                        await convertToBase64(e.target.files[0], 2048, false)
                      );

                      setProductInfo((old) => ({
                        ...old,
                        base64_photos: newArray,
                      }));
                    }}
                    type={'file'}
                    className='upload__image-form-control'
                  />
                </div>

                <div className='upload__image-item'>
                  {productInfo.base64_photos[4] ? (
                    <img
                      className='upload__image-form-control-image'
                      src={productInfo.base64_photos[4]}
                      alt='preview'
                    />
                  ) : null}
                  <FaPlusCircle className='upload__image-iconister' />
                  <p>Preview Image</p>
                  <input
                    onChange={async (e) => {
                      let newArray = productInfo.base64_photos;
                      newArray.push(
                        await convertToBase64(e.target.files[0], 2048, false)
                      );

                      setProductInfo((old) => ({
                        ...old,
                        base64_photos: newArray,
                      }));
                    }}
                    type={'file'}
                    className='upload__image-form-control'
                  />
                </div>

                <div className='upload__image-item'>
                  {productInfo.base64_photos[5] ? (
                    <img
                      className='upload__image-form-control-image'
                      src={productInfo.base64_photos[5]}
                      alt='preview'
                    />
                  ) : null}
                  <FaPlusCircle className='upload__image-iconister' />
                  <p>Preview Image</p>
                  <input
                    onChange={async (e) => {
                      let newArray = productInfo.base64_photos;
                      newArray.push(
                        await convertToBase64(e.target.files[0], 2048, false)
                      );

                      setProductInfo((old) => ({
                        ...old,
                        base64_photos: newArray,
                      }));
                    }}
                    type={'file'}
                    className='upload__image-form-control'
                  />
                </div>

                <div className='upload__image-item'>
                  {productInfo.base64_photos[5] ? (
                    <img
                      className='upload__image-form-control-image'
                      src={productInfo.base64_photos[5]}
                      alt='preview'
                    />
                  ) : null}
                  <FaPlusCircle className='upload__image-iconister' />
                  <p>Preview Image</p>
                  <input
                    onChange={async (e) => {
                      let newArray = productInfo.base64_photos;
                      newArray[5] = await convertToBase64(
                        e.target.files[0],
                        2048,
                        false
                      );

                      setProductInfo((old) => ({
                        ...old,
                        base64_photos: newArray,
                      }));
                    }}
                    type={'file'}
                    className='upload__image-form-control'
                  />
                </div>
              </div>
            </section>
          </div>
        </section>

        <section className='upload__description-section-user'>
          <IdentityBtn
            style={{
              background: '#232230',
              fontSize: '1rem',
              padding: '.8rem 5rem',
            }}
            text={'Title'}
          />

          <div className='upload__form-group'>
            <input
              onChange={(e) =>
                setProductInfo((old) => ({
                  ...old,
                  merchandise_name: e.target.value,
                }))
              }
              value={productInfo.merchandise_name}
              className='upload__form-control'
            />
            <button className='upload__form-edit-btn'>
              <MdEdit />
            </button>
          </div>

          <IdentityBtn
            style={{
              background: '#232230',
              fontSize: '1rem',
              padding: '.8rem 5rem',
            }}
            text={'Description'}
          />
          <section className='upload__text-area-container'>
            <textarea
              onChange={(e) =>
                setProductInfo((old) => ({
                  ...old,
                  merchandise_description: e.target.value,
                }))
              }
              value={productInfo.merchandise_description}
              rows={8}
              cols={70}
              className='upload__text-area'
            ></textarea>
            <button className='upload__text-area-btn'>
              <MdEdit />
            </button>
          </section>

          {(productInfo.merchandise_categories.includes('Tools') ||
            productInfo.merchandise_categories.includes('Audio')) && (
            <>
              <IdentityBtn
                style={{
                  background: '#232230',
                  fontSize: '1rem',
                  padding: '.8rem 5rem',
                }}
                text={'Youtube link'}
              />
              <div className='upload__form-group'>
                <input
                  onChange={(e) =>
                    setProductInfo((old) => ({
                      ...old,
                      youtube_link: e.target.value,
                    }))
                  }
                  value={productInfo.youtube_link}
                  className='upload__form-control'
                />
                <button className='upload__form-edit-btn'>
                  <MdEdit />
                </button>
              </div>
            </>
          )}
          <section className='upload__tags-container'>
            <div className='upload__tags-title-container'>
              <div
                onClick={() => setIsTagsOpen(!isTagsOpen)}
                className='upload__tags-title'
              >
                <BsTags style={{ color: '#FF9700' }} /> Tags{' '}
                <VscTriangleUp
                  className='buy__tag-arrow'
                  style={{
                    color: '#6D6D91',
                    transform: `rotate(${isTagsOpen ? '45deg' : '180deg'})`,
                  }}
                />
              </div>
              <p className='upload__tag-select'>
                Choose up to five{' '}
                <span style={{ color: '#B31FA6', fontSize: '1.1rem' }}>
                  {productInfo.merchandise_tags.length}/
                </span>
                5 tags
              </p>
            </div>

            {isTagsOpen && (
              <section className='upload__tags-inner-container-user'>
                {tags?.map((tag) => (
                  <MainTags
                    key={tag.id}
                    setProductInfo={setProductInfo}
                    productInfo={productInfo}
                    text={tag.name}
                    defaultState={true}
                  />
                ))}
              </section>
            )}
          </section>

          <section className='upload__main-file-upload-container-user'>
            <div className='upload__main-files-upload-twin-user'>
              {fileUploading && (
                <div style={{ position: 'relative', width: '100%' }}>
                  <progress value={cappedProgress} max={maxProgress}></progress>
                  <div
                    style={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      bottom: '2px',
                      width: '100%',
                      height: '100%',
                      zIndex: 1,
                      textAlign: 'center',
                    }}
                  >
                    <img
                      src={gifSpinner}
                      alt='spinner'
                      height={20}
                      width={20}
                    />
                  </div>
                </div>
              )}

              {!fileUploaded && (
                <>
                  <span
                    className='upload__more-text'
                    style={{ fontSize: '0.8rem', marginBottom: '10px' }}
                  >
                    Please note that you're allowed to upload a single zipped
                    file.
                  </span>
                  <label
                    htmlFor='file'
                    className='upload__main-files-upload-text'
                    style={{
                      border: '1px solid #2b2b38',
                      height: '3.5rem',
                      marginTop: '0.5rem',
                    }}
                  >
                    <FaPlusCircle
                      style={{ fontSize: '1.2rem', color: '#AA24AC' }}
                    />{' '}
                    Select Zip File
                  </label>
                  <input
                    id='file'
                    type='file'
                    multiple
                    onChange={(e) => uploadFileToServer(e.target.files[0])}
                    className='upload__fileUploadInput'
                  />
                </>
              )}

              {fileUploaded && (
                <>
                  <ul className='upload__fileList' style={{ border: 'none' }}>
                    <li
                      style={{ paddingRight: '8px', backgroundColor: 'none' }}
                    >
                      <span> {zipFile && zipFile}</span>
                    </li>
                  </ul>
                  <div
                    className='upload__main-files-upload-text'
                    style={{ border: '1px solid #2b2b38', height: '3.5rem' }}
                  >
                    File Uploaded!
                    <BsFileEarmarkCheck
                      style={{ fontSize: '1.2rem', color: '#AA24AC' }}
                    />{' '}
                  </div>
                  <span
                    className='upload__more-text'
                    style={{ fontSize: '0.8rem' }}
                    onClick={() => setFileUploaded(false)}
                  >
                    Re-upload zipped file.
                  </span>
                </>
              )}
            </div>
          </section>

          <section className='upload__price-section'>
            <div className='upload__price-inner-section'>
              <h3>Price</h3>
              <div className='upload__form-group-extra'>
                <span className='upload__license-text'>Basic&nbsp;License</span>
                <section className='upload__skew-container'>
                  <div className='upload__main-control-container'>
                    <div className='upload__control-switch-container'>
                      <button className='upload__control-usd-btn'>
                        <span>USD</span>
                      </button>

                      <input
                        type={'number'}
                        min='0'
                        onChange={(e) => setBasicPrice(e.target.value)}
                        value={basicPrice}
                        className='upload__price-input-user'
                      />
                    </div>
                  </div>

                  <div className='upload__container-controller'>
                    <button
                      onClick={() => setBasicPrice(Number(basicPrice) + 1)}
                      className='upload__controller-btn'
                    >
                      <span>
                        <FaPlus />
                      </span>
                    </button>
                    <button
                      onClick={(e) =>
                        Number(basicPrice) === 0
                          ? setBasicPrice(0)
                          : setBasicPrice(Number(basicPrice) - 1)
                      }
                      className='upload__controller-btn'
                    >
                      <span>
                        <FaMinus />
                      </span>
                    </button>
                  </div>
                </section>
              </div>

              <div className={`upload__form-group-extra`}>
                <span
                  className='upload__license-text upload__form-group-commercial'
                  onClick={() => setIsCommercialActive(!isCommercialActive)}
                >
                  <OrderCheckbox
                    isActive={isCommercialActive}
                    setIsActive={setIsCommercialActive}
                  />
                  Commercial License
                </span>
                <section
                  className={`upload__skew-container ${
                    !isCommercialActive &&
                    'upload__form-group-commercial-inactive'
                  }`}
                >
                  <div className='upload__main-control-container'>
                    <div className='upload__control-switch-container'>
                      <button className='upload__control-usd-btn'>
                        <span>USD</span>
                      </button>
                      <input
                        type={'number'}
                        value={commercialPrice}
                        onChange={(e) => setCommercialPrice(e.target.value)}
                        disabled={!isCommercialActive}
                        className='upload__price-input-user'
                      />
                    </div>
                  </div>

                  <div className='upload__container-controller'>
                    <button
                      onClick={() =>
                        setCommercialPrice(Number(commercialPrice) + 1)
                      }
                      disabled={!isCommercialActive}
                      className='upload__controller-btn'
                    >
                      <span>
                        <FaPlus />
                      </span>
                    </button>
                    <button
                      disabled={!isCommercialActive}
                      className='upload__controller-btn'
                      onClick={() =>
                        Number(commercialPrice) === 0
                          ? setCommercialPrice(0)
                          : setCommercialPrice(Number(commercialPrice) - 1)
                      }
                    >
                      <span>
                        <FaMinus />
                      </span>
                    </button>
                  </div>
                </section>
              </div>
            </div>
            <span
              onClick={() => setShowLicense(!showLicense)}
              className='upload__more-text'
            >
              More about license
            </span>
          </section>

          <section className='upload__publish-section-user'>
            {/* <button
              className='upload__draft-btn-user'
              // disabled={clickProtect}
              onClick={handleDraft}
            >
              <span>{isLoading ? <LoootyLoader /> : 'Draft'}</span>
            </button> */}

            <button
              // disabled={clickProtect}
              opacity={clickProtect ? '0.6' : '1'}
              onClick={handlePublish}
              className='upload__publish-btn-user'
            >
              <span>{isLoading ? <LoootyLoader /> : 'Publish'}</span>
            </button>
          </section>
        </section>
        {showLicense && (
          <MoreAboutLicense toggleShowModal={() => setShowLicense(false)} />
        )}
      </main>
    </div>
  );
};

export default UploadPage;
