import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfileAction, updateUserAction } from '../../api/auth';
import { toast } from 'react-toastify';
import LoadingState from '../loader/LoadingState';
import { showAUserAction } from '../../api/admin';
import { useParams } from 'react-router-dom';
import ProfileInput from '../user/ProfileInput';
import SocialMediaInput from '../user/SocialMediaInput';

const AdminUserProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const [userDetails, setUserDetails] = useState({
    user_id: '',
    name: '',
    email: '',
    phone: '',
    description: '',
    birth_date: '',
  });

  const [userSocialMedia, setUserSocialMedia] = useState([
    {
      name: 'instagram',
      link: '',
    },
    {
      name: 'twitter',
      link: '',
    },
    {
      name: 'facebook',
      link: '',
    },
    {
      name: 'linkedin',
      link: '',
    },
  ]);

  const handleLinkChange = (name, newLink) => {
    let newArray = [...userSocialMedia];
    const object = newArray?.find((obj) => obj.name === name);
    if (object) object.link = newLink;
    setUserSocialMedia(newArray);
  };

  const updateUserProfile = async (obj) => {
    dispatch(
      updateUserAction({
        updateData: {
          user_id: userDetails.user_id,
          ...obj,
        },
        accessToken: user && user.user ? user.user.access_token : '',
      })
    );
  };

  const updateUserSocialMedia = async () => {
    dispatch(
      updateProfileAction({
        updateData: {
          user_id: userDetails.user_id,
          social_media: userSocialMedia,
        },
        accessToken: user && user.user ? user.user.access_token : '',
      })
    );
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(showAUserAction({ user_id: id }))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setUserDetails((prevState) => ({
            ...prevState,
            user_id: response.id,
            name: response.name,
            email: response.email,
            phone: response.phone,
            description: response.description,
            birth_date: response.birth_date,
          }));
          if (response.profile?.social_media) {
            setUserSocialMedia(response.profile?.social_media);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch, id]);

  return (
    <section className='user__main-profile-container'>
      {isLoading ? (
        <LoadingState />
      ) : (
        <form className='user__main-profile-form'>
          <ProfileInput
            onClick={async () =>
              await updateUserProfile({ name: userDetails.name })
            }
            value={userDetails.name}
            onChange={(e) => {
              setUserDetails((old) => ({ ...old, name: e.target.value }));
            }}
            labelName={'USERNAME'}
          />
          <ProfileInput
            onClick={(e) =>
              dispatch(
                updateUserAction({
                  updateData: userDetails,
                  accessToken: user && user.user ? user.user.access_token : '',
                })
              )
            }
            value={userDetails.email}
            labelName={'EMAIL'}
          />

          <ProfileInput
            onClick={async () =>
              await updateUserProfile({ phone: userDetails.phone })
            }
            onChange={(e) =>
              setUserDetails((old) => ({ ...old, phone: e.target.value }))
            }
            labelName={'PHONE'}
            value={userDetails.phone}
          />
          <ProfileInput
            onClick={async () =>
              await updateUserProfile({ description: userDetails.description })
            }
            value={userDetails.description}
            onChange={(e) =>
              setUserDetails((old) => ({ ...old, description: e.target.value }))
            }
            labelName={'BIO'}
          />
          <ProfileInput
            onClick={async () =>
              await updateUserProfile({ birth_date: userDetails.birth_date })
            }
            type={'date'}
            value={userDetails.birth_date}
            onChange={(e) =>
              setUserDetails((old) => ({ ...old, birth_date: e.target.value }))
            }
            labelName={'DOB'}
          />
          <SocialMediaInput
            onSave={updateUserSocialMedia}
            value={userSocialMedia || []}
            onChange={handleLinkChange}
            labelName={'SOCIAL MEDIA LINKS'}
          />
        </form>
      )}
    </section>
  );
};

export default AdminUserProfile;
