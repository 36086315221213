import NavBar, { filterOptions, sortOptions } from "../component/navbar/navbar";
import { RiMapPin5Fill } from "react-icons/ri";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { fetchAStore, subscribeToStoreAction } from "../api/auth";
import { toast } from "react-toastify";
import UniversalTagHeaderDesktop from "../component/user/UniversalTagHeaderDesktop";
import { getTeamRoleKey } from "../utils/getUserTeamRoleKey";
import LoadingState from "../component/loader/LoadingState";
import LandCard from "../component/card/landCard";
import FilterSelector from "../component/admin/FilterSelector";
import FilterClear from "../component/admin/FilterClear";
import { fetchAStoreByAdminAction } from "../api/admin";
import { checkIsAdministrator } from "../utils/checkIsAdministrator";
import UniversalTagHeaderMobile from "../component/user/UniversalTagHeaderMobile";
import { NavBarDropDown } from "../component/navbar/NavBarDropDown";
import NavBarDropdownItem from "../component/navbar/NavBarDropdownItem ";
import { getAllCategoriesAction } from "../api/user";
import { calculateProductRating } from "../utils/calculateProductRating";
import { countProductComments } from "../utils/countProductComments";
import BuyPageModal from "../component/modals/buypage/BuyPageModal";
import { SearchInput } from "../component/footer/footer";

const CreatorImg = "/assets/image/creatorDefaultImg.png";

export const SubscribeToStoreBtn = ({ subscribeToStore, isLoading }) => {
  return (
    <button
      onClick={() => subscribeToStore()}
      style={{ display: "none" }}
      className="user__upload-products-btn subscription-btn"
    >
      <div className="user__upload-products-btn-inner">
        {isLoading ? "Following..." : "FOLLOW"}
      </div>
    </button>
  );
};
export const ProfileUser = ({ merchandiseStore, hasStore }) => {
  return (
    <div className="user__profile-container">
      <div className="user__profile-img-box">
        <img
          src={merchandiseStore?.user?.picture || CreatorImg}
          className="user__profile-img"
          alt="profile"
        />
      </div>
      <h3 className="user__profile-name">
        {merchandiseStore?.store_name || null}
      </h3>
      {/* <p className='user__profile-name' style={{ textTransform: 'lowercase' }}>
        {merchandiseStore?.store_email || null}
      </p> */}
      {/* {hasStore && (
        <div className='user__profile-suscribers'>
          {merchandiseStore?.subscribers?.length || 0} Followers
        </div>
      )} */}
    </div>
  );
};

const Store = () => {
  const [toggleVisAdmin, setToggleVisAdmin] = useState(false);
  const isMobile = useMediaQuery({ minWidth: 281, maxWidth: 768 });
  const user = useSelector((state) => state.user);
  const collections = useSelector((state) => state.user.collections);
  const [storeData, setStoreData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasStore, setHasStore] = useState(false);
  const dispatch = useDispatch();
  const { storeId } = useParams();
  const [activeInPageHeader, setActiveInPageHeader] = useState("store");
  const navigate = useNavigate();
  const [productsHeaderNav, setProductsHeaderNav] = useState("recent");
  const [sortedProducts, setSortedProducts] = useState([]);
  const [
    showStoreProductsFilterContainer,
    setShowStoreProductsFilterContainer,
  ] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");
  const [categories, setCategories] = useState([]);
  const [searchString, setSearchString] = useState("");

  const clickMobileActiveFilter = (filterTitle) => {
    if (activeFilter.toLowerCase() === filterTitle.toLowerCase()) {
      setActiveFilter("");
      setShowStoreProductsFilterContainer(true);
    } else {
      setActiveFilter(filterTitle);
      setShowStoreProductsFilterContainer(true);
    }
  };

  const subscribeToStore = async () => {
    setIsLoading(true);
    await dispatch(
      subscribeToStoreAction({
        storeInfo: {
          id: storeId,
        },
      })
    )
      .unwrap()
      .then(async () => {
        setIsLoading(false);
        let subscribersList = storeData?.subscribers;
        subscribersList.push(user);
        setStoreData((prev) => ({
          ...prev,
          subscribers: subscribersList,
        }));
        toast.success("Subscription successful", {
          autoClose: 2000,
          className: "toast__message",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: "toast__message",
          });
        }
      });
  };
  function sortProducts(sortBy) {
    let sortProducts = [];

    switch (sortBy) {
      case "featured":
        setProductsHeaderNav("featured");
        sortProducts = storeData?.merchandise?.filter(
          (product) =>
            product.featured_status !== null &&
            product.management_approved === true
        );
        break;

      case "recent":
        setProductsHeaderNav("recent");
        sortProducts = storeData?.merchandise
          ?.filter((product) => product.management_approved === true)
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        break;

      case "best sellers":
        setProductsHeaderNav("best sellers");
        sortProducts = storeData?.merchandise
          ?.filter((product) => product.management_approved === true)
          .sort((a, b) => b.sold - a.sold);
        break;

      default:
        sortProducts = storeData?.merchandise
          ?.filter((product) => product.management_approved === true)
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }

    setSortedProducts(sortProducts);
  }
  const searchProducts = () => {
    let merchandiseList = storeData?.merchandise.filter(
      (product) => product.management_approved === true
    );
    const filteredResults = merchandiseList.filter((item) =>
      item.merchandise_name.toLowerCase().includes(searchString.toLowerCase())
    );
    setSortedProducts(filteredResults);
  };

  function filterproducts(property, filterBy) {
    let filterProducts = [];
    let merchandiseList = storeData?.merchandise.filter(
      (product) => product.management_approved === true
    );
    switch (property.toLowerCase()) {
      case "category":
        filterProducts = merchandiseList?.filter((product) => {
          let found = product?.merchandise_categories?.find(
            (category) => category.toLowerCase() === filterBy.toLowerCase()
          );
          if (found) return product;
          return null;
        });
        break;

      case "license":
        if (filterBy.toLowerCase() === "commercial license") {
          filterProducts = merchandiseList?.filter(
            (product) =>
              product.merchandise_commercial_amount !== null &&
              product.merchandise_commercial_amount !== 0
          );
        }

        if (filterBy.toLowerCase() === "basic license") {
          filterProducts = merchandiseList?.filter(
            (product) => product.merchandise_amount !== null
          );
        }

        break;

      case "price":
        let filterByPrice = filterBy.split("to");
        let startPrice = Number(filterByPrice[0]);
        let endPrice = Number(filterByPrice[1]);
        if (filterBy === "All") {
          filterProducts = merchandiseList;
        } else if (filterBy === "Over 200") {
          filterProducts = merchandiseList?.filter((product) => {
            if (
              (product.merchandise_amount >= 200 &&
                product.management_approved === true) ||
              product.merchandise_commercial_amount >= 200
            ) {
              return product;
            }
            return null;
          });
        } else {
          filterProducts = merchandiseList?.filter((product) => {
            if (
              (product.merchandise_amount >= startPrice &&
                product.merchandise_amount <= endPrice) ||
              (product.merchandise_commercial_amount >= startPrice &&
                product.merchandise_commercial_amount <= endPrice)
            ) {
              return product;
            }
            return null;
          });
        }

        break;

      default:
        filterProducts = merchandiseList;
    }

    setSortedProducts(filterProducts);
  }

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    if (getTeamRoleKey(user?.user?.team_roles, "store")) {
      setHasStore(true);
    }

    if (user?.user) {
      if (
        checkIsAdministrator(
          user?.user?.team_roles ? user?.user?.team_roles : user?.user?.roles
        )
      ) {
        dispatch(fetchAStoreByAdminAction({ storeId: storeId }))
          .unwrap()
          .then(async (response) => {
            if (isMounted) {
              setStoreData(response);
              setSortedProducts(
                response?.merchandise?.filter(
                  (product) => product.management_approved === true
                )
              );
              // const productsInCollection = response.merchandise?.map(
              //   async (product) => {
              //     return checkIfProductIsInUserCollection(
              //       product.id,
              //       collections
              //     );
              //   }
              // );
              setIsLoading(false);
            }
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
                className: "toast__message",
              });
            }
            isMounted && setIsLoading(false);
          });
      } else {
        dispatch(fetchAStore({ storeId: storeId }))
          .unwrap()
          .then(async (response) => {
            if (isMounted) {
              setStoreData(response);
              setSortedProducts(
                response?.merchandise?.filter(
                  (product) => product.management_approved === true
                )
              );
              setIsLoading(false);
            }
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
                className: "toast__message",
              });
            }
            isMounted && setIsLoading(false);
          });
      }
    }

    return () => {
      isMounted = false;
    };
  }, [collections, dispatch, user?.user, toggleVisAdmin, storeId]);
  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getAllCategoriesAction({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setCategories(response);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: "toast__message",
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);
  const [productId, setProductId] = useState(null);
  const [buyPagefadeOut, setBuyPageFadeOut] = useState(false);
  const [buyPageModal, setBuyPageModal] = useState(null);
  return (
    <section className="user__main-container">
      <header>
        <NavBar
          pageType={"user"}
          loggedIn={true}
          shortSearch={true}
          style={{
            paddingBottom: "0",
            paddingTop: "0",
          }}
          search={false}
        />
      </header>

      <main className="user__main">
        <section
          style={{
            backgroundImage: `url(${
              storeData?.store_pictures[0]
                ? storeData?.store_pictures[0]
                : "/assets/image/background/empty-store.png"
            })`,
            backgroundSize: `${storeData ? "cover" : "contain"}`,
          }}
          className="user__profile-preview-container"
        >
          {storeData?.visibility === "public" && (
            <SubscribeToStoreBtn
              subscribeToStore={subscribeToStore}
              isLoading={isLoading}
            />
          )}

          {user?.user?.id === storeData?.user_id && (
            <button
              onClick={() => navigate(`/user/store`)}
              className="user__profile-switch-admin-btn"
            >
              Switch back to Admin Mode
            </button>
          )}

          <ProfileUser
            merchandiseStore={storeData}
            user={user}
            profile={user}
            hasStore={hasStore}
          />

          {storeData?.profile?.location && (
            <div className="user__location-container">
              <RiMapPin5Fill /> {storeData?.profile?.location}.
            </div>
          )}
        </section>

        <section className="user__product-action-container">
          {user?.user?.id === storeData?.user_id || toggleVisAdmin ? (
            <div className="user__admin-visitor-container">
              <button
                onClick={() => navigate(`/user/store`)}
                className={`user__AV-btn ${
                  toggleVisAdmin && "user_AV-btn-active"
                }`}
              >
                <span className="user__AV-btn-inner">Admin</span>
              </button>
              <button
                onClick={(e) => setToggleVisAdmin(false)}
                className={`user__AV-btn ${
                  !toggleVisAdmin && "user_AV-btn-active-dup"
                }`}
              >
                <span className="user__AV-btn-inner">Visitor</span>
              </button>
            </div>
          ) : null}
        </section>

        {!isMobile ? (
          <div style={{ width: "80%", margin: "0 auto" }}>
            <UniversalTagHeaderDesktop
              toggleVisAdmin={toggleVisAdmin}
              activeInpageHeader={activeInPageHeader}
              TagList={[{ name: "store" }]}
              setActiveInPageHeader={setActiveInPageHeader}
              style={{
                width: "fitContent",
                marginLeft: "0",
                justifyContent: "start",
              }}
            />
          </div>
        ) : user?.user?.id === storeData?.user_id ? (
          <UniversalTagHeaderMobile
            toggleVisAdmin={toggleVisAdmin}
            activeInpageHeader={activeInPageHeader}
            setActiveInPageHeader={setActiveInPageHeader}
          />
        ) : (
          <UniversalTagHeaderMobile
            toggleVisAdmin={toggleVisAdmin}
            activeInpageHeader={activeInPageHeader}
            TagList={"store"}
            isAccountOwner={user?.user?.id === storeData?.user_id}
            setActiveInPageHeader={setActiveInPageHeader}
          />
        )}

        <section className="user__store-freebies-container">
          {isLoading ? (
            <LoadingState />
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                className="user__store-products-header"
              >
                <div className="user__store-products-header-nav">
                  <div
                    onClick={() => sortProducts("featured")}
                    className={`user__sales-sidebar-header-item ${
                      productsHeaderNav === "featured" &&
                      "user__sales-shi-selected"
                    }`}
                  >
                    FEATURED
                  </div>

                  <div
                    onClick={() => sortProducts("recent")}
                    className={`user__sales-sidebar-header-item ${
                      productsHeaderNav === "recent" &&
                      "user__sales-shi-selected"
                    }`}
                  >
                    RECENT
                  </div>

                  <div
                    onClick={() => sortProducts("best sellers")}
                    className={`user__sales-sidebar-header-item ${
                      productsHeaderNav === "best sellers" &&
                      "user__sales-shi-selected"
                    }`}
                  >
                    BEST SELLERS
                  </div>
                </div>

                <SearchInput
                  style={{}}
                  btnStyle={{
                    padding: ".1rem 2rem",
                    height: "90%",
                    right: "1%",
                  }}
                  controlStyle={{ padding: ".4rem 5rem" }}
                  searchString={searchString}
                  setSearchString={setSearchString}
                  onSearch={searchProducts}
                />

                <section
                  className="admin__filter-selector-parent-container"
                  style={{ marginLeft: "1rem" }}
                >
                  <FilterSelector
                    title="CATEGORY"
                    options={categories?.map((obj) => obj.name)}
                    onChange={filterproducts}
                  />

                  <FilterSelector
                    title="LICENSE"
                    options={["Basic license", "Commercial license"]}
                    onChange={filterproducts}
                  />

                  <FilterSelector
                    title="PRICE"
                    options={[
                      "All",
                      "0 to 50",
                      "51 to 100",
                      "101 to 200",
                      "Over 200",
                    ]}
                    // options={[
                    //   'All',
                    //   'Free',
                    //   'Under 10',
                    //   'Under 50',
                    //   'Under 100',
                    //   'Over 100',
                    // ]}
                    onChange={filterproducts}
                  />

                  <FilterClear
                    onClick={() => {
                      setSortedProducts(
                        storeData?.merchandise?.filter(
                          (product) => product.management_approved === true
                        )
                      );
                      setSearchString("");
                    }}
                  />
                </section>
              </div>

              {isMobile && (
                <div
                  className="landing__navbar-mobile-filter-box"
                  style={{ display: "flex" }}
                >
                  <NavBarDropDown
                    title={"CATEGORY"}
                    isActive={activeFilter === "category"}
                    onClick={() => clickMobileActiveFilter("category")}
                  />
                  <NavBarDropDown
                    title={"SORT"}
                    isActive={activeFilter === "sort"}
                    onClick={() => clickMobileActiveFilter("sort")}
                  />
                  <NavBarDropDown
                    title={"FILTER"}
                    isActive={activeFilter === "filter"}
                    onClick={() => clickMobileActiveFilter("filter")}
                  />
                </div>
              )}

              {showStoreProductsFilterContainer && (
                <div className="nav_mobile_filter-options-container">
                  {activeFilter.toLocaleLowerCase() === "category"
                    ? categories?.map((item) => (
                        <NavBarDropdownItem
                          key={`navitem1-${Math.random()}`}
                          option={item}
                        />
                      ))
                    : activeFilter.toLocaleLowerCase() === "sort"
                    ? sortOptions?.map((item) => (
                        <NavBarDropdownItem
                          key={`navitem2-${Math.random()}`}
                          option={item}
                        />
                      ))
                    : activeFilter.toLocaleLowerCase() === "filter"
                    ? filterOptions?.map((item) => (
                        <NavBarDropdownItem
                          key={`navitem3-${Math.random()}`}
                          option={item}
                        />
                      ))
                    : null}
                </div>
              )}

              <div
                className="user__store-freebies-inner-container"
                style={{ marginTop: "2%" }}
              >
                {sortedProducts && sortedProducts?.length > 0 ? (
                  sortedProducts.map((prod, index) => (
                    <LandCard
                      key={prod.merchandise_name + index}
                      onClick={() => {
                        setBuyPageModal(!buyPageModal);
                        setProductId(prod.id);
                      }}
                      img={
                        prod.merchandise_banners?.length > 0
                          ? prod.merchandise_banners[0]
                          : null
                      }
                      title={prod.merchandise_name}
                      ratings={calculateProductRating(prod.ratings)}
                      owner={prod.store?.store_name}
                      numOfComments={countProductComments(prod.ratings)}
                      numReviewers={prod.ratings?.length}
                      price={prod?.merchandise_amount}
                      firstTag={
                        prod.merchandise_tags ? prod.merchandise_tags[0] : ""
                      }
                      // navigateToStore={() => navigate(`store/${prod.store_id}`)}
                      productId={prod.id}
                    />
                  ))
                ) : (
                  <div className="user__no-product-div">
                    Store does not have any product
                  </div>
                )}
              </div>
            </div>
          )}
        </section>
        {buyPageModal && (
          <BuyPageModal
            toggleShowModal={() => setBuyPageModal(!buyPageModal)}
            fadeOut={buyPagefadeOut}
            setFadeOut={setBuyPageFadeOut}
            id={productId}
          />
        )}
      </main>
    </section>
  );
};

export default Store;
