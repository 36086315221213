import { useState } from 'react';
import { BsTags } from 'react-icons/bs';
import { VscTriangleUp } from 'react-icons/vsc';
import { MainTags } from '../../pages/upload';

const TagsComp = ({ tags = [], nonSelect = false, product }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className='buy__tags-container'>
      <div onClick={() => setIsOpen(!isOpen)} className='buy__tag-header'>
        <BsTags style={{ color: '#FF9700' }} /> Tags{' '}
        <VscTriangleUp
          className='buy__tag-arrow'
          style={{
            color: '#6D6D91',
            transform: `rotate(${isOpen ? '45deg' : '180deg'})`,
          }}
        />
      </div>

      {isOpen && (
        <section className='upload__tags-inner-container'>
          {!nonSelect && <MainTags text={tags[0]} defaultState={false} />}
          {nonSelect
            ? tags?.map((tag) => (
                <MainTags
                  text={tag}
                  defaultState={true}
                  productInfo={product}
                  nonSelect
                />
              ))
            : tags
                ?.slice(1)
                .map((tag) => <MainTags text={tag} defaultState={true} />)}
        </section>
      )}
    </div>
  );
};

export default TagsComp;
