import { unapproveAMerchandiseByAdmin } from '../../../../api/admin';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { BiCategory } from 'react-icons/bi';

export default function DeleteMerchandise({
  toggleShowModal,
  fadeOut,
  setFadeOut,
  refetch,
  id,
  title,
}) {
  const dispatch = useDispatch();

  //update catalogue
  const handleUnnapproveMerchandise = () => {
    dispatch(
      unapproveAMerchandiseByAdmin({
        approveData: {
          id,
        },
      })
    )
      .unwrap()
      .then((response) => {
        console.log(response);
        refetch();
        toast.success('Product was Deleted.', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setTimeout(() => {
          toggleShowModal();
        }, 200);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  };

  return (
    <div
      className={`reg__sign-up-overlay ${fadeOut ? 'lgn__fade-out-anim' : ''}`}
      style={{ color: '#ffffff' }}
    >
      <section className='reg__sign-up-modal user__withdrawal-modal'>
        <div className='reg__close-btn-container'>
          <div
            onClick={(e) => {
              setFadeOut((prev) => !prev);
              setTimeout(() => {
                toggleShowModal();
              }, 200);
            }}
            className='reg__close-btn'
          >
            <span className='reg__close-btn-text'>X</span>
          </div>
        </div>

        <div className='reg__sign-up-forms-container withdrawal-modal'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <BiCategory
              className='user__lib-folder-large'
              style={{
                fontSize: '1.2rem',
                marginRight: '1rem',
                color: '#74747d',
              }}
            />
            <h2
              className='user__withdrawal-modal-heading'
              style={{ fontSize: '1.5rem' }}
            >
              Delete '{title}' ?
            </h2>
          </div>

          <div className='catalogue__grid'>
            <button
              onClick={handleUnnapproveMerchandise}
              className='catalogue__btn remove'
            >
              <span>Delete</span>
            </button>
            <button
              onClick={(e) => {
                setFadeOut((prev) => !prev);
                setTimeout(() => {
                  toggleShowModal();
                }, 200);
              }}
              className='catalogue__btn'
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
