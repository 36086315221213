import UserPurpleInnerStripeBtn from '../../buttons/UserPurpleButton';
// import CardArrowPointer from '../home/CardArrowPointer';
import AfilliatedDropdown from './AfilliatedDropdown';
import FireMailButton from './FireMailButton';
import LongSelectedCone from './LongSelectedCone';
import NotifierMail from './NotifierMail';
import ReportMessageNotifier from './ReportMessageNotifier';
import VerifiedLogo from './VerifiedLogo';
// import { GoLocation } from 'react-icons/go';

const CreatorImg = '/assets/image/creatorDefaultImg.png';
const UserImg = '/assets/image/userDefaultImg.png';
const ListedStore = ({
  isUser = false,
  selected,
  isAffiliated,
  isRequested,
  styleVerifySign,
  selectAStore,
  image,
  name,
  email,
  approved,
  mgtApproved,
  showApproveStoreModal,
  showUnapproveStoreModal,
  showStoreRequestModal,
}) => {
  return (
    <section className='admin__users-listed-outer'>
      <div
        onClick={() => selectAStore()}
        className={`admin__users-listed-user ${selected && 'selected'}`}
      >
        {selected && <LongSelectedCone />}
        <div className='admin__users-listed-item image'>
          <img
            className='admin__users-listed-item image'
            src={image || (approved ? CreatorImg : UserImg)}
            alt=''
          />
        </div>
        <div className='admin__users-listed-item name'>
          {approved && <VerifiedLogo style={styleVerifySign} />}
          <span style={{ textTransform: 'capitalize', marginLeft: '1rem' }}>
            {name}
          </span>
        </div>
        {/* <div className='admin__users-listed-item location'>
          <GoLocation /> {location}
        </div> */}
        <div className='admin__users-listed-item email'>{email}</div>
        {/* <div className='admin__users-listed-item email'>{phone || email}</div> */}
        {!isUser && (
          <div className='admin__users-listed-item extra-container'>
            {selected ? (
              <div className='admin__users-listed-item extra'>
                <NotifierMail isUrgent={true} />
                <FireMailButton />
                <UserPurpleInnerStripeBtn
                  onClick={
                    mgtApproved
                      ? showUnapproveStoreModal
                      : showApproveStoreModal
                  }
                  style={{ width: '10rem', padding: '1rem 3rem' }}
                  text={
                    mgtApproved ? (
                      <span className='no-wrap'>UNAPPROVE STORE</span>
                    ) : (
                      <span className='no-wrap'>APPROVE STORE</span>
                    )
                  }
                />{' '}
              </div>
            ) : (
              <ReportMessageNotifier
                occupantText={isRequested ? 'Store Request' : 'Report'}
                isRequested={isRequested}
                onClick={showStoreRequestModal}
              />
            )}
          </div>
        )}
        {/* <CardArrowPointer
          style={{
            top: '40%',
            right: '1.2%',
            color: `${selected ? '#7C187A' : '#3B3B43'}`,
            transform: `rotate(${selected ? '-90deg' : '90deg'})`,
          }}
        /> */}
      </div>
      {isAffiliated && selected && <AfilliatedDropdown />}
    </section>
  );
};

export default ListedStore;
