import AdminStoreSettings from "./Settings/AdminStoreSettings";
import AdminUserAccountSettings from "./Settings/AdminUserAcountSettings";
import AdminUserNotificationSettings from "./Settings/AdminUserNotificationSettings";

const AdminUserSettingsPageSection = ({sectionName}) => {
    switch(sectionName.toLowerCase()) {
        case "notifications": 
            return <AdminUserNotificationSettings />;
            
        case "account": 
            return <AdminUserAccountSettings />;
            
        case "store": 
            return <AdminStoreSettings />;

        default:
            return <AdminUserNotificationSettings/>
    }
}

export default AdminUserSettingsPageSection