import { FaSearch } from 'react-icons/fa';

const FilterSearchBar = ({
  placeHolder,
  search,
  setSearchString,
  onSearch,
}) => {
  return (
    <div className='admin__input-filter-search-bar'>
      <form
        className='admin__input-filter-skew-container'
        onSubmit={(e) => {
          e.preventDefault();
          onSearch();
        }}
      >
        <input
          className='admin__input-filter-form-control'
          value={search}
          onChange={(e) => setSearchString(e.target.value)}
          placeholder={placeHolder}
        />
        <button className='admin__filter-search-button'>
          <FaSearch style={{ transform: 'skewX(25deg) scaleX(-1)' }} />
        </button>
      </form>
    </div>
  );
};

export default FilterSearchBar;
