
const FilterSelector = ({title="Category", value, setValue, onChange, options}) => {

  return (
    <section className="user_tags-filter-selector-container">
      <div 
        className="user_tags-filter-selector-container-inner"
      >
        <div className="admin__filter-label">{title}</div>
        <select className="admin__filter-main-value" onChange={(e) => onChange(title, e.target.value)}>
          {
            options?.length > 0 && options?.map(item => {
              return (
                <option value={item} key={item}>{item}</option>
              )
            })
              
          } 
        </select>
      </div>
    </section>
  );
};

export default FilterSelector