const FilterClear = ({ onClick }) => {
  return (
    <div className='user__filter-wrapper'>
      <button className='user__filter-clear-btn' onClick={onClick}>
        <span>Clear Filters</span>
      </button>
    </div>
  );
};

export default FilterClear;
