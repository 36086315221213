import { FaSearch } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';

const SearchBar = ({
  short,
  style,
  searchString,
  setSearchString,
  onSearch,
}) => {
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 768 });

  return (
    <section className='search__skew-container'>
      <form
        style={{ ...style, width: isMobile ? 'auto' : short ? '60%' : '100%' }}
        className='search__group-container'
        onSubmit={(e) => {
          e.preventDefault();
          onSearch();
        }}
      >
        <input
          placeholder='Search for any product'
          className='search__input'
          value={searchString || ''}
          onChange={(e) => setSearchString(e.target.value)}
        />

        <button
          style={{ right: '.3%', top: '0%' }}
          className='search__btn'
          type='button'
        >
          <span style={{ transform: 'skewX(25deg) scaleX(-1)' }}>
            <FaSearch fontSize={'1.7rem'} />
          </span>
        </button>
      </form>
    </section>
  );
};

export default SearchBar;