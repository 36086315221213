import { useEffect, useState } from "react"
import OddCard from "../card/oddcard"
import { MdOutlineConnectWithoutContact, MdVisibility } from "react-icons/md"
import { BiMoneyWithdraw } from "react-icons/bi"
import { RiSecurePaymentLine } from "react-icons/ri"
import { LiaNetworkWiredSolid } from "react-icons/lia"

const OddCarousel = () => {
    const [oddActive, setOddActive] = useState(2)
    useEffect(()=>{
        setInterval(()=>{
            setOddActive(oddAct => {
                let newOddAct = oddAct + 1
                if (oddAct + 1 > 4){
                    newOddAct = 0
                }
                return newOddAct
            })
          
        }, 8000)
       
    }, [])
    
    return (
        <section className="opnstore__start-selling-container">
            <div className="opnstore__start-selling-inner-container">
                <OddCard 
                    active={oddActive === 0}
                    icon={<MdOutlineConnectWithoutContact style={{fontSize: '3.5rem'}} />}
                    title='Targetted Audience'
                    description='Reach African animation and game development professionals.'
                />
                <OddCard 
                    active={oddActive === 1}
                    icon={<MdVisibility style={{fontSize: '3.5rem'}} />}
                    title='Increased Visibility'
                    description='Showcase your assets alongside top African creators.'
                />
                <OddCard 
                    active={oddActive === 2} 
                    icon={<BiMoneyWithdraw style={{fontSize: '3.5rem'}} />}
                    title='Low Sales Tax'
                    description='Keep more earnings with a low 10% sales tax'
                />
                <OddCard 
                    active={oddActive === 3}
                    icon={<RiSecurePaymentLine style={{fontSize: '3.5rem'}} />}
                    title='Secure Platform'
                    description='Trustworthy environment with anti-scam measures.'
                />
                <OddCard 
                    active={oddActive === 4}
                    icon={<LiaNetworkWiredSolid style={{fontSize: '3.5rem'}} />}
                    title='Networking'
                    description='Connect with industry professionals.'
                />
            </div>
        </section>
    )
}

export default OddCarousel