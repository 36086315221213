// import { LoootyLogoStatic } from "../loootyLogo";
import { FaEyeSlash } from "react-icons/fa";
import { useContext, useState } from "react";
import { RegisterContext } from "./context/registerContext";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { signInAction } from "../../api/auth";
import { useDispatch } from "react-redux";
import { BsEyeFill } from "react-icons/bs";
import LoootyLoader from "../loader/loootyLoader";
 import { FcGoogle } from 'react-icons/fc';
import Cookies from "js-cookie";
import ForgotPasswordModal from "./forgotPasswordModal";
import { CartContext } from "./context/cartContext";
import { updateUserCartAction } from "../../api/user";
import "./auth.css";

const SignInModal = () => {
  const [fadeOut, setFadeOut] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginInfo, setLoginInfo] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [clickProtect, setClickProtect] = useState(false);

  const [showRegModal, setShowRegModal] = useContext(RegisterContext);
  const [hidePassword, setHidePassword] = useState(true);

   const web_url = process.env.REACT_APP_WEB_URL || '';

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    password: false,
    password_confirmation: false,
  });

   const googleWebSignIn = () => {
     const data = {
       scope: 'openid+profile+email',
       include_granted_scopes: 'true',
      response_type: 'token',
       state: 'state_parameter_passthrough_value',
       redirect_uri: `${web_url}/api/auth/socialite/google/callback`,
       client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
     };
     window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=${data.scope}&include_granted_scopes=${data.include_granted_scopes}&response_type=${data.response_type}&state=${data.state}&redirect_uri=${data.redirect_uri}&client_id=${data.client_id}`;
   };

  // const facebookWebSignIn = () => {
  //   let data = {
  //     client_id: process.env.REACT_APP_FACEBOOK_APP_ID,
  //     nonce:(Math.random() + 1).toString(36),
  //     redirect_uri:`${web_url}/api/auth/socialite/facebook/callback`,
  //     response_mode:'fragment',
  //     response_type:'code+id_token',
  //     scope: '',
  //     state:'state_parameter_passthrough_value',
  //   }

  //   return `https://developer.facebook.com/auth/authorize?client_id=${data.client_id}&nonce=${data.nonce}&redirect_uri=${data.redirect_uri}&response_mode=${data.response_mode}&response_type=${data.response_type}&scope=${data.scope}&state=${data.state}`;
  // }
  const { pathname } = useLocation();
  const { cart } = useContext(CartContext);

  const data = [
    ...cart.map((cartItem) => ({
      product_store_name: cartItem.product_store_name,
      product_outlet_id: cartItem.product_outlet_id,
      product_id: cartItem.product_id,
      license: cartItem.license,
      quantity: cartItem.quantity,
    })),
  ];

  const handleSignIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setClickProtect(true);
    await dispatch(signInAction({ signInData: loginInfo }))
      .unwrap()
      .then(async (result) => {
        setIsLoading(false);
        Cookies.set("user", JSON.stringify(result.data));

        setShowRegModal({ ...showRegModal, signin: false });
        setClickProtect(false);
        pathname === "/" && navigate("/home");
        toast.success("Sign in was successful", {
          autoClose: 2000,
          className: "toast__message",
        });
        if (cart?.length !== 0) {
          await dispatch(
            updateUserCartAction({
              updateData: {
                user_id: result?.data?.id,
                products: data,
              },
            })
          )
            .unwrap()
            .then(() => {})
            .catch((err) => {
              if (err.response) {
                toast.error(err.response.data.message, {
                  autoClose: 2000,
                  className: "toast__message",
                });
              }
            });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setClickProtect(false);
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: "toast__message",
          });
          if (err.response.data.errors) {
            if (err.response.data.errors.email) {
              setErrors((old) => ({ ...old, email: true }));
              toast.error(err.response.data.errors.email[0], {
                autoClose: 2000,
                className: "toast__message",
              });
            }

            if (err.response.data.errors.password) {
              setErrors((old) => ({ ...old, password: true }));
              toast.error(err.response.data.errors.password[0], {
                autoClose: 2000,
                className: "toast__message",
              });
            }
          }
        }
      });
  };

  return (
    <div
      className={`reg__sign-up-overlay ${fadeOut ? "lgn__fade-out-anim" : ""}`}
    >
      <section className="reg__sign-up-modal">
        <div className="reg__close-btn-container">
          <div
            onClick={() => {
              setFadeOut((prev) => !prev);
              setTimeout(() => {
                setShowRegModal({ ...showRegModal, signin: false });
              }, 600);
            }}
            className="reg__close-btn"
          >
            <span className="reg__close-btn-text">X</span>
          </div>
        </div>
        <div className="reg__loooty-innitials-container">
          <div className="reg_logo-container">
            {/* <LoootyLogoStatic style={{ width: "15rem" }} /> */}
          </div>
          <div className="reg__loooty-about">
            <div>Welcome back</div>
          </div>

          <ul className="reg__loooty-features">
            <li className="reg__loooty-features-item">
              Advance search and filtering options
            </li>
            <li className="reg__loooty-features-item">
              Add art work to custom selection
            </li>
            <li className="reg__loooty-features-item">
              Never miss a new artwork with tag
            </li>
            <li className="reg__loooty-features-item">
              Uploader and collection subscription
            </li>
          </ul>
        </div>

        <div className="reg__sign-up-forms-container">
          <form
            autoComplete="off"
            onSubmit={handleSignIn}
            className="reg__sign-up-form"
          >
            <div className="reg__form-group-container">
              <div
                style={{
                  border: errors.email
                    ? ".2rem solid #df4759"
                    : ".2rem solid #2B2B38",
                }}
                className="reg__form-group"
              >
                <input
                  type={"email"}
                  autoComplete="off"
                  onFocus={() => {
                    setErrors((old) => ({ ...old, email: false }));
                  }}
                  onChange={(e) =>
                    setLoginInfo((old) => ({ ...old, email: e.target.value }))
                  }
                  value={loginInfo.email}
                  className="reg__form-control"
                  placeholder="Email"
                />
                <span
                  style={{ visibility: "hidden" }}
                  className="sign__hide-sensitive-info-button"
                >
                  <BsEyeFill className="lgn__hide-sensitive-ib-icon" />
                </span>
              </div>

              <div
                style={{
                  border: errors.password
                    ? ".2rem solid #df4759"
                    : ".2rem solid #2B2B38",
                }}
                className="reg__form-group"
              >
                <input
                  autoComplete="off"
                  type={hidePassword ? "password" : "text"}
                  onFocus={() => {
                    setErrors((old) => ({ ...old, password: false }));
                  }}
                  onChange={(e) =>
                    setLoginInfo((old) => ({
                      ...old,
                      password: e.target.value,
                    }))
                  }
                  value={loginInfo.password}
                  className="reg__form-control"
                  placeholder="Password"
                />
                <span
                  onClick={() => setHidePassword(!hidePassword)}
                  className="sign__hide-sensitive-info-button"
                >
                  {hidePassword ? (
                    <BsEyeFill className="lgn__hide-sensitive-ib-icon" />
                  ) : (
                    <FaEyeSlash className="lgn__hide-sensitive-ib-icon" />
                  )}
                </span>
              </div>
            </div>

            <div
              className="reg__terms-container"
              onClick={() =>
                setShowRegModal({
                  ...showRegModal,
                  forgotPassword: true,
                })
              }
            >
              Forgot Your
              <span style={{ color: "#FF9700", cursor: "pointer" }}>
                Password?
              </span>
            </div>
            {showRegModal.forgotPassword && <ForgotPasswordModal />}
            <div className="reg__form-btn-group">
              <button
                disabled={clickProtect}
                style={{ opacity: clickProtect ? "0.6" : "1" }}
                onClick={handleSignIn}
                className="reg__login-btn"
              >
                <span className="reg__login-btn-text">
                  {isLoading ? <LoootyLoader /> : <>SIGN IN</>}
                </span>
              </button>
            </div>

            <div
              className='reg__login-alternatives-container'
              style={{ display: "hidden" }}
            >
              { <span
                onClick={() => googleWebSignIn()}
                className='reg__btn-google'
              >
                <span>
                  {' '}
                  <FcGoogle
                    style={{ fontSize: '2rem' }}
                  />
                </span>

                <span className='reg__btn-google-text'>
                  Sign&nbsp;In&nbsp;with&nbsp;Google
                </span>
              </span> }

              {/* <a
                href={facebookWebSignIn()}
                className="reg__btn-facebook"
              >
                <FaFacebook
                  style={{ fontSize: "2rem", transform: "skewX(25deg)" }}
                />
                <span className="reg__btn-facebook-text">
                  Sign&nbsp;In&nbsp;with&nbsp;Facebook
                </span>
              </a> */}
              { <div className='reg__form-recovery-container'>
                <p>
                  {' '}
                  Don't have an account?{' '}
                  <span
                    onClick={(e) => {
                      setShowRegModal({
                        ...showRegModal,
                        register: true,
                        signin: false,
                      });
                    }}
                    style={{ color: '#FF9700', cursor: 'pointer' }}
                  >
                    Sign up!{' '}
                  </span>
                </p>
              </div> }
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default SignInModal;