import { useState, useEffect } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { ImFolder } from 'react-icons/im';
import { MdOutlineAdd, MdOutlineClose } from 'react-icons/md';
import { RiPencilFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import {
  fetchFolderAction,
  updateFolderProductsAction,
} from '../../../api/auth';
import { toast } from 'react-toastify';
import LoootyLoader from '../../loader/loootyLoader';

const EditFolderModal = ({
  toggleShowModal,
  folderId,
  merchandiseList,
  collectionMerchandise,
}) => {
  console.log(
    toggleShowModal,
    folderId,
    merchandiseList,
    collectionMerchandise
  );
  const dispatch = useDispatch();
  const [fadeOut, setFadeOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [folderName, setFolderName] = useState(false);
  const [folderMerchandiseList, setFolderMerchandiseList] = useState(null);
  const [addProducts, setAddProducts] = useState(false);

  const updateFolderProducts = async () => {
    setIsLoading(true);

    await dispatch(
      updateFolderProductsAction({
        updateData: {
          folder_id: folderId,
          folder_name: folderName,
          merchandise_list: folderMerchandiseList,
        },
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Folder updated', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  const removeItemFromList = (item) => {
    setFolderMerchandiseList((prevState) =>
      prevState.filter((merchandise) => merchandise.id === item.id)
    );
  };

  const addItemToFolderList = (item) => {
    const arr = [...folderMerchandiseList];
    arr.push(item);
    setFolderMerchandiseList(arr);
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(fetchFolderAction({ folder_id: folderId }))
      .then((res) => {
        if (isMounted) {
          setFolderName(res?.payload?.name);
          setFolderMerchandiseList(merchandiseList);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        isMounted && setIsLoading(false);
        toast.error(
          err?.payload?.response?.data?.message || 'Error! Try again later',
          {
            autoClose: 2000,
            className: 'toast__message',
          }
        );
      });

    return () => (isMounted = false);
  }, [dispatch, folderId, merchandiseList]);

  return (
    <div
      className={`reg__sign-up-overlay ${fadeOut ? 'lgn__fade-out-anim' : ''}`}
    >
      <section className='reg__sign-up-modal user__withdrawal-modal'>
        <div className='reg__close-btn-container'>
          <div
            onClick={(e) => {
              setFadeOut((prev) => !prev);
              setTimeout(() => {
                toggleShowModal();
              }, 600);
            }}
            className='reg__close-btn'
          >
            <span className='reg__close-btn-text'>X</span>
          </div>
        </div>

        <div className='reg__sign-up-forms-container withdrawal-modal'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <ImFolder
              className='user__lib-folder-large'
              style={{
                fontSize: '1rem',
                marginRight: '1rem',
                color: '#74747d',
              }}
            />
            <h2 className='user__withdrawal-modal-heading'>Edit Folder</h2>
          </div>

          <div className='user__edit-folder-modal-input-container'>
            <span className='user__edit-folder-span'>Folder Name</span>
            <div className='user__edit-folder-input-box'>
              <input
                className='user__library-trash-btn-text'
                type={'text'}
                value={folderName || ''}
                onChange={(e) => setFolderName(e.target.value)}
              />
              <RiPencilFill className='' />
            </div>
          </div>

          <div className='user__edit-folder-skew-header-container'>
            <span>Include Products</span>
          </div>

          <div
            className='user__edit-folder-add-products-btn'
            onClick={() => setAddProducts(!addProducts)}
          >
            <FaPlusCircle className='user__edit-folder-add-product-icon' />
            <span>{addProducts ? 'Remove Products' : 'Add Product'}</span>
          </div>

          {!addProducts &&
            folderMerchandiseList?.length > 0 &&
            folderMerchandiseList?.map((merchandise) => (
              <div
                className='user__edit-folder-product-card'
                key={merchandise.id}
              >
                <img
                  src={merchandise?.merchandise_cover_picture[0]}
                  alt={'product'}
                  className='user__edit-folder-product-card-img'
                />
                <div className='user__edit-folder-product-title-box'>
                  <span className='user__edit-folder-product-card-title'>
                    {merchandise.merchandise_name}
                  </span>
                </div>
                <MdOutlineClose
                  className='user__edit-folder-product-card-close-btn'
                  onClick={() => removeItemFromList(merchandise)}
                />
              </div>
            ))}

          <div className='user__edit-folder-modal-info-header'>
            Choose products that will appear in this folder
          </div>

          {addProducts &&
            collectionMerchandise?.length > 0 &&
            collectionMerchandise?.map((merchandise) => (
              <div
                className='user__edit-folder-product-card'
                key={merchandise.id}
              >
                <img
                  src={merchandise?.merchandise_cover_picture[0]}
                  alt={'product'}
                  className='user__edit-folder-product-card-img'
                />
                <div className='user__edit-folder-product-title-box'>
                  <span className='user__edit-folder-product-card-title'>
                    {merchandise.merchandise_name}
                  </span>
                </div>
                <MdOutlineAdd
                  className='user__edit-folder-product-card-close-btn'
                  onClick={() => addItemToFolderList(merchandise)}
                />
              </div>
            ))}

          <div style={{ width: 'fit-content', margin: '1rem auto' }}>
            <button
              onClick={updateFolderProducts}
              className='user__upload-products-btn'
              type='button'
            >
              <div className='user__upload-products-btn-inner'>
                {isLoading ? <LoootyLoader /> : 'SAVE'}
              </div>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditFolderModal;
