import { updateCatalogue } from '../../../../api/admin';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { BiCategory } from 'react-icons/bi';

export default function RemoveFromCatalogue({
  toggleShowModal,
  fadeOut,
  setFadeOut,
  refetch,
  id,
  catalogue,
}) {
  const dispatch = useDispatch();

  //update catalogue
  const handleUpdateCatalogue = (catalogueMerch) => {
    if (!catalogueMerch.includes(Number(id))) {
      toast.warn('Product is not in catalogue,', {
        autoClose: 2000,
        className: 'toast__message',
      });
      return;
    }
    const updatedCatalogue = catalogueMerch.filter(
      (number) => number !== Number(id)
    );

    if (updatedCatalogue.length === 0) {
      toast.warn(
        'Product is the only one in catalogue and catalogue cannot be empty.',
        {
          autoClose: 2000,
          className: 'toast__message',
        }
      );
      setTimeout(() => {
        toggleShowModal();
      }, 200);
      return;
    }

    dispatch(
      updateCatalogue({
        catalogueInfo: {
          id: catalogue.id,
          catalogue_merchandise: updatedCatalogue,
        },
      })
    )
      .unwrap()
      .then(() => {
        refetch();
        toast.success('Product removed from Catalogue', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setTimeout(() => {
          toggleShowModal();
        }, 200);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  };

  return (
    <div
      className={`reg__sign-up-overlay ${fadeOut ? 'lgn__fade-out-anim' : ''}`}
      style={{ color: '#ffffff' }}
    >
      <section className='reg__sign-up-modal user__withdrawal-modal'>
        <div className='reg__close-btn-container'>
          <div
            onClick={(e) => {
              setFadeOut((prev) => !prev);
              setTimeout(() => {
                toggleShowModal();
              }, 200);
            }}
            className='reg__close-btn'
          >
            <span className='reg__close-btn-text'>X</span>
          </div>
        </div>

        <div className='reg__sign-up-forms-container withdrawal-modal'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <BiCategory
              className='user__lib-folder-large'
              style={{
                fontSize: '1.2rem',
                marginRight: '1rem',
                color: '#74747d',
              }}
            />
            <h2
              className='user__withdrawal-modal-heading'
              style={{ fontSize: '1.5rem' }}
            >
              Remove Product from '{catalogue.catalogue_title}'?
            </h2>
          </div>

          <div className='catalogue__grid'>
            <button
              onClick={() =>
                handleUpdateCatalogue(catalogue.catalogue_merchandise)
              }
              className='catalogue__btn remove'
            >
              <span>Remove</span>
            </button>
            <button
              onClick={(e) => {
                setFadeOut((prev) => !prev);
                setTimeout(() => {
                  toggleShowModal();
                }, 200);
              }}
              className='catalogue__btn'
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
