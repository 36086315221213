import {ShareSocial} from 'react-share-social' 

const style = {
    root: {
        background: '#15151c',
        borderRadius: 10,
        border: 0,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        zIndex: '20',
        position: 'absolute',
        left: '40%'
    },
    copyContainer: {
      border: '1px solid blue',
      background: 'rgb(0,0,0,0.7)'
    },
    title: {
      color: 'aquamarine',
      fontStyle: 'italic'
    }
};

export const SocialShare = ({url}) => {
    return <ShareSocial 
       url ={url}
       socialTypes={['facebook','twitter','reddit','linkedin', 'telegram', 'whatsapp']}
       style={style}
    />
}