// import { FaComment } from 'react-icons/fa';
// import { RiStarSFill } from 'react-icons/ri';
// import { calculateProductRating } from '../../utils/calculateProductRating';
// import { countProductComments } from '../../utils/countProductComments';
import {
  IoCaretBackCircleSharp,
  IoCaretForwardCircleSharp,
} from "react-icons/io5";
import { useState } from "react";
import LoootyLoader from "../loader/loootyLoader";

const WolfGuyImg = "/assets/image/loootydefault.jpg";
const MonkeyFaceImg = "/assets/image/monkeyface.jpg";

const CarouselSec = ({ product }) => {
  // const tempRatings = [
  //   {
  //     name: 'Josh',
  //     comment: 'Making good sense',
  //     score: 3,
  //   },
  //   {
  //     name: 'Mary',
  //     comment: 'Making good sense',
  //     score: 5,
  //   },
  //   {
  //     name: 'Josh',
  //     comment: 'Making good sense',
  //     score: 4,
  //   },
  // ];
  const tempImgs = [WolfGuyImg, MonkeyFaceImg, WolfGuyImg, MonkeyFaceImg];
  const [imgIdx, setImgIdx] = useState(0);
  const handleSlide = (dir, total) => {
    if (dir === "prev" && imgIdx > 0) {
      setImgIdx((prev) => +prev - 1);
    } else if (dir === "next" && imgIdx <= total - 1) {
      setImgIdx((prev) => +prev + 1);
    } else return;
  };

  if (!product) return <LoootyLoader />;

  const banners = product.merchandise_banners || [];
  const pictures = product.merchandise_pictures || [];
  const imagesArray = banners.concat(pictures);

  return (
    <div className="buy__carousel-container">
      <div className="buy__property-container">
        <div className="buy__product-name">
          {product?.merchandise_name}{" "}
          <span>
            {" "}
            |{" "}
            {product?.merchandise_categories &&
            typeof product?.merchandise_categories === "object"
              ? product?.merchandise_categories[0]
              : typeof product?.merchandise_categories === "string"
              ? product?.merchandise_categories
              : null}
          </span>
        </div>
        {/* <div className='buy__rating-container'>
          {[...Array(5)].map((_, index) => (
            <RiStarSFill
              key={index}
              style={{
                color:
                  index < Math.ceil(calculateProductRating(tempRatings))
                    ? '#FF9700'
                    : 'rgba(0, 0, 0, 0.6)',
              }}
            />
          ))}
        </div>
        <span className='buy__rating-qty'>{tempRatings?.length}</span>
        <span className='buy__comments-cont'>
          <FaComment /> {countProductComments(tempRatings)}
        </span> */}
      </div>

      <section className="buy__carousel-wrapper">
        <div className="buy_carousel-inner">
          <img
            className="buy__carousel-img"
            src={
              product.merchandise_banners?.length > 0
                ? imagesArray[imgIdx]
                : tempImgs[imgIdx]
            }
            alt=""
          />
        </div>
        <IoCaretBackCircleSharp
          color="lightgray"
          size={25}
          style={{ position: "absolute", top: "50%", left: "5" }}
          onClick={() => handleSlide("prev", imagesArray?.length - 1)}
        />
        <IoCaretForwardCircleSharp
          color="lightgray"
          size={25}
          style={{ position: "absolute", top: "50%", right: "5" }}
          onClick={() => handleSlide("next", imagesArray?.length - 1)}
        />
      </section>

      <section className="upload__image-preview">
        <div className="upload__image-preview-inner">
          {product.merchandise_pictures &&
          product.merchandise_pictures?.length > 0
            ? imagesArray.map((picture) => (
                <div
                  key={`pic-${Math.random()}`}
                  className="upload__image-item"
                >
                  <img
                    className="buy_carousel-preview-img"
                    src={picture ?? WolfGuyImg}
                    alt=""
                  />
                </div>
              ))
            : // <span style={{ textAlign: 'center' }}>No preview image</span>
              tempImgs.map((picture) => (
                <div
                  key={`tempImgs-${Math.random()}`}
                  className="upload__image-item"
                >
                  <img
                    className="buy_carousel-preview-img"
                    src={picture}
                    alt=""
                  />
                </div>
              ))}
        </div>
      </section>
    </div>
  );
};

export default CarouselSec;
