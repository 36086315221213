import {RiPencilFill} from "react-icons/ri"
import { HiFolder } from "react-icons/hi";
import { MdFolderOpen } from "react-icons/md";

const LibraryItem = ({ onClick, onEditClick, prodNumber, active = false, itemName }) => {
  return (
    <button
      className={`user__library-item ${active && "user__library-item-active"}`}
      onClick={onClick}
    >
      <div className="user__library-item-inner">
        {active ? (
          <MdFolderOpen
            className="user__lib-folder-large"
            style={{ fontSize: "1.4rem" }}
            onClick={onClick}
          />
        ) : (
          <HiFolder
            className="user__lib-folder-large not-active"
            style={{ fontSize: "1rem" }}
            onClick={onClick}
          />
        )}
        <div className="user__library-item-body">
          <h5 className={`user__library-item-name ${!active && "not-active"}`} onClick={onClick}>{itemName}</h5>
          <RiPencilFill 
            className="user__library-item-icon" 
            onClick={onEditClick} 
          />
        </div>
      </div>
      <span className="user__library-item-info-count">{prodNumber} items</span>
    </button>
  );
}

export default LibraryItem
  