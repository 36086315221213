import { useEffect, useState } from 'react';
// import { FaPlusCircle } from 'react-icons/fa';
import LibraryItem from './LibraryItem';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  // createFolderAction,
  fetchLibrary,
  fetchMyFolders,
} from '../../api/auth';
import LoadingState from '../loader/LoadingState';
import EditFolderModal from '../modals/collection/EditFolderModal';
import { RiDeleteBinFill, RiPencilFill } from 'react-icons/ri';
// import { HiTrash } from 'react-icons/hi';
import DeleteFolder from '../modals/folder/DeleteFolder';
import MobileLibraryFilterDropdown from '../dropdown/MobileLibraryFilterDropdown';
import { useMediaQuery } from 'react-responsive';
import { filterOptions } from '../navbar/navbar';
import NavBarDropdownItem from '../navbar/NavBarDropdownItem ';
import {
  getAPublicMerchandiseAction,
  getAllCategoriesAction,
} from '../../api/user';
import MobileLibraryFilterDropDownItem from '../dropdown/MobileLibraryFilterDropDownItem';
import LandCard from '../card/landCard';
import { calculateProductRating } from '../../utils/calculateProductRating';
import { countProductComments } from '../../utils/countProductComments';
import Details from '../modals/productdetails/Details';

const Library = () => {
  const dispatch = useDispatch();
  const library = useSelector((state) => state.user.library);
  const [folders, setFolders] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeSideUser, setActiveSideUser] = useState(0);
  // const [newFolderName, setNewFolderName] = useState('');
  const [groupedLibraryProducts, setGroupedLibraryProducts] = useState([]);

  // const [activeProduct, setActiveProduct] = useState({});
  // const [isActive, setIsActive] = useState(null);
  const [settingNavHeader, setSettingsHeaderNav] = useState('All Products');
  const [showEditFolderModal, setShowEditFolderModal] = useState(false);
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [activeFilter, setActiveFilter] = useState('');
  const [
    showLibraryProductsFilterContainer,
    setShowLibraryProductsFilterContainer,
  ] = useState(false);
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 768 });
  const [categories, setCategories] = useState([]);

  const [showEditFolderBoxArray, setShowEditFolderBoxArray] = useState([]);
  const toggleShowEditFolderBox = (index) => {
    const array = showEditFolderBoxArray?.map((bool, i) =>
      i === index ? !bool : false
    );
    setShowEditFolderBoxArray(array);
  };

  const clickMobileActiveFilter = (filterTitle) => {
    if (activeFilter.toLowerCase() === filterTitle.toLowerCase()) {
      setActiveFilter('');
      setShowLibraryProductsFilterContainer(false);
    } else {
      setActiveFilter(filterTitle);
      setShowLibraryProductsFilterContainer(true);
    }
  };

  function groupLibraryProductsByFolderName(data, folderName) {
    let arr = data?.filter(
      (item) =>
        item.hasOwnProperty('folder_name') && item.folder_name === folderName
    );
    setGroupedLibraryProducts(arr);
  }

  const countFolderProducts = (folderId) => {
    let count = 0;
    library?.folder_merchandise?.forEach((item) => {
      if (item.id === folderId) count++;
    });
    return count;
  };
  // const createNewFolder = async () => {
  //   if (!newFolderName || newFolderName.length < 2) {
  //     toast.error('Invalid Folder Name', {
  //       autoClose: 2000,
  //       className: 'toast__message',
  //     });
  //     return;
  //   }
  //   setLoading(true);
  //   await dispatch(
  //     createFolderAction({
  //       folderData: {
  //         name: newFolderName,
  //         type: 'library',
  //       },
  //     })
  //   )
  //     .unwrap()
  //     .then(async (response) => {
  //       setLoading(false);
  //       setNewFolderName('');
  //       toast.success('Folder was created!', {
  //         autoClose: 2000,
  //         className: 'toast__message',
  //       });
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       if (err.response) {
  //         toast.error(err.response.data.message, {
  //           autoClose: 2000,
  //           className: 'toast__message',
  //         });
  //       }
  //     });
  // };

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    dispatch(fetchLibrary({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setGroupedLibraryProducts(response[0]?.merchandise);
          setLoading(false);
        }
      })
      .catch((err) => {
        isMounted && setLoading(false);
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);

    dispatch(fetchMyFolders({ folderType: 'library' }))
      .unwrap()
      .then((response) => {
        setShowEditFolderBoxArray(response?.data?.folders?.map(() => false));
        setFolders(response?.data?.folders);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    dispatch(getAllCategoriesAction({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setCategories(response);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);
  const [collections, setCollections] = useState([]);
  const [viewedProduct, setViewedProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [detailView, showDetailView] = useState(false);

  const fetchAProduct = (id) => {
    dispatch(getAPublicMerchandiseAction({ id: id }))
      .unwrap()
      .then((response) => {
        setViewedProduct(response);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  };
  return (
    <section className='user__library'>
      {showEditFolderModal && (
        <EditFolderModal
          toggleShowModal={() => setShowEditFolderModal(false)}
          folderId={selectedFolderId}
          merchandiseList={groupedLibraryProducts}
          collectionMerchandise={library?.folder_merchandise}
        />
      )}

      {showDeleteFolderModal && (
        <DeleteFolder
          toggleShowModal={() => setShowDeleteFolderModal(false)}
          folderData={selectedFolder}
          merchandiseList={groupedLibraryProducts}
          setSelectedFolder={setSelectedFolder}
        />
      )}

      {isMobile && (
        <div
          className='landing__navbar-mobile-filter-box'
          style={{ display: 'flex' }}
        >
          <MobileLibraryFilterDropdown
            title={'All Products'}
            isActive={activeFilter.toLowerCase() === 'folders'}
            onClick={() => clickMobileActiveFilter('folders')}
            isFolder={true}
          />

          <MobileLibraryFilterDropdown
            title={'CATEGORY'}
            isActive={activeFilter.toLowerCase() === 'category'}
            onClick={() => clickMobileActiveFilter('category')}
          />

          <MobileLibraryFilterDropdown
            title={'FILTER'}
            isActive={activeFilter.toLowerCase() === 'filter'}
            onClick={() => clickMobileActiveFilter('filter')}
          />
        </div>
      )}

      {showLibraryProductsFilterContainer && (
        <div className='nav_mobile_filter-options-container'>
          {activeFilter.toLocaleLowerCase() === 'folders' ? (
            <>
              <MobileLibraryFilterDropDownItem
                folderName={'All Products'}
                numberOfItems={
                  library?.merchandise ? library?.merchandise?.length : 0
                }
                onClick={() => {
                  setActiveSideUser(0);
                  setSettingsHeaderNav('All Products');
                  setGroupedLibraryProducts(library?.merchandise);
                  setShowLibraryProductsFilterContainer(
                    !showLibraryProductsFilterContainer
                  );
                }}
              />

              {folders?.map((item) => (
                <MobileLibraryFilterDropDownItem
                  key={item.id}
                  folderName={item.name}
                  numberOfItems={countFolderProducts(item.id)}
                  onClick={() => {
                    setActiveSideUser(item.id);
                    setSettingsHeaderNav(item.name);
                    setSelectedFolder(item);
                    groupLibraryProductsByFolderName(
                      library.folder_merchandise,
                      item.name
                    );
                    setShowLibraryProductsFilterContainer(
                      !showLibraryProductsFilterContainer
                    );
                  }}
                />
              ))}

              {/* <section className='user__library-sidebar-body'>
                <div className='user__library-trash-btn mobile-user__library-trash-btn'>
                  <input
                    className='user__library-trash-btn-text'
                    type={'text'}
                    placeholder='Create New Folder'
                    value={loading ? 'Saving...' : newFolderName || ''}
                    onChange={(e) => setNewFolderName(e.target.value)}
                  />
                  <FaPlusCircle
                    className='user__library-trash-btn-logo'
                    onClick={createNewFolder}
                  />
                </div>
              </section> */}

              {/* <button
                className='user__library-trash-btn mobile-library-nav-btn'
                onClick={() => {
                  if (!selectedFolder) {
                    toast.error('Cannot delete folder', {
                      autoClose: 2000,
                      className: 'toast__message',
                    });
                    return;
                  } else {
                    setShowDeleteFolderModal(!showDeleteFolderModal);
                  }
                }}
              >
                <HiTrash
                  color='#a52222'
                  style={{ color: '#a52222' }}
                  className=''
                />
                <span className='user__library-trash-btn-text'>Trash</span>
              </button> */}
            </>
          ) : activeFilter.toLocaleLowerCase() === 'category' ? (
            categories?.map((item) => (
              <NavBarDropdownItem option={item} key={item.id} />
            ))
          ) : activeFilter.toLocaleLowerCase() === 'filter' ? (
            filterOptions?.map((item) => (
              <NavBarDropdownItem option={item} key={item.id} />
            ))
          ) : null}
        </div>
      )}

      <div className='user__library-inner'>
        <section className='user__library-sidebar'>
          <section className='user__library-sidebar-header'>
            <div className='user__library-sidebar-header-item'>
              <span className='user__library-sidebar-header-item-text'>
                {settingNavHeader}
              </span>
            </div>
          </section>

          {/* <section className='user__library-sidebar-body'>
            <div className='user__library-trash-btn'>
              <input
                className='user__library-trash-btn-text'
                type={'text'}
                placeholder='Create New Folder'
                value={loading ? 'Saving...' : newFolderName || ''}
                onChange={(e) => setNewFolderName(e.target.value)}
              />
              <FaPlusCircle
                className='user__library-trash-btn-logo'
                onClick={createNewFolder}
              />
            </div>
          </section> */}

          <section className='user__library-sidebar-body'>
            <LibraryItem
              prodNumber={
                library?.folder_merchandise
                  ? library?.folder_merchandise?.length
                  : 0
              }
              itemName={'All Products'}
              onClick={() => {
                setActiveSideUser(0);
                setSettingsHeaderNav('All Products');
                setGroupedLibraryProducts(library?.merchandise);
              }}
              active={activeSideUser === 0}
              onEditClick={() =>
                toast.error('Cannot modify folder', {
                  autoClose: 2000,
                  className: 'toast__message',
                })
              }
            />

            {folders &&
              folders?.length > 0 &&
              folders.map((folderDetails, index) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    width: '95%',
                    margin: 'auto',
                  }}
                  key={folderDetails.id}
                >
                  <LibraryItem
                    prodNumber={countFolderProducts(folderDetails.id)}
                    itemName={folderDetails.name}
                    onClick={() => {
                      setActiveSideUser(folderDetails.id);
                      setSettingsHeaderNav(folderDetails.name);
                      setSelectedFolder(folderDetails);
                      groupLibraryProductsByFolderName(
                        library.folder_merchandise,
                        folderDetails.name
                      );
                    }}
                    active={activeSideUser === folderDetails.id}
                    onEditClick={() => toggleShowEditFolderBox(index)}
                  />

                  {showEditFolderBoxArray[index] && (
                    <div className='user__library-edit-folder-dropdown-container'>
                      <div
                        className='user__library-edit-folder-dropdown-item'
                        onClick={() => {
                          setSelectedFolderId(folderDetails.id);
                          toggleShowEditFolderBox(index);
                          setShowEditFolderModal(!showEditFolderModal);
                        }}
                      >
                        <RiPencilFill className='user__library-item-icon' />
                        <span>Edit folder</span>
                      </div>

                      <div
                        className='user__library-edit-folder-dropdown-item'
                        onClick={() => {
                          setSelectedFolder(folderDetails);
                          toggleShowEditFolderBox(index);
                          setShowDeleteFolderModal(!showDeleteFolderModal);
                        }}
                      >
                        <RiDeleteBinFill className='user__library-item-icon' />
                        <span>Remove</span>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </section>

          {/* <section className='user__library-sidebar-body trash-button-section'>
            <button
              className='user__library-trash-btn'
              onClick={() => {
                if (!selectedFolder) {
                  toast.error('Cannot delete folder', {
                    autoClose: 2000,
                    className: 'toast__message',
                  });
                  return;
                } else {
                  setShowDeleteFolderModal(!showDeleteFolderModal);
                }
              }}
            >
              <HiTrash color='#af1c1c' />
              <span className='user__library-trash-btn-text'>Trash</span>
            </button>
          </section> */}
        </section>

        <section className='user__main-lib-container'>
          {loading || isLoading ? (
            <LoadingState />
          ) : !detailView ? (
            <div>
              <div className='user__main-lib-body-collections'>
                {groupedLibraryProducts?.length > 0 ? (
                  groupedLibraryProducts.map((prod, index) => (
                    <LandCard
                      id={prod.id}
                      key={prod.merchandise_name + index}
                      onClick={() => {
                        fetchAProduct(prod.id);
                        showDetailView(true);
                      }}
                      img={
                        prod.merchandise_banners?.length > 0
                          ? prod.merchandise_banners[0]
                          : null
                      }
                      title={prod.merchandise_name}
                      ratings={calculateProductRating(prod.ratings)}
                      // owner={user?.name}
                      numOfComments={countProductComments(prod.ratings)}
                      numReviewers={prod.ratings?.length}
                      price={prod.merchandise_amount}
                      firstTag={
                        prod.merchandise_tags ? prod.merchandise_tags[0] : ''
                      }
                      firstCate={
                        prod.merchandise_categories
                          ? prod.merchandise_categories[0]
                          : ''
                      }
                      // navigateToStore={() => navigate(`store/${prod.store_id}`)}
                      productId={prod.id}
                      visibility={prod.visibility}
                      wishlistIds={collections}
                      setCollections={setCollections}
                      product={prod}
                      showDetails={true}
                    />
                  ))
                ) : (
                  <div className='user__no-product-div'>
                    Nothing to see here yet!
                  </div>
                )}
              </div>

              {/* {isActive && <CollectionDescription product={activeProduct} />} */}

              <div className='user__main-lib-footer'></div>
            </div>
          ) : (
            <div className='product'>
              <h2
                onClick={() => {
                  showDetailView(false);
                  setViewedProduct(null);
                }}
                style={{
                  marginLeft: 'auto',
                  width: 'fit-content',
                  transform: 'skewX(-25deg)',
                  backgroundColor: 'rgba(56, 55, 74, 0.6)',
                  padding: '0.5rem 1rem',
                }}
              >
                X
              </h2>
              <Details product={viewedProduct} mgtAction={false} />
            </div>
          )}
        </section>
      </div>
    </section>
  );
};

export default Library;
