import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  email: null,
  merchandiseStore: null,
  products: null,
  library: null,
  collections: null,
  folders: null,
  profile: null,
};

const authReducer = createSlice({
  name: 'SignUp',
  initialState,
  reducers: {
    signUp(state, action) {
      state.user = action.payload.user;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    merchandiseStore(state, action) {
      state.merchandiseStore = action.payload.merchandiseStore;
    },
    products(state, action) {
      state.products = action.payload.products;
    },
    library(state, action) {
      state.library = action.payload.library;
    },
    collections(state, action) {
      state.collections = action.payload.collections;
    },
    folders(state, action) {
      state.folders = action.payload.folders;
    },
    profile(state, action) {
      state.profile = action.payload.profile;
    },
    logout(state, action) {
      state.products = null;
      state.merchandiseStore = null;
      state.user = null;
      state.library = null;
      state.collections = null;
    },
  },
});

export const {
  signUp,
  setEmail,
  merchandiseStore,
  library,
  collections,
  folders,
  products,
  profile,
  logout,
} = authReducer.actions;

export default authReducer.reducer;
