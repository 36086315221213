import { useState, useRef, useEffect } from 'react';

const DropDown = ({
  selected,
  onSelect,
  options,
  placeholder,
  icon,
  style,
  listStyle,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (selected) setIsOpen(false);
  }, [selected]);

  return (
    <div className='dropdown' ref={dropdownRef}>
      <div className='dropdown__header' style={style} onClick={toggleDropdown}>
        {placeholder ? placeholder : selected}
        {icon}
      </div>
      {isOpen && (
        <div className={`dropdown__content`}>
          <ul>
            {options?.map((option, index) => (
              <li
                className={selected === option ? 'activeItem' : undefined}
                key={index}
                onClick={() => onSelect && onSelect(option)}
                style={listStyle}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropDown;
