import { FaShoppingCart } from "react-icons/fa";
import { BsFillSuitHeartFill } from "react-icons/bs";

const AffiliatedItemCarts = ({
  merchandiseData
}) => {
  let isEnabled = false;
  return (
    <div className="admin__users-afilliated-item">
      <div className="admin__users-afilliated-item-sub container">
        <img
          className="admin__users-afilliated-item-sub image"
          src={merchandiseData?.merchandise_cover_picture[0]}
          alt=""
        />
      </div>
      <div
        className={`admin__users-afilliated-item-sub product-name ${
          isEnabled && "enabled"
        } `}
      >
        {merchandiseData?.merchandise_name}
      </div>

      <div className="admin__users-afilliated-item-sub product-ppb">
        {merchandiseData?.basic_price}
      </div>

      <div className="admin__users-afilliated-item-sub product-qty">
        {merchandiseData?.sold}
      </div>

      <div className="admin__users-afilliated-item-sub wish">
        <BsFillSuitHeartFill />
        24
      </div>

      <div className="admin__users-afilliated-item-sub wish">
        <FaShoppingCart />
        14
      </div>
    </div>
  );
};

export default AffiliatedItemCarts;