import LoootyLoader from "../../../loader/loootyLoader";
import { MdEdit, MdMoneyOff } from "react-icons/md";

const PostponePayoutModal = ({
  toggleShowModal,
  fadeOut,
  setFadeOut,
  isLoading,
  comment,
  setComment,
  date,
  setDate,
  postponePayout,
}) => {
  return (
    <div
      className={`reg__sign-up-overlay ${fadeOut ? "lgn__fade-out-anim" : ""}`}
      style={{ color: "#ffffff" }}
    >
      <section className="reg__sign-up-modal user__withdrawal-modal">
        <div className="reg__close-btn-container">
          <div
            onClick={(e) => {
              setFadeOut((prev) => !prev);
              setTimeout(() => {
                toggleShowModal();
              }, 600);
            }}
            className="reg__close-btn"
          >
            <span className="reg__close-btn-text">X</span>
          </div>
        </div>

        <div className="reg__sign-up-forms-container withdrawal-modal">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <MdMoneyOff
              className="user__lib-folder-large"
              style={{
                fontSize: "1.5rem",
                marginRight: "1rem",
                color: "#74747d",
              }}
            />
            <h2 className="user__withdrawal-modal-heading">
              Postpone Payout Request
            </h2>
          </div>

          <div>
            <p
              className="user__delete-folder-modal-text"
              style={{ marginBottom: "2rem" }}
            >
              Set a date for payout and send a comment to user
            </p>

            <section className="upload__text-area-container">
              <textarea
                onChange={(e) => setComment(e.target.value)}
                value={comment || ""}
                rows={4}
                cols={30}
                className="upload__text-area"
              ></textarea>
              <button type="button" className="upload__text-area-btn">
                <MdEdit />
              </button>
            </section>
            <section>
              <label className="date__input-label">
                <i></i>
                <input
                  min={new Date().toISOString().split("T")[0]}
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  className="date__input"
                />
              </label>
            </section>
            <div className="user__delete-folder-btn-container">
              <button
                className="user__library-trash-btn"
                type="button"
                onClick={() => {
                  setFadeOut((prev) => !prev);
                  setTimeout(() => {
                    toggleShowModal();
                  }, 400);
                }}
              >
                <span className="user__library-trash-btn-text">Cancel</span>
              </button>

              <div style={{ width: "fit-content", marginLeft: "1rem" }}>
                <button
                  onClick={postponePayout}
                  className="user__upload-products-btn"
                  type="button"
                >
                  <div className="user__upload-products-btn-inner">
                    {isLoading ? <LoootyLoader /> : "Postpone"}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PostponePayoutModal;
