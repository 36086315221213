import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import Details from '../modals/productdetails/Details';
import LoootyLoader from '../loader/loootyLoader';
import {
  getAMerchandiseByAdminAction,
  getCataloguesByAdmin,
} from '../../api/admin';
import { IoMdRemoveCircle } from 'react-icons/io';
import RemoveFromCatalogue from './modals/catalogues/RemoveFromCatalogue';
import { AiFillPlusCircle } from 'react-icons/ai';
import AddToCatalogue from './modals/catalogues/AddToCatalogue';

const AdminUserStore = () => {
  const dispatch = useDispatch();
  const [product, setProduct] = useState(null);
  const [productCatalogues, setProductCatalogues] = useState([]);
  const [activeTab, setActiveTab] = useState('PRODUCT DETAILS');
  const { id } = useParams();
  const navigate = useNavigate();
  const [addCatalogueModal, setAddCatalogueModal] = useState(null);
  const [addModalfadeOut, setAddModalFadeOut] = useState(false);
  const [showCatalogueModal, setShowCatalogueModal] = useState(null);
  const [createModalfadeOut, setCreateModalFadeOut] = useState(false);
  const [catalogue, setCatalogue] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatch(getAMerchandiseByAdminAction({ id: id }))
      .unwrap()
      .then((response) => {
        setProduct(response);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  }, [dispatch, id]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getCataloguesByAdmin({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setProductCatalogues(
            response.data?.filter((obj) =>
              obj.catalogue_merchandise.includes(Number(id))
            )
          );
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch, id]);

  const refetch = () => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getCataloguesByAdmin({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setProductCatalogues(
            response.data?.filter((obj) =>
              obj.catalogue_merchandise.includes(Number(id))
            )
          );
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  };
  const refetchMerchandise = () => {
    dispatch(getAMerchandiseByAdminAction({ id: id }))
      .unwrap()
      .then((response) => {
        setProduct(response);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  };
  return (
    <div>
      <section className='product'>
        <h2
          onClick={() => {
            navigate(-1);
            setProduct(null);
          }}
          style={{
            marginLeft: 'auto',
            width: 'fit-content',
            transform: 'skewX(-25deg)',
            backgroundColor: 'rgba(56, 55, 74, 0.6)',
            padding: '0.5rem 1rem',
          }}
        >
          X
        </h2>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 300,
            }}
          >
            <LoootyLoader />
          </div>
        ) : (
          <>
            <div className='admin__catalogues'>
              <p
                className={activeTab === 'PRODUCT DETAILS' ? 'active' : ''}
                onClick={() => setActiveTab(() => 'PRODUCT DETAILS')}
              >
                PRODUCT DETAILS
              </p>
              <p
                style={{
                  display: product?.management_approved === false && 'none',
                }}
                className={activeTab === 'PRODUCT CATALOGUES' ? 'active' : ''}
                onClick={() => setActiveTab(() => 'PRODUCT CATALOGUES')}
              >
                PRODUCT CATALOGUES
              </p>
            </div>

            {activeTab === 'PRODUCT DETAILS' ? (
              <Details
                product={product}
                mgtAction={true}
                refetchMerchandise={refetchMerchandise}
              />
            ) : (
              <>
                <p
                  style={{
                    color: '#e8e8e8',
                    fontWeight: 600,
                    fontSize: '1.2rem',
                    marginTop: '2rem',
                    marginLeft: '1rem',
                  }}
                >
                  Catalogues this product is in.
                </p>
                <div
                  className='catalogue__grid'
                  style={{ margin: '1rem', maxWidth: 500 }}
                >
                  {!productCatalogues?.length && (
                    <>
                      <p
                        style={{
                          color: '#e8e8e8',
                          marginTop: '2rem',
                          marginLeft: '1rem',
                        }}
                      >
                        This Product does not belong to any Catalogues.
                      </p>
                    </>
                  )}
                  {productCatalogues &&
                    productCatalogues?.map((cat) => (
                      <div
                        key={cat.id}
                        className='admin__categories-tab'
                        style={{
                          justifyContent: 'space-between',
                          backgroundColor: 'rgba(0,0,0,0.2)',
                        }}
                        onClick={() => {
                          setCatalogue(cat);
                          setShowCatalogueModal(true);
                        }}
                      >
                        {cat.catalogue_title}
                        <IoMdRemoveCircle size={20} color='#7c187a' />
                      </div>
                    ))}
                </div>
                <p
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    padding: '0.7rem 1.5rem',
                    cursor: 'pointer',
                    color: '#e8e8e8',
                    width: 'fit-content',
                    marginLeft: '1rem',
                    marginTop: '3rem',
                  }}
                  onClick={() => setAddCatalogueModal(true)}
                >
                  <AiFillPlusCircle size={20} color='#7c187a' />
                  <span>Add to Catalogues</span>
                </p>
              </>
            )}
          </>
        )}
        {addCatalogueModal && (
          <AddToCatalogue
            toggleShowModal={() => setAddCatalogueModal(!addCatalogueModal)}
            fadeOut={addModalfadeOut}
            setFadeOut={setAddModalFadeOut}
            product={product}
            refetch={refetch}
          />
        )}
        {showCatalogueModal && (
          <RemoveFromCatalogue
            toggleShowModal={() => setShowCatalogueModal(!showCatalogueModal)}
            fadeOut={createModalfadeOut}
            setFadeOut={setCreateModalFadeOut}
            catalogue={catalogue}
            refetch={refetch}
            id={id}
          />
        )}
      </section>
    </div>
  );
};

export default AdminUserStore;
