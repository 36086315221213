import { FaSearchLocation } from 'react-icons/fa';

const WolfGuyImg = '/assets/image/loootydefault.jpg';
const CatmanImg = '/assets/image/loootydefault.jpg';

const SuscribersCard = ({ subscription, unSubscribeFromStore }) => {
  return (
    <div className='users__suscribers-card'>
      <div className='users__top-bg-image'>
        <img
          className='users__top-bg-image-img'
          src={subscription?.store_cover_picture[0] ?? WolfGuyImg}
          alt=''
        />
      </div>

      <div className='users__bottom-bg-image-container'>
        <img
          className='users__top-bg-image-profile'
          src={subscription?.user?.picture ?? CatmanImg}
          alt=''
        />
        <div className='users__suscribers-count-container'>
          <h3 className='users__suscribers-count-title'>
            {subscription?.user.name}
          </h3>
          <small className='users__suscribers-count'>
            {subscription?.subscribers.length} suscribers
          </small>
        </div>

        {subscription?.profile?.description && (
          <p className='users__suscribers-count-info'>
            we can only accept the most qualified setters at the moment
          </p>
        )}

        <div className='users__suscribers-location'>
          <FaSearchLocation /> Enugu, Nigeria.
        </div>
        <button
          className='users__suscribers-btn'
          onClick={unSubscribeFromStore}
          style={{ cursor: 'pointer' }}
        >
          <span>Suscribed</span>
        </button>
      </div>
    </div>
  );
};

export default SuscribersCard;
