import AccountSettings from './AccountSettings';
import NotificationSettings from './NotificationSettings'
import StoreSettings from './StoreSettings';

const SettingsPageSection = ({sectionName}) => {
    switch(sectionName.toLowerCase()) {
        case "notifications": 
            return <NotificationSettings />;

        case "account": 
            return <AccountSettings />;

        case "store": 
            return <StoreSettings />;

        default:
            return <NotificationSettings />
    }
}

export default SettingsPageSection