import { Link } from 'react-router-dom';

const MoreAboutLicense = ({ toggleShowModal, fadeOut }) => {
  return (
    <div
      className={`reg__sign-up-overlay ${fadeOut ? 'lgn__fade-out-anim' : ''}`}
      style={{ color: '#ffffff' }}
    >
      <section className='reg__sign-up-modal user__withdrawal-modal'>
        <div className='reg__close-btn-container'>
          <div onClick={toggleShowModal} className='reg__close-btn'>
            <span className='reg__close-btn-text'>X</span>
          </div>
        </div>

        <div className='reg__sign-up-forms-container withdrawal-modal'>
          <div>
            <p
              className=''
              style={{ fontWeight: '600', marginBottom: '0.2rem' }}
            >
              Basic License (Personal, Non-commercial):
            </p>
            <p>
              Our Basic License is perfect for individual users and hobbyists
              who want to use our assets for personal projects and exploration.
              With this license, you have the freedom to create and experiment
              without any commercial intent. It's a great option for learning,
              personal artistry, and non-commercial ventures.
            </p>

            <p
              className=''
              style={{
                fontWeight: '600',
                marginTop: '1.5rem',
                marginBottom: '0.2rem',
              }}
            >
              Commercial License:
            </p>
            <p>
              Our Commercial License is designed for professionals and
              businesses looking to use our assets for commercial purposes. This
              license grants you the rights to incorporate our assets into your
              commercial projects, products, or services. It's ideal for game
              developers, animation studios, and businesses seeking high-quality
              CG assets to enhance their creative work and offerings.
            </p>

            <p style={{ marginTop: '1.5rem' }}>
              For more information on Licenses, check our{' '}
              <Link to='/terms/conditions' className='upload__more-text'>
                Terms of Use.
              </Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MoreAboutLicense;
