import BarLoader from "react-spinners/BarLoader";
import LoootyLoader from "./loootyLoader";

const override = {
  display: "block",
  margin: "20% auto",
  borderColor: "#ffffff",
};

const LoadingState = () => {
  return (
    <div className="sweet-loading" style={{
        height: "100%",
        minHeight: "30vh",
        justifyContent: "center",
        alignContent: "center"
    }}>
      <div style={{width: "10%", margin: "7% auto 40% auto"}}>
        <LoootyLoader />
      </div>
    </div>
  )
}

export const ProgressBar = () => {
  return (
    <div className="sweet-loading" style={{
        display: "block",
        justifySelf: "center"
    }}>
        <BarLoader
            cssOverride={override}
            color="#aa24ac"
            size={80}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
    </div>
  )
}

export default LoadingState