import { MdFolderOpen } from "react-icons/md";

const MobileLibraryFilterDropDownItem = ({folderName,numberOfItems, onClick}) => {
    return (
        <div className="mobile-library__dropdown-filter-item" onClick={onClick}>
            <MdFolderOpen
                className="user__lib-folder-large"
                style={{ fontSize: "1.4rem", marginRight: '5px' }}
            />
            {folderName}
            <span className=""
                style={{
                    
                }}
            >
                {numberOfItems}
            </span>
        </div>
    );
}

export default MobileLibraryFilterDropDownItem;