import { useEffect, useState } from 'react';
import AdminFreebiesSidebar from '../AdminFreebiesSidebar';
import TagsComp from '../TagsComp';
import { setSelectedSubPage } from '../../../redux/reducers/userStateReducer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  approveAStoreByManagementAction,
  getAllStoresAction,
  searchStoresAction,
  unapproveAStoreByManagementAction,
} from '../../../api/admin';
import LoadingState from '../../loader/LoadingState';
import { toast } from 'react-toastify';
import SidebarTabNavigation from '../features/SidebarTabNavigation';
import ListedStore from '../features/ListedStore';
import ApproveStoreModal from '../modals/store/ApproveStoreModal';
import UnapproveStoreModal from '../modals/store/UnapproveStoreModal';
import { StoreRequestModal } from '../modals/store/StoreRequestModal';

const StoreAdmin = () => {
  const [activeSideTab, setActiveSideTab] = useState('All');
  const [selected, setSelected] = useState(0);
  const [selectedStore, setSelectedStore] = useState(null);
  const isStoreOwner = true;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [storesList, setStoresList] = useState([]);
  const [sortedStoresList, setSortedStoresList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showApproveStoreModal, setShowApproveStoreModal] = useState(false);
  const [showStoreRequestStoreModal, setShowStoreRequestStoreModal] =
    useState(false);
  const [showUnapproveStoreModal, setShowUnapproveStoreModal] = useState(false);
  const [approveStoreModalfadeOut, setApproveStoreModalFadeOut] =
    useState(false);
  const [storeRequestModalfadeOut, setStoreRequestModalFadeOut] =
    useState(false);
  const [unapproveStoreModalfadeOut, setUnapproveStoreModalFadeOut] =
    useState(false);
  const [searchString, setSearchString] = useState('');

  const selectAStore = (storeData, id) => {
    setSelected(id);
    setSelectedStore(storeData);
  };

  const filterStoresList = (sortBy) => {
    let sortedStores = [];
    switch (sortBy.toLowerCase()) {
      case 'all':
        setActiveSideTab(sortBy);
        sortedStores = storesList;
        break;

      case 'stores':
        setActiveSideTab(sortBy);
        sortedStores = storesList?.filter((store) => store.management_approved);
        break;

      case 'requests':
        setActiveSideTab(sortBy);
        sortedStores = storesList?.filter(
          (store) => !store.management_approved
        );
        break;

      default:
        sortedStores = storesList;
    }

    setSortedStoresList(sortedStores);
  };

  const calculateStoreSales = (ordersArray) => {
    return ordersArray?.reduce(function (sum, order) {
      return sum + order.order_amount;
    }, 0);
  };

  const approveAStore = async () => {
    setIsLoading(true);

    await dispatch(
      approveAStoreByManagementAction({
        approveData: {
          id: selectedStore?.id,
        },
      })
    )
      .unwrap()
      .then(() => {
        const arr = [...storesList];
        let approvedStore = arr?.find(
          (store) => store.id === selectedStore?.id
        );
        if (approvedStore) {
          approvedStore.management_approved = true;
          setStoresList(arr);
          toast.success('Store has been approved', {
            autoClose: 2000,
            className: 'toast__message',
          });
          setIsLoading(false);
          setApproveStoreModalFadeOut((prev) => !prev);
          setTimeout(() => {
            setShowApproveStoreModal(!showApproveStoreModal);
          }, 400);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  const unapproveAStore = async () => {
    setIsLoading(true);

    await dispatch(
      unapproveAStoreByManagementAction({
        unapproveData: {
          id: selectedStore?.id,
        },
      })
    )
      .unwrap()
      .then(() => {
        const arr = [...storesList];
        let approvedStore = arr?.find(
          (store) => store.id === selectedStore?.id
        );
        if (approvedStore) {
          approvedStore.management_approved = false;
          setStoresList(arr);
          toast.success('Store has been unapproved', {
            autoClose: 2000,
            className: 'toast__message',
          });
          setIsLoading(false);
          setUnapproveStoreModalFadeOut((prev) => !prev);
          setTimeout(() => {
            setShowUnapproveStoreModal(!showUnapproveStoreModal);
          }, 400);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  const searchStores = async () => {
    setIsLoading(true);

    await dispatch(
      searchStoresAction({
        query: {
          search: searchString,
        },
      })
    )
      .unwrap()
      .then((response) => {
        setSortedStoresList(response);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err?.response) {
          setIsLoading(false);
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getAllStoresAction({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setStoresList(response);
          setSortedStoresList(response);
          setSelectedStore(response[0]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);
  return (
    <div className='admin__users-container'>
      {showApproveStoreModal && (
        <ApproveStoreModal
          toggleShowModal={() =>
            setShowApproveStoreModal(!showApproveStoreModal)
          }
          selectedUser={selectedStore}
          isLoading={isLoading}
          fadeOut={approveStoreModalfadeOut}
          setFadeOut={setApproveStoreModalFadeOut}
          approveStore={approveAStore}
        />
      )}

      {showStoreRequestStoreModal && (
        <StoreRequestModal
          toggleShowModal={() =>
            setShowStoreRequestStoreModal(!showStoreRequestStoreModal)
          }
          selectedUser={selectedStore}
          isLoading={isLoading}
          fadeOut={storeRequestModalfadeOut}
          setFadeOut={setStoreRequestModalFadeOut}
          approveStore={approveAStore}
          unapproveStore={unapproveAStore}
        />
      )}

      {showUnapproveStoreModal && (
        <UnapproveStoreModal
          toggleShowModal={() =>
            setShowUnapproveStoreModal(!showUnapproveStoreModal)
          }
          selectedUser={selectedStore}
          isLoading={isLoading}
          fadeOut={unapproveStoreModalfadeOut}
          setFadeOut={setUnapproveStoreModalFadeOut}
          unapproveStore={unapproveAStore}
        />
      )}

      {isLoading ? (
        <div
          style={{
            paddingTop: '5rem',
            height: '5rem',
            width: '100%',
            backgroundColor: '#191921',
          }}
        />
      ) : (
        <AdminFreebiesSidebar
          isStoreOwner={isStoreOwner}
          type='store'
          setActiveSideTab={setActiveSideTab}
          activeSideTab={activeSideTab}
          data={selectedStore}
          sideActionOnclick={() => {
            navigate(`/admin/expanded/${selectedStore?.user_id}`);
            dispatch(setSelectedSubPage({ selected: 'store' }));
          }}
          sidebarMaininfoIts={[
            {
              leftInfo: 'Name',
              rightInfo: selectedStore?.store_name,
            },

            {
              leftInfo: 'Status',
              rightInfo: selectedStore?.visibility,
              showVerifySign: selectedStore?.visibility === 'public',
            },

            {
              leftInfo: 'Products',
              rightInfo: selectedStore?.merchandise?.length || 0,
            },

            {
              leftInfo: 'Sales',
              rightInfo: calculateStoreSales(selectedStore?.orders),
            },

            {
              leftInfo: 'Balance',
              rightInfo: selectedStore?.user?.wallet?.balance,
            },
          ]}
        />
      )}

      <section className='admin__users-list-container'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <SidebarTabNavigation
            isPayoutsPage={false}
            setActiveSideTab={setActiveSideTab}
            activeSideTab={activeSideTab}
            sortFn={filterStoresList}
            itemsList={[
              {
                navigator: 'All',
                showText: 'All',
              },
              {
                navigator: 'Stores',
                showText: 'Stores',
              },
              {
                navigator: 'Requests',
                showText: 'Requests',
              },
            ]}
          />
          <TagsComp
            showFilter={false}
            search={searchString}
            setSearch={setSearchString}
            onSearch={searchStores}
          />
        </div>
        <section className='admin__users-list-body'>
          {isLoading ? (
            <LoadingState />
          ) : (
            sortedStoresList?.map((store, index) => {
              return (
                <ListedStore
                  wholeStore={store}
                  isRequested={!store.management_approved}
                  styleVerifySign={{
                    position: 'absolute',
                    top: '25%',
                    left: '5%',
                  }}
                  id={index}
                  key={index}
                  selected={index === selected}
                  setSelected={setSelected}
                  image={store.store_pictures[0]}
                  name={store.store_name}
                  location={store.profile?.country}
                  email={store.store_email}
                  phone={store.user?.phone}
                  approved={store.visibility === 'public'}
                  mgtApproved={store.management_approved}
                  showApproveStoreModal={() =>
                    setShowApproveStoreModal(!showApproveStoreModal)
                  }
                  showUnapproveStoreModal={() =>
                    setShowUnapproveStoreModal(!showUnapproveStoreModal)
                  }
                  showStoreRequestModal={() =>
                    setShowStoreRequestStoreModal(!showStoreRequestStoreModal)
                  }
                  selectAStore={() => selectAStore(store, index)}
                />
              );
            })
          )}
        </section>
      </section>
    </div>
  );
};

export default StoreAdmin;
