import { useEffect, useState } from 'react';
import AdminFreebiesSidebar from '../AdminFreebiesSidebar';
import TagsComp from '../TagsComp';
import ListedUser from '../features/ListedUser';
import { useNavigate } from 'react-router-dom';
import { setSelectedSubPage } from '../../../redux/reducers/userStateReducer';
import { useDispatch } from 'react-redux';
import {
  blockUserAction,
  getAllUsersAction,
  searchUsersAction,
  unblockUserAction,
} from '../../../api/admin';
import { toast } from 'react-toastify';
import LoadingState from '../../loader/LoadingState';
import BlockUserModal from '../modals/user/BlockUserModal';
import UnblockUserModal from '../modals/user/UnblockUserModal';
import SidebarTabNavigation from '../features/SidebarTabNavigation';

const UsersAdmin = () => {
  const [activeSideTab, setActiveSideTab] = useState('All');
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([]);
  const [sortedUsersList, setSortedUsersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showBlockUserModal, setShowBlockUserModal] = useState(false);
  const [showUnblockUserModal, setShowUnblockUserModal] = useState(false);
  const [blockModalfadeOut, setBlockModalFadeOut] = useState(false);
  const [unblockModalfadeOut, setUnblockModalFadeOut] = useState(false);
  const [searchString, setSearchString] = useState('');

  const selectAUser = (userData, id) => {
    setSelected(id);
    setSelectedUser(userData);
  };

  const blockUser = async () => {
    setIsLoading(true);

    await dispatch(
      blockUserAction({
        blockData: {
          user_id: selectedUser?.id,
        },
      })
    )
      .unwrap()
      .then(() => {
        const arr = [...usersList];
        let blockedUser = arr?.find((user) => user.id === selectedUser?.id);
        if (blockedUser) {
          blockedUser.blocked = true;
          setUsersList(arr);
          toast.success('User Account has been blocked', {
            autoClose: 2000,
            className: 'toast__message',
          });
          setIsLoading(false);
          setBlockModalFadeOut((prev) => !prev);
          setTimeout(() => {
            setShowBlockUserModal(!showBlockUserModal);
          }, 400);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  const unblockUser = async () => {
    setIsLoading(true);

    await dispatch(
      unblockUserAction({
        unblockData: {
          user_id: selectedUser?.id,
        },
      })
    )
      .unwrap()
      .then(() => {
        const arr = [...usersList];
        let blockedUser = arr?.find((user) => user.id === selectedUser?.id);
        if (blockedUser) {
          blockedUser.blocked = false;
          setUsersList(arr);
          toast.success('User Account has been blocked', {
            autoClose: 2000,
            className: 'toast__message',
          });
          setIsLoading(false);
          setUnblockModalFadeOut((prev) => !prev);
          setTimeout(() => {
            setShowUnblockUserModal(!showUnblockUserModal);
          }, 400);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  const filterUsersList = (sortBy) => {
    let sortedUsers = [];

    switch (sortBy.toLowerCase()) {
      case 'all':
        setActiveSideTab(sortBy);
        sortedUsers = usersList;
        break;

      case 'reports':
        setActiveSideTab(sortBy);
        sortedUsers = usersList?.sort((a, b) => b.flag - a.flag);
        break;

      case 'blocked':
        setActiveSideTab(sortBy);
        sortedUsers = usersList?.filter((user) => user?.blocked === true);
        break;

      default:
        sortedUsers = usersList;
    }

    setSortedUsersList(sortedUsers);
  };

  const searchUsers = async () => {
    setIsLoading(true);

    await dispatch(
      searchUsersAction({
        query: {
          search: searchString,
        },
      })
    )
      .unwrap()
      .then((response) => {
        setSortedUsersList(response);
        setSelectedUser(response[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err?.response) {
          setIsLoading(false);
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getAllUsersAction({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setUsersList(response);
          setSortedUsersList(response);
          setSelectedUser(response[0]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  return (
    <div className='admin__users-container'>
      {showBlockUserModal && (
        <BlockUserModal
          toggleShowModal={() => setShowBlockUserModal(!showBlockUserModal)}
          selectedUser={selectedUser}
          isLoading={isLoading}
          fadeOut={blockModalfadeOut}
          setFadeOut={setBlockModalFadeOut}
          blockUser={blockUser}
        />
      )}

      {showUnblockUserModal && (
        <UnblockUserModal
          toggleShowModal={() => setShowUnblockUserModal(!showUnblockUserModal)}
          selectedUser={selectedUser}
          isLoading={isLoading}
          fadeOut={unblockModalfadeOut}
          setFadeOut={setUnblockModalFadeOut}
          unblockUser={unblockUser}
        />
      )}

      {isLoading ? (
        <div
          style={{
            paddingTop: '5rem',
            height: '5rem',
            width: '100%',
            backgroundColor: '#191921',
          }}
        />
      ) : (
        <AdminFreebiesSidebar
          type='user'
          setActiveSideTab={setActiveSideTab}
          activeSideTab={activeSideTab}
          data={selectedUser}
          sideActionOnclick={() => {
            navigate(`/admin/expanded/${selectedUser?.id}`);
            dispatch(setSelectedSubPage({ selected: 'profile' }));
          }}
          sidebarMaininfoIts={[
            {
              leftInfo: 'Name',
              rightInfo: selectedUser?.name,
            },
            {
              leftInfo: 'Email',
              rightInfo: selectedUser?.email,
            },
            {
              leftInfo: 'Phone',
              rightInfo: selectedUser?.phone || '###########',
            },
          ]}
        />
      )}

      <section className='admin__users-list-container'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <SidebarTabNavigation
            isPayoutsPage={false}
            setActiveSideTab={setActiveSideTab}
            activeSideTab={activeSideTab}
            sortFn={filterUsersList}
            itemsList={[
              {
                navigator: 'All',
                showText: 'All',
              },
              {
                navigator: 'Reports',
                showText: 'Reports',
              },
              {
                navigator: 'Blocked',
                showText: 'Blocked',
              },
            ]}
          />
          <TagsComp
            showFilter={false}
            search={searchString}
            setSearch={setSearchString}
            onSearch={searchUsers}
          />
        </div>
        <section className='admin__users-list-body'>
          {isLoading ? (
            <LoadingState />
          ) : (
            sortedUsersList?.map((user, index) => {
              return (
                <ListedUser
                  id={index}
                  key={user.id}
                  selected={index === selected}
                  setSelected={setSelected}
                  selectedUserData={selectedUser}
                  setSelectedUser={setSelectedUser}
                  userData={user}
                  selectAUser={() => selectAUser(user, index)}
                  showBlockUserModal={() =>
                    setShowBlockUserModal(!showBlockUserModal)
                  }
                  showUnblockUserModal={() =>
                    setShowUnblockUserModal(!showUnblockUserModal)
                  }
                />
              );
            })
          )}
        </section>
      </section>
    </div>
  );
};

export default UsersAdmin;
