import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";

const NavBarDropdownItem = ({option, onClick, dropDown=false, isActive}) => {
    return (
        <div className="navbar__dropdown-filter-item" onClick={onClick}>
            {option.name ?? option}
            {
                dropDown && isActive ? 
                    <MdArrowDropUp className="nav__mobile-filter-section-sub-option-arrow-down"/> :
                dropDown && !isActive ?
                    <MdArrowDropDown className="nav__mobile-filter-section-sub-option-arrow-down"/> : 
                null
            }
        </div>
    );
}

export default NavBarDropdownItem;