import { useSelector } from "react-redux";
import Comment from "./Comment";

const WolfGuyImg = "/assets/image/loootydefault.jpg";

const CommentSec = ({ ratings, productId }) => {
  const user = useSelector((state) => state.user.user);

  const sendComment = (e) => {
    e.preventDefault();
  };

  return (
    <div className="buy__comments-main-container">
      <div
        className="buy__send-comment-container"
        style={{ marginTop: "2rem" }}
      >
        <img
          className="buy__user-profile-img"
          src={user?.picture ?? WolfGuyImg}
          alt="user profile"
        />

        <form
          style={{ width: "70%" }}
          className="buy__copy-url-form"
          onSubmit={sendComment}
        >
          <div style={{ width: "100%" }} className="buy__copy-url-form-group">
            <input
              placeholder="write your comment"
              className="buy__copy-url-form-control"
              style={{ width: "100%" }}
            />
            <button className="buy__copy-url-btn">
              <span className="buy__copy-url-btn-text">Send</span>
            </button>
          </div>
        </form>
      </div>

      <div className="buy__comments-listing-container">
        {ratings?.map((cm) => {
          return (
            <Comment
              key={`comment-${Math.random()}`}
              comment={cm.comment}
              author={cm.user?.name}
              info={cm}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CommentSec;
