import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import { setSelectedSubPage } from '../../redux/reducers/userStateReducer';
import NavBar from '../../component/navbar/navbar';
import TagheaderCompMobile from '../../component/admin/TagheaderCompMobile';
import { UniversalTagHeaderDesktopAdmin } from '../../component/user/UniversalTagHeaderDesktop';
import { ChangeCoverBtn, ProfileUserAdmin } from '../user';
import { MdLocationOn } from 'react-icons/md';
import UserPurpleInnerStripeBtn from '../../component/buttons/UserPurpleButton';
import FireMailButton from '../../component/admin/features/FireMailButton';
import AdminPageSelectComp from '../../component/admin/AdminPageSelectComp';
import {
  blockUserAction,
  showAUserAction,
  unblockUserAction,
} from '../../api/admin';
import { toast } from 'react-toastify';
import LoadingState from '../../component/loader/LoadingState';
import BlockUserModal from '../../component/admin/modals/user/BlockUserModal';
import UnblockUserModal from '../../component/admin/modals/user/UnblockUserModal';
import UniversalAdminTagHeaderDesktop from '../../component/admin/UniversalAdminTagHeaderDesktop';
import { fetchProfile } from '../../api/auth';

const adminTags = [
  {
    name: 'home',
    // dropDownList: ["subscriptions"],
  },
  {
    name: 'users',
  },
  {
    name: 'stores',
  },
  {
    name: 'payouts',
  },
  {
    name: 'products',
  },

  {
    name: 'tags & categories',
  },

  {
    name: 'carts&wishlist',
    // dropDownList: ["affiliates"],
  },
];

const userTags = [
  {
    name: 'store',
    // dropDownList: ["subscriptions"],
  },
  {
    name: 'profile',
  },
  {
    name: 'library',
  },
  // {
  //   name: "messages",
  // },
  {
    name: 'sales',
    dropDownList: ['SALES', 'PAYOUT SETTINGS'],
  },
  {
    name: 'settings',
    dropDownList: ['SETTINGS', 'AFFILIATES'],
  },
];

const MinimisedBanner = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    user_id: '',
    name: '',
    email: '',
    subscriptions: '',
    image: '',
    country: '',
    blocked: false,
  });
  const { id } = useParams();
  const [showBlockUserModal, setShowBlockUserModal] = useState(false);
  const [showUnblockUserModal, setShowUnblockUserModal] = useState(false);
  const [blockModalfadeOut, setBlockModalFadeOut] = useState(false);
  const [unblockModalfadeOut, setUnblockModalFadeOut] = useState(false);

  const [userStore, setUserStore] = useState(null);
  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchProfile({}))
      .unwrap()
      .then((response) => {
        console.log('res', response);
        //  setUserDetails({
        //    user_id: response?.user_id,
        //    name: response?.user_name,
        //    email: response?.user_email,
        //    phone: response?.user?.phone,
        //    description: response?.user?.description,
        //    birth_date: response?.user?.birth_date,
        //  });
        //  setUserSocialMedia(response.social_media);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  }, [dispatch]);
  const blockUser = async () => {
    setIsLoading(true);

    await dispatch(
      blockUserAction({
        blockData: {
          user_id: userDetails.user_id,
        },
      })
    )
      .unwrap()
      .then(() => {
        userDetails.blocked = true;
        toast.success('User Account has been blocked', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
        setBlockModalFadeOut((prev) => !prev);
        setTimeout(() => {
          setShowBlockUserModal(!showBlockUserModal);
        }, 400);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  const unblockUser = async () => {
    setIsLoading(true);

    await dispatch(
      unblockUserAction({
        unblockData: {
          user_id: userDetails?.user_id,
        },
      })
    )
      .unwrap()
      .then(() => {
        userDetails.blocked = false;
        toast.success('User Account has been blocked', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
        setUnblockModalFadeOut((prev) => !prev);
        setTimeout(() => {
          setShowUnblockUserModal(!showUnblockUserModal);
        }, 400);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(showAUserAction({ user_id: id }))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setUserDetails((prevState) => ({
            ...prevState,
            user_id: response.id,
            name: response.name,
            email: response.email,
            subscriptions: response.subscriptions,
            image: response.image,
            country: response.country,
            blocked: response.blocked,
          }));
          if (response.store) {
            setUserStore(response.store);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch, id]);

  return (
    <section className='admin__banner-container'>
      {showBlockUserModal && (
        <BlockUserModal
          toggleShowModal={() => setShowBlockUserModal(!showBlockUserModal)}
          selectedUser={userDetails}
          isLoading={isLoading}
          fadeOut={blockModalfadeOut}
          setFadeOut={setBlockModalFadeOut}
          blockUser={blockUser}
        />
      )}

      {showUnblockUserModal && (
        <UnblockUserModal
          toggleShowModal={() => setShowUnblockUserModal(!showUnblockUserModal)}
          selectedUser={userDetails}
          isLoading={isLoading}
          fadeOut={unblockModalfadeOut}
          setFadeOut={setUnblockModalFadeOut}
          unblockUser={unblockUser}
        />
      )}

      {isLoading ? (
        <LoadingState />
      ) : (
        <div className='admin__banner-container-inner'>
          <div className='admin__profile-banner-container'>
            {userStore && (
              <ChangeCoverBtn
                style={{ position: 'relative', top: '0', right: '0' }}
              />
            )}

            <ProfileUserAdmin
              merchandiseStore={userStore}
              user={userDetails}
              hasStore={userStore ? true : false}
            />
          </div>

          {userDetails?.country && (
            <div className='admin__profile-location-container'>
              <MdLocationOn />
              <span>{userDetails?.country}</span>
            </div>
          )}

          <div className='admin__fire-button-container'>
            <FireMailButton />
            <UserPurpleInnerStripeBtn
              onClick={
                userDetails?.blocked
                  ? () => setShowUnblockUserModal(!showUnblockUserModal)
                  : () => setShowBlockUserModal(!showBlockUserModal)
              }
              style={{ width: '10rem', padding: '1rem 3rem' }}
              text={
                userDetails?.blocked ? (
                  <>
                    <span>UNBLOCK</span>
                    <span>USER</span>
                  </>
                ) : (
                  <>
                    <span>BLOCK</span>
                    <span>USER</span>
                  </>
                )
              }
            />
          </div>
        </div>
      )}
    </section>
  );
};

const Expanded = () => {
  let toggleVisAdmin = true;
  const isMobile = useMediaQuery({ minWidth: 481, maxWidth: 768 });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const activeInpageHeaderAdmin = useSelector(
    (state) => state.userSubPageState.selectedAdmin
  ); //Enum types: store, profile, library, messages, sales, settings
  const activeInpageHeaderUser = useSelector(
    (state) => state.userSubPageState.selected
  );
  const [activeInpageHeader, setActiveInPageHeader] = useState(
    activeInpageHeaderAdmin
  );
  const [activeInpageTagHeader, setActiveInPageTagHeader] = useState(
    activeInpageHeaderUser || 'profile'
  );

  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    if (isMounted && !toggleVisAdmin) {
      dispatch(setSelectedSubPage({ selected: 'store' }));
    }
    return () => (isMounted = false);
  }, [dispatch, toggleVisAdmin]);

  return (
    <section className='admin__main-container'>
      <header>
        <NavBar
          pageType={'user'}
          loggedIn={true}
          shortSearch={true}
          style={{ paddingBottom: '0', paddingTop: '0' }}
          search={true}
        />
      </header>

      <main className='admin__main'>
        <div className='admin__separator'></div>
        {/*  */}

        {!isMobile ? (
          <div className='admin__tags-compose-container'>
            <UniversalTagHeaderDesktopAdmin
              style={{ zoom: '.8', marginBottom: '3rem' }}
              toggleVisAdmin={toggleVisAdmin}
              activeInpageHeader={activeInpageHeader}
              setActiveInPageHeader={setActiveInPageHeader}
              TagList={adminTags}
              onClick={() => navigate('/admin/product')}
            />
            <UniversalAdminTagHeaderDesktop
              style={{ zoom: '.8', marginBottom: '2rem' }}
              toggleVisAdmin={toggleVisAdmin}
              activeInpageHeader={activeInpageTagHeader}
              TagList={userTags}
              setActiveInPageHeader={setActiveInPageTagHeader}
              hide={
                activeInpageHeaderAdmin === 'payouts' ||
                activeInpageHeaderAdmin === 'products'
              }
            />
            {activeInpageHeaderAdmin !== 'products' && <MinimisedBanner />}
          </div>
        ) : (
          <TagheaderCompMobile
            toggleVisAdmin={toggleVisAdmin}
            activeInpageHeader={activeInpageHeader}
          />
        )}

        <AdminPageSelectComp
          inPageName={activeInpageHeaderUser}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </main>
    </section>
  );
};

export default Expanded;
