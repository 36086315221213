import { GrMail } from "react-icons/gr";
import { FaHeart, FaShoppingCart } from "react-icons/fa";
import HomeCard from "../home/HomeCard";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchSystemStatisticsByAdminAction } from "../../../api/admin";
import { toast } from "react-toastify";
import LoadingState from "../../loader/LoadingState";

const HomeAdmin = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  // const [revenuePercent, setRevenuePercent] = useState(10);

  // const [applicationSettings, setApplicationSettings] = useState({
  //   store_operating_status: 'available',
  //   multiple_outlet_checkout_status: 'available',
  //   pay_stack_payment_status: 'available',
  //   flutter_wave_payment_status: 'available',
  //   sales_commission_cap: 20,
  //   sales_commission_currency: 'USD',
  //   sales_commission_percentage: 10,
  // });
  const [systemStats, setSystemStats] = useState({
    revenue: 0,
    loooty_revenue: 0,
    balance: 0,
    users: 0,
    new_users: 0,
    affiliates: 0,
    unapproved_affiliates: 0,
    stores: 0,
    unapproved_stores: 0,
    cart_products: 0,
    collection_products: 0,
    products: 0,
    payout: 0,
    payouts_pending: 0,
  });

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(fetchSystemStatisticsByAdminAction())
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setSystemStats(response);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: "toast__message",
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  return (
    <div className="admin__home-container">
      {isLoading ? (
        <div
          style={{
            width: "80%",
            margin: "2rem auto",
            justifyContent: "center",
          }}
        >
          <LoadingState />
        </div>
      ) : (
        <>
          <section className="admin__home-inner-container">
            <HomeCard
              mainFigure={systemStats?.users}
              mainFigureDescription="Newly signed up"
              textHeaderLeft={"Users"}
              textHeaderRight={"Total"}
              childrenComp={<span>{systemStats?.users_since_two_weeks}</span>}
            />
            <HomeCard
              mainFigure={systemStats?.stores}
              mainFigureDescription="Store request"
              textHeaderLeft={"Stores"}
              textHeaderRight={"Total"}
              childrenComp={
                <div className="admin__home-card-child-container">
                  {systemStats?.stores_unapproved} <GrMail />
                </div>
              }
            />
            <HomeCard
              mainFigure={systemStats?.affiliates}
              mainFigureDescription="Closed"
              textHeaderLeft={"Affiliates"}
              textHeaderRight={"Total"}
              childrenComp={
                <span>
                  {systemStats?.affiliates_unapproved} {"(Today)"}
                </span>
              }
            />
            <HomeCard
              mainFigure={systemStats?.merchandise}
              mainFigureDescription="Wish-list & Cart"
              textHeaderLeft={"Products"}
              textHeaderRight={"Total"}
              childrenComp={
                <div className="admin__home-card-child-container">
                  <span className="admin__home-card-child-container">
                    <FaHeart />
                    {systemStats?.wishlist_merchandise}
                  </span>{" "}
                  <span className="admin__home-card-child-container">
                    <FaShoppingCart />
                    {systemStats?.cart_merchandise}
                  </span>
                </div>
              }
            />
            <HomeCard
              mainFigure={systemStats?.total_orders}
              textHeaderLeft={"Orders Placed"}
              textHeaderRight={"Total"}
              childrenComp={
                <div className="admin__home-card-child-container">
                  <span className="admin__home-card-child-container">
                    Pending: {systemStats?.pending_orders}
                  </span>{" "}
                  <span className="admin__home-card-child-container">
                    Closed: {systemStats?.closed_orders}
                  </span>
                </div>
              }
            />
            <HomeCard
              mainFigure={formatCurrency(systemStats?.order_paid_amount)}
              textHeaderLeft={"Revenue"}
              textHeaderRight={"Total Revenue Generated"}
              mainFigureDescription="Overall Sales"
              newActive={true}
            />
            <HomeCard
              mainFigure={formatCurrency(systemStats?.order_paid_sub_amount)}
              mainFigureDescription="Payment Request"
              textHeaderLeft={"Balance"}
              textHeaderRight={"Total Balance"}
              newActive={true}
              childrenComp={
                <div
                  style={{ color: "#FF9700" }}
                  className="admin__home-card-child-container"
                >
                  <GrMail />
                </div>
              }
            />

            {systemStats.custodial_accounts?.map((cust, index) => (
              <HomeCard
                key={index}
                mainFigure={formatCurrency(cust?.user?.wallet?.balance)}
                textHeaderLeft={<span className="capitalize">{cust.name}</span>}
                mainFigureDescription="Wallet Balance"
                newActive={true}
              />
            ))}

            {/* <HomeCard
              mainFigure={formatCurrency(systemStats?.order_paid_amount)}
              mainFigureDescription='Recent'
              textHeaderLeft={'Loooty Revenue'}
              newActive={true}
              childrenComp={null}
              revenuePercent={revenuePercent}
              setRevenuePercent={setRevenuePercent}
              applicationSettings={applicationSettings}
              setApplicationSettings={setApplicationSettings}
              isLoootyRevenue
            /> */}
          </section>

          <section className="admin__home-ra-container">
            <div className="admin__home-ra-inner" style={{ minHeight: "30vh" }}>
              <h3 className="admin__home-ra-header">Recent Activities</h3>
              {/* <div className="admin__home-ra-content">
                {recentActivitiesData
                  ? recentActivitiesData.map((rowItem, index) => {
                      return (
                        <ContentRow
                          key={index}
                          header={rowItem.header}
                          date={rowItem.date}
                          email={rowItem.email}
                          adminName={rowItem.adminName}
                          target={rowItem.target}
                          active={rowItem.active}
                        />
                      );
                    })
                  : null}
              </div> */}
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default HomeAdmin;
export function formatCurrency(amount) {
  // Convert to number or return 'Invalid amount'
  const numericAmount = parseFloat(amount);
  if (isNaN(numericAmount)) {
    return "Invalid amount";
  }
  const roundedAmount = numericAmount.toFixed(2);
  const formattedAmount =
    roundedAmount % 1 === 0 ? Math.floor(numericAmount) : roundedAmount;

  const numberWithCommas = formattedAmount
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const result = `$${numberWithCommas}`;

  return result;
}
