import { FaLocationArrow } from 'react-icons/fa';

const CreatorImg = '/assets/image/creatorDefaultImg.png';
const UserImg = '/assets/image/userDefaultImg.png';
const UserInfoProfile = ({ userData }) => {
  return (
    <div className='admin__sidebar-profile-container'>
      <div className='admin__sidebar-user-img-container'>
        <img
          className='admin__sidebar-user-img'
          src={
            userData?.picture ||
            (userData?.visibility === 'public' ? CreatorImg : UserImg)
          }
          alt='profile-img'
        />
      </div>
      <h3 className='capitalize'>{userData?.name}</h3>
      <h6>{userData?.subscriptions?.length || 0} subscriptions</h6>
      <div className='admin__sidebar-user-loc-info'>
        {userData?.profile?.country && (
          <>
            <FaLocationArrow /> {userData?.profile?.coutry}
          </>
        )}
      </div>
    </div>
  );
};

export default UserInfoProfile;
