import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchProfile,
  updateProfileAction,
  updateUserAction,
} from "../../api/auth";
import ProfileInput from "./ProfileInput";
import SocialMediaInput from "./SocialMediaInput";
import { toast } from "react-toastify";
import LoadingState from "../loader/LoadingState";

const Profile = ({ refetchHeadProfile }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState({});
  const [userDetails, setUserDetails] = useState({
    user_id: user?.user_id,
    name: user?.user_name,
    email: user?.user_email,
    phone: user?.user?.phone,
    description: user?.user?.description,
    birth_date: user?.user?.birth_date,
  });
  const [userSocialMedia, setUserSocialMedia] = useState([
    {
      name: "instagram",
      link: "",
    },
    {
      name: "twitter",
      link: "",
    },
    {
      name: "facebook",
      link: "",
    },
    {
      name: "linkedin",
      link: "",
    },
  ]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    dispatch(fetchProfile({}))
      .unwrap()
      .then((response) => {
        if (!isMounted) return;
        setUser(response);
        setUserDetails({
          user_id: response?.user_id,
          name: response?.user_name,
          email: response?.user_email,
          phone: response?.user?.phone,
          description: response?.user?.description,
          birth_date: response?.user?.birth_date,
        });

        if (response.social_media !== null) {
          setUserSocialMedia(response.social_media);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: "toast__message",
          });
        }
      });
    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  const refetchProfile = () => {
    setIsLoading(true);
    dispatch(fetchProfile({}))
      .unwrap()
      .then((response) => {
        setUser(response);
        setUserDetails({
          user_id: response?.user_id,
          name: response?.user_name,
          email: response?.user_email,
          phone: response?.user?.phone,
          description: response?.user?.description,
          birth_date: response?.user?.birth_date,
        });
        setUserSocialMedia(response.social_media);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: "toast__message",
          });
        }
      });
  };
  const handleLinkChange = (name, newLink) => {
    setUserSocialMedia((prevUserSocialMedia) => {
      return prevUserSocialMedia?.map((obj) => {
        if (obj.name === name) {
          return { ...obj, link: newLink };
        }
        return obj;
      });
    });
  };

  const updateUserProfile = async (obj) => {
    dispatch(
      updateUserAction({
        updateData: {
          user_id: user?.user.id,
          ...obj,
        },
        accessToken: user ? user.access_token : "",
      })
    ).then((res) => {
      refetchProfile();
      refetchHeadProfile();
      toast.success(res.payload.message, {
        autoClose: 2000,
        className: "toast__message",
      });
    });
  };

  const updateUserSocialMedia = async () => {
    dispatch(
      updateProfileAction({
        updateData: {
          user_id: user.id,
          social_media: userSocialMedia,
        },
        accessToken: user ? user.access_token : "",
      })
    );
    toast.success("User profile updated", {
      autoClose: 2000,
      className: "toast__message",
    });
  };
  const dateParts =
    userDetails?.birth_date && userDetails?.birth_date.split("-");
  const currentYear =
    dateParts && dateParts.length === 2 && new Date().getFullYear();
  const formattedDate =
    dateParts &&
    dateParts.length === 2 &&
    `${currentYear}-${dateParts[0]}-${dateParts[1]}`;
  return (
    <section className="user__main-profile-container">
      {isLoading ? (
        <LoadingState />
      ) : (
        <form className="user__main-profile-form">
          <ProfileInput
            onClick={async () =>
              await updateUserProfile({ name: userDetails.name })
            }
            value={userDetails.name}
            onChange={(e) => {
              setUserDetails((old) => ({ ...old, name: e.target.value }));
            }}
            labelName={"USERNAME"}
          />
          <ProfileInput
            onClick={(e) =>
              dispatch(
                updateUserAction({
                  updateData: userDetails,
                  accessToken: user ? user.access_token : "",
                })
              )
            }
            value={userDetails.email}
            labelName={"EMAIL"}
          />

          <ProfileInput
            onClick={async () =>
              await updateUserProfile({ phone: userDetails.phone })
            }
            onChange={(e) =>
              setUserDetails((old) => ({ ...old, phone: e.target.value }))
            }
            labelName={"PHONE"}
            value={userDetails.phone}
          />
          <ProfileInput
            onClick={async () =>
              await updateUserProfile({ description: userDetails.description })
            }
            value={userDetails.description}
            onChange={(e) =>
              setUserDetails((old) => ({ ...old, description: e.target.value }))
            }
            labelName={"BIO"}
          />
          <ProfileInput
            onClick={async () =>
              await updateUserProfile({ birth_date: userDetails.birth_date })
            }
            type={"date"}
            value={formattedDate}
            onChange={(e) => {
              setUserDetails((old) => ({ ...old, birth_date: e.target.value }));
            }}
            labelName={"DOB"}
          />

          <SocialMediaInput
            onSave={updateUserSocialMedia}
            value={userSocialMedia || []}
            onChange={handleLinkChange}
            labelName={"SOCIAL LINKS"}
          />
        </form>
      )}
    </section>
  );
};

export default Profile;
