import AdminUserLibrary from './AdminUserLibrary';
import AdminUserProfile from './AdminUserProfile';
import AdminUserStore from './AdminUserStore';
import AdminUserBalance from './inpage/AdminUserBalance';
import AdminUserSales from './inpage/AdminUserSales';
import AdminUserSettings from './inpage/AdminUserSettings';

const AdminPageSelectComp = ({ inPageName }) => {
  switch (inPageName.toLowerCase()) {
    case 'profile':
      return <AdminUserProfile />;

    case 'store':
      return <AdminUserStore />;

    case 'library':
      return <AdminUserLibrary />;

    case 'sales':
      return <AdminUserSales />;

    case 'payout settings':
      return <AdminUserBalance />;

    case 'settings':
      return <AdminUserSettings />;

    default:
      return <AdminUserProfile />;
  }
};

export default AdminPageSelectComp;
