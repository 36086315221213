import { useContext, useState } from 'react';
import { RegisterContext } from './context/registerContext';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import LoootyLoader from '../loader/loootyLoader';
import { resetPasswordLink } from '../../api/auth';

const web_url = process.env.REACT_APP_WEB_URL || '';

const ForgotPasswordModal = () => {
  const [fadeOut, setFadeOut] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [showRegModal, setShowRegModal] = useContext(RegisterContext);

  const [errors, setErrors] = useState({
    old_password: false,
    new_password: false,
    new_password_confirmation: false,
  });
  // const facebookWebSignIn = () => {
  //   let data = {
  //     client_id: process.env.REACT_APP_FACEBOOK_APP_ID,
  //     nonce:(Math.random() + 1).toString(36),
  //     redirect_uri:`${web_url}/api/auth/socialite/facebook/callback`,
  //     response_mode:'fragment',
  //     response_type:'code+id_token',
  //     scope: '',
  //     state:'state_parameter_passthrough_value',
  //   }

  //   return `https://developer.facebook.com/auth/authorize?client_id=${data.client_id}&nonce=${data.nonce}&redirect_uri=${data.redirect_uri}&response_mode=${data.response_mode}&response_type=${data.response_type}&scope=${data.scope}&state=${data.state}`;
  // }

  const user = useSelector((state) => state.user.user);
  const [userDetails, setUserDetails] = useState({
    email: user?.email,
  });
  const sendResetMail = async (e) => {
    e.preventDefault();
    if (!userDetails.email) {
      toast.error('Please input email', {
        autoClose: 2000,
        className: 'toast__message',
      });
      return;
    }
    setIsLoading(true);
    await dispatch(
      resetPasswordLink({
        resetData: {
          email: userDetails.email,
          reset_form_link: `${web_url}/reset-password`,
        },
      })
    )
      .then((res) => {
        toast.success('A link has been sent to your email', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
        setShowRegModal({
          ...showRegModal,
          forgotPassword: false,
          login: false,
        });
        localStorage.setItem('loootymail', userDetails.email);
      })
      .catch((err) => {
        console.log({ err });
        toast.error(err || 'Error! Try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };
  return (
    <div
      className={`reg__sign-up-overlay ${fadeOut ? 'lgn__fade-out-anim' : ''}`}
    >
      <section className='reg__sign-up-modal'>
        <div className='reg__close-btn-container'>
          <div
            onClick={(e) => {
              setFadeOut((prev) => !prev);
              setTimeout(() => {
                setShowRegModal({ ...showRegModal, forgotPassword: false });
              }, 600);
            }}
            className='reg__close-btn'
          >
            <span className='reg__close-btn-text'>X</span>
          </div>
        </div>
        <div className='reg__loooty-innitials-container'>
          <div className='reg_logo-container'></div>
          <div className='reg__loooty-about'>
            <div>Welcome back</div>
          </div>

          <ul className='reg__loooty-features'>
            <li className='reg__loooty-features-item'>
              Advance search and filtering options
            </li>
            <li className='reg__loooty-features-item'>
              Add art work to custom selection
            </li>
            <li className='reg__loooty-features-item'>
              Never miss a new artwork with tag
            </li>
            <li className='reg__loooty-features-item'>
              Uploader and collection subscription
            </li>
          </ul>
        </div>

        <div className='reg__sign-up-forms-container'>
          <form
            autoComplete='off'
            onSubmit={(e) => sendResetMail(e)}
            className='reg__sign-up-form'
          >
            <div>
              <div className='reg__form-group-container'>
                <p style={{ color: '#b0b0bf' }}>
                  Enter the email you used to sign up on LOOOTY.
                </p>
                <div
                  style={{
                    border: errors.email
                      ? '.2rem solid #df4759'
                      : '.2rem solid #2B2B38',
                  }}
                  className='reg__form-group'
                >
                  <input
                    autoComplete='off'
                    type='text'
                    onFocus={() => {
                      setErrors((old) => ({ ...old, email: false }));
                    }}
                    onChange={(e) =>
                      setUserDetails((old) => ({
                        ...old,
                        email: e.target.value,
                      }))
                    }
                    value={userDetails.email}
                    className='reg__form-control'
                    placeholder='Enter email'
                  />
                </div>
              </div>
              <div
                onClick={sendResetMail}
                className='reg__login-btn'
                style={{ marginTop: '2rem', padding: '1rem 2rem' }}
              >
                <span className='reg__login-btn-text'>
                  {isLoading ? <LoootyLoader /> : 'Send Reset Link'}
                </span>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default ForgotPasswordModal;
