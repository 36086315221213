// import emailjs from "@emailjs/browser";
import { useContext, useState, useEffect } from "react";
import { FaDiscord, FaTelegram, FaTimes, FaTwitter } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { requestAccess } from "../api/coming";
import { RegisterContext } from "../component/auth/context/registerContext";
import { OrangeBtn } from "../component/landing/slogan";
import LoootyLoader from "../component/loader/loootyLoader";
import NavBar from "../component/navbar/navbar";
import OrderItem from "../component/user/OrderItem";
import { joinWaitlist } from "../api/user";

const BattleCryBanner = ({ id }) => {
  const mobile = useMediaQuery({ minWidth: 320, maxWidth: 768 });
  const setShowRegModal = useContext(RegisterContext);
  const [showImage1, setShowImage1] = useState(true);

  const toggleImages = () => {
    setShowImage1((prevShowImage) => !prevShowImage);
  };

  useEffect(() => {
    const interval = setInterval(toggleImages, 5000); // 5000 milliseconds = 5 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div id={id} className="coming__soon-battlecry-container">
      =
      <img
        className={
          mobile
            ? "coming__soon-battlecry-img mobile"
            : "coming__soon-battlecry-img"
        }
        src={
          showImage1
            ? "/assets/image/comingsoon/coming-soon-hero-text.svg"
            : "/assets/image/comingsoon/hometext2.svg"
        }
        alt="battle cry"
      />
      <div className="comming__soon-hero-text-box">
        <p className="intro">
          Find the perfect 2D, 3D, Plugins, Tools, AR/VR Assets, Scripts,
          Sounds, and VFX Packs to enhance your animation or game development
          project.
        </p>

        <div className="coming__soon-action-btn-box">
          <OrangeBtn
            text="Join Waitlist"
            style={{ padding: "1.1rem 6.5rem" }}
            onClick={() => {
              setShowRegModal[1]((old) => ({ ...old, isComingSoonOpen: true }));
            }}
          />
        </div>
        {/* <p className="outro">
          be the first to know when we launch in June
        </p> */}
      </div>
    </div>
  );
};

const NewSupporters = ({ id }) => {
  return (
    <div id={id} className="coming__soon-new-supporters-container">
      <section className="coming__soon-supporters-container-inner">
        <div className="coming__soon-tag-button-container">
          <button className="coming__soon-tag-button">
            <span>Supporters</span>
          </button>
        </div>
        <div className="coming__soon-new-supporters-box">
          <div className="coming__soon-supporter-card">
            <img
              className="coming__soon-brand-image"
              id="orange-landing-logo"
              src="/assets/image/comingsoon/orange.png"
              alt="brands"
            />
          </div>
          <div className="coming__soon-supporter-card">
            <img
              className="coming__soon-brand-image"
              src="/assets/image/comingsoon/new-sizes/Landscape-logo-white-orange.svg"
              alt="brands"
            />
          </div>
          <div className="coming__soon-supporter-card">
            <img
              className="coming__soon-brand-image"
              id="liqair-landing-logo"
              src="/assets/image/comingsoon/liqair.png"
              alt="brands"
            />
          </div>

          <div className="coming__soon-supporter-card">
            <img
              className="coming__soon-brand-image"
              id="konnadex-landing-logo"
              src="/assets/image/comingsoon/new-sizes/konnadex.svg"
              alt="brands"
            />
          </div>
          <div className="coming__soon-supporter-card">
            <img
              className="coming__soon-brand-image"
              id="swift-landing-logo"
              src="/assets/image/comingsoon/new-sizes/swiftxr-logo.svg"
              alt="brands"
            />
          </div>
        </div>
      </section>

      <section className="coming__soon-assets-section">
        <h2>AFRICAN ASSETS</h2>
        <p className="coming__soon-assets-section-text">
          Loooty is the only marketplace that puts African cultural assets at
          its core. We connect you to the best African creators who offer
          high-quality assets and freelance services for animation and game
          development studios. Our goal is to onboard every animation and game
          development studio in Africa before targetting the global market.
        </p>

        <div className="coming__soon-assets-container">
          <div className="coming__soon-assets-box">
            <div className="coming__soon-assets-img-box">
              <img alt="asset" src="/assets/image/comingsoon/Weapon_01.svg" />
            </div>
            <div className="coming__soon-asset-name-box">3D Nzappa</div>
          </div>
          <div className="coming__soon-assets-box">
            <div className="coming__soon-assets-img-box">
              <img alt="asset" src="/assets/image/comingsoon/Weapon_02.svg" />
            </div>
            <div className="coming__soon-asset-name-box">3D Masai Shield</div>
          </div>
          <div className="coming__soon-assets-box">
            <div className="coming__soon-assets-img-box">
              <img alt="asset" src="/assets/image/comingsoon/Weapon_03.svg" />
            </div>
            <div className="coming__soon-asset-name-box">3D Shotel Sword</div>
          </div>
          <div className="coming__soon-assets-box">
            <div className="coming__soon-assets-img-box">
              <img alt="asset" src="/assets/image/comingsoon/Weapon_04.svg" />
            </div>
            <div className="coming__soon-asset-name-box">3D Takouba Sword</div>
          </div>
        </div>
      </section>
    </div>
  );
};

const Features = ({ id }) => {
  return (
    <div id={id} className="coming__soon-features-container">
      <section className="coming__soon-assets-section">
        <h2>FEATURES</h2>

        <div className="coming__soon-features-card-container">
          <div className="coming__soon-features-card">
            <p>
              Wide range of 2D, 3D, Plugins, Tools, AR/VR Assets, Scripts,
              Sounds, and VFX Packs to choose from
            </p>
          </div>
          <div className="coming__soon-features-card">
            <p>
              Handpicked African cultural assets created by talented African
              creators
            </p>
          </div>
          <div className="coming__soon-features-card">
            <p>Affordable pricing with creators setting their own costs</p>
          </div>
          <div className="coming__soon-features-card">
            <p>
              Reduced sales tax rate, one of the lowest in the CG Marketplace
            </p>
          </div>
          <div className="coming__soon-features-card">
            <p>
              Multiple payment systems supported by every African country
              including Paystack, Flutterwave, Stripe and Paypal
            </p>
          </div>
          <div className="coming__soon-features-card">
            <p>
              Anti-scam policies in place to ensure a safe and legitimate
              transaction
            </p>
          </div>
          <div className="coming__soon-features-card">
            <p>
              Quality control for assets and services offered on our platform
            </p>
          </div>
          <div className="coming__soon-features-card">
            <p>
              Unbiased customer service team to handle disputes between creators
              and customers
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

const NewFooterInner = () => {
  const setShowRegModal = useContext(RegisterContext);
  return (
    <div className="coming__soon-footer-innner">
      <div className="coming__soon-footer-innner-btn-box">
        <OrangeBtn
          text="Join Waitlist"
          style={{
            padding: "1.3rem 6rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            fontSize: "1.3rem",
            fontWeight: "800",
          }}
          onClick={() => {
            setShowRegModal[1]((old) => ({ ...old, isComingSoonOpen: true }));
          }}
        />
      </div>

      <p className="coming__soon-footer-innner-text">
        Join the Loooty community today and discover the beauty of authentic
        African CG assets.
      </p>

      <div className="coming__soon-social-medias">
        <div className="coming__soon-social-media-inner">
          <a
            href="https://t.me/loootystore"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span>
              <FaTelegram />
            </span>
          </a>
          <a
            href="https://twitter.com/loootystore?t=nfX-NSZrbTszIxoKLx-7kA&s=09"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span>
              <FaTwitter />
            </span>
          </a>
          <a
            href="https://instagram.com/loootystore?igshid=NTc4MTIwNjQ2YQ=="
            rel="noopener noreferrer"
            target="_blank"
          >
            <span>
              <RiInstagramFill />
            </span>
          </a>
          <a
            href="https://discord.gg/ubJmSxEQhf"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span>
              <FaDiscord />
            </span>
          </a>
        </div>
        <p>
          Copyright &copy; {new Date().getFullYear()} Loooty. All Rights
          Reserved
        </p>
      </div>
      {/* <LoootyLogo style={{ width: "15rem", height: "10rem", zIndex:0 }} /> */}
    </div>
  );
};

const NewFooter = ({ id }) => {
  return (
    <div id={id} className="coming__soon-footer">
      <div className="coming__soon-footer-inner">
        <NewFooterInner />
      </div>
    </div>
  );
};

const FormControlTitle = ({
  style,
  text = "Nickname",
  isCompulsory = true,
}) => {
  return (
    <div className="coming__soon-form-control-title-container">
      <h3 style={style} className="coming__soon-form-control-title">
        <span>
          {text}{" "}
          {isCompulsory && (
            <span style={{ fontSize: "1.1rem", color: "#df4759" }}>*</span>
          )}
        </span>
      </h3>
    </div>
  );
};

const RequestAccessFormControl = ({
  error,
  onClick,
  isCompulsory,
  type,
  style,
  value,
  onChange,
  titleText,
  placeholder = "Stacky",
}) => {
  return (
    <div className="coming__soon-form-group">
      <FormControlTitle isCompulsory={isCompulsory} text={titleText} />
      <div
        style={{ border: `.25rem solid ${error ? "#df4759" : "#4D4D63"} ` }}
        className="coming__soon-form-control-skew-container"
      >
        <input
          onClick={onClick}
          required={true}
          type={type}
          value={value}
          onChange={onChange}
          style={style}
          placeholder={placeholder}
          className="coming__soon-form-control"
        />
      </div>
    </div>
  );
};

const RequestAccessModal = () => {
  const [requestDetails, setRequestAccess] = useState({
    timestamp: "",
    nickname: "",
    country: "",
    email: "",
    wouldSell: null,
    workTeam: "No, Am an Individual",
    tags: [],
    hearAbout: "",
    portfolio: "",
  });
  const { nickname, country, email } = requestDetails;
  const emptyFields =
    nickname === "" ||
    country === "" ||
    email === "" ||
    requestDetails.wouldSell === "";
  const [errors, setErrors] = useState({
    timestamp: false,
    nickname: false,
    country: false,
    email: false,
    wouldSell: "Yes",
    portfolio: false,
  });
  const [loading, setLoading] = useState(false);
  const [proceed, setProceed] = useState(true);
  console.log(requestDetails.wouldSell);
  let templateParams = {
    from_name: "Loooty Admin",
    to_name: "User",
    to_email: "veryjett@gmail.com",
    message: "Thank you for being part of the early launchers see you soon!",
  };

  const errorHandler = () => {
    setProceed(true);
    if (!requestDetails.country || requestDetails.country === "") {
      setErrors((old) => ({ ...old, country: true }));
      setProceed(false);
    }

    if (!requestDetails.nickname || requestDetails.nickname === "") {
      setErrors((old) => ({ ...old, nickname: true }));
      setProceed(false);
    }

    if (!requestDetails.email || requestDetails.email === "") {
      setErrors((old) => ({ ...old, email: true }));
      setProceed(false);
    }

    if (requestDetails.wouldSell === null) {
      toast.error("Please select if you would like to sell on Loooty", {
        autoClose: 2000,
        className: "toast__message",
      });
    }

    if (!proceed) {
      toast.error("Selected fields are required!", {
        autoClose: 2000,
        className: "toast__message",
      });
    }
  };

  const handleRequestAccess = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let finalData = requestDetails;
      finalData.timestamp = new Date();
      errorHandler();
      if (proceed) {
        setRequestAccess((old) => ({
          ...old,
          timestamp: new Date(),
          tags: JSON.stringify(old.tags),
        }));
        let stringifiedTags = "";
        for (let i = 0; i < finalData.tags.length; i++) {
          stringifiedTags += `${finalData.tags[i]}, `;
        }
        finalData.tags = stringifiedTags;
        await requestAccess(finalData);
        await handleDetailsSubmit();
        toast.success("Request was submitted successfully, Thank you!", {
          autoClose: 2000,
          className: "toast__message",
        });
        templateParams.to_email = finalData.email;
        templateParams.to_name = finalData.nickname;
        setShowRegModal[1]((old) => ({ ...old, isComingSoonOpen: false }));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const setShowRegModal = useContext(RegisterContext);

  const handleDetailsSubmit = async () => {
    // e.preventDefault();
    setLoading(true);

    await joinWaitlist({ ...requestDetails })
      .then(async (response) => {
        if (response) {
          setLoading(false);
          setShowRegModal[1]((old) => ({ ...old, isComingSoonOpen: false }));
        }
      })
      .catch((err) => {
        setLoading(false);

        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: "toast__message",
          });
        }
      });
  };
  return (
    <div
      onClick={() =>
        setShowRegModal[1]((old) => ({ ...old, isComingSoonOpen: false }))
      }
      className="coming__soon-new-request-access-modal-overlay"
    >
      <section
        onClick={(e) => e.stopPropagation()}
        className="coming__soon-new-request-access-modal"
      >
        <div
          onClick={() => {
            setShowRegModal[1]((old) => ({ ...old, isComingSoonOpen: false }));
          }}
          className="coming__soon-new-close-btn"
        >
          <span>
            <FaTimes />
          </span>
        </div>
        <div className="coming__soon-request-access-modal-inner">
          <h3
            style={{
              fontWeight: "900",
              fontSize: "1.5rem",
            }}
          >
            JOIN WAITLIST
          </h3>
          <form className="coming__soon-request-access-form">
            <div className="coming__soon-first-row">
              <RequestAccessFormControl
                onClick={() =>
                  setErrors((old) => ({ ...old, nickname: false }))
                }
                error={errors.nickname}
                placeholder="Adams"
                value={requestDetails.nickname}
                onChange={(e) => {
                  setProceed(true);
                  setRequestAccess((old) => ({
                    ...old,
                    nickname: e.target.value,
                  }));
                }}
                titleText={"Nickname"}
              />

              <RequestAccessFormControl
                onClick={() => setErrors((old) => ({ ...old, country: false }))}
                error={errors.country}
                placeholder="eg, South Africa"
                value={requestDetails.country}
                onChange={(e) => {
                  setProceed(true);
                  setRequestAccess((old) => ({
                    ...old,
                    country: e.target.value,
                  }));
                }}
                titleText={"Country"}
              />

              <RequestAccessFormControl
                onClick={() => setErrors((old) => ({ ...old, email: false }))}
                error={errors.email}
                placeholder="eg, hackone@gmail.com"
                value={requestDetails.email}
                type="email"
                onChange={(e) => {
                  setProceed(true);
                  setRequestAccess((old) => ({
                    ...old,
                    email: e.target.value,
                  }));
                }}
                titleText={"Email"}
              />
            </div>

            <div className="coming__soon-second-row">
              <FormControlTitle text="Would you like to sell on Loooty?" />
              <div className="coming__soon-second-row-inner">
                <div className="coming__soon-navbar-request-btn coming__soon-sell-on-looty-box">
                  <OrderItem
                    itemTitle={"Yes"}
                    isChecked={requestDetails?.wouldSell === true}
                    setIsChecked={() =>
                      setRequestAccess((old) => ({
                        ...old,
                        wouldSell: true,
                      }))
                    }
                  />
                </div>
                <div className="coming__soon-navbar-request-btn coming__soon-sell-on-looty-box">
                  <OrderItem
                    itemTitle={"No"}
                    isChecked={requestDetails?.wouldSell === false}
                    setIsChecked={() =>
                      setRequestAccess((old) => ({
                        ...old,
                        wouldSell: false,
                      }))
                    }
                  />
                </div>
              </div>
            </div>

            {requestDetails?.wouldSell === true && (
              <div className="coming__soon-first-row">
                <RequestAccessFormControl
                  placeholder="eg, www.myportfolio.com"
                  value={requestDetails.portfolio}
                  isCompulsory={false}
                  onChange={(e) =>
                    setRequestAccess((old) => ({
                      ...old,
                      portfolio: e.target.value,
                    }))
                  }
                  titleText={"Portfolio Link"}
                />
              </div>
            )}

            <button
              type="submit"
              disabled={emptyFields}
              onClick={(e) => handleRequestAccess(e)}
              className="coming__soon-navbar-request-btn orange"
            >
              <span>{loading ? <LoootyLoader /> : "JOIN"}</span>
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

const ComingSoon = () => {
  const showRegModal = useContext(RegisterContext);

  if (typeof window !== "undefined") localStorage.clear();

  return (
    <section className="coming__soon-container">
      {showRegModal[0].isComingSoonOpen && <RequestAccessModal />}

      <NavBar isComingSoon={true} pageType={"coming soon"} />

      <BattleCryBanner id={"battlecry"} />
      <div style={{ position: "relative" }}>
        <img
          src="/assets/image/comingsoon/BGPattern.png"
          className="coming__soon-footer-image"
          alt=""
        />

        <NewSupporters id={"new-supportes"} />

        <section className="coming__soon-image-background">
          <Features id={"features"} />
        </section>

        <NewFooter id="new-footer" />
      </div>
    </section>
  );
};

export default ComingSoon;
