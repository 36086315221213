import AllNotificationPage from "../notify-inpage/AllNotificationPage";

const PageSelectCompNotifier = ({ inPageName }) => {
    switch (inPageName) {
      case "all (3)":
        return <AllNotificationPage />;
      
  
      default:
        return <AllNotificationPage />;
    }
  };

  export default PageSelectCompNotifier
