import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LoadingState from "../../loader/LoadingState";
import {
  BalanceAnnounceCard,
  DropDownBox,
  IrregularBtn,
  StatisticsBar,
  getDateRange,
} from "../../user/Sales";
import { useNavigate, useParams } from "react-router-dom";
import WithdrawalModal from "../../modals/sales/WithdrawalModal";
import { FaPaypal } from "react-icons/fa";
import { GrStripe } from "react-icons/gr";
import { ContentRowSales } from "../home/ContentRow";
import { OrangeBtn } from "../../landing/slogan";
import {
  fetchStoreSalesStatsAsAdminAction,
  fetchStoreStatsAsAdminAction,
} from "../../../api/admin";
import { toast } from "react-toastify";

const StatisticsBox = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState("12 months");
  const [salesStats, setSalesStats] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    let isMounted = true;
    let dateRange = getDateRange(selectedDateRange);

    setIsLoading(true);

    dispatch(
      fetchStoreSalesStatsAsAdminAction({
        storeData: {
          user_id: id,
          ...dateRange,
        },
      })
    )
      .then((res) => {
        if (isMounted) {
          setSalesStats(res?.payload?.sales);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        isMounted && setIsLoading(false);
        toast.error(
          err.payload?.response?.data?.message || "Error try again later",
          {
            autoClose: 2000,
            className: "toast__message",
          }
        );
      });

    return () => (isMounted = false);
  }, [dispatch, id, selectedDateRange]);

  return (
    <div className="user__sales-statistics-box">
      {isLoading ? (
        <LoadingState />
      ) : (
        <div>
          <div className="user__sales-statistics-box-header">
            <h1 className="user__sales-header-text">Sales of your product</h1>
            <DropDownBox
              selected={selectedDateRange}
              setSelected={setSelectedDateRange}
            />
          </div>
          <div className="user__sales-statistics-box-body">
            <div className="user__sales-stats-side">
              <span className="user__sales-stats-side-count"> 1</span>
              <span className="user__sales-stats-side-count"> 2</span>
            </div>
            <div className="user__sales-statistics-box-body-inner">
              {salesStats && Array.isArray(salesStats) ? (
                salesStats?.map((item) => {
                  return (
                    <StatisticsBar
                      key={item.date}
                      percentage={item.count}
                      date={item.date}
                    />
                  );
                })
              ) : salesStats !== null && typeof salesStats === "object" ? (
                <StatisticsBar
                  key={Object.values(salesStats)?.date}
                  percentage={Object.values(salesStats)["count"]}
                  date={Object.values(salesStats)["date"]}
                />
              ) : (
                <div
                  className="user__no-product-div"
                  style={{ width: "auto", margin: "auto", height: "auto" }}
                >
                  Nothing to see here yet!
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const AdminUserSales = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggleFiatCrypto, setToggleFiatCrypto] = useState("fiat");
  const [selectedPaymentGateway, setSelectedPaymentGateway] =
    useState("paypal");
  const [isLoading, setIsLoading] = useState(false);
  const [storePayoutStats, setStorePayoutStats] = useState(null);
  const [showWithdrawalModal, setShowWithdrawalModal] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    let dateRange = getDateRange("1 month");

    dispatch(
      fetchStoreStatsAsAdminAction({
        storeData: {
          user_id: id,
          ...dateRange,
        },
      })
    )
      .then((res) => {
        if (isMounted) {
          setStorePayoutStats(res?.payload?.stats);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        isMounted && setIsLoading(false);
        toast.error(
          err.payload?.response?.data?.message || "Error try again later",
          {
            autoClose: 2000,
            className: "toast__message",
          }
        );
      });

    return () => (isMounted = false);
  }, [dispatch, id]);

  return (
    <section className="user__sales">
      {showWithdrawalModal && (
        <WithdrawalModal
          toggleShowModal={() => setShowWithdrawalModal(false)}
        />
      )}

      <div className="user__sales-sidebar">
        <section className="user__sales-sidebar-header">
          <div
            onClick={(e) => setToggleFiatCrypto("fiat")}
            className={`user__sales-sidebar-header-item ${
              toggleFiatCrypto === "fiat" && "user__sales-shi-selected"
            }`}
          >
            FIAT
          </div>

          <div
            onClick={(e) => setToggleFiatCrypto("crypto")}
            className={`user__sales-sidebar-header-item ${
              toggleFiatCrypto === "crypto" && "user__sales-shi-selected"
            }`}
          >
            CRYPTO
          </div>
        </section>

        <section className="user__sales-sidebar-body">
          <div
            onClick={(e) => setSelectedPaymentGateway("paypal")}
            className={`user__sales-sidebar-body-item ${
              selectedPaymentGateway === "paypal" &&
              "user__sales-sidebar-bi-selected"
            }`}
          >
            <span>
              <FaPaypal /> Sales&nbsp;Report
            </span>
          </div>

          <div
            onClick={(e) => setSelectedPaymentGateway("stripe")}
            className={`user__sales-sidebar-body-item ${
              selectedPaymentGateway === "stripe" &&
              "user__sales-sidebar-bi-selected"
            }`}
          >
            <span>
              <GrStripe /> Affiliate&nbsp;Contract
            </span>
          </div>
        </section>
      </div>

      <div className="user__sales-main-content">
        <section className="user__sales-mc-header">
          <div className="user__sales-mc-left">Notice:</div>
          <div className="user__sales-mc-right">
            We send out payment every 1<sup>st</sup> and 15<sup>th</sup> of
            every month You must have a balance of $20 or more at the time to be
            eligible.
            <p>
              if you do not meet the minimum balance, no worries, we will try
              again next time.
            </p>
          </div>
        </section>

        <section className="user__sales-balance-body">
          <section className="user__sales-balance-first-row">
            <div className="user__sales-balance-announcer-container">
              <BalanceAnnounceCard
                title="Daily Average:"
                value={isLoading ? "..." : storePayoutStats?.daily_average || 0}
              />
              <BalanceAnnounceCard
                title="Past 30 Days:"
                value={
                  isLoading ? "..." : storePayoutStats?.past_30days_orders || 0
                }
              />
              <BalanceAnnounceCard
                title="Total:"
                value={isLoading ? "..." : storePayoutStats?.total_orders || 0}
                purple={true}
              />
            </div>
            <StatisticsBox />
          </section>

          {isLoading ? (
            <LoadingState />
          ) : (
            <section className="user__sales-balance-second-row">
              <div className="user__sales-next-payout">
                {" "}
                <h3 className="user__sales-next-payout-text">NEXT PAYOUT</h3>
              </div>

              <div className="admin__home-ra-inner">
                <h3 className="admin__home-ra-header">
                  Sales and activities from October 1st to October 15th
                </h3>
                {storePayoutStats ? (
                  <div className="admin__home-ra-content">
                    <ContentRowSales
                      header="Sales"
                      date={`$ ${storePayoutStats?.first_transactions?.sales}`}
                      target={`$ ${storePayoutStats?.second_transactions?.sales}`}
                    />
                    <ContentRowSales
                      header="Marketplace fees"
                      date={`$ ${storePayoutStats?.first_transactions?.marketplace_fee}`}
                      target={`$ ${storePayoutStats?.second_transactions?.marketplace_fee}`}
                    />
                    <ContentRowSales
                      header="Affiliate fees"
                      date={`$ ${storePayoutStats?.first_transactions?.affiliate_fees}`}
                      target={`$ ${storePayoutStats?.second_transactions?.affiliate_fees}`}
                    />
                  </div>
                ) : null}
              </div>
              <div className="user__sales-next-payouts">
                <BalanceAnnounceCard
                  title="Processing Orders"
                  value={storePayoutStats?.processing_orders}
                />
                <BalanceAnnounceCard
                  title="Ready for Payout"
                  value={storePayoutStats?.payout_amount}
                  yellow={true}
                />
                <div className="user__sales-payout-withdraw-btn">
                  <OrangeBtn
                    text="WITHDRAW"
                    style={{ padding: "1rem 5.5rem" }}
                    onClick={() => setShowWithdrawalModal(true)}
                  />
                  <IrregularBtn
                    onClick={() => navigate(`/user/payout settings`)}
                  />
                </div>
              </div>
            </section>
          )}
        </section>
      </div>
    </section>
  );
};

export default AdminUserSales;
