import { useState } from "react";
import LoootyLoader from "../../loader/loootyLoader";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

import {
  fetchMyWalletAction,
  sendPayoutRequestAction,
} from "../../../api/auth";
import { useEffect } from "react";

const WithdrawalModal = ({ toggleShowModal }) => {
  let user = null;
  if (typeof window !== "undefined") {
    const userCookie = Cookies.get("user");
    user = userCookie ? JSON.parse(userCookie) : null;
  }
  console.log(user, "user");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fadeOut, setFadeOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(null);
  const [chosenPaymentMethod, setChosenPaymentMethod] = useState(null);
  const [balanceAmount, setBalanceAmount] = useState(0);
  const sendWithdrawalRequest = async () => {
    if (!amount || amount < 20) {
      toast.error("Invalid amount", {
        autoClose: 2000,
        className: "toast__message",
      });
      return;
    }

    if (!chosenPaymentMethod) {
      toast.error("Choose a payment option", {
        autoClose: 2000,
        className: "toast__message",
      });
      return;
    }

    setIsLoading(true);

    await dispatch(
      sendPayoutRequestAction({
        payoutData: {
          amount: +amount,
          payout_type: "fiat",
          user_id: user?.id,
        },
      })
    )
      .unwrap()
      .then(() => {
        toast.success("Withdrawal request sent", {
          autoClose: 2000,
          className: "toast__message",
        });
        setIsLoading(false);
        setBalanceAmount((prev) => prev - amount);
        setFadeOut((prev) => !prev);
        setTimeout(() => {
          toggleShowModal();
        }, 600);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Error! Try again later", {
          autoClose: 2000,
          className: "toast__message",
        });
        setIsLoading(false);
      });
  };
  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(fetchMyWalletAction())
      .then((res) => {
        if (isMounted) {
          const bankDetails = res?.payload?.bank_details;

          setBalanceAmount(res?.payload?.balance);
          setChosenPaymentMethod(bankDetails?.email);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        isMounted && setIsLoading(false);
        toast.error(
          err.payload?.response?.data?.message || "Error try again later",
          {
            autoClose: 2000,
            className: "toast__message",
          }
        );
      });

    return () => (isMounted = false);
  }, [dispatch]);

  return (
    <div
      className={`reg__sign-up-overlay ${fadeOut ? "lgn__fade-out-anim" : ""}`}
    >
      <section className="reg__sign-up-modal user__withdrawal-modal">
        <div className="reg__close-btn-container">
          <div
            onClick={(e) => {
              setFadeOut((prev) => !prev);
              setTimeout(() => {
                toggleShowModal();
              }, 600);
            }}
            className="reg__close-btn"
          >
            <span className="reg__close-btn-text">X</span>
          </div>
        </div>

        <div className="reg__sign-up-forms-container withdrawal-modal">
          <h2 className="user__withdrawal-modal-heading">Withdrawal Slip</h2>

          <div className="user__withdrawal-modal-balance-amount-box">
            <span>Total Balance:</span>
            <span className="user__withdrawal-modal-balance-amount">
              ${balanceAmount}
            </span>
          </div>

          <div className="user__withdrawal-modal-payment-header">
            PAYMENT DETAILS
          </div>

          <div className="user__withdrawal-modal-input-container">
            <div
              className="user__withdrawal-modal-input-box"
              style={{ color: "#ffffff" }}
            >
              <input
                type="number"
                placeholder="Enter quantity."
                className="user__withdrawal-input"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <span className="user__withdrawal-modal-currency-indicator">
                USD
              </span>
              <span className="user__withdrawal-modal-all-balance-indicator">
                All
              </span>
            </div>

            <div className="user__withdrawal-input-divider" />
          </div>

          <div className="user__withdrawal-modal-input-container">
            <div className="user__withdrawal-modal-input-box">
              {chosenPaymentMethod !== null ? (
                <div
                  className="user__withdrawal-input"
                  style={{ color: "#ffffff" }}
                >
                  <span>{chosenPaymentMethod}</span>
                </div>
              ) : (
                <div
                  className="user__withdrawal-input"
                  style={{ color: "#ffffff" }}
                  onClick={() => {
                    setFadeOut((prev) => !prev);
                    setTimeout(() => {
                      toggleShowModal();
                    }, 600);
                    navigate(`/user/payout settings`);
                  }}
                >
                  <span>Add a payment method</span>
                </div>
              )}
            </div>

            <div className="user__withdrawal-input-divider" />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1rem",
            }}
            className="user__withdrawal-modal-pay-me-container"
          >
            <span style={{ color: "#38383e" }}>Pay me:</span>
            <div className="user__withdrawal-modal-pay-me-amount-indicator-box">
              $ {amount ? amount : "-- . --"}
            </div>
          </div>

          <div className="user__withdrawal-modal-container button">
            <button
              className="user__sales-mc-item-btn"
              type="button"
              onClick={sendWithdrawalRequest}
            >
              <span>{isLoading ? <LoootyLoader /> : "CONFIRM"}</span>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WithdrawalModal;
