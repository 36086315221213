// import { AiTwotoneMessage } from 'react-icons/ai';
import { BsSuitHeartFill } from 'react-icons/bs';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  addProductIntoCollection,
  fetchCollections,
  rateProductAction,
  removeProductIntoCollection,
} from '../../api/auth';
// import { useNavigate } from 'react-router-dom';
// import { calculateProductRating } from '../../utils/calculateProductRating';
// import { RiStarSFill } from 'react-icons/ri';
import Cookies from 'js-cookie';

const tsunamiImg = '/assets/image/loootydefault.jpg';

export const StarRating = ({
  rateNumber = 4,
  id,
  style,
  isClickable = true,
}) => {
  const dispatch = useDispatch();
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);

  const rateProduct = async (score) => {
    //
    setRating(score);

    await dispatch(
      rateProductAction({
        storeData: {
          merchandise_id: id,
          comment: 'Very Good',
          score,
        },
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Rating successful', {
          autoClose: 2000,
          className: 'toast__message',
        });
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  };

  return (
    <div className='landing__star-rating'>
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type='button'
            key={index}
            className={`landing__rating-button  ${
              index <= (hover || rating)
                ? 'landing__rating-on'
                : 'landing__rating-off'
            } ${index <= rateNumber && 'landing__rating-active'}`}
            onClick={isClickable ? () => rateProduct(index) : null}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
          >
            <span className='star' style={style}>
              &#9733;
            </span>
          </button>
        );
      })}
    </div>
  );
};

const WhiteList = ({
  color = '#7c187a',
  degrees,
  left,
  right,
  top,
  bottom,
  isTop,
  isFav,
  addToWishlist,
  removeFromWishlist,
}) => {
  return (
    <div
      style={{ transform: `rotate(${degrees})`, left, right, top, bottom }}
      className='landing__card-whiteList'
    >
      {isTop && isFav && (
        <BsSuitHeartFill
          style={{
            color: 'red',
            fontSize: '1.1rem',
            left: '15%',
            top: '13%',
            position: 'absolute',
            cursor: 'pointer',
          }}
          onClick={removeFromWishlist}
        />
      )}

      {isTop && !isFav && (
        <BsSuitHeartFill
          style={{
            color: '#ffffff',
            fontSize: '1.1rem',
            left: '15%',
            top: '13%',
            position: 'absolute',
            cursor: 'pointer',
          }}
          onClick={addToWishlist}
        />
      )}

      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 87.11 91.79'>
        <g id='Layer_2' data-name='Layer 2'>
          <g id='Layer_1-2' data-name='Layer 1'>
            <polygon
              points='0.34 91.79 0 0.1 87.11 0 0.34 91.79'
              style={{ fill: color }}
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

const LandCard = ({
  onClick,
  id,
  img,
  title,
  owner,
  numReviewers,
  price,
  productId,
  firstCate,
  visibility,
  navigateToStore,
  wishlistIds,
  setCollections,
  product,
  showDetails,
}) => {
  const [activeCard, setActiveCard] = useState(false);

  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const wishlistNumber =
    wishlistIds &&
    Object.keys(wishlistIds).map((key) => Number(wishlistIds[key]));
  const isWishlisted = wishlistNumber?.includes(id);
  let user = {};

  if (typeof window !== 'undefined' && Cookies.get('user')) {
    try {
      user = JSON.parse(Cookies.get('user'));
    } catch (error) {
      // Handle the error, e.g., the cookie contains invalid JSON
      console.error("Error parsing 'user' cookie:", error);
    }
  }

  //Add to wishlist
  const addProductToCollection = async (productID) => {
    try {
      await dispatch(
        addProductIntoCollection({
          collectionInfo: {
            user_id: user?.id,
            wish_list_merchandise: [productID],
          },
        })
      ).unwrap();

      return true;
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message, {
          autoClose: 2000,
          className: 'toast__message',
        });
      }
      return false;
    }
  };

  const wishlistItem = (productID) => {
    addProductToCollection(productID).then((success) => {
      if (success) {
        dispatch(fetchCollections({}))
          .unwrap()
          .then((response) => {
            setCollections(response.wish_list_merchandise);
            toast.success('wishlist updated', {
              autoClose: 4000,
              className: 'toast__message',
            });
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message, {
                autoClose: 4000,
                className: 'toast__message',
              });
            }
          });
      }
    });
  };

  //Remove from wishlist
  const removeProductToCollection = async (productID) => {
    try {
      await dispatch(
        removeProductIntoCollection({
          collectionInfo: {
            user_id: user?.id,
            wish_list_merchandise: [productID],
          },
        })
      ).unwrap();

      return true;
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message, {
          autoClose: 2000,
          className: 'toast__message',
        });
      }
      return false;
    }
  };

  const unwishlistItem = (productID) => {
    removeProductToCollection(productID).then((success) => {
      if (success) {
        dispatch(fetchCollections({}))
          .unwrap()
          .then((response) => {
            setCollections(response.wish_list_merchandise);
            toast.success('wishlist updated', {
              autoClose: 4000,
              className: 'toast__message',
            });
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message);
            }
          });
      }
    });
  };

  // const tempRatings = [
  //   {
  //     name: 'Josh',
  //     comment: 'Making good sense',
  //     score: 3,
  //   },
  //   {
  //     name: 'Mary',
  //     comment: 'Making good sense',
  //     score: 5,
  //   },
  //   {
  //     name: 'Josh',
  //     comment: 'Making good sense',
  //     score: 4,
  //   },
  // ];
  return (
    <div
      onMouseEnter={(e) => {
        setActiveCard(true);
      }}
      onMouseLeave={(e) => {
        setActiveCard(false);
      }}
      className='landing__card-userStore'
    >
      {activeCard && visibility !== 'private' && visibility !== 'no-show' && (
        <WhiteList
          isTop={true}
          top={'-2%'}
          left='-2%'
          isFav={isWishlisted}
          addToWishlist={async () => await wishlistItem(productId)}
          removeFromWishlist={async () => await unwishlistItem(productId)}
        />
      )}

      <div className='landing__card-image-box'>
        <img
          src={img ?? tsunamiImg}
          alt=''
          className='landing-card-img'
          onClick={onClick}
        />
        {visibility === 'private' && (
          <div
            className='landing__card-overlay'
            // onClick={() => navigate(`/user/upload?productId=${productId}`)}
          >
            <span>In review</span>
          </div>
        )}
      </div>
      <div className={'landing__card-body'}>
        <h3 className={'landing__product-title'} onClick={onClick}>
          {title}
        </h3>
        {firstCate && (
          <>
            <h5 className='landing__product-title-affirm'>
              by{' '}
              <span
                className='landing__product-author'
                style={{ color: activeCard ? '#FF9700' : '#6D6D91' }}
                onClick={navigateToStore}
              >
                {owner}
              </span>{' '}
              in {firstCate}
            </h5>
          </>
        )}

        <div className='landing__rating-container'>
          {visibility !== 'private' && visibility !== 'no-show' && (
            <>
              {/* <div className='landing__rating-container-inner'>
                <StarRating rateNumber={ratings} id={productId} />
              </div> */}
              {/* <div
                className='landing__rating-container-inner'
                onClick={() => navigate(`/buy/page/${productId}`)}
              >
                {[...Array(5)].map((_, index) => (
                  <RiStarSFill
                    key={index}
                    style={{
                      color:
                        index < Math.ceil(calculateProductRating(tempRatings))
                          ? '#FF9700'
                          : 'rgba(0, 0, 0, 0.4)',
                    }}
                  />
                ))}
              </div> */}
              <div className='landing__card-stats-container'>
                {/* <span className='landing__card-stats-views'>
                  {numReviewers}
                </span> */}

                {/* <span
                  className='landing__card-stats-comments'
                  onClick={() => navigate(`/buy/page/${productId}`)}
                >
                  <AiTwotoneMessage
                    style={{ marginRight: '5px', marginTop: 1 }}
                  />{' '}
                  {numOfComments || 0}
                </span> */}
              </div>
            </>
          )}
          {activeCard && (
            <WhiteList
              color={'#FF9700'}
              degrees={'180deg'}
              bottom={'10%'}
              right='-2%'
            />
          )}

          <div
            style={{ color: activeCard ? '#000000' : '#ffffff' }}
            className='landing__price'
          >
            ${price === 0 ? 'FREE' : Number(price).toLocaleString()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandCard;
