import LoootyLoader from "../../../loader/loootyLoader";

export const StoreRequestModal = ({
    toggleShowModal, 
    storeRequestData,
    fadeOut, 
    setFadeOut, 
    isLoading, 
    approveStore,
    unapproveStore,
}) => {
    return (
        <div
            className={`reg__sign-up-overlay ${fadeOut ? "lgn__fade-out-anim" : ""}`}
        >
            <section className="reg__sign-up-modal user__withdrawal-modal">
                <div className="reg__close-btn-container">
                    <div
                        onClick={(e) => {
                            setFadeOut((prev) => !prev);
                            setTimeout(() => {toggleShowModal();}, 600);
                        }}
                        className="reg__close-btn"
                    >
                        <span className="reg__close-btn-text">X</span>
                    </div>
                </div>
    
                <div className="reg__sign-up-forms-container withdrawal-modal">
                    <h2 className="user__withdrawal-modal-heading" style={{color: '#fff'}}>Store Request</h2>
    
                    <div style={{width: '40%', margin: 'auto'}}>
                        <div className="admin__store-request-modal-heading">
                            <span className="">Links to Portfolio</span>
                        </div>
                    </div>

                    <div style={{textAlign: 'center', marginTop: '2rem', gap: '1rem'}}>
                        {
                            storeRequestData?.portfolio_links.map((link, index) => (
                                <span 
                                    key={index} 
                                    style={{color: '#fa9401'}}
                                >
                                    {link}
                                </span>
                            ))
                        }
                    </div>
                    
                    <div style={{width: 'fit-content', margin: '2rem auto'}}>
                        <div className="admin__store-request-modal-heading">
                            <span className="">Why do you want to sell on loooty?</span>
                        </div>
                    </div>

                    <div style={{textAlign: 'center', marginTop: '2rem'}}>
                        {
                            storeRequestData?.why_loooty && (
                                <span 
                                    style={{color: '#5f5f74'}}
                                >
                                    {storeRequestData?.why_loooty}
                                </span>
                            )
                        }
                    </div>
                   
                    <div className="user__delete-folder-btn-container">
                        <div style={{width: "60%", marginLeft: "1rem"}}>
                            <button 
                                className="user__library-trash-btn" 
                                type="button" 
                                onClick={unapproveStore}
                            >
                                <span className="user__library-trash-btn-text">
                                    { isLoading ? <LoootyLoader /> : 'Reject' }
                                </span>
                            </button>
                        </div>

                        <div style={{width: "50%"}}>
                            <button
                                onClick={approveStore}
                                className="user__sales-mc-item-btn"
                                type="button"
                            >
                                <div className="user__upload-products-btn-inner">
                                    { isLoading ? <LoootyLoader /> : "Accept" }
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
    
        </div>
      )
}