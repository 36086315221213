import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { setSelectedSubPage } from '../../redux/reducers/userStateReducer';
import NavBar from '../../component/navbar/navbar';
import PageSelectComp from '../../component/admin/PageSelectComp';
import TagheaderCompMobile from '../../component/admin/TagheaderCompMobile';
import { UniversalTagHeaderDesktopAdmin } from '../../component/user/UniversalTagHeaderDesktop';

const hasStoreTags = [
  {
    name: 'home',
    // dropDownList: ["subscriptions"],
  },
  {
    name: 'users',
  },
  {
    name: 'stores',
  },
  {
    name: 'payouts',
  },
  {
    name: 'products',
  },

  {
    name: 'tags & categories',
  },

  // {
  //   name: 'carts&wishlist',
  //   // dropDownList: ["affiliates"],
  // },
];

const Admin = () => {
  const toggleVisAdmin = true;
  const isMobile = useMediaQuery({ minWidth: 481, maxWidth: 768 });
  const dispatch = useDispatch();

  const stateInpageHeader = useSelector(
    (state) => state.userSubPageState.selectedAdmin
  ); //Enum types: store, profile, library, messages, sales, settings

  const [activeInpageHeader, setActiveInPageHeader] =
    useState(stateInpageHeader);

  useEffect(() => {
    if (!toggleVisAdmin) {
      dispatch(setSelectedSubPage({ selected: 'store' }));
    }
  }, [dispatch, toggleVisAdmin]);
  return (
    <section className='admin__main-container'>
      <header>
        <NavBar
          pageType={'user'}
          loggedIn={true}
          shortSearch={true}
          style={{ paddingBottom: '0', paddingTop: '0' }}
          search={false}
        />
      </header>

      <main className='admin__main'>
        <div className='admin__separator'></div>
        {/*  */}

        {!isMobile ? (
          <UniversalTagHeaderDesktopAdmin
            style={{ zoom: '.8' }}
            toggleVisAdmin={toggleVisAdmin}
            activeInpageHeader={activeInpageHeader}
            setActiveInPageHeader={setActiveInPageHeader}
            TagList={hasStoreTags}
          />
        ) : (
          <TagheaderCompMobile
            toggleVisAdmin={toggleVisAdmin}
            activeInpageHeader={activeInpageHeader}
            setActiveInPageHeader={setActiveInPageHeader}
          />
        )}

        <PageSelectComp inPageName={activeInpageHeader} />
      </main>
    </section>
  );
};

export default Admin;
