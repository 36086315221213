import axios from 'axios';
import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { socialSignInAction } from '../api/auth';
import LoadingState from '../component/loader/LoadingState';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const SocialiteCallback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const base_url = process.env.REACT_APP_BASE_URL || '';
    const url = window.location.href.replace('#', '?');
    const searchParams = new URLSearchParams(new URL(url).search);
    let access_token = searchParams.get('access_token');
    let id_token = searchParams.get('id_token');

    // Check if google login or apple login
    let socialite_url;
    let token;

    if (access_token) {
      socialite_url = `${base_url}/api/auth/socialite/google/callback`;
      token = access_token;
    }

    if (id_token) {
      socialite_url = `${base_url}/api/auth/socialite/apple/callback`;
      token = id_token;
    }

    if (socialite_url && token) {
      axios({
        url: socialite_url,
        method: 'get',
        params: {
          token: token,
        },
      })
        .then((res) => {
          Cookies.set('user', JSON.stringify(res?.data?.data));
          dispatch(socialSignInAction({ data: res.data.data }));
          navigate('/home');
        })
        .catch((err) => {
          return console.log({ err });
        });
    }
  }, [dispatch, navigate]);

  return (
    <section className='landing__main-app'>
      <div
        style={{ width: '80%', margin: '2rem auto', justifyContent: 'center' }}
      >
        <LoadingState />
      </div>
    </section>
  );
};

export default SocialiteCallback;
