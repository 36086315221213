import { useEffect, useState } from 'react';
// import { FaPlusCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import LandCard from '../card/landCard';
import LibraryItem from './LibraryItem';
import {
  // createFolderAction,
  fetchCollections,
  fetchMyFolders,
} from '../../api/auth';
import { checkIfProductIsInUserCollection } from '../../utils/checkIfProdductInUserCollection';
import LoadingState from '../loader/LoadingState';
import { RiPencilFill, RiDeleteBinFill } from 'react-icons/ri';
import EditFolderModal from '../modals/collection/EditFolderModal';
import DeleteFolder from '../modals/folder/DeleteFolder';
import MobileLibraryFilterDropdown from '../dropdown/MobileLibraryFilterDropdown';
import {
  getAllCategoriesAction,
  getAllPublicMerchandiseAction,
} from '../../api/user';
import { useMediaQuery } from 'react-responsive';
import MobileLibraryFilterDropDownItem from '../dropdown/MobileLibraryFilterDropDownItem';
import NavBarDropdownItem from '../navbar/NavBarDropdownItem ';
import { filterOptions } from '../navbar/navbar';
import { calculateProductRating } from '../../utils/calculateProductRating';
import { countProductComments } from '../../utils/countProductComments';
import { useNavigate } from 'react-router-dom';
import BuyPageModal from '../modals/buypage/BuyPageModal';

const Collections = () => {
  const dispatch = useDispatch();
  const collections = useSelector((state) => state.user.collections);
  const [folders, setFolders] = useState([]);
  const [activeSideUser, setActiveSideUser] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [newFolderName, setNewFolderName] = useState('');
  const [groupedCollectionProducts, setGroupedCollectionProducts] = useState(
    collections?.merchandise
  );
  const [settingNavHeader, setSettingsHeaderNav] = useState('All Products');
  const [isInCollection, setIsInCollection] = useState([]);
  const [showEditFolderModal, setShowEditFolderModal] = useState(false);
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [activeFilter, setActiveFilter] = useState('');
  const [collectionsIds, setCollectionsIds] = useState([]);
  const [productId, setProductId] = useState(null);
  const [buyPagefadeOut, setBuyPageFadeOut] = useState(false);
  const [buyPageModal, setBuyPageModal] = useState(null);
  const [
    showCollectionsProductsFilterContainer,
    setShowCollectionsProductsFilterContainer,
  ] = useState(false);
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 768 });
  const [categories, setCategories] = useState([]);

  const [showEditFolderBoxArray, setShowEditFolderBoxArray] = useState([]);
  const navigate = useNavigate();
  const toggleShowEditFolderBox = (index) => {
    const array = showEditFolderBoxArray?.map((bool, i) =>
      i === index ? !bool : false
    );
    setShowEditFolderBoxArray(array);
  };

  const clickMobileActiveFilter = (filterTitle) => {
    if (activeFilter.toLowerCase() === filterTitle.toLowerCase()) {
      setActiveFilter('');
      setShowCollectionsProductsFilterContainer(false);
    } else {
      setActiveFilter(filterTitle);
      setShowCollectionsProductsFilterContainer(true);
    }
  };

  function groupCollectionProductsByFolderName(data, folderName) {
    if (data && folderName) {
      let arr = data?.filter(
        (item) =>
          item.hasOwnProperty('folder_name') && item.folder_name === folderName
      );
      setGroupedCollectionProducts(arr);
    }
  }

  // const createNewFolder = async () => {
  //   if (!newFolderName || newFolderName.length < 2) {
  //     toast.error('Invalid Folder Name', {
  //       autoClose: 2000,
  //       className: 'toast__message',
  //     });
  //     return;
  //   }
  //   setLoading(true);
  //   await dispatch(
  //     createFolderAction({
  //       folderData: {
  //         name: newFolderName,
  //         type: 'collection',
  //       },
  //     })
  //   )
  //     .unwrap()
  //     .then(async () => {
  //       setLoading(false);
  //       setNewFolderName('');
  //       toast.success('Folder was created!', {
  //         autoClose: 2000,
  //         className: 'toast__message',
  //       });
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       if (err.response) {
  //         toast.error(err.response.data.message, {
  //           autoClose: 2000,
  //           className: 'toast__message',
  //         });
  //       }
  //     });
  // };

  const countFolderProducts = (folderId) => {
    let count = 0;
    collections?.merchandise_list?.forEach((item) => {
      if (item.id === folderId) count++;
    });
    return count;
  };

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    dispatch(fetchCollections({}))
      .unwrap()
      .then((response) => {
        if (isMounted) setLoading(false);
        setCollectionsIds(response);
      })
      .catch((err) => {
        isMounted && setLoading(false);
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    dispatch(fetchMyFolders({ folderType: 'collection' }))
      .unwrap()
      .then(async (response) => {
        if (isMounted) {
          setShowEditFolderBoxArray(
            response?.data?.data?.data?.map(() => false)
          );
          setFolders(response?.data?.data?.data);
          const productsInCollection =
            groupedCollectionProducts &&
            groupedCollectionProducts?.map(async (product) => {
              return checkIfProductIsInUserCollection(
                Number(product.merchandise_id),
                collections
              );
            });
          const result = await Promise.all(productsInCollection);
          setIsInCollection(result);
          setLoading(false);
        }
      })
      .catch((err) => {
        isMounted && setLoading(false);
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch, groupedCollectionProducts, collections]);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    dispatch(getAllCategoriesAction({ page: 1 }))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setCategories(response);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    dispatch(getAllPublicMerchandiseAction({ page: 1 }))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setGroupedCollectionProducts(
            response.filter((obj) => collectionsIds.includes(obj.id))
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch, collectionsIds]);
  // console.log('group', groupedCollectionProducts);
  return (
    <section className='user__library'>
      {showEditFolderModal && (
        <EditFolderModal
          toggleShowModal={() => setShowEditFolderModal(false)}
          folderId={selectedFolderId}
          merchandiseList={groupedCollectionProducts}
          collectionMerchandise={collections?.wish_list_merchandise}
        />
      )}

      {showDeleteFolderModal && (
        <DeleteFolder
          toggleShowModal={() => setShowDeleteFolderModal(false)}
          folderData={selectedFolder}
          merchandiseList={groupedCollectionProducts}
          setSelectedFolder={setSelectedFolder}
        />
      )}

      {isMobile && (
        <div
          className='landing__navbar-mobile-filter-box'
          style={{ display: 'flex' }}
        >
          <MobileLibraryFilterDropdown
            title={'All Products'}
            isActive={activeFilter.toLowerCase() === 'folders'}
            onClick={() => clickMobileActiveFilter('folders')}
            isFolder={true}
          />

          <MobileLibraryFilterDropdown
            title={'CATEGORY'}
            isActive={activeFilter.toLowerCase() === 'category'}
            onClick={() => clickMobileActiveFilter('category')}
          />

          <MobileLibraryFilterDropdown
            title={'FILTER'}
            isActive={activeFilter.toLowerCase() === 'filter'}
            onClick={() => clickMobileActiveFilter('filter')}
          />
        </div>
      )}

      {showCollectionsProductsFilterContainer && (
        <div className='nav_mobile_filter-options-container'>
          {activeFilter.toLocaleLowerCase() === 'folders' ? (
            <>
              <MobileLibraryFilterDropDownItem
                folderName={'All Products'}
                numberOfItems={
                  collections?.merchandise_list
                    ? collections?.merchandise_list?.length
                    : 0
                }
                onClick={() => {
                  setActiveSideUser(0);
                  setSettingsHeaderNav('All Products');
                  setGroupedCollectionProducts(collections?.merchandise_list);
                  setShowCollectionsProductsFilterContainer(
                    !showCollectionsProductsFilterContainer
                  );
                }}
              />

              {folders?.map((item) => (
                <MobileLibraryFilterDropDownItem
                  key={item.id}
                  folderName={item.name}
                  numberOfItems={countFolderProducts(item.id)}
                  onClick={() => {
                    setActiveSideUser(item.id);
                    setSettingsHeaderNav(item.name);
                    setSelectedFolder(item);
                    groupCollectionProductsByFolderName(
                      collections.merchandise_list,
                      item.name
                    );
                    setShowCollectionsProductsFilterContainer(
                      !showCollectionsProductsFilterContainer
                    );
                  }}
                />
              ))}

              {/* <section className='user__library-sidebar-body'>
                <div className='user__library-trash-btn mobile-user__library-trash-btn'>
                  <input
                    className='user__library-trash-btn-text'
                    type={'text'}
                    placeholder='Create New Folder'
                    value={loading ? 'Saving...' : newFolderName || ''}
                    onChange={(e) => setNewFolderName(e.target.value)}
                  />
                  <FaPlusCircle
                    className='user__library-trash-btn-logo'
                    onClick={() => createNewFolder()}
                  />
                </div>
              </section> */}

              <button
                className='user__library-trash-btn mobile-library-nav-btn'
                onClick={() => {
                  if (!selectedFolder) {
                    toast.error('Cannot delete folder', {
                      autoClose: 2000,
                      className: 'toast__message',
                    });
                    return;
                  } else {
                    setShowDeleteFolderModal(!showDeleteFolderModal);
                  }
                }}
              >
                <RiDeleteBinFill className='user__library-trash-btn-logo' />
                <span className='user__library-trash-btn-text'>Trash</span>
              </button>
            </>
          ) : activeFilter.toLocaleLowerCase() === 'category' ? (
            categories?.map((item) => <NavBarDropdownItem option={item} />)
          ) : activeFilter.toLocaleLowerCase() === 'filter' ? (
            filterOptions?.map((item) => <NavBarDropdownItem option={item} />)
          ) : null}
        </div>
      )}

      <div className='user__library-inner'>
        <section className='user__library-sidebar'>
          <section className='user__library-sidebar-header'>
            <div className='user__library-sidebar-header-item'>
              <span className='user__library-sidebar-header-item-text'>
                {settingNavHeader}
              </span>
            </div>
          </section>

          {/* <section className='user__library-sidebar-body'>
            <div className='user__library-trash-btn'>
              <input
                className='user__library-trash-btn-text'
                type={'text'}
                placeholder='Create New Folder'
                value={loading ? 'Saving...' : newFolderName || ''}
                onChange={(e) => setNewFolderName(e.target.value)}
              />
              <FaPlusCircle
                className='user__library-trash-btn-logo'
                onClick={createNewFolder}
              />
            </div>
          </section> */}

          <section className='user__library-sidebar-body'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                width: '95%',
                margin: 'auto',
              }}
            >
              <LibraryItem
                prodNumber={
                  collections?.merchandise_list
                    ? collections?.merchandise_list?.length
                    : 0
                }
                itemName={'All Products'}
                onClick={() => {
                  setActiveSideUser(0);
                  setSettingsHeaderNav('All Products');
                  setGroupedCollectionProducts(collections?.merchandise_list);
                }}
                active={activeSideUser === 0}
                onEditClick={() =>
                  toast.error('Cannot modify folder', {
                    autoClose: 2000,
                    className: 'toast__message',
                  })
                }
              />
            </div>

            {folders &&
              folders?.length > 0 &&
              folders?.map((folderDetails, index) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    width: '95%',
                    margin: 'auto',
                  }}
                  key={folderDetails.id}
                >
                  <LibraryItem
                    prodNumber={countFolderProducts(folderDetails.id)}
                    itemName={folderDetails.name}
                    onClick={() => {
                      setActiveSideUser(folderDetails.id);
                      setSettingsHeaderNav(folderDetails.name);
                      setSelectedFolder(folderDetails);
                      groupCollectionProductsByFolderName(
                        collections.merchandise_list,
                        folderDetails.name
                      );
                    }}
                    active={activeSideUser === folderDetails.id}
                    onEditClick={() => {
                      setActiveSideUser(folderDetails.id);
                      setSettingsHeaderNav(folderDetails.name);
                      setSelectedFolder(folderDetails);
                      groupCollectionProductsByFolderName(
                        collections.merchandise_list,
                        folderDetails.name
                      );
                      toggleShowEditFolderBox(index);
                    }}
                  />

                  {showEditFolderBoxArray[index] && (
                    <div className='user__library-edit-folder-dropdown-container'>
                      <div
                        className='user__library-edit-folder-dropdown-item'
                        onClick={() => {
                          setSelectedFolderId(folderDetails.id);
                          toggleShowEditFolderBox(index);
                          setShowEditFolderModal(!showEditFolderModal);
                        }}
                      >
                        <RiPencilFill className='user__library-item-icon' />
                        <span>Edit folder</span>
                      </div>
                      <div
                        className='user__library-edit-folder-dropdown-item'
                        onClick={() => {
                          setSelectedFolder(folderDetails);
                          toggleShowEditFolderBox(index);
                          setShowDeleteFolderModal(!showDeleteFolderModal);
                        }}
                      >
                        <RiDeleteBinFill className='user__library-item-icon' />
                        <span>Remove</span>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </section>

          {/* <section className='user__library-sidebar-body trash-button-section'>
            <button
              className='user__library-trash-btn'
              onClick={() => {
                if (!selectedFolder) {
                  toast.error('Cannot delete folder', {
                    autoClose: 2000,
                    className: 'toast__message',
                  });
                  return;
                } else {
                  setShowDeleteFolderModal(!showDeleteFolderModal);
                }
              }}
            >
              <RiDeleteBinFill className='user__library-trash-btn-logo' />
              <span className='user__library-trash-btn-text'>Trash</span>
            </button>
          </section> */}
        </section>

        <section className='user__main-lib-container'>
          {loading ? (
            <LoadingState />
          ) : (
            <div>
              <div className='user__main-lib-body'>
                {groupedCollectionProducts &&
                groupedCollectionProducts?.length > 0 ? (
                  groupedCollectionProducts?.map((prod, index) => (
                    <LandCard
                      id={prod.id}
                      key={prod.merchandise_name + index}
                      onClick={() => {
                        setBuyPageModal(!buyPageModal);
                        setProductId(prod.id);
                      }}
                      img={
                        prod.merchandise_banners?.length > 0
                          ? prod.merchandise_banners[0]
                          : null
                      }
                      title={prod.merchandise_name}
                      ratings={calculateProductRating(prod.ratings)}
                      owner={prod.store?.store_name}
                      numOfComments={countProductComments(prod.ratings)}
                      numReviewers={prod.ratings?.length}
                      price={prod.merchandise_amount}
                      firstTag={
                        prod.merchandise_tags ? prod.merchandise_tags[0] : ''
                      }
                      firstCate={
                        prod.merchandise_categories
                          ? prod.merchandise_categories[0]
                          : ''
                      }
                      navigateToStore={() =>
                        navigate(`/store/${prod.store_id}`)
                      }
                      productId={prod.id}
                      wishlistIds={collectionsIds}
                      visibility={'no-show'}
                      isInCollection={isInCollection}
                      // setCollections={setCollections}
                    />
                  ))
                ) : (
                  <div className='user__no-product-div'>
                    Nothing to see here yet!
                  </div>
                )}
              </div>

              <div className='user__main-lib-footer'></div>
            </div>
          )}
        </section>

        {buyPageModal && (
          <BuyPageModal
            toggleShowModal={() => setBuyPageModal(!buyPageModal)}
            fadeOut={buyPagefadeOut}
            setFadeOut={setBuyPageFadeOut}
            id={productId}
          />
        )}
      </div>
    </section>
  );
};

export default Collections;
