import Footer from "../component/footer/footer";
import NavBar from "../component/navbar/navbar";
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
    const navigate = useNavigate();
    return (
        <div>
            <header>
                <NavBar/>
            </header>
            <main>
                <section className="privacy_policy_container">
                  <p
                    onClick={() => navigate(-1)}
                    style={{
                    padding: '1rem 2rem',
                    marginBottom: '2rem',
                    border: '1px solid #1d1d1d',
                    width: 'fit-content',
                    cursor: 'pointer',
                    }}
                    >
                        Go Back
                    </p>
                    <h1 className="privacy_policy-title">LOOOTY PRIVACY POLICY</h1>

                    <div className="privacy_policy-body">
                        <span className="privacy_policy-section-title">Who we are</span> 

                        <div className="privacy_policy-paragraph">
                            Loooty is an Afro-centric Art Asset marketplace that enables artists to display and sell their 2D and 3D digital artwork, while also providing buyers with a diverse selection of high-quality art pieces in various styles and formats. It allows for a variety of assets which includes an Afrocentric base but is open to assets from all over the world, 2D Assets, UI Assets, Game UI Assets, 3D Assets, Game-ready, VR, AR, Plugins, Tools and Sound.
                        </div>

                        <div className="privacy_policy-paragraph">
                            Loooty values your privacy rights, thus, this Privacy Policy describes your privacy rights regarding our collection, use, storage, sharing and protection of your personal information on our Platforms and with third parties. All questions relating to this policy can be directed to <span className="support-contact">support@loooty.com.</span>
                        </div>

                        <span className="privacy_policy-section-title">
                            Overview
                        </span>

                        <div className="privacy_policy-paragraph">
                            The term ‘you’/ ‘User’ refers to anyone who visits or uses our Platform. Similarly, “we”, “our” or “us” means Loooty, which will be responsible for the collection and use of your personal data as defined under this Privacy Policy.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">Age restriction:</span> Our Platform Services are not intended for anyone under the age of 18, or where it so applies, the age of majority in your country. We do not knowingly collect personally identifiable information from anyone under the age of 18. 
                        </div>
                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">Third-Party Privacy Policy:</span> This privacy policy has been developed in order for you to understand how we collect, use, communicate, disclose and otherwise make use of personal information and thus applies to the Loooty Platform only. We do not exercise control over external websites integrated on our platform or displayed on our Platform. These other sites may place their own cookies, plug-ins, or other files on your computer or mobile device, collect data or solicit personal information from you. We do not control these third-party websites or platforms, and we are not responsible for their privacy statements. Please consult or read such third parties’ privacy statements before proceeding.
                        </div>
                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">Review of Policy:</span> This policy may be changed or altered in line with business exigencies and regulations. These changes will become effective when we post the revised Privacy Policy on our Platform. We may provide you with notifications and alerts regarding the Privacy Policy, its revisions, or Personal Data collected by posting them on our website and, if you are a registered user, by contacting you through your account dashboard, and/or email address which you have given us.
                        </div>


                        <span className="privacy_policy-section-title">
                            Definitions:
                        </span>
                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">Data/Information:</span> means any information relating to you, including your name, identification number, location data, online identifier address, photo, email address, pins, passwords, licenses, bank details, date of birth, health, criminal records, medical information, and other unique identifiers such as but not limited to Media Access Control (MAC) address, Internet Protocol (IP) address, International Mobile Equipment Identity (IMEI) number, Bank Verification Number (BVN), International Mobile Subscriber Identity (IMSI) number, Subscriber Identification Module (SIM) and others. It also includes factors specific to your physical, physiological, genetic, mental, economic, cultural, or social identity. 
                        </div>
                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">Cookies:</span> A cookie is a small data file that is transferred to your computer or mobile device. It enables us to remember your account log-in information, IP addresses, web traffic, number of times you visit, browser type and version, device details, date and time of visits.
                        </div>
                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">Process/Processing:</span> means any operation or set of operations which is performed on your Personal Data or sets of Personal Data, whether by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure, or destruction.
                        </div>

                        <span className="privacy_policy-section-title">
                            The Personal Data we collect and how we collect them. 
                        </span>

                        <div className="privacy_policy-paragraph">
                            We only collect information that we need to provide our services to you as User. They include:
                        </div>
                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">Data you directly give us.</span> We collect this information when you create an account with us or update your account information. When you use our services, we collect the identity data you provide us, such as your full name, government-issued means of identification, wallet address, portfolio link, date of birth and gender. We may also obtain financial data on your transaction such as bank account details, card details linked to Loooty or input on our platform when you use our service. When you register on our platform as a Creator, we may collect your product information such as titles, descriptions, images, pricing, and transaction details. 
                        </div>

                        Also, we collect your communication information such as when you correspond with us for the purpose of making enquires or seeking support. In this instance, for quality assurance purposes we may monitor or record telephone conversations with you or anyone acting on your behalf.  By contacting us, you understand and acknowledge that your communication with us may be overheard, monitored or recorded without notice or warning to you. 

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">Data we collect automatically.</span> When you utilize our services, we collect your device information and usage information automatically. Our servers automatically record information that your browser sends whenever you visit a website, such as links you have clicked on, your device's Internet Protocol address (e.g., IP address), browser version length of visit on certain pages, unique device identifier, log-in information, location and other device details. For more information about our use of Cookies and how you can control/manage them, please see our 
                            Cookies Policy.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">Data that we receive from others.</span> We may retrieve additional personal data about you from third parties such as your financial institution identification/verification services, and other third-party service providers. We may combine that data with other information we have about you. We may also retrieve your personal data through public sources of information such as open government databases, and we may infer additional Personal Data based on the Personal Data described above. For example, for visitors, we may infer your interests based on the web pages you view.
                        </div>

                        <span className="privacy_policy-section-title">How We Use Personal Data</span>

                        <div className="privacy_policy-paragraph">We use the personal data that we collect for the following reasons:</div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">1.</span>To aid the creation and management of accounts and account verification for Users on our Platform.
                        </div>
                        
                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">2.</span>To process and fulfil your product orders
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">3.</span>
                            To process your payment transactions as well as send notices to you relating to your use of the Platform or any new feature on the Platform, including the security updates when necessary or reasonable for better implementation.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">4.</span> To provide, administer and communicate with you about products, services, offers, programs and promotions by email, telephone calls, or other equivalent forms of electronic communication.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">5.</span>
                            To detect, prevent, and manage risk against fraud and illegal activities using internal and third-party screening tools.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">6.</span>
                            To improve features, website/App content, and analyze data to develop products and services. 
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">7.</span>
                            Send marketing content, newsletters, and service updates curated by us, however, we will provide you with an option to unsubscribe if you do not want to hear from us. 
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">8.</span>
                            Verify your identity and the information you provide in line with our statutory obligations to maintain KYC. 
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">9.</span>
                            Maintain up-to-date records of Users.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">10.</span>
                            Resolve transaction disputes that may arise, including investigations by law enforcement or regulatory bodies. 
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">11.</span>
                            To monitor the usage of our Platform and maintain the use of the service on the platform.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">12.</span>
                            Any other purpose that we disclose to you while providing our Services to you. 
                        </div>
                    
                        <span className="privacy_policy-section-title">
                            The lawful basis we rely on for processing your personal information:
                        </span>

                        Our processing of your Personal Information is lawful if:

                        <div className="privacy_policy-paragraph">We have your consent to process your Personal Information for one or more specific purposes; or</div>
                        <div className="privacy_policy-paragraph">The processing is necessary for the execution of a contract to which you are a party, or to take steps -your request- prior to entering a contract; or</div>
                        <div className="privacy_policy-paragraph">Processing is necessary for compliance with a legal obligation to which we are subject to; or</div>
                        <div className="privacy_policy-paragraph">Processing is necessary to protect your interests or that of another natural person; or</div>
                        <div className="privacy_policy-paragraph">Processing is necessary for the performance of a task carried out in the public interest or in the exercise of an official public mandate vested in us.</div>

                        <span className="privacy_policy-section-title">
                            Sharing your Data
                        </span>

                        <div className="privacy_policy-paragraph">
                            We may share or use your personal data to facilitate our business relationships and contract with you, to comply with financial, regulatory, and other legal obligations, and to pursue our legitimate business interests. We will not share or disclose your personal data with a third party without your consent except as necessary to provide our Services to you or as described in this Privacy Policy.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">Service providers:</span> We may share your personal information with agents working on our behalf, service providers or partners (such as identity verification agencies, and payment processing/service providers), and with Creators to enable them to process your orders or in resolving any issues you may have. Our relationship with these service providers and partners is governed by a contract to ensure adequate protection of personal data in accordance with the terms of this Privacy Policy.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">Affiliates:</span> We enable access to personal data across our subsidiaries, affiliates, and related companies for purposes identified in this privacy policy, for example, where we share common data systems or where access is needed to provide our services and operate our business.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">Financial Service Partners:</span> We share the Personal Data of our Users with certain Financial institutions we partner with (such as banks), to provide Services to the associated Business Users. For example, we share certain Personal Data of Users (payment information) with financial institutions to facilitate payment of purchases made on our platform.  
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">Corporate transactions:</span> We may disclose personal data as part of a corporate transaction or proceeding such as a merger, financing, acquisition, bankruptcy, dissolution, or a transfer, divestiture, or sale of all or a portion of our business or assets.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">Security, safety, and protecting rights:</span> We will disclose personal data if we believe it is necessary to protect our Creators and others, or to operate and maintain the security of our Services and Platform, including to prevent or stop an attack on our computer systems or networks; or to protect the rights or property or ourselves or others.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">Fraud Prevention and Risk Management:</span> We will share your personal data where it is necessary to help prevent fraud or assess and manage risk. Such as where we detect fraudulent activity on your accounts or evaluate credit risk.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">Compliance and prevention of harm:</span> We share Personal Data as we believe necessary to: 
                        
                            <div className="privacy_policy-sub-section">comply with applicable laws or rules imposed by payment method in connection with the use of that payment method.</div>
                            <div className="privacy_policy-sub-section">enforce our contractual rights. </div>
                            <div className="privacy_policy-sub-section">protect the Services, rights, privacy, safety, and property of Loooty or others; and </div>
                            <div className="privacy_policy-sub-section">respond to requests from courts, law enforcement agencies, regulatory agencies, and other public and government authorities.</div>
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-outline">Third-party analytics and advertising companies:</span> These companies may also collect personal data through our website and apps. Such data may include marketing and communications data, demographic data, content and files, geolocation data, usage data, etc.; and are governed by such third party’s separate Privacy Policy.  
                        </div>

                        <span className="privacy_policy-section-title" id="cooky-policy">Cookies</span>

                        <div className="privacy_policy-paragraph">
                            We use cookies and similar tracking technologies to track the activity on our service and store certain information. For more information on Cookies and how you can control them, please see our Cookies Policy. 
                        </div>

                        <span className="privacy_policy-section-title">
                            How We Protect Your Information
                        </span>

                        <div className="privacy_policy-paragraph">    
                            We are committed to managing your Personal Information in line with global best practices. We protect your Personal Information using physical, technical, and administrative security measures to reduce the risks of loss, misuse, unauthorized access, disclosure, and alteration. We also use industry-recommended security protocols to safeguard your Personal Information and maintain a data breach procedure to deal with incidents of breach. Other security safeguards include but are not limited to data encryption, firewalls, and physical access controls to our building and files and only granting access to Personal Information to only employees who require it to fulfil their job responsibilities.
                        </div>

                        <div className="privacy_policy-paragraph">
                            If you notice or suspect any breach of your personal information or suspect that your access credentials have been compromised, please contact our Data Protection Officer immediately. This will enable us to take the necessary steps towards ensuring the security of your Personal Information or account. We will report any breaches that will compromise your rights and freedoms to the relevant regulatory authority within 72 hours of discovery of the breach.
                        </div>

                        <span className="privacy_policy-section-title">
                            Your Data Protection Rights
                        </span>

                        You have the following rights regarding the Personal Data we control about you:

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>You can request confirmation of whether we process Personal Data relating to you, and if so, to request a copy of that Personal Data.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>You can request that we rectify or update your Personal Data that is inaccurate, incomplete, or outdated.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            You can request that we erase your Personal Data in certain circumstances such as where the data is no longer required. However, this right is limited as we may be required by law to retain the data.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            You can request that we restrict the use of your Personal Data in certain circumstances. When such a request is made, it means your Personal Data can only be used for certain purposes e.g., to exercise legal rights
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            You can request that we export your Personal Data (which you have given to us) that we hold to another company, where technically feasible.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>You can object to the processing of your Personal Information for marketing purposes by adjusting your preference on the settings page or by clicking the unsubscribe link in our marketing emails. 
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>You can object to automated decision-making and processing and request for human review of the decision. 
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>The basis of our processing of your Personal information is consent. You can withdraw your consent at any time for further processing of your Personal Data. Please note that where you object to the processing of your Personal Information, we may not be able to provide our Services to you.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>If you have an account with us, you can review your User Account settings, update your Personal Information on it, and close your user account at any time, subject to either the User Terms and Conditions.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>If you wish to exercise any of these rights, you may write to us at <span className="support-contact">Support@loooty.com.</span>
                        </div>

                        <span className="privacy_policy-section-title">Storage Limitation</span>

                        <div className="privacy_policy-paragraph">
                            We will retain your information for as long as reasonably necessary for the purpose of providing our services to you; for the duration your account is active and we have your consent; or in the case of disputes, until the claim is satisfied, or it expires.
                        </div>

                        <div className="privacy_policy-paragraph">
                            Retaining your information is also subject to legal, accounting, statutory/regulatory, administrative, and operational requirements. Therefore, even after closing your account with us, we may retain certain personal data and transaction data to comply with these obligations. We will destroy all personal data where possible or anonymized it in other instances.
                        </div>

                        <span className="privacy_policy-section-title">Transfer of Personal Data</span>

                        <div className="privacy_policy-paragraph">
                            While providing our Services, we may rely on third-party servers, databases co-located with hosting providers resident in foreign countries. This may be regarded as transferring of your personal data to computers or servers in foreign countries. Any transfer of Personal Information out of Nigeria will be in accordance with the provisions of relevant data protection regulations. We will only transfer your Personal Information if one of the following conditions are met:
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>If we have received your consent to do so.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            If the transfer is necessary for the performance of a contract between us or if it is  necessary for the implementation of pre-contractual measures taken at your request.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            If the transfer is necessary to conclude a contract between us and a third party in your interest.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            If the transfer is necessary by reason of public interest.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            Where necessary for the establishment, exercise, or defence of legal claims.
                        </div>

                        <div className="privacy_policy-paragraph">
                            <span className="privacy_policy-bullet">.</span>
                            If the transfer is necessary to protect your vital interest as a data subject or where you are physically or legally incapable of giving consent.
                        </div>

                        In any instance, we will ensure Personal Information is transmitted in a safe and secure manner. 

                        <span className="privacy_policy-section-title">
                            Policy Violations
                        </span>

                        <div className="privacy_policy-paragraph">
                            Any violation of this Privacy Policy should be brought to the attention of the Data Protection Officer for appropriate sanctioning and treatment. By signing up on our Platform, you certify that the information provided for registration is correct to the best of your knowledge. Also, when providing the personal data of any other person, you confirm that you are only providing accurate and up-to-date data in accordance with their instructions and can provide evidence of their consent to the data processing described in this Policy as and when required by us. Please note that any attempt to mislead may result in prosecution and the deliberate provision of inaccurate data results in a privacy violation.
                        </div>

                        <span className="privacy_policy-section-title">
                            Dispute Resolution 
                        </span>

                        <div className="privacy_policy-paragraph">
                            We are dedicated to ensuring that you are satisfied with our management of your Information. However, in the unlikely event that you have a complaint, please contact us via the details below, stating your name and details of your complaint. Upon receipt of your complaint, we will endeavour to contact you with a view to amicably resolving such a complaint within the shortest possible time. The foregoing notwithstanding, all disputes arising from this policy shall first be resolved by amicable negotiation. However, if we fail to resolve the disputes amicably by such mutual consultations, both of us shall further attempt to resolve the dispute by Mediation through a mutually appointed mediator or by reference to the Lagos State Multi-Door Courthouse (“LMDC”).  The awards or decisions of LMDC shall be binding on both of us. Each of us shall bear our respective costs in connection with the mediation. 
                        </div>

                        <span className="privacy_policy-section-title">
                            Contacting our Data Protection Officer (DPO)
                        </span>

                        <div className="privacy_policy-paragraph">
                            If you have any questions relating to this Privacy Policy, complaints, or would like to find out more about exercising your data privacy rights, please reach out to our DPO via:
                        </div>

                        <div className="privacy_policy-contact-box">
                            <span>Physical address: please insert</span>
                            <span>Email at <span className="support-contact">support@loooty.com</span></span>
                        </div>
                    </div>
                </section>
            </main>

            <footer className="landing__footer">
                <Footer />
            </footer>
        </div>
    );
}

export default PrivacyPolicy;