import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { HiFolder } from "react-icons/hi";
import { MdFolderOpen } from "react-icons/md";

const MobileLibraryFilterDropdown = ({
  title,
  isActive,
  isFolder = false,
  containerStyle,
  onClick,
}) => {
  return (
    <div
      className="mobile-library_filter-dropdown"
      style={{
        ...containerStyle,
        backgroundColor: isActive ? "rgb(35, 34, 48)" : "#15151c",
        border: isActive ? "none" : "0.4px solid #6D6D91",
      }}
      onClick={onClick}
    >
      <div
        className="navbar__dropdown-filter-box"
        style={{
          display: "flex",
          color: isActive ? "#FF9700" : "#6D6D91",
          position: "relative",
          alignItems: "center",
        }}
      >
        {isFolder &&
          (isActive ? (
            <MdFolderOpen
              className="user__lib-folder-large"
              style={{ fontSize: "1.4rem", marginRight: "5px" }}
              onClick={onClick}
            />
          ) : (
            <HiFolder
              className="user__lib-folder-large not-active"
              style={{ fontSize: "1rem", marginRight: "5px" }}
              onClick={onClick}
            />
          ))}

        {title}

        {isActive ? (
          <MdArrowDropUp className="user__mobile-page-section-header-arrow-down" />
        ) : (
          <MdArrowDropDown className="user__mobile-page-section-header-arrow-down" />
        )}
      </div>
    </div>
  );
};

export default MobileLibraryFilterDropdown;
